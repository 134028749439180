type OptionsType = {
  translation?: string
  translationKey?: string | string[]
  exists: (key: string) => boolean
}

export function resolveTranslation(t: Function, options: OptionsType) {
  if (
    !options.translationKey ||
    (Array.isArray(options.translationKey) && !options.translationKey.length)
  ) return options.translation

  if (!Array.isArray(options.translationKey)) options.translationKey = [options.translationKey]

  return options.translationKey.map((key: string) => t(key)).join('\n')
}

export function getExistingTranslation(t: Function, options: OptionsType) {
  if (
    !options.translationKey || (Array.isArray(options.translationKey) && !options.translationKey.length)
  ) return options.exists(options.translation) ? options.translation : null

  if (!Array.isArray(options.translationKey)) options.translationKey = [options.translationKey]

  return options.translationKey.find((key: string) => options.exists(key))
}
