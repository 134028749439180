import { parseNumber, parsePercentage } from 'utils/numberParser'
import { convertFromBase } from 'utils/unitConverter'

import { _prefixShipmentLineItem } from 'reducers/shipments/shipmentsSlice'

import { columnDict as _shipmentColumnDict, getComplexColumns as shipmentGetComplexColumns } from './shipments'

const defaultOptions = {
  isColumnToggle: true,
  sortable: false,
  filter: false,
  isGlobalSearch: false,
}

const soVisibleColumns = [
  'formattedNumber',
  'status',
  'customer',
  'plannedDate',
  'expeditedDate',
  'poNumber',
]

const shipmentColumnDict = Object
  .keys(_shipmentColumnDict)
  .reduce(
    (acc, key) => {
      return {
        ...acc,
        [key]: {
          ..._shipmentColumnDict[key],
          hidden: !soVisibleColumns.includes(key),
          isColumnToggle: key !== 'formattedNumber',
        },
      }
    },
    {},
  )

export const prefixField = (field) => `${_prefixShipmentLineItem}.${field}`

const _invisibleOptions = { isGlobalSearch: true, isViewHidden: true, hidden: true }
export const columnDict = {
  ...shipmentColumnDict,
  [prefixField('primaryName')]: { field: prefixField('primaryName'), ..._invisibleOptions },
  [prefixField('secondaryName')]: { field: prefixField('secondaryName'), ..._invisibleOptions },
  [prefixField('manufacturingOrderTitle')]: { field: prefixField('manufacturingOrderTitle'), ..._invisibleOptions },
  [prefixField('manufacturingOrderType')]: { field: prefixField('manufacturingOrderType'), ..._invisibleOptions },
  [prefixField('manufacturingOrderSku')]: { field: prefixField('manufacturingOrderSku'), ..._invisibleOptions },
  [prefixField('manufacturingOrderPartNumber')]: {
    field: prefixField('manufacturingOrderPartNumber'),
    ..._invisibleOptions,
  },
  [prefixField('manufacturingOrderFormattedNumber')]: {
    field: prefixField('manufacturingOrderFormattedNumber'),
    ..._invisibleOptions,
  },
  [prefixField('salesOrderName')]: { field: prefixField('salesOrderName'), ..._invisibleOptions },
  [prefixField('templateSku')]: {
    field: prefixField('templateSku'),
    ...defaultOptions,
    sortable: true,
    filter: true,
    hidden: true,
  },
  [prefixField('templatePartNumber')]: {
    field: prefixField('templatePartNumber'),
    ...defaultOptions,
    sortable: true,
    filter: true,
    hidden: true,
  },

  [prefixField('displayNumber')]: { field: prefixField('displayNumber'), ...defaultOptions },
  [prefixField('displayDescription')]: { field: prefixField('displayDescription'), ...defaultOptions },

  [prefixField('ordered')]: { field: prefixField('ordered'), ...defaultOptions, sortable: true },
  [prefixField('picked')]: { field: prefixField('picked'), ...defaultOptions, sortable: true },

  [prefixField('toleranceUnderFactor')]: {
    field: prefixField('toleranceUnderFactor'),
    ...defaultOptions,
    sortable: true,
    filter: true,
    hidden: true,
  },
  [prefixField('toleranceOverFactor')]: {
    field: prefixField('toleranceOverFactor'),
    ...defaultOptions,
    sortable: true,
    filter: true,
    hidden: true,
  },

  [prefixField('toPrepareMin')]: { field: prefixField('toPrepareMin'), ...defaultOptions },
  [prefixField('toPrepare')]: { field: prefixField('toPrepare'), ...defaultOptions },
  [prefixField('toPrepareMax')]: { field: prefixField('toPrepareMax'), ...defaultOptions },

  [prefixField('grossWeight')]: { field: prefixField('grossWeight'), ...defaultOptions, hidden: true },
  [prefixField('theoreticalGrossWeight')]: {
    field: prefixField('theoreticalGrossWeight'),
    ...defaultOptions,
    hidden: true,
  },
  [prefixField('plannedGrossWeight')]: { field: prefixField('plannedGrossWeight'), ...defaultOptions, hidden: true },

  [prefixField('netWeight')]: { field: prefixField('netWeight'), ...defaultOptions, hidden: true },
  [prefixField('theoreticalNetWeight')]: {
    field: prefixField('theoreticalNetWeight'),
    ...defaultOptions,
    hidden: true,
  },
  [prefixField('plannedNetWeight')]: { field: prefixField('plannedNetWeight'), ...defaultOptions, hidden: true },
}

const addDefaultProps = (key) => columnDict[prefixField(key)]

const _measure = (value, unit, { measureDigits, culture }) => {
  const parsedNumber = parseNumber(value, measureDigits, culture)

  return `${parsedNumber} ${unit}`
}

const _weight = (value, unit, { measureDigits, culture }) => {
  const converted = convertFromBase('weight', +value, unit, true)

  return `${parseNumber(
    converted,
    measureDigits,
    culture,
  )} ${unit}`
}

const _percentage = (value, options) => {
  return parsePercentage(value/100, options.measureDigits, options.culture)
}

export function getComplexColumns(t, {
  measureDigits,
  culture,
  dateColumnTemplate,
  isTimeOnShipmentPlannedDate,
}) {
  const complexColumns = {
    [prefixField('primaryName')]: addDefaultProps(`primaryName`),
    [prefixField('secondaryName')]: addDefaultProps(`secondaryName`),
    [prefixField('manufacturingOrderTitle')]: addDefaultProps(`manufacturingOrderTitle`),
    [prefixField('manufacturingOrderType')]: addDefaultProps(`manufacturingOrderType`),
    [prefixField('manufacturingOrderSku')]: addDefaultProps(`manufacturingOrderSku`),
    [prefixField('manufacturingOrderPartNumber')]: addDefaultProps(`manufacturingOrderPartNumber`),
    [prefixField('manufacturingOrderFormattedNumber')]: addDefaultProps(`manufacturingOrderFormattedNumber`),
    [prefixField('salesOrderName')]: addDefaultProps(`salesOrderName`),
    [prefixField('templateSku')]: {
      ...addDefaultProps(`templateSku`),
      header: t('items:item.fields.sku.label'),
    },
    [prefixField('templatePartNumber')]: {
      ...addDefaultProps(`templatePartNumber`),
      header: t('items:item.fields.partNumber'),
    },
    [prefixField('displayNumber')]: {
      ...addDefaultProps('displayNumber'),
      header: t('shipments:shipment.item.number'),
    },
    [prefixField('displayDescription')]: {
      ...addDefaultProps('displayDescription'),
      header: t('shipments:shipment.item.description'),
    },

    [prefixField('ordered')]: {
      ...addDefaultProps('ordered'),
      header: t('shipments:shipment.item.ordered'),
      parseValue: (props) => _measure(
        convertFromBase(
          props[prefixField('dimension')],
          props[prefixField('ordered')],
          props[prefixField('unit')],
          false,
        ),
        props[prefixField('unit')],
        { measureDigits, culture },
      ),
    },
    [prefixField('picked')]: {
      ...addDefaultProps('picked'),
      header: t('shipments:shipment.item.picked'),
      parseValue: (props) => _measure(
        convertFromBase(
          props[prefixField('dimension')],
          props[prefixField('picked')],
          props[prefixField('unit')],
          false,
        ),
        props[prefixField('unit')],
        { measureDigits, culture },
      ),
    },

    [prefixField('toleranceOverFactor')]: {
      ...addDefaultProps('toleranceOverFactor'),
      header: t('shipments:shipment.item.toleranceOverFactor'),
      parseValue: (props) => _percentage(
        props[prefixField('toleranceOverFactor')],
        { measureDigits, culture },
      ),
    },
    [prefixField('toleranceUnderFactor')]: {
      ...addDefaultProps('toleranceUnderFactor'),
      header: t('shipments:shipment.item.toleranceUnderFactor'),
      parseValue: (props) => _percentage(
        props[prefixField('toleranceUnderFactor')],
        { measureDigits, culture },
      ),
    },

    [prefixField('toPrepareMin')]: {
      ...addDefaultProps('toPrepareMin'),
      header: t('shipments:shipment.item.toPrepareMin'),
      parseValue: (props) => _measure(
        convertFromBase(
          props[prefixField('dimension')],
          props[prefixField('toPrepareMin')],
          props[prefixField('unit')],
          false,
        ),
        props[prefixField('unit')],
        { measureDigits, culture },
      ),
    },
    [prefixField('toPrepare')]: {
      ...addDefaultProps('toPrepare'),
      header: t('shipments:shipment.item.toPrepare'),
      parseValue: (props) => _measure(
        convertFromBase(
          props[prefixField('dimension')],
          props[prefixField('toPrepare')],
          props[prefixField('unit')],
          false,
        ),
        props[prefixField('unit')],
        { measureDigits, culture },
      ),
    },
    [prefixField('toPrepareMax')]: {
      ...addDefaultProps('toPrepareMax'),
      header: t('shipments:shipment.item.toPrepareMax'),
      parseValue: (props) => _measure(
        convertFromBase(
          props[prefixField('dimension')],
          props[prefixField('toPrepareMax')],
          props[prefixField('unit')],
          false,
        ),
        props[prefixField('unit')],
        { measureDigits, culture },
      ),
    },
    [prefixField('theoreticalGrossWeight')]: {
      ...addDefaultProps('theoreticalGrossWeight'),
      header: t('shipments:shipment.item.theoreticalGrossWeight'),
      parseValue: (props) => _weight(
        props[prefixField('theoreticalGrossWeight')],
        props[prefixField('grossWeightUnit')],
        { measureDigits, culture },
      ),
    },
    [prefixField('plannedGrossWeight')]: {
      ...addDefaultProps('plannedGrossWeight'),
      header: t('shipments:shipment.item.plannedGrossWeight'),
      parseValue: (props) => _weight(
        props[prefixField('plannedGrossWeight')],
        props[prefixField('grossWeightUnit')],
        { measureDigits, culture },
      ),
    },
    [prefixField('grossWeight')]: {
      ...addDefaultProps('grossWeight'),
      header: t('shipments:shipment.item.pickedGrossWeight'),
      parseValue: (props) => _weight(
        props[prefixField('grossWeight')],
        props[prefixField('grossWeightUnit')],
        { measureDigits, culture },
      ),
    },

    [prefixField('theoreticalNetWeight')]: {
      ...addDefaultProps('theoreticalNetWeight'),
      header: t('shipments:shipment.item.theoreticalNetWeight'),
      parseValue: (props) => _weight(
        props[prefixField('theoreticalNetWeight')],
        props[prefixField('netWeightUnit')],
        { measureDigits, culture },
      ),
    },
    [prefixField('plannedNetWeight')]: {
      ...addDefaultProps('plannedNetWeight'),
      header: t('shipments:shipment.item.plannedNetWeight'),
      parseValue: (props) => _weight(
        props[prefixField('plannedNetWeight')],
        props[prefixField('netWeightUnit')],
        { measureDigits, culture },
      ),
    },
    [prefixField('netWeight')]: {
      ...addDefaultProps('netWeight'),
      header: t('shipments:shipment.item.pickedNetWeight'),
      parseValue: (props) => _weight(
        props[prefixField('netWeight')],
        props[prefixField('netWeightUnit')],
        { measureDigits, culture },
      ),
    },
  }

  const _complexShipmentColumns = shipmentGetComplexColumns(t, { dateColumnTemplate, isTimeOnShipmentPlannedDate })

  return {
    ...complexColumns,
    ..._complexShipmentColumns,
    name: { ..._complexShipmentColumns.name, hidden: true },
    carrier: { ..._complexShipmentColumns.carrier, hidden: true },
    city: { ..._complexShipmentColumns.city, hidden: true },
    province: { ..._complexShipmentColumns.province, hidden: true },
    cost: { ..._complexShipmentColumns.cost, hidden: true },
  }
}
