import { TFunction } from 'i18next'
import { DefaultRecord, getKeys } from 'types/utils'

import { mapColumns } from 'components/alix-front/smart-grid/columns/utils'

import { entities, EntityName } from 'utils/entities'

type Params<T> = {
  entityName: EntityName
  columnsToShow?: (keyof T)[]
  filterOutColumnsFunction?: (column: keyof T) => boolean
}

export const getSimpleColumnsInfo = <
T extends DefaultRecord,
>({ entityName, columnsToShow, filterOutColumnsFunction }: Params<T>) => {
  type K = keyof T

  const entity = entities[entityName]

  const fields = entity.getFields() as Record<K, any>

  const columnDict = getKeys(fields)
    .reduce((acc, field) => {
      if (columnsToShow && !columnsToShow.includes(field)) return acc
      else if (filterOutColumnsFunction && filterOutColumnsFunction(field)) return acc

      const fieldData = fields[field]

      if (fieldData.translationPath) {
        delete fieldData.translationPath
      }

      acc[field] = {
        field: field,
        sortable: true,
        filter: true,
        ...fields[field],
      }
      return acc
    }, {} as Record<K, any>)

  const getComplexColumns = (t, { fields, culture, simpleDict, exists, ...options }) => {
    const complexColumns = mapColumns(
      Object.values(simpleDict),
      { t, fields, entity: entity, exists, handlerOptions: {
        culture,
        useEntityTranslation: true,
        ...options,
      } },
    )

    return complexColumns.reduce((acc, column) => {
      acc[column.field] = column
      return acc
    }, {})
  }

  return {
    columnDict,
    getComplexColumns,
  }
}

export type PrefixedComplexColumnsParams = {
  prefix?: string
  formatHeader?: (
    translatedHeader: string,
    t: TFunction,
    prefixHeaders: boolean
  ) => string
  forceColumnOptions?: DefaultRecord
  prefixHeaders?: boolean
  overrideVisibleFields?: string[]
  forceIsViewHiddenFields?: string[]
}

export const baseComplexColumnHeader = (translationKey: string) => (
  columnHeader: string, t: TFunction, prefixHeaders: boolean,
) => {
  if (!prefixHeaders) return columnHeader

  return t(translationKey, { header: columnHeader, interpolation: { escapeValue: false } })
}

export const prefixFieldFromPrefix = (field: string, prefix?: string) => {
  if (!prefix) return field

  return `${prefix}${field}`
}

export const getPrefixedColumnsHelpers = ({
  prefix,
  formatHeader,
  forceColumnOptions = {},
  prefixHeaders = false,
  overrideVisibleFields = [],
  forceIsViewHiddenFields = [],
}: PrefixedComplexColumnsParams,
) => {
  /**
   * ! To access the field in the data set, we need to add the prefix to the field name
   */
  const prefixField = (field) => prefixFieldFromPrefix(field, prefix)

  const unPrefixField = (field) => {
    if (!prefix) return field

    return field.replace(prefix, '')
  }

  const prefixColumnDict = (
    columnDict: DefaultRecord,
  ) => {
    return Object
      .keys(columnDict)
      .reduce((acc, key) => {
        const prefixedKey = prefixField(key)

        if (forceIsViewHiddenFields.includes(prefixedKey)) return acc

        acc[prefixedKey] = {
          ...columnDict[key],
          ...forceColumnOptions,
          field: prefixedKey,
        }

        /**
         * If the array has fields, we take for granted that all other fields are hidden
         */
        if (overrideVisibleFields.length) {
          acc[prefixedKey].hidden = !overrideVisibleFields.includes(prefixedKey)
        }

        return acc
      }, {})
  }

  const prefixComplexColumns = (complexColumns, prefixedColumnDictm: DefaultRecord, { t }: {t: TFunction}) => {
    if (!prefix) {
      return complexColumns
    }

    const keys = Object.keys(prefixedColumnDictm)

    return Object
      .keys(complexColumns)
      .reduce(
        (acc, key) => {
          const prefixedKey = prefixField(key)

          if (!keys.includes(prefixedKey)) return acc

          acc[prefixedKey] = {
            ...complexColumns[key],
            field: prefixedKey,
            header: formatHeader?.(complexColumns[key].header, t, prefixHeaders) ?? complexColumns[key].header,
          }

          return acc
        },
        {},
      )
  }

  return {
    prefixColumnDict,
    prefixComplexColumns,
    prefixField,
    unPrefixField,
  }
}
