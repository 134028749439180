import equal from 'fast-deep-equal/react'
import moment from 'moment'

import { FilterOperator } from 'primereact/api'

import { COLUMN_TYPE_DICT, NO_VALUES_MATCH_MODES, FROM_MATCH_MODE_DICT, getDefaultConstraint } from './columns'

export function getGridReducer(dataHandler) {
  return (state, action) => {
    switch (action.type) {
    case 'updateMenuGrid': {
      if (action.payload === state.menuGrid) {
        return state
      }

      const newState = {
        ...state,
        menuGrid: action.payload,
      }
      if (equal(newState, state)) {
        return state
      }

      return newState
    }
    case 'updateColumns': {
      const newColumns = action.payload.map((newColumn) => ({
        ...state.columns.initial.find((i) => i.field === newColumn.field),
        ...newColumn,
      }))
      const newState = {
        ...state,
        columns: {
          ...state.columns,
          available: newColumns,
          displayed: newColumns.filter((column) =>
            typeof column.hiddenOverride === 'boolean' ? !column.hiddenOverride : !column.hidden,
          ),
          filterable: newColumns.filter((column) => !!column.filter),
        },
      }

      if (equal(newState, state)) {
        return state
      }

      return newState
    }
    case 'setDisplayedColumns': {
      const displayed = action.payload?.length > 0 ? action.payload : state.columns.displayed
      const available = state.columns.available.map((available) => ({
        ...available,
        hidden: !displayed.some((column) => column.field === available.field),
      }))
      const newState = {
        ...state,
        columns: { ...state.columns, available, displayed },
      }
      if (equal(newState, state)) {
        return state
      }

      return newState
    }
    case 'appendSort': {
      const newState = {
        ...state,
        sort: { ...state.sort, ...action.payload },
      }
      if (equal(newState, state)) {
        return state
      }

      return newState
    }
    case 'setGlobalFilter': {
      const newState = {
        ...state,
        filters: { ...state.filters, globalFilter: action.payload },
      }
      if (equal(newState, state)) {
        return state
      }

      return newState
    }
    case 'appendFilters': {
      const newState = {
        ...state,
        filters: {
          ...state.filters,
          filters: {
            ...state.filters.filters,
            ...action.payload,
          },
        },
      }
      if (equal(newState, state)) {
        return state
      }

      return newState
    }
    case 'setSearchParams': {
      const newState = { ...state, search: { ...state.search, params: action.payload } }
      if (equal(newState, state)) {
        return state
      }

      return newState
    }
    case 'setRerouteSearch': {
      const search = _getSearchState(action.payload, state.columns.filterable)
      const newState = {
        ...state,
        isReroute: true,
        search,
        sort: _getSortState(search),
      }
      if (equal(newState, state)) {
        return state
      }

      return newState
    }
    case 'resetReroute': {
      const newState = { ...state, isReroute: false }
      if (equal(newState, state)) {
        return state
      }

      return newState
    }
    default:
      return state
    }
  }
}

export function getGridState({ columns, sort, isSearchUrl, query, menuGrid, fields }) {
  const initialColumns = columns.map((column) => {
    if (!column.fieldInfo) {
      const fieldInfo = fields?.[column.field]
      return { ...column, fieldInfo, dataType: COLUMN_TYPE_DICT[fieldInfo?.type] }
    }

    return column
  })
  const columnsState = _getColumnsState(initialColumns)
  const search = isSearchUrl ?
    _getSearchState(query, columnsState.filterable) :
    { params: {}, filters: _getDefaultFilters(columnsState.filterable) }
  return {
    query,
    isSearchUrl,
    filters: _getFiltersState(search),
    search,
    sort: _getSortState(search, sort),
    columns: { initial: initialColumns, ...columnsState },
    isReroute: false,
    menuGrid,
  }
}

const _getSearchState = (query, filterColumns) => {
  const params = {}
  const filters = _getDefaultFilters(filterColumns)

  for (const [key, value] of query) {
    const keyInfo = key.split('_')
    const filterColumn = filterColumns.find((column) => (column.filterField || column.field) === keyInfo[0])
    if (key === 'viewId') {
      params.viewId = value
    } else if (key === 'globalFilter') {
      params.globalFilter = value
    } else if (key === 'sortField') {
      params.sortField = value
    } else if (key === 'sortOrder') {
      params.sortOrder = value
    } else if (filterColumn) {
      params[key] = value

      const matchModeAbbreviation = keyInfo[1]
      const _value = (value || '').split(',')
      if (matchModeAbbreviation === 'in') {
        if (_value.filter((v) => !!v).length > 0) {
          filters[keyInfo[0]] = { value: _value, matchMode: matchModeAbbreviation }
        }
      } else {
        const constraints = _value
          .map((filterValue, index) => {
            let value = filterValue
            if (filterColumn.dataType === 'date') {
              value = !!Date.parse(filterValue) ? new Date(moment(filterValue).utc()) : ''
            }
            return {
              value,
              matchMode: FROM_MATCH_MODE_DICT[index === 0 ? matchModeAbbreviation : keyInfo[index + 2]],
            }
          })
          .filter((constraint) =>
            !!constraint.matchMode && (!!constraint.value || NO_VALUES_MATCH_MODES.includes(constraint.matchMode)),
          )
        if (constraints.length > 0) {
          filters[keyInfo[0]] = { constraints, operator: keyInfo[2] || FilterOperator.AND }
        }
      }
    }
  }

  return { params, filters }
}

const _getDefaultFilters = (filterColumns) => {
  const defaultFilters = {}

  filterColumns.forEach((column) => {
    const constraint = getDefaultConstraint(column)
    if (column.filterElement?.type === 'multiselect') {
      defaultFilters[column.filterField || column.field] = constraint
    } else {
      defaultFilters[column.filterField || column.field] = {
        constraints: [constraint],
        operator: FilterOperator.AND,
      }
    }
  })
  return defaultFilters
}

const _getFiltersState = (search) => {
  return {
    globalFilter: search.params.globalFilter || '',
    filters: search.filters,
  }
}

const _getSortState = (search, sort) => {
  return {
    sortField: search.params.sortField || sort?.sortField,
    sortOrder: search.params.sortOrder || sort?.sortOrder,
  }
}

const _getColumnsState = (columns = []) => {
  const filterable = columns.filter((column) => !!column.filter)
  const fields = columns.map((column) => column.field)

  return {
    available: columns,
    fields,
    displayed: columns.filter((column) => !column.hidden && fields.includes(column.field)),
    filterable,
  }
}
