import React from 'react'

import SmartStatus from 'components/alix-front/smart-status/SmartStatus'

const defaultOptions = {
  sortable: true,
  filter: true,
  isGlobalSearch: true,
}

const getReceptionDateColumn = (t, hideHours, dateColumnTemplate) => {
  const field = 'receptionDate'
  const simple = { field, sortable: true, filter: true }
  if (typeof t != 'function') {
    return simple
  }

  return {
    ...simple,
    header: t('receptions:reception.fields.receptionDate.label'),
    template: (props) =>
      dateColumnTemplate( props.receptionDate, hideHours),
  }
}

export const columnDict = {
  reception: { field: 'reception', isDefaultSort: true, defaultSortOrder: -1, ...defaultOptions },
  reference: { field: 'reference', ...defaultOptions },
  status: { field: 'status' },
  receptionDate: getReceptionDateColumn(),
  carrier: { field: 'carrier', ...defaultOptions },
  vendor: { field: 'vendor', ...defaultOptions },
}

export function getComplexColumns(t, { dateColumnTemplate, culture, hideHours }) {
  return {
    reception: { header: t('receptions:reception.fields.reception.label') },
    reference: { header: t('receptions:reception.fields.reference.label') },
    status: {
      header: t('receptions:reception.fields.status.label'),
      template: (props) => {
        if (!props.status) {
          return null
        }

        return (
          <SmartStatus
            status={props.status}
            dictionaryKey="reception"
          />
        )
      },
    },
    receptionDate: getReceptionDateColumn(t, hideHours, dateColumnTemplate),
    carrier: { header: t('receptions:reception.fields.carrier.label') },
    vendor: {
      header: t('receptions:reception.fields.vendor.label'),
    },
  }
}
