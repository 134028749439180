import { createSlice } from '@reduxjs/toolkit'

import { buildGetUrl } from 'utils/api'
import { isJob, safeFetchJson } from 'utils/safeFetch'

import { ReceptionItemApi, getLineItemFields, parseReceptionItem } from './receptionsSlice'

type ItemsCountResult = {count: number}

const initialState = {
  data: [],
  count: 0,
  fields: getLineItemFields(),
}

const receptionsItemsSlice = createSlice({
  name: 'receptionLineItems',
  initialState,
  reducers: {
    setReceptionItems: (state, action) => {
      state.data = action.payload.data
    },
    setReceptionItemsCount: (state, action) => {
      state.count = action.payload.count
    },
  },
})

export async function fetchReceptionItemsbyIds(ids: (string|number)[], data: Record<string, any>) {
  if (!ids?.length) return []
  const { isSuccess, result } = await safeFetchJson<ReceptionItemApi>(`/new_api/receptions/items/${ids}`)
  return isSuccess && Array.isArray(result) ?
    result.map((receptionItem) => parseReceptionItem(receptionItem, data)) :
    []
}

export const { setReceptionItems, setReceptionItemsCount } = receptionsItemsSlice.actions

export function fetchReceptionItems(data: Record<string, any> = {}, mapData: Record<string, any> = {}) {
  return async function() {
    const { isSuccess, result } = await safeFetchJson<ReceptionItemApi>(
      buildGetUrl(`/new_api/receptions/items`, data),
    )

    return isSuccess && Array.isArray(result) ? result.map((lineItem) => parseReceptionItem(lineItem, mapData)) : []
  }
}

const getCount = (result: ItemsCountResult|ItemsCountResult[]) => {
  result = Array.isArray(result) ? result[0] : result

  return result?.count || 0
}

export function fetchReceptionItemsCount(data: Record<string, any> = {}) {
  return async function(dispatch) {
    const { isSuccess, result } = await safeFetchJson<ItemsCountResult>(
      buildGetUrl(`/new_api/receptions/items/count`, data),
    )

    const count = isSuccess && !isJob(result) ? getCount(result) : 0

    dispatch(setReceptionItemsCount({ count }))

    return count
  }
}

export default receptionsItemsSlice.reducer
