import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SmartStatus from 'components/alix-front/smart-status/SmartStatus'

const defaultOptions = {
  sortable: false,
  filter: false, // TODO (slalancette) : #DEV-I752 - Implement reorder with filtered rows
  isGlobalSearch: true,
}

const securityColumnTemplate = (props) => {
  return (
    <div className="a-smart-security-groups">
      {props.securityGroups.map((securityGroup) => (
        <SmartStatus
          key={securityGroup}
          className='a-smart-status-default a-smart-security-group'
          override={
            {
              title: securityGroup,
            }
          }
        />
      ))}
    </div>
  )
}

const nameColumnTemplate = (props) => {
  return (
    <div className="a-align-center a-smart-name-container">
      <FontAwesomeIcon
        className='a-smart-icon-container'
        icon={props.iconClass}
      />
      <span>
        {props.name}
      </span>
    </div>
  )
}

const iconColumnTemplate = (props) => {
  return (
    <div className="a-align-center a-smart-icon-class-container">
      <FontAwesomeIcon
        className='a-smart-icon-container'
        icon={props.iconClass}
      />
      <span>
        {props.iconClass}
      </span>
    </div>
  )
}

export const columnDict = {
  name: {
    field: 'name',
    className: 'a-dashboard-name',
    ...defaultOptions,
    template: nameColumnTemplate,
  },
  url: { field: 'url', className: 'a-dashboard-url', ...defaultOptions },
  iconClass: {
    field: 'iconClass',
    ...defaultOptions,
    viewTemplate: iconColumnTemplate,
    hidden: true,
  },
  securityGroups: {
    field: 'securityGroups',
    className: 'a-dashboard-security-groups',
    template: securityColumnTemplate,
    filterElement: { type: 'multiSelect' },
    ...defaultOptions,
  },
  rank: {
    field: 'rank',
    isDefaultSort: true,
    ...defaultOptions,
    hidden: true,
  },
}

export function getComplexComlumns(t, { securityGroupsOptions = [], simpleDict }) {
  return {
    ...simpleDict,
    securityGroups: {
      ...simpleDict.securityGroups,
      filterElement: {
        type: 'multiSelect',
        options: securityGroupsOptions.map((securityGroup) => ({
          value: securityGroup.value,
          label: securityGroup.name,
        })),
      },
    },
  }
}
