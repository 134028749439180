import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputText } from 'primereact/inputtext'

import './style.css'

type SmartGlobalFilterProps = {
  value: string
  placeholder?: string
  onClear: () => void
  onEnter?: () => void
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (ev: React.FocusEvent<HTMLInputElement, Element>) => void
}

export type SmartGlobalFilterRef = {
  inputRef: React.MutableRefObject<any>
}

const SmartGlobalFilter = forwardRef<SmartGlobalFilterRef, SmartGlobalFilterProps>(
  ({ value, placeholder, onClear, onChange, onEnter, onBlur }, ref) => {
    const { t } = useTranslation()

    const inputRef = useRef(null)

    useImperativeHandle<SmartGlobalFilterRef, SmartGlobalFilterRef>(ref, () => ({ inputRef }), [])

    return (
      <span className="a-smart-global-filter p-input-icon-left">
        <FontAwesomeIcon icon={['fas', 'magnifying-glass']} />
        <FontAwesomeIcon
          icon={['fas', 'xmark']}
          onClick={onClear}
        />
        <InputText
          ref={inputRef}
          value={value}
          title={value}
          onChange={onChange}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') onEnter?.()
          }}
          onBlur={onBlur}
          placeholder={placeholder || t('common:filter')}
          onFocus={(ev) => ev.target.select()}
        />
      </span>
    )
  })

SmartGlobalFilter.displayName = 'SmartGlobalFilter'
export default SmartGlobalFilter
