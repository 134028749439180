import React, { useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SmartMenu from 'components/alix-front/smart-menu/SmartMenu'

type WrapperOptions = {
  active: boolean
  className?: string
}

type SmartMoreActionsProps = {
  actions: any[]
  className?: string
  menuClassName?: string
  wrapperOptions?: WrapperOptions
};

function SmartMoreActions({ actions, className, menuClassName, wrapperOptions }: SmartMoreActionsProps) {
  const { t } = useTranslation()
  const menuRef = useRef<any>(null)

  const _moreActions = useMemo(() => (
    <>
      <div
        className={className}
        onClick={(event) => menuRef.current.toggle(event)}
      >
        <div>
          {t('common:button.moreActions')}
        </div>
        <FontAwesomeIcon icon={['fas', 'chevron-down']} />
      </div>
      <SmartMenu
        ref={menuRef}
        className={menuClassName}
        actions={actions}
      />
    </>
  ), [t, className, menuClassName, actions])

  if (actions.length <= 0) return null

  return wrapperOptions?.active ? (
    <div className={wrapperOptions.className}>
      {_moreActions}
    </div>
  ) : _moreActions
}

SmartMoreActions.displayName = 'SmartMoreActions'
export default SmartMoreActions
