import { mapColumns } from 'components/alix-front/smart-grid/columns/utils'

import { entities } from 'utils/entities'

const defaultOptions = {
  sortable: true,
  filter: true,
  isGlobalSearch: true,
}

export const columnDict = {
  code: { field: 'code', isColumnToggle: false, ...defaultOptions },
  description: { field: 'description', ...defaultOptions },
}

export function getComplexColumns(t, { fields, simpleDict }) {
  const complexColumns = mapColumns(
    Object.values(simpleDict),
    { t, fields, entity: entities['incoterms'], handlerOptions: { useEntityTranslation: true } },
  )

  return complexColumns.reduce((acc, column) => {
    acc[column.field] = column
    return acc
  }, {})
}
