import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import moment from 'moment'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { dateToDisplay } from 'utils/dateParser'

import './style.css'

type EmailIndicatiorProps = {
  emailingDetails: {
    sent_date?: string
    delivered_date?: string
    open_date?: string
    bounced_date?: string
    complained_date?: string
  }
  language: string
}

function EmailIndicator({ emailingDetails, language }: EmailIndicatiorProps) {
  const { t } = useTranslation(['emails'])

  const dateOptions = useMemo(() => ({ language, showTime: true }), [language])

  const isShow = useMemo(() => {
    return !!emailingDetails?.sent_date
  }, [emailingDetails?.sent_date])

  const icon = useMemo(() => {
    if (emailingDetails?.open_date) {
      return 'envelope-open'
    }
    return 'envelope'
  }, [emailingDetails?.open_date])

  const className = useMemo(() => {
    const classNames = ['a-email-indicator']
    if (emailingDetails?.bounced_date || emailingDetails?.complained_date) {
      classNames.push('a-red')
    }
    return classNames.join(' ')
  }, [emailingDetails?.bounced_date, emailingDetails?.complained_date])

  const title = useMemo(() => {
    const lines = []
    if (emailingDetails?.sent_date) {
      lines.push(t('emails:event.emailSent', {
        date: dateToDisplay(moment(emailingDetails.sent_date), dateOptions),
      }))
    }

    if (emailingDetails?.delivered_date) {
      lines.push(t('emails:event.emailDelivered', {
        date: dateToDisplay(moment(emailingDetails.delivered_date), dateOptions),
      }))
    }
    if (emailingDetails?.open_date) {
      lines.push(t('emails:event.emailOpened', {
        date: dateToDisplay(moment(emailingDetails.open_date), dateOptions),
      }))
    }
    if (emailingDetails?.bounced_date) {
      lines.push(t('emails:event.emailBounced', {
        date: dateToDisplay(moment(emailingDetails.bounced_date), dateOptions),
      }))
    }
    if (emailingDetails?.complained_date) {
      lines.push(t('emails:event.emailComplained', {
        date: dateToDisplay(moment(emailingDetails.complained_date), dateOptions),
      }))
    }
    return lines.join('\n')
  }, [t, emailingDetails, dateOptions])

  return (
    isShow ?
      <FontAwesomeIcon
        icon={icon}
        title={title}
        className={className}
      /> : null
  )
}

EmailIndicator.displayName = 'EmailIndicator'
export default EmailIndicator
