import React from 'react'

import EmailIndicator from 'components/alix-front/email-indicator/EmailIndicator'
import SmartStatus from 'components/alix-front/smart-status/SmartStatus'

const statusColumnFilterTemplate = (option, type) => (
  <SmartStatus
    className="a-smart-grid-icon-info"
    status={option.value}
    dictionaryKey="purchaseOrder"
    type={type}
  />
)

const statusColumnTemplate = (props, type) => (
  <SmartStatus
    className="a-smart-grid-icon-info"
    status={props[type]}
    dictionaryKey="purchaseOrder"
    type={type}
  />
)

const getDeliveryTypeColumn = (t, template) => {
  const simple = {
    field: 'deliveryType',
    sortable: true,
    filter: true,
    hidden: true,
    filterElement: { type: 'multiselect' },
  }

  if (typeof t != 'function') {
    return simple
  }

  return {
    header: t('purchaseOrders:purchaseOrder.fields.deliveryType.deliveryType'),
    filterElement: {
      ...simple.filterElement,
      options: [
        { label: t('purchaseOrders:purchaseOrder.fields.deliveryType.supplier_pickup'), value: 'supplier_pickup' },
        { label: t('purchaseOrders:purchaseOrder.fields.deliveryType.dropship'), value: 'dropship' },
        { label: t('purchaseOrders:purchaseOrder.fields.deliveryType.plant'), value: 'plant' },
      ],
    },
    template,
  }
}

const getStatusColumn = (t, itemTemplate, template) => {
  const simple = { field: 'status', sortable: true, filter: true, filterElement: { type: 'multiselect' } }

  if (typeof t != 'function') {
    return simple
  }

  return {
    header: t('purchaseOrders:purchaseOrder.fields.status'),
    filterElement: {
      ...simple.filterElement,
      options: [
        { label: t('purchaseOrders:status.draft'), value: 'draft' },
        { label: t('purchaseOrders:status.issued'), value: 'issued' },
        { label: t('purchaseOrders:status.closed'), value: 'closed' },
        { label: t('purchaseOrders:status.canceled'), value: 'canceled' },
      ],
      itemTemplate,
    },
    template,
  }
}

const getReceivingStatusColumn = (t, itemTemplate, template) => {
  const simple = { field: 'receivingStatus', sortable: true, filter: true, filterElement: { type: 'multiselect' } }

  if (typeof t != 'function') {
    return simple
  }

  return {
    header: t('purchaseOrders:purchaseOrder.fields.receivingStatus'),
    filterElement: {
      ...simple.filterElement,
      options: [
        { label: t('purchaseOrders:receivingStatus.to_receive'), value: 'to_receive', type: 'receivingStatus' },
        {
          label: t('purchaseOrders:receivingStatus.partially_received'),
          value: 'partially_received',
          type: 'receivingStatus',
        },
        { label: t('purchaseOrders:receivingStatus.received'), value: 'received', type: 'receivingStatus' },
      ],
      itemTemplate,
    },
    template,
  }
}

const getBillingStatusColumn = (t, itemTemplate, template) => {
  const simple = { field: 'billingStatus', sortable: true, filter: true, filterElement: { type: 'multiselect' } }

  if (typeof t != 'function') {
    return simple
  }

  return {
    header: t('purchaseOrders:purchaseOrder.fields.billingStatus'),
    filterElement: {
      ...simple.filterElement,
      options: [
        { label: t('purchaseOrders:billingStatus.not_billed'), value: 'not_billed', type: 'billingStatus' },
        {
          label: t('purchaseOrders:billingStatus.partially_billed'),
          value: 'partially_billed',
          type: 'billingStatus',
        },
        { label: t('purchaseOrders:billingStatus.billed'), value: 'billed', type: 'billingStatus' },
      ],
      itemTemplate,
    },
    template,
  }
}

const getPaymentStatusColumn = (t, itemTemplate, template) => {
  const simple = { field: 'paymentStatus', sortable: true, filter: true, filterElement: { type: 'multiselect' } }

  if (typeof t != 'function') {
    return simple
  }

  return {
    header: t('purchaseOrders:purchaseOrder.fields.paymentStatus'),
    filterElement: {
      ...simple.filterElement,
      options: [
        { label: t('purchaseOrders:paymentStatus.payment_pending'), value: 'payment_pending', type: 'paymentStatus' },
        { label: t('purchaseOrders:paymentStatus.partially_paid'), value: 'partially_paid', type: 'paymentStatus' },
        { label: t('purchaseOrders:paymentStatus.paid'), value: 'paid', type: 'paymentStatus' },
      ],
      itemTemplate,
    },
    template,
  }
}

export const columnDict = {
  'poNumber': { field: 'poNumber', sortable: true, filter: true, isGlobalSearch: true, isColumnToggle: false },
  'status': getStatusColumn(),
  'receivingStatus': getReceivingStatusColumn(),
  'billingStatus': getBillingStatusColumn(),
  'paymentStatus': getPaymentStatusColumn(),
  'vendorName': { field: 'vendorName', sortable: true, filter: true, isGlobalSearch: true },
  'deliveryType': getDeliveryTypeColumn(),
  'shipTo.street': {
    field: 'shipTo.street',
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    parseValue: (props) => props.shipTo.street,
  },
  'referenceNumber': { field: 'referenceNumber', sortable: true, filter: true, isGlobalSearch: true },
  'orderDate': { field: 'orderDate', sortable: true, filter: true },
  'expectedDeliveryDate': { field: 'expectedDeliveryDate', sortable: true, filter: true, hidden: true },
  'ownerName': { field: 'ownerName', sortable: true, filter: true, isGlobalSearch: true, hidden: true },
  'plantName': { field: 'plantName', sortable: true, filter: true, hidden: true },
  'createdDate': { field: 'createdDate', sortable: true, filter: true, hidden: true },
  'createdBy': { field: 'createdBy', sortable: true, filter: true, hidden: true },
  'modifiedDate': { field: 'modifiedDate', sortable: true, filter: true, hidden: true },
  'modifiedBy': { field: 'modifiedBy', sortable: true, filter: true, hidden: true },
}

export function getComplexColumns(t, { dateColumnTemplate, language = 'fr' }) {
  return {
    'poNumber': {
      header: t('purchaseOrders:purchaseOrder.fields.purchaseOrder'),
      template: (props) => {
        if (!props.id) {
          return null
        }
        return (
          <div>
            { props.poNumber }
            <EmailIndicator
              emailingDetails={props.emailingDetails}
              language={language}
            />
          </div>
        )
      },
    },
    'status': getStatusColumn(
      t,
      (option) => statusColumnFilterTemplate(option, 'status'),
      (props) => statusColumnTemplate(props, 'status'),
    ),
    'receivingStatus': getReceivingStatusColumn(
      t,
      (option) => statusColumnFilterTemplate(option, 'receivingStatus'),
      (props) => props.status != 'draft' && props.status != 'canceled' ?
        statusColumnTemplate(props, 'receivingStatus') :
        undefined,
    ),
    'billingStatus': getBillingStatusColumn(
      t,
      (option) => statusColumnFilterTemplate(option, 'billingStatus'),
      (props) => props.status != 'draft' && props.status != 'canceled' ?
        statusColumnTemplate(props, 'billingStatus') :
        null,
    ),
    'paymentStatus': getPaymentStatusColumn(
      t,
      (option) => statusColumnFilterTemplate(option, 'paymentStatus'),
      (props) => props.status != 'draft' && props.status != 'canceled' && props.paymentStatus ?
        statusColumnTemplate(props, 'paymentStatus') :
        null,
    ),
    'vendorName': { header: t('purchaseOrders:purchaseOrder.fields.vendor') },
    'deliveryType': getDeliveryTypeColumn(t, (props) => (
      t(`purchaseOrders:purchaseOrder.fields.deliveryType.${props.deliveryType}`)
    )),
    'shipTo.street': { header: t('purchaseOrders:purchaseOrder.fields.deliverTo') },
    'referenceNumber': { header: t('purchaseOrders:purchaseOrder.fields.referenceNumber') },
    'orderDate': {
      header: t('purchaseOrders:purchaseOrder.fields.orderDate'),
      template: (props) => dateColumnTemplate(props.orderDate, true),
    },
    'expectedDeliveryDate': {
      header: t('purchaseOrders:purchaseOrder.fields.expectedDeliveryDate'),
      template: (props) => dateColumnTemplate(props.expectedDeliveryDate, true),
    },
    'ownerName': { header: t('purchaseOrders:purchaseOrder.fields.owner') },
    'plantName': { header: t('purchaseOrders:purchaseOrder.plant') },
    'createdDate': {
      header: t('purchaseOrders:purchaseOrder.fields.createdDate'),
      template: (props) => dateColumnTemplate(props.createdDate),
    },
    'createdBy': { header: t('purchaseOrders:purchaseOrder.fields.createdBy') },
    'modifiedDate': {
      header: t('purchaseOrders:purchaseOrder.fields.modifiedDate'),
      template: (props) => dateColumnTemplate(props.modifiedDate),
    },
    'modifiedBy': { header: t('purchaseOrders:purchaseOrder.fields.modifiedBy') },
  }
}
