import { AppDispatch } from 'store'
import { ApiToSlice, BaseEntityApi, GetFields, Modify } from 'types/slices'
import { v4 as uuid } from 'uuid'

import { buildGetUrl, parse } from 'utils/api'
import { dataToFormData, formDataToArray } from 'utils/mapperHelper'
import { isJob, parseError, safeFetch, safeFetchJson } from 'utils/safeFetch'

import { InventoryLedger, InventoryLedgerApi, parseInventoryLedger } from 'reducers/inventories/inventoriesSlice'
import { SalesOrderApi } from 'reducers/sales-orders/types'

import {
  GET_CONSIGNMENT_ITEMS,
  UPDATE_CONSIGNMENT_ITEMS,
  SET_IS_CREATE,
  RESET_FORM,
  SET_CONSIGNMENT_LINE_ITEMS_FORM_SELECTION,
  DUPLICATE_CONSIGNMENT_LINE_ITEMS_FROM_SELECTION,
  DELETE_CONSIGNMENT_LINE_ITEMS_FROM_SELECTION,
  SET_CONSIGNMENT_LINE_ITEMS_FORM,
  SAVE_CONSIGNMENT_LINE_ITEMS_FORM,
} from './types'

export type LineItemsFormState = {
  selections: Record<string, any>[],
  insertions: Record<string, any>,
  updates: Record<string, any>,
  deletions: Record<string, any>,
}

type ConsignmentItemsSaveData = {
  insertions: Record<string, any>[],
  updates: Record<string, any>[],
  deletions: string[],
}

const dataSetName = 'consignmentItemView'
const itemFields = getLineItemFields()
const initialState = {
  dataSetName,
  fields: {},
  itemFields,
  consignmentItems: [],
  activeForm: getDefaultForm(),
  activeConsignmentItem: getDefaultConsignmentItem(),
  duplicateConsignmentItem: null,
}

function getDefaultForm() {
  return {
    isCreate: false,
    hasChanges: false,
    hasLineItemsChanges: false,
    isValid: false,
    resetCount: 0,
    global: {},
    lineItems: buildInitialLineItemsFormState(),
  }
}

export default function consignmentItemsReducer(state = initialState, action: any) {
  const { payload } = action
  switch (action.type) {
  case GET_CONSIGNMENT_ITEMS: {
    return buildConsignmentItemState(state, payload)
  }
  case SET_IS_CREATE: {
    const lineItemsForm = state.duplicateConsignmentItem ?
      buildInitialLineItemsFormState(state.duplicateConsignmentItem.lineItems, true) :
      state.activeForm.lineItems

    const activeConsignmentItem = state.duplicateConsignmentItem ?
      state.duplicateConsignmentItem : state.activeConsignmentItem

    const newActiveForm = {
      ...state.activeForm,
      isCreate: payload,
      lineItems: lineItemsForm,
      isValid: isFormValid(lineItemsForm),
    }

    return {
      ...state,
      duplicateConsignmentItem: null,
      activeConsignmentItem,
      activeForm: {
        ...newActiveForm,
        hasChanges: hasChanges(newActiveForm.global),
        hasLineItemsChanges: hasLineItemsFormChanges(newActiveForm.lineItems),
      },
    }
  }
  case RESET_FORM: {
    const lineItemsForm = buildInitialLineItemsFormState(
      state.activeForm.isCreate ? undefined : state.activeConsignmentItem.lineItems,
    )

    const newActiveForm = {
      ...state.activeForm,
      isValid: isFormValid(lineItemsForm),
      resetCount: state.activeForm.resetCount + 1,
      lineItems: lineItemsForm,
    }

    return {
      ...state,
      activeForm: {
        ...newActiveForm,
        hasChanges: hasChanges(newActiveForm.global),
        hasLineItemsChanges: hasLineItemsFormChanges(newActiveForm.lineItems),
      },
    }
  }
  case SAVE_CONSIGNMENT_LINE_ITEMS_FORM: {
    const form = state.activeForm.lineItems
    let consignmentItems = state.consignmentItems

    const deletedIds = Object.keys(form.deletions)
    if (deletedIds.length) {
      consignmentItems = state.consignmentItems.filter((consignmentItem) => !deletedIds.includes(consignmentItem.id))
    }

    payload.forEach((upsert) => {
      const existingIndex = consignmentItems.findIndex((item) => item.id === upsert.id)
      if (existingIndex !== -1) {
        consignmentItems[existingIndex] = { ...consignmentItems[existingIndex], ...upsert }
      } else {
        consignmentItems.push(upsert)
      }
    })

    return buildConsignmentItemState(state, consignmentItems)
  }
  case SET_CONSIGNMENT_LINE_ITEMS_FORM: {
    return buildLineItemsFormState(state, payload)
  }
  case SET_CONSIGNMENT_LINE_ITEMS_FORM_SELECTION: {
    return buildLineItemsFormState(state, { ...state.activeForm.lineItems, selections: payload })
  }
  case DUPLICATE_CONSIGNMENT_LINE_ITEMS_FROM_SELECTION: {
    const newInsertions = { ...state.activeForm.lineItems.insertions }
    const itemFields = getLineItemFields(false, true)
    state.activeForm.lineItems.selections.forEach((selection) => {
      const newInsertionId = uuid()
      newInsertions[newInsertionId] = dataToFormData(
        { ...selection, id: newInsertionId },
        itemFields,
        true,
      )

      const toDuplicate = (
        state.activeForm.lineItems.insertions[selection.id] ||
        state.activeForm.lineItems.updates[selection.id] ||
        {}
      )

      Object.keys(toDuplicate)
        .filter((key) => key !== 'id' && key !== 'isGlobalChange')
        .forEach((key) => {
          newInsertions[newInsertionId][key] = { ...toDuplicate[key], isChanged: !itemFields[key]?.skip }
        })
    })
    return buildLineItemsFormState(state, { ...state.activeForm.lineItems, selections: [], insertions: newInsertions })
  }
  case DELETE_CONSIGNMENT_LINE_ITEMS_FROM_SELECTION: {
    const newInsertions = { ...state.activeForm.lineItems.insertions }
    const newDeletions = { ...state.activeForm.lineItems.deletions }
    state.activeForm.lineItems.selections.forEach((selection) => {
      if (newInsertions[selection.id]) {
        delete newInsertions[selection.id]
      } else {
        newDeletions[selection.id] = selection
      }
    })

    return buildLineItemsFormState(state, {
      ...state.activeForm.lineItems,
      selections: [],
      insertions: newInsertions,
      deletions: newDeletions,
    })
  }
  case UPDATE_CONSIGNMENT_ITEMS: {
    return buildConsignmentItemState(state, payload)
  }
  default: {
    return state
  }
  }
}

type MapData = {
  defaultUnits?: {
    qty: string
    weight: string
    length: string
    surface: string
    volume: string
  }
}

export type ConsignmentItemApi = Modify<{
  overwritten_name: string
  overwritten_notes: string
  contract_number: string
  expected_delivery_date: Date
  supplier_part_number: string
  customer_display_name: string
  sales_order_item_id: string
  sales_order_id: SalesOrderApi['id']
  sales_order_name: SalesOrderApi['name']
  sales_order_status: SalesOrderApi['status']
  sales_order_date: SalesOrderApi['sales_order_date']
  measure: number
  unit: string
  template_dimension: string
  template_unit: string
  received_count: number
  conversion_factor: number
  primary_name: string
  secondary_name: string
  primary_notes: string
  secondary_notes: string
  inventories: InventoryLedgerApi[]
  rank: number
  template_id: string
  template_sku: string
  template_title: string
  template_secondary_description: string
  material_title: string
  treatment_title: string
  category_title: string
  raw_imperial_title: string
  raw_metric_title: string
  template_part_number: string
  template_revision: string
  template_manufacturer: string
  ship_to_attention: string
}, BaseEntityApi>

type Exceptions = {
  supplier_part_number: 'partNumber'
  material_title: 'templateMaterial'
  treatment_title: 'templateTreatment'
  category_title: 'templateCategory'
  raw_imperial_title: 'templateRawImperial'
  raw_metric_title: 'templateRawMetric'
}

export type ConsignmentItem = ApiToSlice<
  Modify<ConsignmentItemApi, {
    name: string
    notes: string
    inventories: InventoryLedger[]
    inventory_count: number
  }>,
  Exceptions
>

export function getLineItemFields(
  editOnly?: boolean,
  skipDuplicate?: boolean,
): GetFields<ConsignmentItemApi, ConsignmentItem, MapData> {
  function getTemplateUnit(lineItem: ConsignmentItemApi, options: MapData) {
    const dimension = lineItem?.template_dimension
    const defaultUnits = options?.defaultUnits || {}

    return lineItem?.template_unit || defaultUnits[dimension]
  }

  function getUnit(lineItem: ConsignmentItemApi, options: MapData) {
    return +lineItem?.conversion_factor > 0 ? lineItem?.unit || '' : getTemplateUnit(lineItem, options)
  }

  function getInventoryCount(inventories = []) {
    return new Set(inventories.map((inventory) => inventory.id)).size
  }

  function _parseItemName(item: ConsignmentItemApi, isPrimaryLanguage: boolean) {
    return item?.overwritten_name || (
      isPrimaryLanguage ? item?.primary_name || item?.secondary_name : item?.secondary_name || item?.primary_name
    )
  }

  function _parseItemNotes(item: ConsignmentItemApi, isPrimaryLanguage: boolean) {
    return item?.overwritten_notes || (
      isPrimaryLanguage ? item?.primary_notes || item?.secondary_notes : item?.secondary_notes || item?.primary_notes
    )
  }

  function parseItemName(item: ConsignmentItemApi) {
    return _parseItemName(item, true)
  }

  function parseItemNotes(item: ConsignmentItemApi) {
    return _parseItemNotes(item, true)
  }

  const fields: GetFields<ConsignmentItemApi, ConsignmentItem> = {
    id: { dataSetName, dbField: 'id', type: 'string' },
    exist: { dataSetName, dbField: 'exist', type: 'boolean' },
    name: { dataSetName, dbField: 'overwritten_name', isEdit: true, type: 'string', parse: parseItemName },
    overwrittenName: { dataSetName, dbField: 'overwritten_name', type: 'string' },
    notes: { dataSetName, dbField: 'overwritten_notes', isEdit: true, type: 'text', parse: parseItemNotes },
    overwrittenNotes: { dataSetName, dbField: 'overwritten_notes', type: 'text' },
    contractNumber: { dataSetName, dbField: 'contract_number', isEdit: true },
    expectedDeliveryDate: {
      dataSetName,
      dbField: 'expected_delivery_date',
      isEdit: true,
      type: 'date',
      isTimezoned: false,
    },
    partNumber: { dataSetName, dbField: 'supplier_part_number', isEdit: true, type: 'string' },
    customerDisplayName: { dataSetName, dbField: 'customer_display_name', type: 'string' },
    salesOrderItemId: { dataSetName, dbField: 'sales_order_item_id', type: 'string' },
    salesOrderId: { dataSetName, dbField: 'sales_order_id', type: 'string' },
    salesOrderName: { dataSetName, dbField: 'sales_order_name', type: 'string' },
    salesOrderStatus: { dataSetName, dbField: 'sales_order_status', type: 'status', dictionaryKey: 'salesOrder' },
    salesOrderDate: { dataSetName, dbField: 'sales_order_date', type: 'date', isTimezoned: false },
    measure: { dataSetName, dbField: 'measure', isEdit: true, formDefaultValue: 0, type: 'measure' },
    unit: { dataSetName, dbField: 'unit', isEdit: true, parse: getUnit },
    receivedCount: {
      dataSetName,
      dbField: 'received_count',
      type: 'measure',
      parse: (data) => data.received_count ?? 0,
    },
    inventories: { parse: (lineItem, options) => (lineItem?.inventories || []).map((inventory) => {
      return { ...parseInventoryLedger(inventory, options), relationId: inventory.relation_id }
    }) },
    inventoryCount: { parse: (lineItem) => getInventoryCount(lineItem?.inventories) },
    createdDate: { dataSetName, dbField: 'created_date', type: 'date' },
    createdBy: { dataSetName, dbField: 'created_by', type: 'string' },
    createdById: { dataSetName, dbField: 'created_by_id', type: 'id' },
    modifiedDate: { dataSetName, dbField: 'modified_date', type: 'date' },
    modifiedBy: { dataSetName, dbField: 'modified_by', type: 'string' },
    modifiedById: { dataSetName, dbField: 'modified_by_id', type: 'id' },
    rank: { dataSetName, dbField: 'rank', isEdit: true, type: 'integer' },
    templateId: { dataSetName, dbField: 'template_id', isEdit: true, type: 'id', relationEntity: 'items' },
    templateSku: { dataSetName, dbField: 'template_sku', type: 'string' },
    templateTitle: { dataSetName, dbField: 'template_title', type: 'string' },
    templateSecondaryDescription: { dataSetName, dbField: 'template_secondary_description', type: 'string' },
    templateMaterial: { dataSetName, dbField: 'material_title', type: 'string' },
    templateTreatment: { dataSetName, dbField: 'treatment_title', type: 'string' },
    templateCategory: { dataSetName, dbField: 'category_title', type: 'string' },
    templateRawImperial: { dataSetName, dbField: 'raw_imperial_title', type: 'string' },
    templateRawMetric: { dataSetName, dbField: 'raw_metric_title', type: 'string' },
    templatePartNumber: { dataSetName, dbField: 'template_part_number', type: 'string' },
    templateRevision: { dataSetName, dbField: 'template_revision', type: 'string' },
    templateManufacturer: { dataSetName, dbField: 'template_manufacturer', type: 'string' },
    templateDimension: { dataSetName, dbField: 'template_dimension' },
    templateUnit: { dataSetName, dbField: 'template_unit', parse: getTemplateUnit },
    shipToAttention: { dataSetName, dbField: 'ship_to_attention', type: 'string' },
  }

  let fieldsToReturn = Object.keys(fields)
  let _toSkip = []
  if (editOnly) {
    fieldsToReturn = Object.keys(fields).filter((key) => fields[key].isEdit)
    fieldsToReturn.push(...[
      'id', 'salesOrderItemId', 'templateTitle', 'unit', 'templateUnit', 'templateDimension',
      'inventories', 'inventoryCount', 'receivedCount',
    ])
  } else if (skipDuplicate) {
    _toSkip = [
      'createdDate', 'createdBy', 'createdById', 'modifiedDate', 'modifiedBy', 'modifiedById', 'templateTitle',
      'templateSecondaryDescription', 'templateMaterial', 'templateTreatment', 'templateCategory', 'templateSku',
      'templateRawImperial', 'templateRawMetric', 'templatePartNumber', 'templateRevision', 'templateManufacturer',
      'exist', 'rank', 'salesOrderId', 'salesOrderName', 'salesOrderStatus', 'salesOrderDate', 'receivedCount',
      'customerDisplayName', 'overwrittenName', 'overwrittenNotes', 'inventories', 'inventoryCount',
      'templateUnit', 'templateDimension',
    ]
  }

  return fieldsToReturn.reduce((acc, key) => {
    const newAcc = { ...acc }
    newAcc[key] = fields[key]
    if (_toSkip.includes(key)) {
      newAcc[key].skip = true
    }
    return newAcc
  }, {})
}

export function getConsignmentItemTitle(consignmentItem) {
  return consignmentItem.name
}

export function fetchConsignmentItems(data: Record<string, any>, mapData?: Record<string, any>) {
  return async function fetchConsignmentItemsThunk(dispatch: AppDispatch) {
    const consignmentItems = await _fetchConsignmentItems(data, mapData)
    dispatch({ type: GET_CONSIGNMENT_ITEMS, payload: consignmentItems })
    return consignmentItems
  }
}

async function _fetchConsignmentItems(data: Record<string, any>, mapData?: Record<string, any>) {
  let consignmentItems = []

  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/consignments', data))).json()
    if (result.isSuccess) {
      consignmentItems = result.result.map((consignmentItem: Record<string, any>) =>
        parseConsignmentItem(consignmentItem, mapData),
      )
    }
  } catch (err) {
    console.error(err)
  }

  return consignmentItems
}

export async function fetchConsignmentItemsByIds(
  ids: any[],
  data?: Record<string, any>,
  mapData?: Record<string, any>,
) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson<Record<string, any>>(
    buildGetUrl(`/new_api/consignments/${ids}`, data),
  )

  return isSuccess && !isJob(result) ?
    result.map((consignmentItem) => parseConsignmentItem(consignmentItem, mapData)) :
    []
}

export function parseConsignmentItem(consignmentItem: Record<string, any>, mapData: Record<string, any> = {}) {
  const options = {
    ...mapData,
    defaultData: getDefaultConsignmentItem(),
    fields: itemFields,
    dataSetName,
  }

  return parse(consignmentItem, options)
}

export function saveConsignmentItemsForm(lineItemsForm, mapData?: Record<string, any>) {
  const convertedUpdates = formDataToArray(lineItemsForm.updates, true, true, true)
  const updatesWithChanges = convertedUpdates.filter((update) =>
    Object.keys(update).length > 1, // check if update contains more fields than just 'id'
  )

  const consignmentItemSaveData: ConsignmentItemsSaveData = {
    insertions: formDataToArray(lineItemsForm.insertions, true, true, false),
    updates: updatesWithChanges,
    deletions: Object.keys(lineItemsForm.deletions),
  }

  return saveConsignmentItems(consignmentItemSaveData, mapData)
}

function saveConsignmentItems(consignmentItemsSaveData: ConsignmentItemsSaveData, mapData?: Record<string, any>) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ consignmentItems: consignmentItemsSaveData }),
  }

  return async function saveConsignmentItemsThunk(dispatch) {
    try {
      const result = await (await safeFetch(buildGetUrl(`/new_api/consignments/save/`), requestOptions)).json()
      const updatedItems = result.isSuccess ? result.result : []
      const payload = updatedItems ?
        updatedItems.map((updatedItem) => parseConsignmentItem(updatedItem, mapData)) :
        null
      const error = !result.isSuccess ? result.result : null

      dispatch({ type: SAVE_CONSIGNMENT_LINE_ITEMS_FORM, payload, error })
      return { isSuccess: result.isSuccess, result }
    } catch (error) {
      dispatch({ type: SAVE_CONSIGNMENT_LINE_ITEMS_FORM, error })
      return { isSuccess: false, result: error }
    }
  }
}

export function updateConsignmentItems(consignmentItems, mapData?: Record<string, any>) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ consignmentItems }),
  }

  return async function updateConsignmentItemsThunk(dispatch) {
    try {
      const result = await (await safeFetch(buildGetUrl(`/new_api/consignments/batch/`), requestOptions)).json()
      const updatedItems = result.isSuccess ? result.result : []
      const payload = updatedItems ?
        updatedItems.map((updatedItem) => parseConsignmentItem(updatedItem, mapData)) :
        null
      const error = !result.isSuccess ? result.result : null

      dispatch({ type: UPDATE_CONSIGNMENT_ITEMS, payload, error })
      return { isSuccess: result.isSuccess, result }
    } catch (error) {
      dispatch({ type: UPDATE_CONSIGNMENT_ITEMS, error })
      return { isSuccess: false, result: error }
    }
  }
}

export async function adjustLineItemInventories(consignmentItemId, splitDict, mergeDict, deltaDict) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ splitDict, mergeDict, deltaDict }),
  }

  try {
    return await (await safeFetch(
      `/new_api/consignments/${consignmentItemId}/inventories/adjust`,
      requestOptions,
    )).json()
  } catch (error) {
    return parseError(error)
  }
}

export function updateLineItemsFormSelections(selections) {
  return async function updateLineItemsFormSelectionsThunk(dispatch) {
    dispatch({ type: SET_CONSIGNMENT_LINE_ITEMS_FORM_SELECTION, payload: selections })
  }
}

export function duplicateFromSelection(dispatch) {
  dispatch({ type: DUPLICATE_CONSIGNMENT_LINE_ITEMS_FROM_SELECTION })
}

export function deleteFromSelection(dispatch) {
  dispatch({ type: DELETE_CONSIGNMENT_LINE_ITEMS_FROM_SELECTION })
}

export function updateLineItemsForm(newLineItemsForm) {
  return async function updateLineItemsFormThunk(dispatch) {
    dispatch({ type: SET_CONSIGNMENT_LINE_ITEMS_FORM, payload: newLineItemsForm })
  }
}

function getDefaultConsignmentItem() {
  return parse({}, { fields: itemFields })
}

function buildConsignmentItemState(state, payload) {
  if (!payload) {
    return state
  }

  const lineItemsForm = buildInitialLineItemsFormState(payload)

  const newActiveForm = {
    ...state.activeForm,
    isCreate: false,
    isValid: isFormValid(lineItemsForm),
    lineItems: lineItemsForm,
  }

  return {
    ...state,
    consignmentItems: payload,
    activeConsignmentItem: payload,
    activeForm: {
      ...newActiveForm,
      hasChanges: hasChanges(newActiveForm.global),
      hasLineItemsChanges: hasLineItemsFormChanges(newActiveForm.lineItems),
    },
  }
}

function buildLineItemsFormState(state, lineItems) {
  const newActiveForm = { ...state.activeForm, lineItems }
  return {
    ...state,
    activeForm: {
      ...state.activeForm,
      hasChanges: hasChanges(newActiveForm.global),
      hasLineItemsChanges: hasLineItemsFormChanges(newActiveForm.lineItems),
      isValid: isFormValid(lineItems),
      lineItems,
    },
  }
}

function buildInitialLineItemsFormState(lineItems?, isDuplicate = false): LineItemsFormState {
  return {
    selections: [],
    insertions: isDuplicate ? buildLineItemsFormData(lineItems, true) : {},
    updates: !isDuplicate ? buildLineItemsFormData(lineItems) : {},
    deletions: {},
  }
}

function buildLineItemsFormData(lineItems = [], isDuplicate = false) {
  const formData = {}

  lineItems.forEach((lineItem) => {
    formData[lineItem.id] = dataToFormData(lineItem, getLineItemFields(!isDuplicate, isDuplicate), isDuplicate)
    formData[lineItem.id].isGlobalChange = isDuplicate
  })

  return formData
}

function getLineItemsFormItems(lineItemsForm): unknown[] {
  const deletionIds = Object.keys(lineItemsForm.deletions)
  const insertions = Object.values(lineItemsForm.insertions)
  const updates = Object.keys(lineItemsForm.updates)
    .filter((id) => !deletionIds.includes(id))
    .map((id) => lineItemsForm.updates[id])

  return [...insertions, ...updates]
}

export function getLineItemsFormValueDict(lineItemsForm): any[] {
  return formDataToArray(getLineItemsFormItems(lineItemsForm), false, false, true)
}

function hasChanges(form): boolean {
  return Object.values(form).some((val: any) => val.isChanged)
}

function _hasLineItemsChanges(lineItem): boolean {
  return Object.values(lineItem).some((update) => hasChanges(update))
}

function hasLineItemsFormChanges(lineItemsForm): boolean {
  return !!Object.keys(lineItemsForm.insertions).length ||
    !!Object.keys(lineItemsForm.deletions).length ||
    _hasLineItemsChanges(lineItemsForm.updates)
}

function isFormValid(lineItemsForm) {
  const lineItemsFormItems = getLineItemsFormItems(lineItemsForm)
  return lineItemsFormItems.length > 0
}

export function templateToLineItem(id, item, lineItemFieldKeys, rank, removeFieldsAfterMap = false) {
  const fieldMapper = {
    name: ['description', 'secondaryDescription'],
    dimension: 'dimension',
    unit: 'measureUnit',
    measure: ['measure', 'purchaseMoq'],
    templateUnit: 'initialMeasureUnit',
    partNumber: 'partNumber',
    notes: ['primaryLongDescriptionPurchase', 'secondaryLongDescriptionPurchase'],
    expectedDeliveryDate: 'expectedDeliveryDate',
  }

  const fieldsToRemove = []
  const lineItemData = { id, rank }
  Object.keys(fieldMapper).forEach((field) => {
    const mapper = fieldMapper[field]
    if (removeFieldsAfterMap) {
      if (Array.isArray(mapper)) {
        fieldsToRemove.push(...mapper)
      } else {
        fieldsToRemove.push(mapper)
      }
    }
    lineItemData[field] = item[Array.isArray(mapper) ? mapper.find((m) => item[m]) : mapper] ?? null
  })
  Object.keys(item).forEach((key) => {
    const templateDataKey = `template${key.charAt(0).toUpperCase() + key.slice(1)}`
    if (lineItemFieldKeys.includes(templateDataKey)) {
      lineItemData[templateDataKey] = item[key]
      if (removeFieldsAfterMap) {
        fieldsToRemove.push(key)
      }
    }
  })
  fieldsToRemove.forEach((fieldToRemove) => delete item[fieldToRemove])

  return lineItemData
}
