import React from 'react'

import moment from 'moment'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SmartStatus from 'components/alix-front/smart-status/SmartStatus'

import { dateToDisplay } from 'utils/dateParser'
import { getDimensionIcon } from 'utils/iconMapper'
import { parseNumber, parseCurrency, parsePercentage } from 'utils/numberParser'
import { convertFromBase } from 'utils/unitConverter'

import { getBaseAvailableMeasure, getBaseNetFlow } from 'reducers/inventories/inventoriesSlice'

import DateColumn from './DateColumn'

const parseExcelDate = (date, includeHours) => Date.parse(date) ?
  dateToDisplay(moment(date), includeHours ? { format: 'DD/MM/YYYY HH:mm' } : { format: 'DD/MM/YYYY' }) :
  ''

const toggleColumn = (field, t) => {
  const simple = { field, sortable: true, filter: true, filterElement: { type: 'multiselect' } }

  if (typeof t != 'function') {
    return simple
  }

  return {
    ...simple,
    header: t([`inventories:inventory.fields.${field}.label`, `inventories:inventory.fields.${field}`]),
    filterElement: {
      ...simple.filterElement,
      options: [
        { label: t('common:activated'), value: 'true' },
        { label: t('common:deactivated'), value: 'false' },
      ],
    },
    parseValue: (props) => t(`common:${props[field] ? 'activated' : 'deactivated'}`),
  }
}

const getReceptionDateColumn = (t, hideHours, dateColumnTemplate) => {
  const simple = { field: 'receivedDate', sortable: true, filter: true }

  if (typeof t != 'function') {
    return simple
  }

  return {
    ...simple,
    header: t('inventories:inventory.fields.receivedDate.label'),
    template: (props) =>
      dateColumnTemplate(hideHours ? props.receivedDate : props.receivedDatetime, hideHours),
    parseExcel: (props) => parseExcelDate(hideHours ? props.receivedDate : props.receivedDatetime, !hideHours),
  }
}

export const columnDict = {
  item: { field: 'item', sortable: true, filter: true, isGlobalSearch: true, isColumnToggle: false },
  dimensionOrder: {
    field: 'dimensionOrder',
    sortable: true,
    filter: true,
    filterField: 'dimension',
    filterElement: { type: 'multiselect' },
  },
  status: {
    field: 'status',
    isColumnToggle: false,
    sortable: true,
    filter: true,
    filterElement: { type: 'multiselect' },
  },
  tag: { field: 'tag', sortable: true, filter: true, isGlobalSearch: true },
  sku: { field: 'sku', sortable: true, filter: true, isGlobalSearch: true },
  upc: { field: 'upc', sortable: true, filter: true, isGlobalSearch: true },
  ean: { field: 'ean', sortable: true, filter: true, isGlobalSearch: true },
  material: { field: 'material', sortable: true, filter: true, isGlobalSearch: true },
  category: { field: 'category', sortable: true, filter: true, isGlobalSearch: true },
  serialNumber: { field: 'serialNumber', sortable: true, filter: true, isGlobalSearch: true },
  description: { field: 'description', sortable: true, filter: true, isGlobalSearch: true },
  secondaryDescription: { field: 'secondaryDescription', sortable: true, filter: true, isGlobalSearch: true },
  rawMetric: { field: 'rawMetric', sortable: true, filter: true, isGlobalSearch: true },
  rawImperial: { field: 'rawImperial', sortable: true, filter: true, isGlobalSearch: true },
  partNumber: { field: 'partNumber', sortable: true, filter: true, isGlobalSearch: true },
  treatment: { field: 'treatment', sortable: true, filter: true, isGlobalSearch: true },
  revision: { field: 'revision', sortable: true, filter: true, isGlobalSearch: true },
  project: { field: 'project', sortable: true, filter: true, isGlobalSearch: true },
  plantName: { field: 'plantName', sortable: true, filter: true, isGlobalSearch: true },
  plannedUnitCost: { field: 'plannedUnitCost', sortable: true, className: 'a-right' },
  realUnitCost: { field: 'realUnitCost', sortable: true, className: 'a-right' },
  initialMeasure: { field: 'initialMeasure', sortable: true, className: 'a-right' },
  availableMeasure: { field: 'availableMeasure', sortable: true, className: 'a-right' },
  convertedOnOrderMeasure: { field: 'convertedOnOrderMeasure', sortable: true, className: 'a-right' },
  convertedReservedMeasure: { field: 'convertedReservedMeasure', sortable: true, className: 'a-right' },
  convertedNetFlowMeasure: { field: 'convertedNetFlowMeasure', sortable: true, className: 'a-right' },
  convertedCurrentMeasure: { field: 'convertedCurrentMeasure', sortable: true, className: 'a-right' },
  convertedGrossWeight: { field: 'convertedGrossWeight', sortable: true, className: 'a-right' },
  convertedTareWeight: { field: 'convertedTareWeight', sortable: true, className: 'a-right' },
  convertedNetWeight: { field: 'convertedNetWeight', sortable: true, className: 'a-right' },
  measureUnit: { field: 'measureUnit', sortable: true, filter: true, isGlobalSearch: true },
  convertedUnitWeight: { field: 'convertedUnitWeight', sortable: true, className: 'a-right' },
  expirationDate: { field: 'expirationDate', sortable: true, filter: true },
  invoiceDate: { field: 'invoiceDate', sortable: true, filter: true },
  invoice: { field: 'invoice', sortable: true, filter: true, isGlobalSearch: true },
  receivedDate: getReceptionDateColumn(),
  receptionNumber: { field: 'receptionNumber', sortable: true, filter: true, isGlobalSearch: true },
  receptionCarrier: { field: 'receptionCarrier', sortable: true, filter: true, isGlobalSearch: true },
  plannedTargetTag: { field: 'plannedTargetTag', sortable: true, filter: true },
  manufacturer: { field: 'manufacturer', sortable: true, filter: true, isGlobalSearch: true },
  location: { field: 'location', sortable: true, filter: true, isGlobalSearch: true },
  vendorName: { field: 'vendorName', sortable: true, filter: true, isGlobalSearch: true },
  vendorPartNumber: { field: 'vendorPartNumber', sortable: true, filter: true, isGlobalSearch: true },
  convertedVendorListPrice: { field: 'convertedVendorListPrice', sortable: true },
  vendorDiscount: { field: 'vendorDiscount', sortable: true },
  vendorCurrencySymbol: { field: 'vendorCurrencySymbol', sortable: true },
  vendorExchangeRate: { field: 'vendorExchangeRate', sortable: true },
  baseCost: { field: 'baseCost', sortable: true, className: 'a-right' },
  createdDate: { field: 'createdDate', sortable: true, filter: true },
  createdBy: { field: 'createdBy', sortable: true, filter: true, isGlobalSearch: true },
  modifiedDate: { field: 'modifiedDate', sortable: true, filter: true },
  modifiedBy: { field: 'modifiedBy', sortable: true, filter: true, isGlobalSearch: true },
  manufacturedDate: { field: 'manufacturedDate', sortable: true, filter: true },
  expectedDeliveryDate: { field: 'expectedDeliveryDate', sortable: true, filter: true },
  returnNumber: { field: 'returnNumber', sortable: true, filter: true, isGlobalSearch: true },
  returnDate: { field: 'returnDate', sortable: true, filter: true },
  isDropship: toggleColumn('isDropship'),
  groupName: { field: 'groupName', sortable: true, filter: true, isGlobalSearch: true },
  type: {
    field: 'type',
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    filterElement: { type: 'multiselect' },
  },
  inventoryManagementType: {
    field: 'inventoryManagementType',
    sortable: true,
    filter: true,
    filterElement: { type: 'multiselect' },
  },
  isSelling: toggleColumn('isSelling'),
  isManufactured: toggleColumn('isManufactured'),
  isPurchased: toggleColumn('isPurchased'),
  isBin: toggleColumn('isBin'),
  referenceNumber: {
    field: 'referenceNumber',
    sortable: true,
    filter: true,
    isGlobalSearch: true,
  },
  purchaseOrderItemContractNumber: {
    field: 'purchaseOrderItemContractNumber',
    sortable: true,
    filter: true,
    isGlobalSearch: true,
  },
}

export function getComplexColumns(
  t,
  {
    primaryLanguage,
    secondaryLanguage,
    culture,
    measureDigits,
    currencyCode,
    currencySymbol,
    dateColumnTemplate,
    simpleDict,
    hideHours,
    dataSetName,
    itemFullcardRef,
    options,
  },
) {
  const inventoryManagementTypes = {
    quantity_and_value: t('items:item.fields.inventoryManagementType.quantityAndValue'),
    quantity_only: t('items:item.fields.inventoryManagementType.quantityOnly'),
    not_managed: t('items:item.fields.inventoryManagementType.notManaged'),
  }

  const specificDataSetColumns = _getComplexColumnsByDataSetName(dataSetName, options)
  return {
    item: {
      header: t('inventories:inventory.fields.item.label'),
      hidden: false,
      template: (props) => {
        const iconData = getDimensionIcon(props.dimension)
        return (
          <div className="a-flex">
            <div className="a-inventory-grid-dimension-icon">
              <FontAwesomeIcon
                icon={['fas', iconData.icon]}
                transform={iconData.transform}
              />
            </div>
            <div
              className={`a-smart-grid-default-template ${!!itemFullcardRef ? 'a-clickable-link' : ''}`}
              title={props.item}
              onClick={() => !!itemFullcardRef? itemFullcardRef.current?.open('item', props.templateId) : null}
            >
              {props.item}
            </div>
          </div>
        )
      },
    },
    dimensionOrder: {
      header: t('inventories:inventory.fields.dimensionOrder'),
      hidden: false,
      filterElement: {
        ...simpleDict.dimensionOrder.filterElement,
        options: [
          { label: t('common:dimension.qty'), value: 'qty', dimension: 'qty' },
          { label: t('common:dimension.weight'), value: 'weight', dimension: 'weight' },
          { label: t('common:dimension.length'), value: 'length', dimension: 'length' },
          { label: t('common:dimension.surface'), value: 'surface', dimension: 'surface' },
          { label: t('common:dimension.volume'), value: 'volume', dimension: 'volume' },
        ],
        itemTemplate: (option) => {
          const iconData = getDimensionIcon(option.dimension)
          return (
            <div className="a-flex">
              <div className="a-inventory-grid-dimension-icon">
                <FontAwesomeIcon
                  icon={['fas', iconData.icon]}
                  transform={iconData.transform}
                />
              </div>
              <div>
                {option.label}
              </div>
            </div>
          )
        },
      },
      template: (props) => {
        if (!props.dimension) {
          return null
        }

        const iconData = getDimensionIcon(props.dimension)
        return (
          <div className="a-flex">
            <div className="a-inventory-grid-dimension-icon">
              <FontAwesomeIcon
                icon={['fas', iconData.icon]}
                transform={iconData.transform}
              />
            </div>
            <div
              className="a-smart-grid-default-template"
              title={t(`common:dimension.${props.dimension}`)}
            >
              {t(`common:dimension.${props.dimension}`)}
            </div>
          </div>
        )
      },
      parseExcel: (props) => t(`common:dimension.${props.dimension}`),
    },
    status: {
      header: t('inventories:inventory.fields.status.label'),
      hidden: false,
      filterElement: {
        ...simpleDict.status.filterElement,
        options: [
          { label: t('inventories:status.ready'), value: 'ready' },
          { label: t('inventories:status.stand-by'), value: 'stand-by' },
          { label: t('inventories:status.to-order'), value: 'to-order' },
          { label: t('inventories:status.ordered'), value: 'ordered' },
          { label: t('inventories:status.done'), value: 'done' },
          { label: t('inventories:status.merged'), value: 'merged' },
          { label: t('inventories:status.returned'), value: 'returned' },
          { label: t('inventories:status.to-return'), value: 'to-return' },
        ],
        itemTemplate: (option) => {
          return (
            <SmartStatus
              status={option.value}
              dictionaryKey="inventory"
            />
          )
        },
      },
      template: (props) => {
        if (!props.status) {
          return null
        }

        return (
          <SmartStatus
            status={props.status}
            dictionaryKey="inventory"
          />
        )
      },
      parseExcel: (props) => {
        const statusDict = {
          ready: t('inventories:status.ready'),
          stand_by: t('inventories:status.stand-by'),
          to_order: t('inventories:status.to-order'),
          ordered: t('inventories:status.ordered'),
          done: t('inventories:status.done'),
          merged: t('inventories:status.merged'),
          returned: t('inventories:status.returned'),
          to_return: t('inventories:status.to-return'),
        }
        return statusDict[props.status]
      },
    },
    tag: { header: t('inventories:inventory.fields.tag.label'), hidden: false },
    sku: { header: t('inventories:inventory.fields.sku') },
    upc: { header: t('inventories:inventory.fields.upc') },
    ean: { header: t('inventories:inventory.fields.ean') },
    material: { header: t('inventories:inventory.fields.material'), hidden: false },
    category: { header: t('inventories:inventory.fields.category') },
    serialNumber: { header: t('inventories:inventory.fields.serialNumber') },
    description: {
      header: `${t('inventories:inventory.fields.description.label')}${
        primaryLanguage ? ` (${primaryLanguage})` : ''
      }`,
      hidden: false,
    },
    secondaryDescription: {
      header: `${t('inventories:inventory.fields.description.label')}${
        secondaryLanguage ? ` (${secondaryLanguage})` : ''
      }`,
    },
    rawMetric: { header: t('inventories:inventory.fields.rawMetric') },
    rawImperial: { header: t('inventories:inventory.fields.rawImperial') },
    partNumber: { header: t('inventories:inventory.fields.partNumber'), hidden: false },
    treatment: { header: t('inventories:inventory.fields.treatment') },
    revision: { header: t('inventories:inventory.fields.revision') },
    project: { header: t('inventories:inventory.fields.project'), hidden: false },
    plantName: { header: t('inventories:inventory.fields.plant') },
    plannedUnitCost: {
      header: t('inventories:inventory.fields.plannedUnitCost'),
      parseValue: (props) => {
        return `${parseCurrency(
          props.plannedUnitCost,
          props.vendorCurrencyCode || currencyCode || undefined,
          props.vendorCurrencySymbol || currencySymbol || undefined,
          4,
          culture,
        )} / ${props.measureUnit}`
      },
      parseExcel: (props) => props.plannedUnitCost,
    },
    realUnitCost: {
      header: t('inventories:inventory.fields.realUnitCost.label'),
      parseValue: (props) => {
        return `${parseCurrency(
          props.realUnitCost,
          props.vendorCurrencyCode || currencyCode || undefined,
          props.vendorCurrencySymbol || currencySymbol || undefined,
          4,
          culture,
        )} / ${props.measureUnit}`
      },
      parseExcel: (props) => props.realUnitCost,
    },
    initialMeasure: {
      header: t('inventories:inventory.fields.initialMeasure'),
      parseValue: (props) => {
        return `${parseNumber(
          convertFromBase(props.dimension, props.initialMeasure, props.measureUnit, false),
          measureDigits,
          culture,
        )} ${props.measureUnit}`
      },
      parseExcel: (props) => props.convertedInitialMeasure,
    },
    availableMeasure: {
      header: t('inventories:inventory.fields.availableMeasure'),
      parseValue: (props) => {
        return `${parseNumber(
          convertFromBase(props.dimension, getBaseAvailableMeasure(props), props.measureUnit, false),
          measureDigits,
          culture,
        )} ${props.measureUnit}`
      },
      parseExcel: (props) => props.availableMeasure,
    },
    convertedCurrentMeasure: {
      header: t('inventories:inventory.fields.currentMeasure'),
      parseValue: (props) => {
        return `${parseNumber(
          convertFromBase(props.dimension, props.currentMeasure, props.measureUnit, false),
          measureDigits,
          culture,
        )} ${props.measureUnit}`
      },
      parseExcel: (props) => props.convertedCurrentMeasure,
    },
    convertedOnOrderMeasure: {
      header: t('inventories:inventory.fields.onOrderMeasure'),
      parseValue: (props) => {
        return `${parseNumber(
          convertFromBase(props.dimension, props.onOrderMeasure, props.measureUnit, false),
          measureDigits,
          culture,
        )} ${props.measureUnit}`
      },
      parseExcel: (props) => props.convertedOnOrderMeasure,
    },
    convertedReservedMeasure: {
      header: t('inventories:inventory.fields.reservedMeasure'),
      parseValue: (props) => {
        return `${parseNumber(
          convertFromBase(props.dimension, props.reservedMeasure, props.measureUnit, false),
          measureDigits,
          culture,
        )} ${props.measureUnit}`
      },
      parseExcel: (props) => props.convertedReservedMeasure,
    },
    convertedNetFlowMeasure: {
      header: t('inventories:inventory.fields.netFlowMeasure'),
      parseValue: (props) => {
        return `${parseNumber(
          convertFromBase(props.dimension, getBaseNetFlow(props), props.measureUnit, false),
          measureDigits,
          culture,
        )} ${props.measureUnit}`
      },
      parseExcel: (props) => props.convertedNetFlowMeasure,
    },
    convertedGrossWeight: {
      header: t('inventories:inventory.fields.grossWeight'),
      parseValue: (props) => {
        return `${parseNumber(
          props.convertedGrossWeight,
          measureDigits,
          culture,
        )} ${props.weightUnit}`
      },
      parseExcel: (props) => props.convertedGrossWeight,
    },
    convertedTareWeight: {
      header: t('inventories:inventory.fields.tareWeight'),
      parseValue: (props) => {
        return `${parseNumber(
          props.convertedTareWeight,
          measureDigits,
          culture,
        )} ${props.weightUnit}`
      },
      parseExcel: (props) => props.convertedTareWeight,
    },
    convertedNetWeight: {
      header: t('inventories:inventory.fields.netWeight'),
      parseValue: (props) => {
        return `${parseNumber(
          props.convertedNetWeight,
          measureDigits,
          culture,
        )} ${props.weightUnit}`
      },
      parseExcel: (props) => props.convertedNetWeight,
    },
    measureUnit: { header: t('inventories:inventory.fields.measureUnit') },
    convertedUnitWeight: {
      header: t('inventories:inventory.fields.unitWeight'),
      parseValue: (props) => {
        if (props.dimension != 'weight') {
          return `${parseNumber(
            props.convertedUnitWeight,
            measureDigits,
            culture,
          )} ${props.weightUnit} / ${props.measureUnit}`
        }
      },
      parseExcel: (props) => {
        if (props.dimension != 'weight') {
          return props.convertedUnitWeight
        }
      },
    },
    expirationDate: {
      header: t('inventories:inventory.fields.expirationDate'),
      template: (props) => {
        const className = new Date(props.expirationDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0) ?
          'a-red' : ''
        return (
          <div className={className}>
            <DateColumn
              date={props.expirationDate}
              hideHours
            />
          </div>
        )
      },
      parseExcel: (props) => parseExcelDate(props.expirationDate),
    },
    invoiceDate: {
      header: t('inventories:inventory.fields.invoiceDate.label'),
      template: (props) => dateColumnTemplate(props.invoiceDate, true),
      parseExcel: (props) => parseExcelDate(props.invoiceDate),
    },
    invoice: { header: t('inventories:inventory.fields.invoice.label') },
    receivedDate: getReceptionDateColumn(t, hideHours, dateColumnTemplate),
    receptionNumber: { header: t('inventories:inventory.fields.receptionNumber.label') },
    receptionCarrier: { header: t('inventories:inventory.fields.receptionCarrier.label') },
    plannedTargetTag: {
      header: t('inventories:inventory.fields.plannedTarget'),
      parseValue: (props) => {
        if (props.plannedTargetLocationCode) {
          return `${props.plannedTargetLocationCode} - ${props.plannedTargetTag}`
        } else {
          return props.plannedTargetTag
        }
      },
    },
    manufacturer: { header: t('inventories:inventory.fields.manufacturer') },
    location: {
      header: t('inventories:inventory.fields.location'),
    },
    vendorName: { header: t('inventories:inventory.fields.vendor') },
    vendorPartNumber: { header: t('inventories:inventory.fields.vendorPartNumber.label') },
    convertedVendorListPrice: {
      header: t('inventories:inventory.fields.vendorListPrice.label'),
      parseValue: (props) => {
        return `${parseCurrency(
          props.convertedVendorListPrice,
          props.vendorCurrencyCode || currencyCode || undefined,
          props.vendorCurrencySymbol || currencySymbol || undefined,
          4,
          culture,
        )}`
      },
      parseExcel: (props) => props.convertedVendorListPrice,
    },
    vendorDiscount: {
      header: t('inventories:inventory.fields.vendorDiscount.label'),
      parseValue: (props) => {
        return `${parsePercentage(
          props.vendorDiscount/100,
          2,
          culture,
        )}`
      },
    },
    vendorCurrencySymbol: { header: t('inventories:inventory.fields.vendorCurrency') },
    vendorExchangeRate: {
      header: t('inventories:inventory.fields.vendorExchangeRate.label'),
      parseValue: (props) => {
        return `${parseNumber(
          props.vendorExchangeRate,
          { minimumFractionDigits: 2, maximumFractionDigits: 8 },
          culture,
        )}`
      },
      parseExcel: (props) => props.vendorExchangeRate,
    },
    baseCost: {
      header: t('inventories:inventory.fields.vendorBaseCost'),
      parseValue: (props) => {
        return `${parseCurrency(
          props.baseCost,
          props.vendorCurrencyCode || currencyCode || undefined,
          props.vendorCurrencySymbol || currencySymbol || undefined,
          4,
          culture,
        )}`
      },
      parseExcel: (props) => props.baseCost,
    },
    createdDate: {
      header: t('inventories:inventory.fields.createdDate'),
      template: (props) => dateColumnTemplate(props.createdDate),
      parseExcel: (props) => parseExcelDate(props.createdDate),
    },
    createdBy: { header: t('inventories:inventory.fields.createdBy') },
    modifiedDate: {
      header: t('inventories:inventory.fields.modifiedDate'),
      template: (props) => dateColumnTemplate(props.modifiedDate),
      parseExcel: (props) => parseExcelDate(props.modifiedDate),
    },
    modifiedBy: {
      header: t('inventories:inventory.fields.modifiedBy'),
    },
    manufacturedDate: {
      header: t('inventories:inventory.fields.manufacturedDate'),
      template: (props) => dateColumnTemplate(props.manufacturedDate, true),
      parseExcel: (props) => parseExcelDate(props.manufacturedDate),
    },
    expectedDeliveryDate: {
      header: t('inventories:inventory.fields.deliveryDate'),
      hidden: false,
      template: (props) => dateColumnTemplate(props.expectedDeliveryDate, true),
      parseExcel: (props) => parseExcelDate(props.expectedDeliveryDate),
    },
    returnNumber: { header: t('inventories:inventory.fields.returnNumber') },
    returnDate: {
      header: t('inventories:inventory.fields.returnDate'),
      template: (props) => dateColumnTemplate(props.returnDate, true),
      parseExcel: (props) => parseExcelDate(props.returnDate),
    },
    isDropship: toggleColumn('isDropship', t),
    groupName: { header: t('inventories:inventory.fields.group') },
    type: {
      header: t('inventories:inventory.fields.type'),
      filterElement: {
        ...simpleDict.type.filterElement,
        options: [
          { label: t('items:item.fields.type.good'), value: 'good' },
          { label: t('items:item.fields.type.service'), value: 'service' },
          { label: t('items:item.fields.type.custom_service'), value: 'custom_service' },
        ],
      },
      parseValue: (props) => t(`items:item.fields.type.${props.type}`),
    },
    inventoryManagementType: {
      header: t('inventories:inventory.fields.inventoryManagementType'),
      filterElement: {
        ...simpleDict.inventoryManagementType.filterElement,
        options: [
          { label: inventoryManagementTypes.quantity_and_value, value: 'quantity_and_value' },
          { label: inventoryManagementTypes.quantity_only, value: 'quantity_only' },
          { label: inventoryManagementTypes.not_managed, value: 'not_managed' },
        ],
      },
      parseValue: (props) => inventoryManagementTypes[props.inventoryManagementType],
    },
    isSelling: toggleColumn('isSelling', t),
    isManufactured: toggleColumn('isManufactured', t),
    isPurchased: toggleColumn('isPurchased', t),
    isBin: toggleColumn('isBin', t),
    referenceNumber: { header: t('inventories:inventory.fields.referenceNumber') },
    purchaseOrderItemContractNumber: {
      header: t('inventories:inventory.fields.purchaseOrderItemContractNumber'),
    },
    ...specificDataSetColumns,
  }

  function _getComplexColumnsByDataSetName(dataSetName, options) {
    const columns = {}

    switch (dataSetName) {
    case 'negative_inventory_view':
      columns.convertedNegativeMeasure = {
        header: t('inventories:inventory.fields.negativeMeasure'),
        parseValue: (props) => {
          return `${parseNumber(
            props.convertedNegativeMeasure,
            measureDigits,
            culture,
          )} ${props.measureUnit}`
        },
        parseExcel: (props) => props.convertedNegativeMeasure,
        field: 'convertedNegativeMeasure',
        sortable: true,
        className: 'a-right',
      }
      break
    case 'inventory_balance_view':
      columns.convertedLedgerMeasureSum = {
        header: t('inventories:inventory.fields.ledgerMeasureSum'),
        parseValue: (props) => {
          return `${parseNumber(
            props.convertedLedgerMeasureSum,
            measureDigits,
            culture,
          )} ${props.measureUnit}`
        },
        parseExcel: (props) => props.convertedLedgerMeasureSum,
        field: 'convertedLedgerMeasureSum',
        sortable: true,
        className: 'a-right',
      }
      columns.convertedInventoryMeasureSum = {
        header: t('inventories:inventory.fields.inventoryMeasureSum'),
        parseValue: (props) => {
          return `${parseNumber(
            props.convertedInventoryMeasureSum,
            measureDigits,
            culture,
          )} ${props.measureUnit}`
        },
        parseExcel: (props) => props.convertedInventoryMeasureSum,
        field: 'convertedInventoryMeasureSum',
        sortable: true,
        className: 'a-right',
      }
      break
    case 'not_consumed_inventory_view':
      columns.convertedPlannedMeasure = {
        header: t('inventories:inventory.fields.plannedMeasure'),
        parseValue: (props) => {
          return `${parseNumber(
            convertFromBase(props.dimension, props.plannedMeasure, props.measureUnit, false),
            measureDigits,
            culture,
          )} ${props.measureUnit}`
        },
        parseExcel: (props) => props.convertedPlannedMeasure,
        field: 'convertedPlannedMeasure',
        sortable: true,
        className: 'a-right',
      }
      columns.convertedConsumedMeasure = {
        header: t('inventories:inventory.fields.consumedMeasure'),
        parseValue: (props) => {
          return `${parseNumber(
            convertFromBase(props.dimension, props.consumedMeasure, props.measureUnit, false),
            measureDigits,
            culture,
          )} ${props.measureUnit}`
        },
        parseExcel: (props) => props.convertedConsumedMeasure,
        field: 'convertedConsumedMeasure',
        sortable: true,
        className: 'a-right',
      }
      columns.itemNumber = {
        header: t('inventories:inventory.fields.itemNumber'),
        filter: true,
        sortable: true,
        field: 'itemNumber',
      }
      columns.itemTitle = {
        header: t('inventories:inventory.fields.itemTitle'),
        filter: true,
        sortable: true,
        field: 'itemTitle',
        template: (props, _1, _2, { iframedFullcard }) => (
          <span
            className="a-smart-grid-default-template a-clickable-link"
            title={props.itemTitle}
            onClick={() => iframedFullcard?.open('card', props.itemId)}
          >
            {props.itemTitle}
          </span>
        ),
      }
      columns.itemProjectTitle = {
        header: t('inventories:inventory.fields.itemProjectTitle'),
        filter: true,
        sortable: true,
        field: 'itemProjectTitle',
      }
      break
    }
    return columns
  }
}
