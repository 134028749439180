import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { MultiSelect } from 'primereact/multiselect'

function FilterMultiSelect({ className, options, onChange, onHide, itemTemplate, value, autoOpen, disabled }) {
  const { t } = useTranslation(['common'])
  const multiSelectRef = useRef(null)

  useEffect(() => {
    if (autoOpen) {
      setTimeout(() => multiSelectRef.current.show(), 100)
    }
  }, [autoOpen])

  return (
    <MultiSelect
      ref={multiSelectRef}
      className={className}
      panelClassName="a-smart-grid-multiselect-filter-panel"
      options={options}
      value={value}
      onChange={onChange}
      placeholder={t('common:all')}
      itemTemplate={itemTemplate}
      onHide={onHide}
      showClear
      disabled={disabled}
    />
  )
}

export default FilterMultiSelect
