import { Dispatch, SetStateAction, createContext } from 'react'

type State = {
  fetched: boolean,
  data: any[],
  preferenceId: any,
  selected: any,
  favorites: any[]
}

type Context = {
  state: State
  setState: Dispatch<SetStateAction<State>>
  viewMode: boolean
}

export default createContext({
  state: getInitialState(),
  setState: () => {},
  viewMode: false,
} as Context)

export function getInitialState(): State {
  return { fetched: false, data: [], preferenceId: null, selected: null, favorites: [] }
}
