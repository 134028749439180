export function getDimensionIcon(dimension) {
  switch (dimension) {
  case 'weight': {
    return { icon: 'scale-balanced' }
  }
  case 'length': {
    return { icon: 'arrows-left-right' }
  }
  case 'surface': {
    return { icon: 'maximize', transform: { rotate: 45 } }
  }
  case 'volume': {
    return { icon: 'flask' }
  }
  case 'qty':
  default: {
    return { icon: 'list-ol' }
  }
  }
}
