import Joi from 'joi'

import { SmartFormField as SmartFormFieldType } from 'reducers/smart-form/smartFormSlice'

/**
 * @param {Joi.Schema} schema
 * @return {number | undefined}
 */
export const getMaxLength = (schema) => {
  const max = schema.$_getRule('max')

  if (!max) return

  return max.args.limit
}

/**
 * @param {Joi.Schema} schema
 * @return {boolean | undefined}
 */
export const getIsRequired = (schema) => {
  return schema?._flags?.presence === 'required'
}

export const getInputTypeFromJoiSchema = (schema) => {
  return schema?.type
}

/**
 * @typedef {Object} SchemaInfo
 * @property {number} [maxLength]
 * @property {boolean} isRequired
 * @property {string} [type]
 */

/**
 *
 * @param {Record<string, Joi.Schema>} schema
 * @param {SmartFormFieldType} field
 * @return {SmartFormFieldType}
 */
export const parseSchema = (schema, field) => {
  const _schema = schema[field.name]

  if (!_schema) return field

  return {
    maxLength: getMaxLength(_schema) ?? undefined,
    isRequired: getIsRequired(_schema) ?? false,
    type: getInputTypeFromJoiSchema(_schema),
    ...field,
  }
}

/**
 * Schema for a url
 *
 * Ex:
 *  ✅ - https://www.google.com
 *  ✅ - google.com
 *  ❌ - google
 *
 * @param {(Joi.StringSchema) => Joi.StringSchema} subSchema - Sub schema to apply to the url schema
 * @returns {Joi.Schema}
 */
export const urlSchema = (subSchema = (schema) => schema) => {
  const base = Joi
    .string()
    .regex(/^(https?:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/)
  return subSchema(base)
}
