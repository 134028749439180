import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SmartStatus from 'components/alix-front/smart-status/SmartStatus'

import { getDimensionIcon } from 'utils/iconMapper'
import { parseNumber } from 'utils/numberParser'

const defaultOptions = {
  sortable: true,
  filter: true,
  isGlobalSearch: true,
}

const getReceptionDateColumn = (t, hideHours, dateColumnTemplate) => {
  const field = 'receptionDate'
  const simple = { field, sortable: true, filter: true }
  if (typeof t != 'function') {
    return simple
  }

  return {
    ...simple,
    header: t(`receptions:reception.fields.${field}.label`),
    template: (props) =>
      dateColumnTemplate( props.receptionDate, hideHours),
  }
}

const _invisibleOptions = { hidden: true, filter: true, sortable: true, isGlobalSearch: true }
export const columnDict = {
  receptionName: { field: 'receptionName', ...defaultOptions },
  receptionStatus: { field: 'receptionStatus' },
  receptionDate: getReceptionDateColumn(),
  receptionReference: { field: 'receptionReference', ...defaultOptions },
  receptionCarrier: { field: 'receptionCarrier', ..._invisibleOptions },
  vendor: { field: 'vendor', ...defaultOptions },
  inventoryInvoiceTitle: { field: 'inventoryInvoiceTitle', ..._invisibleOptions },
  inventoryInvoiceDate: { field: 'inventoryInvoiceDate', hidden: true, sortable: true, filter: true },
  convertedMeasure: { field: 'convertedMeasure', sortable: true },
  inventoryTag: { field: 'inventoryTag', ...defaultOptions },
  dimension: {
    field: 'dimension',
    filter: true,
    sortable: true,
    filterElement: { type: 'multiselect' },
  },
  inventoryStatus: {
    field: 'inventoryStatus',
    filter: true,
    sortable: true,
    filterElement: { type: 'multiselect' },
  },
  inventorySupplierPartNumber: { field: 'inventorySupplierPartNumber', ..._invisibleOptions },
  inventoryPartNumber: { field: 'inventoryPartNumber', ..._invisibleOptions },
  inventoryRevision: { field: 'inventoryRevision', ..._invisibleOptions },
  inventoryManufacturer: { field: 'inventoryManufacturer', ..._invisibleOptions },
  inventorySerialNumber: { field: 'inventorySerialNumber', ..._invisibleOptions },
  locationTitle: { field: 'locationTitle', ..._invisibleOptions },
  templateSku: { field: 'templateSku', ...defaultOptions },
  projectTitle: { field: 'projectTitle', ..._invisibleOptions },
  createdDate: { field: 'createdDate', hidden: true, sortable: true, filter: true },
  modifiedDate: { field: 'modifiedDate', hidden: true, sortable: true, filter: true },
}

const _measure = (value, unit, { measureDigits, culture }) => {
  const parsedNumber = parseNumber(value, measureDigits, culture)

  return `${parsedNumber} ${unit}`
}

const addDefaultProps = (key) => columnDict[key]

export function getComplexColumns(t, {
  dateColumnTemplate,
  measureDigits,
  culture,
  hideHours,
  simpleDict,
  options,
}) {
  return {
    receptionName: {
      ...addDefaultProps('receptionName'),
      header: t('receptions:reception.fields.reception.label'),
    },
    receptionStatus: {
      header: t('receptions:reception.fields.status.label'),
      template: (props) => {
        if (!props.receptionStatus) {
          return null
        }

        return (
          <SmartStatus
            status={props.receptionStatus}
            dictionaryKey="reception"
          />
        )
      },
    },
    receptionDate: getReceptionDateColumn(t, hideHours, dateColumnTemplate),
    receptionReference: {
      ...addDefaultProps('receptionReference'),
      header: t('receptions:reception.fields.reference.label'),
    },
    receptionCarrier: {
      ...addDefaultProps('receptionCarrier'),
      header: t('receptions:reception.fields.carrier.label'),
    },
    vendor: {
      ...addDefaultProps('vendor'),
      header: t('receptions:reception.fields.vendor.label'),
    },
    inventoryInvoiceTitle: {
      ...addDefaultProps('inventoryInvoiceTitle'),
      header: t('inventories:inventory.fields.invoiceTitle'),
    },
    inventoryInvoiceDate: {
      ...addDefaultProps('inventoryInvoiceDate'),
      header: t('inventories:inventory.fields.invoiceDate.label'),
      template: (props) => dateColumnTemplate(props.inventoryInvoiceDate, true),
    },
    convertedMeasure: {
      ...addDefaultProps('convertedMeasure'),
      header: t('receptions:reception.fields.convertedMeasure'),
      parseValue: (props) => _measure(
        props.convertedMeasure,
        props.measureUnit,
        { measureDigits, culture },
      ),
    },
    inventoryTag: {
      ...addDefaultProps('inventoryTag'),
      header: t('inventories:inventory.fields.tag.label'),
      template: (props, _1, _2, { iframedFullcard }) => {
        return (
          <div
            className='a-clickable-link a-smart-grid-default-template'
            onClick={() => iframedFullcard?.open('inventory', props.inventoryId)}
          >
            {props.inventoryTag}
          </div>
        )
      },
    },
    dimension: {
      header: t('items:item.fields.item'),
      filterElement: {
        ...simpleDict.dimension.filterElement,
        options: [
          { label: t('common:dimension.qty'), value: 'qty' },
          { label: t('common:dimension.weight'), value: 'weight' },
          { label: t('common:dimension.length'), value: 'length' },
          { label: t('common:dimension.surface'), value: 'surface' },
          { label: t('common:dimension.volume'), value: 'volume' },
        ],
        itemTemplate: (option) => {
          const iconData = getDimensionIcon(option.value)
          return (
            <div className="a-flex">
              <div className="a-item-grid-dimension-icon">
                <FontAwesomeIcon
                  icon={['fas', iconData.icon]}
                  transform={iconData.transform}
                />
              </div>
              <div>
                {option.label}
              </div>
            </div>
          )
        },
      },
      template: (props) => {
        const iconData = getDimensionIcon(props.dimension)
        return (
          <div className="a-flex">
            <div className="a-item-grid-dimension-icon">
              <FontAwesomeIcon
                icon={['fas', iconData.icon]}
                transform={iconData.transform}
              />
            </div>
            <div
              className="a-smart-grid-default-template"
              title={props.templateTitle}
            >
              {props.templateTitle}
            </div>
          </div>
        )
      },
    },
    inventoryStatus: {
      ...addDefaultProps('inventoryStatus'),
      header: t('receptions:reception.fields.inventoryStatus'),
      filterElement: {
        ...simpleDict.inventoryStatus.filterElement,
        options: [
          { label: t('inventories:status.ready'), value: 'ready' },
          { label: t('inventories:status.stand-by'), value: 'stand-by' },
          { label: t('inventories:status.to-order'), value: 'to-order' },
          { label: t('inventories:status.ordered'), value: 'ordered' },
          { label: t('inventories:status.done'), value: 'done' },
          { label: t('inventories:status.merged'), value: 'merged' },
          { label: t('inventories:status.returned'), value: 'returned' },
          { label: t('inventories:status.to-return'), value: 'to-return' },
        ],
        itemTemplate: (option) => {
          return (
            <SmartStatus
              status={option.value}
              dictionaryKey="inventory"
            />
          )
        },
      },
      template: (props) => {
        if (!props.inventoryStatus) {
          return null
        }

        return (
          <SmartStatus
            status={props.inventoryStatus}
            dictionaryKey="inventory"
          />
        )
      },
    },
    inventorySupplierPartNumber: {
      ...addDefaultProps('inventorySupplierPartNumber'),
      header: t('inventories:inventory.fields.supplierPartNumber'),
    },
    inventoryPartNumber: {
      ...addDefaultProps('inventoryPartNumber'),
      header: t('inventories:inventory.fields.partNumber'),
    },
    inventoryRevision: {
      ...addDefaultProps('inventoryRevision'),
      header: t('inventories:inventory.fields.revision'),
    },
    inventoryManufacturer: {
      ...addDefaultProps('inventoryManufacturer'),
      header: t('inventories:inventory.fields.manufacturer'),
    },
    inventorySerialNumber: {
      ...addDefaultProps('inventorySerialNumber'),
      header: t('inventories:inventory.fields.serialNumber'),
    },
    locationTitle: {
      ...addDefaultProps('locationTitle'),
      header: t('locations:location.fields.location'),
    },
    templateSku: {
      ...addDefaultProps(`templateSku`),
      header: t('items:item.fields.sku.label'),
    },
    projectTitle: {
      ...addDefaultProps('projectTitle'),
      header: t('inventories:inventory.fields.project'),
    },
    createdDate: {
      ...addDefaultProps('createdDate'),
      header: t('inventories:inventory.fields.createdDate'),
      template: (props) => dateColumnTemplate(props.createdDate, false),
    },
    modifiedDate: {
      ...addDefaultProps('modifiedDate'),
      header: t('inventories:inventory.fields.modifiedDate'),
      template: (props) => dateColumnTemplate(props.modifiedDate, false),
    },
  }
}
