import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  getPrefixedColumnsHelpers,
  baseComplexColumnHeader,
} from 'components/alix-front/legacy-smart-grid/columns/utils'
import SmartStatus from 'components/alix-front/smart-status/SmartStatus'

import { getDimensionIcon } from 'utils/iconMapper'
import {
  parseNumber,
  parseCurrency,
  parsePercentage,
} from 'utils/numberParser'
import { convertFromBase, convertFromDollarPerBase } from 'utils/unitConverter'

export const dimensionComplexColumn = (t, filterElement = { type: 'multiselect' }) => ({
  header: t('items:item.fields.dimension'),
  filterElement: {
    ...filterElement,
    options: [
      { label: t('common:dimension.qty'), value: 'qty' },
      { label: t('common:dimension.weight'), value: 'weight' },
      { label: t('common:dimension.length'), value: 'length' },
      { label: t('common:dimension.surface'), value: 'surface' },
      { label: t('common:dimension.volume'), value: 'volume' },
    ],
    itemTemplate: (option) => {
      const iconData = getDimensionIcon(option.value)
      return (
        <div className="a-flex">
          <div className="a-item-grid-dimension-icon">
            <FontAwesomeIcon
              icon={['fas', iconData.icon]}
              transform={iconData.transform}
            />
          </div>
          <div>
            {option.label}
          </div>
        </div>
      )
    },
  },
  template: (props) => {
    const iconData = getDimensionIcon(props.dimension)
    return (
      <div className="a-flex">
        <div className="a-item-grid-dimension-icon">
          <FontAwesomeIcon
            icon={['fas', iconData.icon]}
            transform={iconData.transform}
          />
        </div>
        <div
          className="a-smart-grid-default-template"
          title={t(`common:dimension.${props.dimension}`)}
        >
          {t(`common:dimension.${props.dimension || 'qty'}`)}
        </div>
      </div>
    )
  },
})

export const getPrefixedComplexColumns = ({
  prefix = undefined,
  forceColumnOptions = undefined,
  forceIsViewHiddenFields = [],
  prefixHeaders = false,
  overrideVisibleFields = [],
} = {}) => {
  const { prefixColumnDict, prefixComplexColumns, prefixField, unPrefixField } = getPrefixedColumnsHelpers({
    prefix,
    formatHeader: baseComplexColumnHeader('items:smartGridHeaderWithPrefix'),
    forceColumnOptions,
    prefixHeaders,
    overrideVisibleFields,
    forceIsViewHiddenFields,
  })

  const getStatusColumn = (itemTemplate, template, t) => {
    const simple = {
      field: 'status',
      sortable: true,
      filter: true,
      filterElement: { type: 'multiselect' },
    }

    if (typeof t != 'function') {
      return simple
    }

    return {
      ...simple,
      header: t('items:item.fields.status'),
      filterElement: {
        ...simple.filterElement,
        options: [
          { label: t('items:status.active'), value: 'active' },
          { label: t('items:status.inactive'), value: 'inactive' },
        ],
        itemTemplate,
      },
      template,
    }
  }

  const statusColumnFilterTemplate = (option) => (
    <SmartStatus
      className={
        option.value == 'active' ?
          'a-smart-status-green' :
          'a-smart-status-default'
      }
      status={option.value}
      dictionaryKey="items"
    />
  )

  const toggleColumn = (field, t) => {
    const simple = {
      field,
      sortable: true,
      filter: true,
      filterElement: { type: 'multiselect' },
    }

    if (typeof t != 'function') {
      return simple
    }

    const unprefixField = unPrefixField(field)

    return {
      ...simple,
      header: t([
        `items:item.fields.${unprefixField}.label`,
        `items:item.fields.${unprefixField}`,
      ]),
      hidden: true,
      filterElement: {
        ...simple.filterElement,
        options: [
          { label: t('common:activated'), value: 'true' },
          { label: t('common:deactivated'), value: 'false' },
        ],
      },
      parseValue: (props) =>
        t(`common:${props[field] ? 'activated' : 'deactivated'}`),
    }
  }

  const columnDict = {
    title: {
      field: 'title',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
      isColumnToggle: false,
    },
    dimensionOrder: {
      field: 'dimensionOrder',
      sortable: true,
      filter: true,
      filterField: 'dimension',
      filterElement: {
        type: 'multiselect',
      },
    },
    tag: {
      field: 'tag',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
      hidden: true,
    },
    category: {
      field: 'category',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
      hidden: true,
    },
    status: getStatusColumn(),
    sku: { field: 'sku', sortable: true, filter: true, isGlobalSearch: true },
    description: {
      field: 'description',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    material: {
      field: 'material',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    secondaryDescription: {
      field: 'secondaryDescription',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
      hidden: true,
    },
    upc: {
      field: 'upc',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
      hidden: true,
    },
    ean: {
      field: 'ean',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
      hidden: true,
    },
    rawMetric: {
      field: 'rawMetric',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
      hidden: true,
    },
    rawImperial: {
      field: 'rawImperial',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
      hidden: true,
    },
    mainVendor: {
      field: 'mainVendor',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    mainVendorPartNumber: {
      field: 'mainVendorPartNumber',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    mainVendorListPrice: {
      field: 'mainVendorListPrice',
      sortable: true,
      hidden: true,
    },
    mainVendorDiscount: {
      field: 'mainVendorDiscount',
      sortable: true,
      hidden: true,
      className: 'a-right',
    },
    convertedSellingPrice: {
      field: 'convertedSellingPrice',
      sortable: true,
      hidden: true,
      className: 'a-right',
    },
    calculatedUnitCost: {
      field: 'calculatedUnitCost',
      sortable: true,
      className: 'a-right',
    },
    mainVendorCurrency: {
      field: 'mainVendorCurrency',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
      hidden: true,
    },
    mainVendorExchangeRate: {
      field: 'mainVendorExchangeRate',
      sortable: true,
      hidden: true,
      className: 'a-right',
    },
    mainVendorBaseCost: {
      field: 'mainVendorBaseCost',
      sortable: true,
      hidden: true,
      className: 'a-right',
    },
    partNumber: {
      field: 'partNumber',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    treatment: {
      field: 'treatment',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
      hidden: true,
    },
    revision: {
      field: 'revision',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
      hidden: true,
    },
    inventoryTotal: {
      field: 'inventoryTotal',
      sortable: true,
      className: 'a-right',
    },
    manufacturer: {
      field: 'manufacturer',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    purchaseMoq: {
      field: 'purchaseMoq',
      sortable: true,
      hidden: true,
      className: 'a-right',
    },
    unitWeight: {
      field: 'unitWeight',
      sortable: true,
      hidden: true,
      className: 'a-right',
    },
    unitSurface: {
      field: 'unitSurface',
      sortable: true,
      hidden: true,
      className: 'a-right',
    },
    unitVolume: {
      field: 'unitVolume',
      sortable: true,
      hidden: true,
      className: 'a-right',
    },
    createdDate: {
      field: 'createdDate',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
      hidden: true,
    },
    createdBy: {
      field: 'createdBy',
      sortable: true,
      filter: true,
      hidden: true,
    },
    modifiedDate: {
      field: 'modifiedDate',
      sortable: true,
      filter: true,
      hidden: true,
    },
    modifiedBy: {
      field: 'modifiedBy',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
      hidden: true,
    },
    group: {
      field: 'group',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
      hidden: true,
    },
    type: {
      field: 'type',
      sortable: true,
      filter: true,
      filterElement: { type: 'multiselect' },
      hidden: true,
    },
    salesCustomerName: {
      field: 'salesCustomerName',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    isCustomerSpecific: {
      field: 'isCustomerSpecific',
      sortable: true,
      filter: true,
      hidden: true,
    },
    inventoryManagementType: {
      field: 'inventoryManagementType',
      sortable: true,
      filter: true,
      filterElement: { type: 'multiselect' },
      hidden: true,
    },
    salesPriceComputeMode: {
      field: 'salesPriceComputeMode',
      sortable: true,
      filter: true,
      filterElement: { type: 'multiselect' },
      hidden: true,
    },
    isSelling: toggleColumn(prefixField('isSelling')),
    isManufactured: toggleColumn(prefixField('isManufactured')),
    isPurchased: toggleColumn(prefixField('isPurchased')),
    attributeDescription: { field: 'attributeDescription', sortable: true, filter: true, isGlobalSearch: true },
    attributeSecondaryDescription: {
      field: 'attributeSecondaryDescription',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    attributeOptionDecription: {
      field: 'attributeOptionDecription',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    attributeOptionSecondaryDescription: {
      field: 'attributeOptionSecondaryDescription',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    customerConsignmentRequired: toggleColumn(prefixField('customerConsignmentRequired')),
    customerFinishedGoodRequired: toggleColumn(prefixField('customerFinishedGoodRequired')),
    promptExtraConsignment: toggleColumn(prefixField('promptExtraConsignment')),
    associatedRawTemplateTitle: {
      field: 'associatedRawTemplateTitle',
      sortable: true,
      filter: true,
      hidden: true,
    },
    configurableTemplateTitle: {
      field: 'configurableTemplateTitle',
      sortable: true,
      filter: true,
      hidden: true,
    },
    configurableType: {
      field: 'configurableType',
      sortable: true,
      filter: true,
      filterElement: { type: 'multiselect' },
      isGlobalSearch: true,
      hidden: true,
    },
    countryOfOrigin: {
      field: 'countryOfOrigin',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
      hidden: true,
    },
    harmonizedSystemCode: {
      field: 'harmonizedSystemCode',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
      hidden: true,
    },
  }

  const prefixedColumnDict = prefixColumnDict(columnDict)

  function getComplexColumns(
    t,
    {
      primaryLanguage,
      secondaryLanguage,
      culture,
      measureDigits,
      priceDigits,
      currencyCode,
      currencySymbol,
      dateColumnTemplate,
      simpleDict,
    },
  ) {
    const inventoryManagementTypes = {
      quantity_and_value: t(
        'items:item.fields.inventoryManagementType.quantityAndValue',
      ),
      quantity_only: t(
        'items:item.fields.inventoryManagementType.quantityOnly',
      ),
      not_managed: t('items:item.fields.inventoryManagementType.notManaged'),
    }

    const complexColumns = {
      dimensionOrder: dimensionComplexColumn(t, simpleDict[prefixField('dimensionOrder')].filterElement),
      title: {
        header: t('items:item.fields.item'),
        template: (props) => {
          const iconData = getDimensionIcon(props[prefixField('dimension')])
          return (
            <div className="a-flex">
              <div className="a-item-grid-dimension-icon">
                <FontAwesomeIcon
                  icon={['fas', iconData.icon]}
                  transform={iconData.transform}
                />
              </div>
              <div
                className="a-smart-grid-default-template"
                title={props[prefixField('title')]}
              >
                {props[prefixField('title')]}
              </div>
            </div>
          )
        },
      },
      tag: { header: t('items:item.fields.tag') },
      category: { header: t('items:item.fields.category') },
      sku: { header: t('items:item.fields.sku.label') },
      description: {
        header: `${t('items:item.fields.description')}${
          primaryLanguage ? ` (${primaryLanguage})` : ''
        }`,
      },
      material: { header: t('items:item.fields.material') },
      secondaryDescription: {
        header: `${t('items:item.fields.description')}${
          secondaryLanguage ? ` (${secondaryLanguage})` : ''
        }`,
      },
      upc: { header: t('items:item.fields.upc') },
      ean: { header: t('items:item.fields.ean') },
      rawMetric: { header: t('items:item.fields.rawMetric') },
      rawImperial: { header: t('items:item.fields.rawImperial') },
      mainVendor: { header: t('items:item.fields.vendor.label') },
      mainVendorPartNumber: { header: t('items:item.fields.vendorPartNumber') },
      mainVendorListPrice: {
        header: t('items:item.fields.vendorListPrice'),
        parseValue: (props) => {
          return props[prefixField('mainVendorId')] ?
            `${parseCurrency(
              props[prefixField('mainVendorListPrice')],
              props[prefixField('mainVendorCurrency')] || undefined,
              props[prefixField('mainVendorSymbol')] || undefined,
              priceDigits,
              culture,
            )} / ${props[prefixField('initialMeasureUnit')]}` :
            null
        },
      },
      mainVendorDiscount: {
        header: t('items:item.fields.vendorDiscount'),
        parseValue: (props) => {
          return
          props[prefixField('mainVendorId')] ?
            parsePercentage(
              props[prefixField('mainVendorDiscount')] / 100,
              2,
              culture,
            ) :
            null
        },
      },
      convertedSellingPrice: {
        header: t('items:item.fields.sellingPrice.label'),
        parseValue: (props) =>
          props[prefixField('type')] === 'custom_service' &&
          props[prefixField('salesPriceComputeMode')] != 'fixed_price' ?
            t('common:notAvailable') :
            `${parseCurrency(
              props[prefixField('convertedSellingPrice')],
              currencyCode || undefined,
              currencySymbol || undefined,
              priceDigits,
              culture,
            )} / ${props[prefixField('sellingUnit')] || 'un'}`,
      },
      calculatedUnitCost: {
        header: t('items:item.fields.unitCost.label'),
        parseValue: (props) => {
          return `${parseCurrency(
            convertFromDollarPerBase(
              props[prefixField('dimension')],
              props[prefixField('calculatedUnitCost')],
              props[prefixField('initialMeasureUnit')],
            ),
            props[prefixField('mainVendorCurrency')] || currencyCode,
            props[prefixField('mainVendorSymbol')] || currencySymbol,
            priceDigits,
            culture,
          )} / ${props[prefixField('initialMeasureUnit')] || 'un'}`
        },
      },
      mainVendorCurrency: { header: t('items:item.fields.vendorCurrency') },
      mainVendorExchangeRate: {
        header: t('items:item.fields.vendorExchangeRate'),
        parseValue: (props) => {
          return props[prefixField('mainVendorId')] ?
            parseNumber(
              props[prefixField('mainVendorExchangeRate')],
              { minimumFractionDigits: 2, maximumFractionDigits: 8 },
              culture,
            ) :
            null
        },
      },
      mainVendorBaseCost: {
        header: t('items:item.fields.vendorBaseCost'),
        parseValue: (props) => {
          return props[prefixField('mainVendorId')] ?
            `${parseCurrency(
              props[prefixField('mainVendorBaseCost')],
              currencyCode || undefined,
              currencySymbol || undefined,
              priceDigits,
              culture,
            )} / ${props[prefixField('initialMeasureUnit')] || 'un'}` :
            null
        },
      },
      partNumber: { header: t('items:item.fields.partNumber') },
      treatment: { header: t('items:item.fields.treatment') },
      revision: { header: t('items:item.fields.revision') },
      inventoryTotal: {
        header: t('items:item.fields.inventoryTotal'),
        parseValue: (props) => {
          return `${parseNumber(
            props[prefixField('inventoryTotal')],
            measureDigits,
            culture,
          )} ${props[prefixField('initialMeasureUnit')] || 'un'}`
        },
      },
      manufacturer: { header: t('items:item.fields.manufacturer') },
      purchaseMoq: {
        header: t('items:item.fields.initialMeasure'),
        parseValue: (props) => {
          return `${parseNumber(
            convertFromBase(
              props[prefixField('dimension')],
              props[prefixField('purchaseMoq')],
              props[prefixField('initialMeasureUnit')],
              false,
            ),
            measureDigits,
            culture,
          )} ${props[prefixField('initialMeasureUnit')] || 'un'}`
        },
      },
      unitWeight: {
        header: t('items:item.fields.unitWeight'),
        parseValue: (props) => {
          return props[prefixField('dimension')] === 'weight' ?
            '' :
            `${parseNumber(
              props[prefixField('convertedUnitWeight')],
              measureDigits,
              culture,
            )} ${props[prefixField('weightUnit')]} / ${
              props[prefixField('initialMeasureUnit')] || 'un'
            }`
        },
      },
      unitSurface: {
        header: t('items:item.fields.unitSurface'),
        parseValue: (props) => {
          return props[prefixField('dimension')] === 'surface' ?
            '' :
            `${parseNumber(
              props[prefixField('convertedUnitSurface')],
              measureDigits,
              culture,
            )} ${props[prefixField('surfaceUnit')]} / ${
              props[prefixField('initialMeasureUnit')] || 'un'
            }`
        },
      },
      unitVolume: {
        header: t('items:item.fields.unitVolume'),
        parseValue: (props) => {
          return props[prefixField('dimension')] === 'volume' ?
            '' :
            `${parseNumber(
              props[prefixField('convertedUnitVolume')],
              measureDigits,
              culture,
            )} ${props[prefixField('volumeUnit')]} / ${
              props[prefixField('initialMeasureUnit')] || 'un'
            }`
        },
      },
      createdDate: {
        header: t('items:item.fields.createdDate'),
        template: (props) =>
          dateColumnTemplate(props[prefixField('createdDate')]),
      },
      createdBy: { header: t('items:item.fields.createdBy.label') },
      modifiedDate: {
        header: t('items:item.fields.modifiedDate'),
        template: (props) =>
          dateColumnTemplate(props[prefixField('modifiedDate')]),
      },
      modifiedBy: { header: t('items:item.fields.modifiedBy') },
      group: { header: t('items:item.fields.group') },
      type: {
        header: t('items:item.fields.type.type'),
        filterElement: {
          ...simpleDict[prefixField('type')].filterElement,
          options: [
            { label: t('items:item.fields.type.good'), value: 'good' },
            { label: t('items:item.fields.type.service'), value: 'service' },
            {
              label: t('items:item.fields.type.custom_service'),
              value: 'custom_service',
            },
          ],
        },
        template: (props) =>
          props[prefixField('type')] ? t(`items:item.fields.type.${props[prefixField('type')]}`) : null,
      },
      inventoryManagementType: {
        header: t(
          'items:item.fields.inventoryManagementType.inventoryManagementType',
        ),
        filterElement: {
          ...simpleDict[prefixField('inventoryManagementType')].filterElement,
          options: [
            {
              label: inventoryManagementTypes.quantity_and_value,
              value: 'quantity_and_value',
            },
            {
              label: inventoryManagementTypes.quantity_only,
              value: 'quantity_only',
            },
            {
              label: inventoryManagementTypes.not_managed,
              value: 'not_managed',
            },
          ],
        },
        template: (props) =>
          inventoryManagementTypes[
            props[prefixField('inventoryManagementType')]
          ],
      },
      salesPriceComputeMode: {
        header: t('items:item.fields.salesPriceComputeMode.label'),
        filterElement: {
          ...simpleDict[prefixField('salesPriceComputeMode')].filterElement,
          options: [
            {
              label: t('items:item.fields.salesPriceComputeMode.compute_all'),
              value: 'compute_all',
            },
            {
              label: t(
                'items:item.fields.salesPriceComputeMode.consumption_sales_price',
              ),
              value: 'consumption_sales_price',
            },
            {
              label: t('items:item.fields.salesPriceComputeMode.fixed_price'),
              value: 'fixed_price',
            },
          ],
        },
        template: (props) =>
          props[prefixField('type')] === 'custom_service' &&
          props[prefixField('salesPriceComputeMode')] ?
            t(
              `items:item.fields.salesPriceComputeMode.${
                props[prefixField('salesPriceComputeMode')]
              }`,
            ) :
            '',
      },
      isSelling: toggleColumn(prefixField('isSelling'), t),
      isManufactured: toggleColumn(prefixField('isManufactured'), t),
      isPurchased: toggleColumn(prefixField('isPurchased'), t),
      attributeDescription: { header:
      `${t('items:item.fields.attribute.label')}${primaryLanguage ? ` (${primaryLanguage})` : ''}`,
      },
      attributeSecondaryDescription: { header:
      `${t('items:item.fields.attribute.label')}${secondaryLanguage ? ` (${secondaryLanguage})` : ''}`,
      },
      attributeOptionDecription: { header:
      `${t('items:item.fields.attributeOption.label')}${primaryLanguage ? ` (${primaryLanguage})` : ''}`,
      },
      attributeOptionSecondaryDescription: { header:
      `${t('items:item.fields.attributeOption.label')}${secondaryLanguage ? ` (${secondaryLanguage})` : ''}`,
      },

      customerConsignmentRequired: toggleColumn(
        'customerConsignmentRequired',
        t,
      ),
      customerFinishedGoodRequired: toggleColumn(
        'customerFinishedGoodRequired',
        t,
      ),
      promptExtraConsignment: toggleColumn(prefixField('promptExtraConsignment'), t),
      status: {
        ...getStatusColumn(
          (option) => statusColumnFilterTemplate(option),
          {
            isColumnToggle: false,
            dataSetName: 'templateView',
            dbField: 'status',
            defaultValue: 'active',
            isEdit: true,
          },
          t,
        ),
        header: t('items:item.fields.status'),
        template: (props) => {
          return (
            <div
              className={
                props[prefixField('status')] == 'active' ?
                  'a-smart-status a-smart-status-green' :
                  'a-smart-status a-smart-status-default'
              }
            >
              {t(`items:status.${props[prefixField('status')]}`)}
            </div>
          )
        },
      },
      salesCustomerName: { header: t('items:item.fields.salesCustomerName') },
      isCustomerSpecific: toggleColumn(prefixField('isCustomerSpecific'), t),
      associatedRawTemplateTitle: {
        header: t('items:item.fields.associatedRawTemplateTitle'),
      },
      configurableTemplateTitle: {
        header: t('items:item.fields.configurableTemplateTitle'),
      },
      configurableType: {
        header: t('items:item.fields.configurableType.configurableType'),
        filterElement: {
          ...simpleDict[prefixField('configurableType')].filterElement,
          options: [
            {
              label: t('items:item.fields.configurableType.base'),
              value: 'base',
            },
            {
              label: t('items:item.fields.configurableType.variant'),
              value: 'variant',
            },
          ],
        },
        template: (props) =>
          props[prefixField('configurableType')] ?
            t(
              `items:item.fields.configurableType.${
                props[prefixField('configurableType')]
              }`,
            ) :
            '',
      },
      countryOfOrigin: { header: t('items:item.fields.countryOfOrigin') },
      harmonizedSystemCode: {
        header: t('items:item.fields.harmonizedSystemCode'),
      },
    }

    return prefixComplexColumns(complexColumns, prefixedColumnDict, { t })
  }

  return {
    getComplexColumns,
    columnDict: prefixedColumnDict,
  }
}

const { columnDict, getComplexColumns } = getPrefixedComplexColumns()

export { columnDict, getComplexColumns }
