import React from 'react'

import { Skeleton } from 'primereact/skeleton'

import { getFirstString } from 'utils/getFirstOfType'

/**
 *
 * @param {*} props
 * @param {boolean} [props.loading]
 * @param {string} [props.value]
 * @param {string} [props.defaultValue]
 * @param {string} [props.width]
 * @param {string} [props.height]
 * @param {string} [props.borderRadius]
 * @param {string} [props.className]
 * @param {string} [props.wrapperClassName]
 * @param {React.ReactNode} [props.children]
 * @param {React.CSSProperties} [props.style]
 * @returns
 */
function SmartSkeleton({
  loading = true,
  value,
  defaultValue,
  width = '100%',
  height,
  borderRadius = '4px',
  className,
  wrapperClassName,
  children,
  style,
}) {
  const getLoadingSection = () => {
    const classes = []
    if (className) {
      classes.push(className)
    }
    if (defaultValue && !value) {
      classes.push('a-default-value')
    }

    const title = getFirstString(value, defaultValue)

    return (loading ?
      <Skeleton
        width={width}
        height={height}
        borderRadius={borderRadius}
      /> : typeof children != 'undefined' ? children : (
        <div
          className={classes.join(' ')}
          title={title}
        >
          {value || defaultValue}
        </div>
      )
    )
  }

  return (wrapperClassName || style ?
    <div
      className={wrapperClassName}
      style={style}
    >
      {getLoadingSection()}
    </div> : getLoadingSection()
  )
}

export default SmartSkeleton
