import React, { useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import SmartInputNumber from 'components/alix-front/smart-input-number/SmartInputNumber'

import { calculateCostWithDiscount } from 'utils/costCalculator'
import { isCurrencySymbolFirst, parseCurrency } from 'utils/numberParser'
import { convertFromDollarPerBase, convertToDollarPerBase } from 'utils/unitConverter'

import { updateLineItemsForm } from 'reducers/sales-orders/salesOrdersSlice'

const getNewFormData = (salesOrderLineItemId, toBaseUnitPrice, form, formKey = 'insertions') => {
  return {
    ...form[formKey][salesOrderLineItemId],
    unitPrice: {
      ...form[formKey][salesOrderLineItemId].unitPrice,
      value: toBaseUnitPrice,
      isChanged: true,
    },
    discounted: {
      ...form[formKey][salesOrderLineItemId].discounted,
      value: calculateCostWithDiscount(
        toBaseUnitPrice,
        form[formKey][salesOrderLineItemId].discount.value,
        false,
      ),
    },
  }
}

function UnitPriceInput({ salesOrderLineItem, formData, editMode, isClickable, onValueClick, priceSynced }) {
  // init globals
  const dispatch = useDispatch()

  // init store
  const loginUser = useSelector((state) => state.loginUser)
  const settingsStore = useSelector((state) => state.settings)
  const salesOrdersStore = useSelector((state) => state.salesOrders)

  const culture = useMemo(() => `${loginUser.language || 'fr'}-CA`, [loginUser.language])
  const isPrefix = useMemo(
    () => isCurrencySymbolFirst(0, salesOrdersStore.activeForm.currencyInfo.currencyCode, culture),
    [salesOrdersStore.activeForm.currencyInfo.currencyCode, culture],
  )
  const { _prefix, _suffix } = useMemo(
    () => ({
      _prefix: isPrefix ? salesOrdersStore.activeForm.currencyInfo.currencySymbol : null,
      _suffix: isPrefix ? null : salesOrdersStore.activeForm.currencyInfo.currencySymbol,
    }),
    [isPrefix, salesOrdersStore.activeForm.currencyInfo.currencySymbol],
  )

  const initialValue = useMemo(() => convertFromDollarPerBase(
    salesOrderLineItem.dimension,
    formData.unitPrice?.value,
    salesOrderLineItem.unit,
  ), [salesOrderLineItem.dimension, formData.unitPrice?.value, salesOrderLineItem.unit])

  const handleChange = useCallback((ev) => {
    const toBaseUnitPrice = ev?.value === null ? null : convertToDollarPerBase(
      salesOrderLineItem.dimension,
      ev.value,
      salesOrderLineItem.unit,
    )

    const newLineItemsForm = { ...salesOrdersStore.activeForm.lineItems }
    if (newLineItemsForm.insertions[salesOrderLineItem.id]) {
      newLineItemsForm.insertions[salesOrderLineItem.id] = getNewFormData(
        salesOrderLineItem.id,
        toBaseUnitPrice,
        salesOrdersStore.activeForm.lineItems,
      )
    } else {
      newLineItemsForm.updates[salesOrderLineItem.id].isGlobalChange = true
      newLineItemsForm.updates[salesOrderLineItem.id] = getNewFormData(
        salesOrderLineItem.id,
        toBaseUnitPrice,
        salesOrdersStore.activeForm.lineItems,
        'updates',
      )
    }
    dispatch(updateLineItemsForm(newLineItemsForm))
  }, [
    dispatch,
    salesOrderLineItem.id,
    salesOrderLineItem.dimension,
    salesOrderLineItem.unit,
    salesOrdersStore.activeForm.lineItems,
  ])

  const inputNotSyncClasses = useMemo(() => {
    const classes = []
    if (isClickable) {
      classes.push('a-measure-clickable-link')
    }
    if (!priceSynced) {
      classes.push('a-smart-status-label-yellow')
    }
    return classes.join(' ')
  }, [isClickable, priceSynced])

  const isUnknown = useMemo(() => {
    const isLineInsertion = !!salesOrdersStore.activeForm.lineItems.insertions[salesOrderLineItem.id]
    const isCustomService = salesOrderLineItem.templateType === 'custom_service'

    return isLineInsertion && isCustomService
  }, [salesOrdersStore, salesOrderLineItem])

  if (!editMode) {
    return (
      <div
        className={inputNotSyncClasses}
        onClick={isClickable ? onValueClick : null}
      >
        {parseCurrency(
          initialValue,
          salesOrdersStore.activeForm.currencyInfo.currencyCode,
          salesOrdersStore.activeForm.currencyInfo.currencySymbol,
          settingsStore.global.priceDigits,
          culture,
        )}
      </div>
    )
  }

  return (
    <div className="a-form-element-value a-inline-input">
      <SmartInputNumber
        initialValue={isUnknown ? 0 : initialValue}
        onChange={handleChange}
        mode="decimal"
        prefix={_prefix}
        suffix={_suffix}
        minFractionDigits={settingsStore.global.number_decimals.price_min}
        maxFractionDigits={settingsStore.global.number_decimals.price_max}
        defaultClassName={!priceSynced ? 'a-smart-status-label-yellow' : ''}
        disabled={isUnknown}
      />
    </div>
  )
}

export default UnitPriceInput
