import { mapColumns } from 'components/alix-front/smart-grid/columns/utils'

import { entities } from 'utils/entities'

const defaultOptions = {
  sortable: true,
  filter: true,
  isGlobalSearch: true,
}

export const columnDict = {
  name: { field: 'name', isColumnToggle: false, ...defaultOptions },
  secondaryName: { field: 'secondaryName', ...defaultOptions },
}

export function getComplexColumns(t, { fields, primaryLanguage, secondaryLanguage, simpleDict }) {
  const complexColumns = mapColumns(
    Object.values(simpleDict),
    { t, fields, entity: entities['country-of-origins'], handlerOptions: {
      useEntityTranslation: true,
    } },
  )

  return complexColumns.reduce((acc, column) => {
    acc[column.field] = column
    return acc
  }, {})
}
