import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { v4 as uuid } from 'uuid'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'primereact/tooltip'

import { targetClass } from 'components/alix-front/smart-input-tooltip/SmartInputTooltip'

import './style.css'

const _dataAttributes = {}
/**
 * @typedef {object} Props
 * @property {string | React.ReactElement} [title]
 * @property {any} [onClick]
 * @property {boolean} [editMode]
 * @property {boolean} [enabled=true]
 * @property {string} [disabledTooltip]
 * @property {string} [className]
 * @property {string} [titleClassName]
 * @property {boolean} [primary]
 * @property {boolean} [warning]
 * @property {boolean} [small]
 * @property {boolean} [light]
 * @property {string} [icon]
 * @property {string} [iconClassName]
 * @property {object} [dataAttributes=_dataAttributes]
 * @property {boolean} [fixedWidth=false]
 * @property {boolean} [isIconButton=false]
 * @property {boolean} [showHasLink=false]
 * @property {boolean} [processing=false]
 * @property {string} [tooltip]
 */

/**
 * @param {React.PropsWithRef<Props>} props
 * @return {React.ReactElement}
 */
function SmartButton({
  editMode = true,
  title,
  onClick,
  enabled = true,
  tooltip,
  disabledTooltip,
  className,
  titleClassName,
  primary,
  warning,
  small,
  light,
  icon,
  dataAttributes = _dataAttributes,
  fixedWidth = false,
  isIconButton = false,
  showHasLink = false,
  iconClassName,
  processing = false,
}, ref) {
  const { t } = useTranslation(['common'])
  const id = useRef(uuid())

  const callback = useCallback((event) => {
    if (!enabled || typeof onClick != 'function') {
      return
    }

    onClick(event)
  }, [enabled, onClick])

  useImperativeHandle(ref, () => ({ onClick: callback }))

  const _icon = useMemo(() => processing ? 'spinner-third' : icon, [icon, processing])
  const _iconClassName = useMemo(() => processing ? 'a-spin' : iconClassName, [iconClassName, processing])
  const _titleClassName = useMemo(() => processing ? 'a-invisible' : titleClassName, [processing, titleClassName])

  const _className = useMemo(() => {
    const classes = [targetClass(id.current)]

    if (editMode) classes.push('a-smart-button')

    if (showHasLink) classes.push('a-has-link')

    if (small) classes.push('a-small')

    if (light) classes.push('a-light')

    if (primary) classes.push('a-primary')

    if (!enabled) classes.push('a-disabled')

    if (warning) classes.push('a-warning')

    if (processing) classes.push('a-processing')

    return classes.join(' ')
  }, [editMode, showHasLink, small, light, primary, enabled, warning, processing])

  if (!disabledTooltip) {
    disabledTooltip = t('common:button.disabled')
  }

  const _tooltip = useMemo(() => {
    return enabled ? tooltip : disabledTooltip || tooltip
  }, [disabledTooltip, tooltip, enabled])

  return (
    <div className={className}>
      <div
        className={_className}
        onClick={callback}
        data-is-icon-button={isIconButton}
        role="button"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...dataAttributes}
      >
        {_icon ? (
          <FontAwesomeIcon
            icon={[_icon === 'spinner-third' ? 'fad' : 'fas', _icon]}
            fixedWidth={fixedWidth}
            className={_iconClassName}
            style={!editMode ? { paddingTop: '0.3rem' } : undefined}
          />
        ) : null}
        {title ? (
          <div className={_titleClassName}>
            {title}
          </div>
        ) : null}
      </div>
      <Tooltip
        content={_tooltip}
        position="bottom"
        className="a-no-arrow"
        target={`.${targetClass(id.current)}`}
        style={{ textAlign: 'center' }}
      />
    </div>
  )
}

export default forwardRef(SmartButton)
