export const GET_PURCHASE_ORDERS_COUNT = 'GET_PURCHASE_ORDERS_COUNT'
export const GET_PURCHASE_ORDERS = 'GET_PURCHASE_ORDERS'
export const GET_PURCHASE_ORDER = 'GET_PURCHASE_ORDER'
export const CREATE_PURCHASE_ORDER = 'CREATE_PURCHASE_ORDER'
export const UPDATE_PURCHASE_ORDER = 'UPDATE_PURCHASE_ORDER'
export const DELETE_PURCHASE_ORDER = 'DELETE_PURCHASE_ORDER'
export const SEND_PURCHASE_ORDER_TO_ZOHO_BOOKS = 'SEND_PURCHASE_ORDER_TO_ZOHO_BOOKS'

export const GET_CURRENCIES = 'GET_PURCHASE_ORDER_CURRENCIES'

export const CLEAR_PURCHASE_ORDER = 'CLEAR_PURCHASE_ORDER'
export const DUPLICATE_PURCHASE_ORDER = 'DUPLICATE_PURCHASE_ORDER'
export const CLEAR_CREATE_SUCCESS = 'CLEAR_PURCHASE_ORDER_CREATE_SUCCESS'
export const CLEAR_UPDATE_SUCCESS = 'CLEAR_PURCHASE_ORDER_UPDATE_SUCCESS'
export const CLEAR_DELETE_SUCCESS = 'CLEAR_PURCHASE_ORDER_DELETE_SUCCESS'
export const CLEAR_SEND_TO_ZOHO_BOOKS_SUCCESS = 'CLEAR_SEND_PURCHASE_ORDER_TO_ZOHO_BOOKS_SUCCESS'
export const CLEAR_CREATE_ERROR = 'CLEAR_PURCHASE_ORDER_CREATE_ERROR'
export const CLEAR_UPDATE_ERROR = 'CLEAR_PURCHASE_ORDER_UPDATE_ERROR'
export const CLEAR_DELETE_ERROR = 'CLEAR_PURCHASE_ORDER_DELETE_ERROR'
export const CLEAR_SEND_TO_ZOHO_BOOKS_ERROR = 'CLEAR_SEND_PURCHASE_ORDER_TO_ZOHO_BOOKS_ERROR'

export const SET_IS_CREATE = 'SET_PURCHASE_ORDER_IS_CREATE'
export const SET_GLOBAL_FORM = 'SET_PURCHASE_ORDER_GLOBAL_FORM'
export const SET_VENDOR_DETAILS = 'SET_PURCHASE_ORDER_VENDOR_DETAILS'
export const SET_BILL_TO_FORM = 'SET_BILL_TO_FORM'
export const SET_SHIP_TO_FORM = 'SET_SHIP_TO_FORM'
export const SET_LINE_ITEM_MEASURE = 'SET_PURCHASE_ORDER_LINE_ITEM_MEASURE'
export const SET_LINE_ITEM_INVENTORIES = 'SET_PURCHASE_ORDER_LINE_ITEM_INVENTORIES'
export const SET_LINE_ITEMS_FORM = 'SET_PURCHASE_ORDER_LINE_ITEMS_FORM'
export const SET_LINE_ITEMS_FORM_SELECTIONS = 'SET_PURCHASE_ORDER_LINE_ITEMS_FORM_SELECTIONS'
export const DUPLICATE_LINE_ITEMS_FROM_SELECTION = 'DUPLICATE_PURCHASE_ORDER_LINE_ITEMS_FROM_SELECTION'
export const DELETE_LINE_ITEMS_FROM_SELECTION = 'DELETE_PURCHASE_ORDER_LINE_ITEMS_FROM_SELECTION'
export const RESET_FORM = 'RESET_PURCHASE_ORDER_FORM'

export const CLEAR_SELECTIONS = 'CLEAR_SELECTIONS'

export const UPDATE_PURCHASE_ORDER_ITEM = 'UPDATE_PURCHASE_ORDER_ITEM'
export const SET_PO_ITEM_REPORTING_TAGS_FORM = 'SET_PO_ITEM_REPORTING_TAGS_FORM'
export const INIT_PO_ITEM_REPORTING_TAGS_FORM = 'INIT_PO_ITEM_REPORTING_TAGS_FORM'
export const DELETE_PO_ITEM_REPORTING_TAGS_FROM_SELECTION =
  'DELETE_PO_ITEM_REPORTING_TAGS_FROM_SELECTION'
export const SET_PO_ITEM_REPORTING_TAGS_FORM_SELECTION =
  'SET_PO_ITEM_REPORTING_TAGS_FORM_SELECTION'
