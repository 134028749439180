import React, { useEffect, useMemo } from 'react'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { v4 as uuid } from 'uuid'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Steps } from 'primereact/steps'

import SmartButton from 'components/alix-front/smart-button/SmartButton'

import { resolveTranslation } from 'utils/resolveTranslation'

import { addPopup, removePopup } from 'reducers/popups/popupsSlice'

import './style.css'

/**
 * @typedef {Object} Props
  * @property {React.ReactNode} children
  * @property {Object} [wizard]
  * @property {string} [className]
  * @property {boolean} [isOpen]
  * @property {string | null} [title]
  * @property {boolean} [warning]
  * @property {function} [onCancel]
  * @property {function} onConfirm
  * @property {boolean} [isConfirm]
  * @property {boolean} [isCancel]
  * @property {string} [confirmDisabledTooltip]
  * @property {string[]} [confirmDisabledTooltipKey]
  * @property {React.ReactNode} [footerInfoTemplate]
  * @property {string} [cancelKey]
  * @property {string} [cancelButtonTitle] - if not provided, will use the translation of the cancelKey
  * @property {string} [confirmKey]
  * @property {string} [confirmButtonTitle] - if not provided, will use the translation of the confirmKey
  * @property {boolean} [hideFooter]
  * @property {boolean} [emptyFooter]
  * @property {React.ReactNode} [sideContent]
  * @property {Array<function>} [customHeaderActions]
  * @property {string} [appendToClass] - class of the element to append the popup to, default is 'a-app-content'
  * @property {string} [contentClassName]
  * @property {boolean} [isCancelPrimary]
 */

/**
 * @param {Props} props
 * @return {React.ReactElement}
 */
function SmartPopup({
  children,
  wizard,
  className,
  isOpen = false,
  title,
  warning,
  onCancel,
  onConfirm,
  isConfirm = true,
  isCancel = true,
  confirmDisabledTooltip,
  confirmDisabledTooltipKey,
  footerInfoTemplate,
  cancelKey = 'cancel',
  cancelButtonTitle,
  confirmKey = 'confirm',
  confirmButtonTitle,
  hideFooter = false,
  emptyFooter = false,
  sideContent = null,
  customHeaderActions,
  appendToClass = 'a-app-content',
  contentClassName,
  isCancelPrimary = false,
}) {
  const { t, i18n } = useTranslation(['common'])
  const dispatch = useDispatch()
  const popup = useMemo(() => ({ id: uuid() }), [])

  const activePageIndex = useMemo(() => wizard?.pages.findIndex((page) => page.active), [wizard?.pages])
  const isPrevious = useMemo(
    () => wizard?.pages.length > 0 && activePageIndex != 0 && !!wizard?.pages?.[activePageIndex - 1]?.content,
    [activePageIndex, wizard?.pages],
  )
  const isNext = useMemo(
    () => wizard?.pages.length > 0 && activePageIndex != wizard?.pages.length - 1,
    [activePageIndex, wizard?.pages.length],
  )

  useEffect(() => {
    if (isOpen) {
      dispatch(addPopup(popup))
    } else {
      dispatch(removePopup(popup.id))
    }

    return () => {
      dispatch(removePopup(popup.id))
    }
  }, [dispatch, isOpen, popup])

  const lockNavigation = useMemo(
    () => !!wizard?.lockNavigation || !!wizard?.forceLockNavigation,
    [wizard?.lockNavigation, wizard?.forceLockNavigation],
  )

  const _isCancel = useMemo(() => isCancel && !lockNavigation, [isCancel, lockNavigation])

  const activePage = wizard?.pages?.[activePageIndex]

  const activePageClasses = ['a-form-element-content', 'a-smart-popup-content']
  if (!!activePage?.wrapperClassName) {
    activePageClasses.push(activePage?.wrapperClassName)
  }
  if (contentClassName) {
    activePageClasses.push(contentClassName)
  }
  const previousEnabled = (
    !!activePage &&
    (typeof activePage.previousEnabled === 'boolean' ? activePage.previousEnabled : true) &&
    !lockNavigation
  )
  const nextEnabled = (
    !!activePage &&
    (typeof activePage.nextEnabled === 'boolean' ? activePage.nextEnabled : true) &&
    !lockNavigation
  )

  const _cancelButtonTitle = useMemo(() => {
    return cancelButtonTitle ?? t(`common:button.${cancelKey}`)
  }, [cancelKey, cancelButtonTitle, t])

  const _confirmButtonTitle = useMemo(() => {
    return confirmButtonTitle ?? t(`common:button.${confirmKey}`)
  }, [confirmKey, confirmButtonTitle, t])

  const footerButtonOrder = useMemo(() => {
    const cancelButton = typeof onCancel === 'function' ? (
      <SmartButton
        key="cancel"
        primary={isCancelPrimary}
        title={_cancelButtonTitle}
        onClick={onCancel}
        className="a-smart-popup-button"
        enabled={_isCancel}
      />
    ) : null
    const previousButton = isPrevious ? (
      <SmartButton
        key="previous"
        title={t('common:button.previous')}
        enabled={previousEnabled}
        disabledTooltip={activePage?.previousDisabledTooltip}
        onClick={() => !lockNavigation ? wizard.previous() : null}
        className="a-smart-popup-button"
      />
    ) : null
    const nextOrConfirmButton = isNext ? (
      <SmartButton
        key="next"
        primary={!isCancelPrimary}
        title={t('common:button.next')}
        enabled={nextEnabled}
        disabledTooltip={resolveTranslation(t, {
          translation: activePage?.nextDisabledTooltip,
          translationKey: activePage?.nextDisabledTooltipKey,
          exists: i18n.exists,
        })}
        onClick={() => !lockNavigation ? wizard.next() : null}
        className="a-smart-popup-button"
      />
    ) : (
      <SmartButton
        key="confirm"
        primary={!isCancelPrimary}
        enabled={isConfirm}
        disabledTooltip={resolveTranslation(t, {
          translation: confirmDisabledTooltip,
          translationKey: confirmDisabledTooltipKey,
          exists: i18n.exists,
        })}
        warning={warning}
        title={_confirmButtonTitle}
        onClick={onConfirm}
        className="a-smart-popup-button"
      />
    )
    if (isCancelPrimary) return [previousButton, nextOrConfirmButton, cancelButton]
    return [cancelButton, previousButton, nextOrConfirmButton]
  }, [
    _cancelButtonTitle,
    _confirmButtonTitle,
    activePage?.nextDisabledTooltip,
    activePage?.nextDisabledTooltipKey,
    isCancelPrimary,
    isConfirm,
    isNext,
    isPrevious,
    lockNavigation,
    nextEnabled,
    onCancel,
    onConfirm,
    previousEnabled,
    t,
    warning,
    confirmDisabledTooltip,
    confirmDisabledTooltipKey,
    wizard,
    _isCancel,
    activePage?.previousDisabledTooltip,
    i18n.exists,
  ])

  if (!isOpen) {
    return null
  }

  return ReactDOM.createPortal(
    (
      <div
        className={`a-smart-popup a-form-element-container${className ? ` ${className}` : ''}`}
        data-id={popup.id}
      >
        <div className="a-flex-column w-full">
          <div className="a-smart-popup-overlay a-hidden" />
          <div className="a-form-element-content a-smart-popup-header">
            <div className="a-smart-popup-title">
              {title}
            </div>
            <div className="a-smart-popup-actions">
              {customHeaderActions?.map((action) => action())}
              {typeof onCancel === 'function' ? (
                <div
                  className={`a-smart-popup-action ${_isCancel ? '' : 'a-disabled'}`}
                  onClick={() => _isCancel ? onCancel() : null}
                >
                  <FontAwesomeIcon icon={['fas', 'xmark']} />
                </div>
              ) : null}
            </div>
          </div>
          {wizard?.pages.length > 1 ? (
            <Steps
              className="a-smart-popup-wizard-header"
              model={wizard.pages.map((page) => ({
                label: resolveTranslation(
                  t, { translation: page.title, translationKey: page.titleTranslationKey, exists: i18n.exists },
                ),
              }))}
              activeIndex={activePageIndex}
            />
          ) : null}
          <div className={activePageClasses.join(' ')}>
            {activePage?.content || children}
          </div>
          {!hideFooter ? (
            <div className="a-form-element-content a-smart-popup-footer">
              {!emptyFooter ? (
                <>
                  <div className="a-smart-popup-footer-info">
                    {typeof footerInfoTemplate === 'function' ? footerInfoTemplate() : undefined}
                  </div>
                  <div className="a-smart-popup-buttons">
                    {footerButtonOrder.map((button) => button)}
                  </div>
                </>
              ) : null}
            </div>
          ) : null}
        </div>
        {sideContent}
      </div>
    ),
    document.querySelector(`.${appendToClass}`),
  )
}

export default SmartPopup
