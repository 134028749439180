import { dimensionComplexColumn } from 'components/alix-front/legacy-smart-grid/columns/items'
import { mapColumns } from 'components/alix-front/smart-grid/columns/utils'

import { ReadingRegister, ReadingRegisterEntity } from 'reducers/readings-register/readingRegisterSlice'

const columnsToExclude: (keyof ReadingRegister)[] = [
  'id', 'deviceId', 'equipmentId', 'stepId', 'entityId', 'measureUnit', 'createdById', 'modifiedById',
]

const columnsToHide: (keyof ReadingRegister)[] = [
  'modifiedBy', 'modifiedDate',
]

const entity = ReadingRegisterEntity
const fields = entity.getFields()

export const columnDict = Object.keys(fields).reduce(
  (acc, field: keyof ReadingRegister) => {
    const fieldData = fields[field]

    if (columnsToExclude.includes(field)) {
      return acc
    }

    if (fieldData.translationPath) {
      delete fieldData.translationPath
    }

    const realField = fieldData.dbField

    acc[field] = {
      field: field,
      sortable: realField,
      filter: realField,
      hidden: columnsToHide.includes(field),
      ...fields[field],
    }
    return acc
  },
  {} as Record<string, any>,
)

export function getComplexColumns(
  t,
  { fields, culture },
) {
  const complexColumns = mapColumns(Object.values(columnDict), {
    t,
    fields,
    entity: entity,
    handlerOptions: {
      culture,
      useEntityTranslation: true,
    },
  }).reduce((acc, column) => {
    acc[column.field] = column
    return acc
  }, {}) as any

  return {
    ...complexColumns,
    dimension: {
      ...dimensionComplexColumn(t),
      header: complexColumns.dimension.header,
    },
  }
}
