import { labelTypes } from 'components/alix-front/labels/label/labelTypes'
import { mapColumns } from 'components/alix-front/smart-grid/columns/utils'

import { entities } from 'utils/entities'

const defaultOptions = {
  sortable: true,
  filter: true,
  isGlobalSearch: true,
}

export const columnDict = {
  name: { field: 'name', isColumnToggle: false, ...defaultOptions },
  status: { field: 'status', filterElement: { type: 'multiselect' }, ...defaultOptions },
  type: { field: 'type', filterElement: { type: 'multiselect',
    options: labelTypes
      .map((type) =>({ label: type, value: type }))
      .concat({ label: 'fallback', value: null }),
  }, ...defaultOptions },
  qrCodeSize: { field: 'qrCodeSize', ...defaultOptions },
  description: { field: 'description', ...defaultOptions },
  label: { field: 'label' },
  zebraLabel: { field: 'zebraLabel' },
}

export function getComplexColumns(t, { fields, simpleDict }) {
  const complexColumns = mapColumns(Object.values(simpleDict), {
    t,
    fields,
    entity: entities['labels'],
    handlerOptions: { useEntityTranslation: true },
  })

  return complexColumns.reduce((acc, column) => {
    switch (column.field) {
    case 'type':
      acc[column.field] = {
        ...column,
        filterElement: {
          ...column.filterElement,
          options: column.filterElement.options.map(
            (option) => ({
              ...option,
              label: t(`labels:label.fields.type.${option.value ? `options.${option.label}` : 'fallback'}`),
            })),
        },
        parseValue: ({ type }) =>
          t(`labels:label.fields.type.${type ? `options.${type}` : 'fallback'}`),
      }
      break
    default:
      acc[column.field] = column
      break
    }
    return acc
  }, {})
}
