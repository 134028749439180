
import { buildGetUrl } from 'utils/api'
import { safeFetch } from 'utils/safeFetch'

import {
  GET_ZOHO_BOOKS_INTEGRATION,
  SET_ZOHO_BOOKS_TAXES,
} from './types'

const initialState = {
  taxDict: {},
  zohoBooksIntegration: {},
}

export default function integrationsReducer(state = initialState, action) {
  const { payload } = action

  switch (action.type) {
  case GET_ZOHO_BOOKS_INTEGRATION: {
    return {
      ...state,
      zohoBooksIntegration: payload,
    }
  }
  case SET_ZOHO_BOOKS_TAXES: {
    return {
      ...state,
      taxDict: payload,
    }
  }
  default: {
    return state
  }
  }
}

export async function fetchIntegrations(type) {
  let integration
  try {
    const result = await (await safeFetch(buildGetUrl(`/api/integrations/${type}`))).json()
    if (result.isSuccess) {
      integration = result.result[0]
    }
  } catch (err) {
    console.error(err)
  }

  return integration
}

export function fetchZohoBooksIntegrations() {
  return async function fetchZohoBooksIntegrationsThunk(dispatch) {
    const zohoBooksIntegration = await fetchIntegrations('zoho-books')
    dispatch({ type: GET_ZOHO_BOOKS_INTEGRATION, payload: zohoBooksIntegration })
  }
}

export async function fetchTaxes(dispatch) {
  const taxDict = await _fetchTaxes(true)
  if (taxDict) {
    dispatch({
      type: SET_ZOHO_BOOKS_TAXES,
      payload: taxDict,
    })
  }
}

export async function _fetchTaxes(withDictionary = false) {
  let taxes = []

  try {
    const result = await (await safeFetch('/api/integrations/zoho-books/taxes')).json()
    if (result.isSuccess) {
      taxes = result.result
      taxes.forEach((tax) => {
        tax.displayName = `${tax.tax_name} [${tax.tax_percentage}%]`
        if (tax.taxes) {
          tax.taxes.forEach((tax) => {
            tax.displayName = `${tax.tax_name} [${tax.tax_percentage}%]`
          })
        }
      })
    }
  } catch (err) {
    console.error(err)
  }

  if (withDictionary) {
    return {
      taxes,
      taxDict: taxes.reduce((acc, tax) => {
        acc[tax.tax_id] = tax
        return acc
      }, {}),
    }
  }

  return taxes
}

export async function fetchTaxRules(type) {
  let taxRules = []

  try {
    const result = await (await safeFetch(buildGetUrl(`/api/integrations/zoho-books/taxes/rules`, { type }))).json()
    if (result.isSuccess) {
      taxRules = result.result
    }
  } catch (err) {
    console.error(err)
  }

  return taxRules
}
