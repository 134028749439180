import React from 'react'

import SmartStatus from 'components/alix-front/smart-status/SmartStatus'

const getStatusColumn = (t, itemTemplate, template) => {
  const simple = { field: 'status', sortable: true, filter: true, filterElement: { type: 'multiselect' } }

  if (typeof t != 'function') {
    return simple
  }

  return {
    ...simple,
    header: t('projects:project.fields.status'),
    filterElement: {
      ...simple.filterElement,
      options: [
        { label: t('projects:status.draft'), value: 'draft' },
        { label: t('projects:status.quote_submitted'), value: 'quote_submitted' },
        { label: t('projects:status.on_hold'), value: 'on_hold' },
        { label: t('projects:status.open'), value: 'open' },
        { label: t('projects:status.closed'), value: 'closed' },
        { label: t('projects:status.canceled'), value: 'canceled' },
      ],
      itemTemplate,
    },
    template,
    hidden: false,
  }
}

export const columnDict = {
  title: { field: 'title', sortable: true, filter: true, isGlobalSearch: true, isColumnToggle: false },
  status: getStatusColumn(),
  type: {
    field: 'type',
    sortable: true,
    isDefaultSort: true,
    defaultSortOrder: 1,
    filter: true,
    filterElement: { type: 'multiselect' },
  },
  formattedNumber: { field: 'formattedNumber', sortable: true, filter: true, isGlobalSearch: true },
  plantName: { field: 'plantName', sortable: true, filter: true, isGlobalSearch: true },
  clientDisplayName: { field: 'clientDisplayName', sortable: true, filter: true, isGlobalSearch: true },
  ownerFullName: { field: 'ownerFullName' },
  clientOnLabel: { field: 'clientOnLabel', sortable: true, filter: true, filterElement: { type: 'multiselect' } },
  promisedDate: { field: 'promisedDate', sortable: true, filter: true },
  createdBy: { field: 'createdBy', sortable: true, filter: true },
  createdDate: { field: 'createdDate', sortable: true, filter: true },
  modifiedBy: { field: 'modifiedBy', sortable: true, filter: true },
  modifiedDate: { field: 'modifiedDate', sortable: true, filter: true },
}

export function getComplexColumns(t, { dateColumnTemplate }) {
  return {
    title: { header: t('projects:project.fields.title.label'), hidden: false },
    status: getStatusColumn(
      t,
      (option) => (
        <SmartStatus
          className="a-smart-grid-icon-info"
          status={option.value}
          dictionaryKey="project"
        />
      ),
      (props) => (
        <SmartStatus
          className="a-smart-grid-icon-info"
          status={props.status}
          dictionaryKey="project"
        />
      ),
    ),
    type: {
      header: t('projects:project.fields.type.label'),
      hidden: false,
      filterElement: {
        ...columnDict.type.filterElement,
        options: [
          { label: t('projects:type.manufacturing'), value: 'manufacturing' },
          { label: t('projects:type.sales'), value: 'sales' },
          { label: t('projects:type.standard'), value: 'standard' },
          { label: t('projects:type.template'), value: 'template' },
        ],
      },
      template: (props) => t(`projects:type.${props.type}`),
    },
    formattedNumber: { header: t('projects:project.fields.formattedNumber.label'), hidden: false },
    plantName: { header: t('projects:project.fields.plantName.label'), hidden: false },
    clientDisplayName: { header: t('projects:project.fields.clientDisplayName.label'), hidden: false },
    ownerFullName: { header: t('projects:project.fields.ownerFullName.label'), hidden: false },
    clientOnLabel: {
      header: t('projects:project.fields.clientOnLabel'),
      filterElement: {
        ...columnDict.clientOnLabel.filterElement,
        options: [
          { label: t('common:activated'), value: 'true' },
          { label: t('common:deactivated'), value: 'false' },
        ],
      },
      parseValue: (props) => t(`common:${props.clientOnLabel ? 'activated' : 'deactivated'}`),
    },
    promisedDate: {
      header: t('projects:project.fields.promisedDate'),
      hidden: false,
      template: (props) => dateColumnTemplate(props.promisedDate, true),
    },
    createdBy: { header: t('projects:project.fields.createdBy') },
    createdDate: {
      header: t('projects:project.fields.createdDate'),
      template: (props) => dateColumnTemplate(props.createdDate),
    },
    modifiedBy: { header: t('projects:project.fields.modifiedBy'), hidden: false },
    modifiedDate: {
      header: t('projects:project.fields.modifiedDate'),
      hidden: false,
      template: (props) => dateColumnTemplate(props.modifiedDate),
    },
  }
}
