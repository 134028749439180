import { AppDispatch } from 'store'

import {
  SET_READ_SCALE_ERROR,
  SET_SCALE_MIN_OUT_OF_RANGE_ERROR,
  SET_SCALE_MAX_OUT_OF_RANGE_ERROR,
  CLEAR_READ_SCALE_ERROR,
  CLEAR_SCALE_MIN_OUT_OF_RANGE_ERROR,
  CLEAR_SCALE_MAX_OUT_OF_RANGE_ERROR,
} from './types'

const initState = {
  readScaleError: null,
  minOutOfRangeError: null,
  maxOutOfRangeError: null,
}

export default function iiotErrorReducer(state = initState, action: any) {
  const { error } = action

  switch (action.type) {
  case SET_READ_SCALE_ERROR: {
    return {
      ...state,
      readScaleError: error,
    }
  }
  case CLEAR_READ_SCALE_ERROR: {
    return {
      ...state,
      readScaleError: null,
    }
  }
  case SET_SCALE_MIN_OUT_OF_RANGE_ERROR: {
    return {
      ...state,
      minOutOfRangeError: error,
    }
  }
  case CLEAR_SCALE_MIN_OUT_OF_RANGE_ERROR: {
    return {
      ...state,
      minOutOfRangeError: null,
    }
  }
  case SET_SCALE_MAX_OUT_OF_RANGE_ERROR: {
    return {
      ...state,
      maxOutOfRangeError: error,
    }
  }
  case CLEAR_SCALE_MAX_OUT_OF_RANGE_ERROR: {
    return {
      ...state,
      maxOutOfRangeError: null,
    }
  }
  default:
    return state
  }
}

export function clearReadScaleError(dispatch: AppDispatch) {
  dispatch({ type: CLEAR_READ_SCALE_ERROR })
}

export function setMinOutOfRangeError(min: string, read: string) {
  return function setMinOutOfRangeErrorThunk(dispatch: AppDispatch) {
    dispatch({ type: SET_SCALE_MIN_OUT_OF_RANGE_ERROR, error: { error: { name: 'MinOutOfRangeError' }, min, read } })
  }
}

export function clearMinOutOfRangeError(dispatch: AppDispatch) {
  dispatch({ type: CLEAR_SCALE_MIN_OUT_OF_RANGE_ERROR })
}

export function setMaxOutOfRangeError(max: string, read: string) {
  return function setMaxOutOfRangeErrorThunk(dispatch: AppDispatch) {
    dispatch({ type: SET_SCALE_MAX_OUT_OF_RANGE_ERROR, error: { error: { name: 'MaxOutOfRangeError' }, max, read } })
  }
}

export function clearMaxOutOfRangeError(dispatch: AppDispatch) {
  dispatch({ type: CLEAR_SCALE_MAX_OUT_OF_RANGE_ERROR })
}
