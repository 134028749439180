import { createSlice } from '@reduxjs/toolkit'
import { HubAppDispatch } from 'store'
import { ApiToSlice, GetFields } from 'types/slices'

import { buildGetUrl, parse } from 'utils/api'
import { valueOrDefault } from 'utils/defaultValueHelper'
import { EntityType } from 'utils/entities'
import { isJob, safeFetchJson } from 'utils/safeFetch'

// alix-hub dataSetName
const dataSetName = 'client'

const initialState = {
  dataSetName,
  fields: getClientFields(),
  count: 0,
  data: [],
}

const clientSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload.data
    },
    setCount: (state, action) => {
      state.count = action.payload.count
    },
    addTestEnvironment: (state, action) => {
      state.count++
      state.data.push(action.payload.client)
      state.data.find((client) => client.id === action.payload.parentClientId).testId = action.payload.client.id
    },
    removeTestEnvironment: (state, action) => {
      state.count--
      state.data = state.data.filter((client) => client.id !== action.payload.clientId)
      state.data.find((client) => client.testId === action.payload.clientId).testId = null
    },
  },
})

export const {
  setData: setClients,
  setCount: setClientsCount,
  addTestEnvironment,
  removeTestEnvironment,
} = clientSlice.actions

export const ClientTypeEnum = {
  SANDBOX: 'sandbox',
  TEST: 'test',
  PRODUCTION: 'production',
  DEMO: 'demo',
} as const

// eslint-disable-next-line no-var
var ClientType = Object.values(ClientTypeEnum)

export type ClientApi = {
  id: string;
  title: string;
  isactive: boolean;
  hostname: string;
  alix_app_version_id: number;
  hub_app_version_id: number;
  client_type: string;
  alix_database_id: number;
  exist: boolean;
  type: typeof ClientType[number];
  tenant_code: string;
  trial_start_date: string | null;
  trial_duration: number;
  is_offline_payment: boolean;
  zoho_subscription_customer_id: string;
  custom_identity_provider_id: string | null;
  sandbox_id: string | null;
  test_id: string | null;
  appversion_id: number;
  appversion_exist: boolean;
  appversion_created_date: string;
  appversion_modified_date: string;
  appversion_scope: string;
  appversion_name: string;
  appversion_description: string | null;
  appversion_default_alix_database_id: number | null;
}

export type Client = ApiToSlice<ClientApi>

type ClientGetFields = GetFields<ClientApi, Client>

export function getClientFields(): ClientGetFields {
  return {
    'id': { dataSetName, dbField: 'id', type: 'string' },
    'title': { dataSetName, dbField: 'title', type: 'string' },
    'isactive': { dataSetName, dbField: 'isactive', type: 'boolean' },
    'hostname': { dataSetName, dbField: 'hostname', type: 'string' },
    'alixAppVersionId': { dataSetName, dbField: 'alix_app_version_id', type: 'integer' },
    'hubAppVersionId': { dataSetName, dbField: 'hub_app_version_id', type: 'integer' },
    'clientType': { dataSetName, dbField: 'client_type', type: 'string' },
    'alixDatabaseId': { dataSetName, dbField: 'alix_database_id', type: 'integer' },
    'exist': { dataSetName, dbField: 'exist', type: 'boolean' },

    'type': {
      dataSetName,
      dbField: 'type',
      type: 'status',
      dictionaryKey: 'client',
      values: ClientType,
      translationPath: 'clients:client.fields.type.values',
      dictionaryType: 'type',
    },

    'tenantCode': { dataSetName, dbField: 'tenant_code', type: 'string' },
    'trialStartDate': { dataSetName, dbField: 'trial_start_date', type: 'string' },
    'trialDuration': { dataSetName, dbField: 'trial_duration', type: 'integer' },
    'isOfflinePayment': { dataSetName, dbField: 'is_offline_payment', type: 'boolean' },
    'zohoSubscriptionCustomerId': { dataSetName, dbField: 'zoho_subscription_customer_id', type: 'string' },
    'customIdentityProviderId': { dataSetName, dbField: 'custom_identity_provider_id', type: 'string' },
    'sandboxId': { dataSetName, dbField: 'sandbox_id', type: 'string' },
    'testId': { dataSetName, dbField: 'test_id', type: 'string' },
    'appversionId': { dataSetName, dbField: 'appversion_id', type: 'integer' },
    'appversionExist': { dataSetName, dbField: 'appversion_exist', type: 'boolean' },
    'appversionCreatedDate': { dataSetName, dbField: 'appversion_created_date', type: 'string' },
    'appversionModifiedDate': { dataSetName, dbField: 'appversion_modified_date', type: 'string' },
    'appversionScope': { dataSetName, dbField: 'appversion_scope', type: 'string' },
    'appversionName': { dataSetName, dbField: 'appversion_name', type: 'string' },
    'appversionDescription': { dataSetName, dbField: 'appversion_description', type: 'string' },
    'appversionDefaultAlixDatabaseId': { dataSetName, dbField: 'appversion_default_alix_database_id', type: 'integer' },
  }
}

export function getClientTitle(client: Client): string {
  return client.title
}

export function fetchClients(fetchData?: Record<string, any>) {
  return async function fetchClientsThunk(dispatch: HubAppDispatch) {
    const data = await _fetchClients(fetchData)
    dispatch(setClients({ data }))
    return data
  }
}

export async function _fetchClients(fetchData: Record<string, any> = {}) {
  let clients = []

  try {
    const { isSuccess, result } = await safeFetchJson<ClientApi, true>(
      buildGetUrl('/api/admin/clients', fetchData),
    )
    if (isSuccess && !isJob(result)) {
      clients = result.map((client) => parseClient(client))
    }
  } catch (err) {
    console.error(err)
  }

  return clients
}

export function parseClient(client: ClientApi): Client {
  const options = {
    defaultData: getDefaultClient(),
    fields: initialState.fields,
    dataSetName: dataSetName,
  }

  return parse(client, options)
}

function getDefaultClient(): Client {
  return parse({}, { fields: initialState.fields })
}

export async function createTestEnvironment(clientId: string) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ clientId }),
  }
  const { isSuccess, result } = await safeFetchJson<ClientApi, false>('/api/admin/test-environment', requestOptions)

  const client = isSuccess && !isJob<ClientApi, false>(result) ? parseClient(result) : null
  const error = !isSuccess ? result : null
  return { isSuccess, client, error }
}

export async function refreshTestEnvironment(clientId: string) {
  const { isSuccess, result } = await safeFetchJson(
    `/api/admin/test-environment/refresh/${clientId}`,
    { method: 'POST' },
  )
  const error = !isSuccess ? result : null
  return { isSuccess, error }
}

export async function deleteTestEnvironment(clientId: string) {
  const { isSuccess, result } = await safeFetchJson(`/api/admin/test-environment/${clientId}`, { method: 'DELETE' })
  const error = !isSuccess ? result : null
  return { isSuccess, error }
}

export const ClientEntity: EntityType<Client> = {
  translationFile: 'clients',
  coreRelationName: 'client',
  getFields: getClientFields,
  // (odeschenes) : Entity only used in the admin panel, no need for a fetcher for the events
  fetcher: async() => [],
  getTitle: getClientTitle,
  namespace: (entity, field, options = {}) => {
    const fieldState = valueOrDefault(options.fieldState, 'label')

    return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.${fieldState}`
  },
  relationTranslationFiles: [],
  getDimension: (entity, entityField) => null,
  getUnit: (entity, entityField) => null,
  parser: parseClient,
}

export default clientSlice.reducer
