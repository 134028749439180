export type DefaultRecord = Record<string, any>

export const getKeys = <T extends DefaultRecord>(obj: T) => Object.keys(obj) as (keyof T)[]

export type PrefixedRecord<
  T extends DefaultRecord,
  P extends string
> = {
  [K in keyof T as K extends string ? `${P}${K}` : never]: T[K]
}

export function prefixRecord<
  T extends Record<string, any>,
  P extends string
>(obj: T, prefix: P): { [K in keyof T as `${P}${Capitalize<string & K>}`]: T[K] } {
  const result = {} as { [K in keyof T as `${P}${Capitalize<string & K>}`]: T[K] };

  (Object.keys(obj) as Array<keyof T>).forEach((key) => {
    const capitalizedKey = `${String(key).charAt(0).toUpperCase()}${String(key).slice(1)}`
    const prefixedKey = `${prefix}${capitalizedKey}`

    result[prefixedKey] = obj[key]
  })

  return result
}

export type DeepPartial<T> = T extends object ? {
  [P in keyof T]?: DeepPartial<T[P]>;
} : T;

/**
 * Used like : T without U
 */
export type Without<T, U> = Pick<T, Exclude<keyof T, keyof U>>;
