import React from 'react'

import moment from 'moment'
import { v4 as uuid } from 'uuid'

import { FilterMatchMode } from 'primereact/api'
import { InputText } from 'primereact/inputtext'

import FilterCalendar, { parseRange } from 'components/alix-front/legacy-smart-grid/FilterCalendar'
import FilterMultiSelect from 'components/alix-front/legacy-smart-grid/FilterMultiSelect'

import { addToDate, dateToString, getNow } from 'utils/dateParser'
import { valueOrTrue } from 'utils/defaultValueHelper'

import { NO_VALUES_MATCH_MODES as NO_VALUES_MATCH_MODES_IMPORT } from 'reducers/views/viewsSlice'

import {
  columnDict as attributesDict,
  getComplexColumns as getAttributeComplexColumns,
} from './attributes'
import {
  columnDict as cardDict,
  getComplexColumns as getCardComplexColumns,
} from './cards'
import {
  columnDict as clientColumnDict,
  getComplexColumns as getClientComplexColumns,
} from './clients'
import {
  columnDict as consumptionsDict,
  getComplexColumns as getConsumptionsComplexColumns,
} from './consumptions'
import { columnDict as contactDict, getComplexColumns as getContactComplexColumns } from './contacts'
import {
  columnDict as costCardDict,
  getComplexColumns as getCostCardComplexColumns,
} from './cost-cards'
import {
  columnDict as countryOfOriginsDict,
  getComplexColumns as getCountryOfOriginComplexColumns,
} from './country-of-origins'
import {
  columnDict as dashboardsDict,
  getComplexComlumns as getDashboardComplexColumns,
} from './dashboards'
import DateColumn from './DateColumn'
import {
  columnDict as emailTemplateDict,
  getComplexColumns as getEmailTemplateComplexColumns,
} from './email-templates'
import {
  columnDict as harmonizedSystemCodesDict,
  getComplexColumns as getHarmonizedSystemCodeComplexColumns,
} from './harmonized-system-codes'
import {
  columnDict as incotermsDict,
  getComplexColumns as getIncotermComplexColumns,
} from './incoterms'
import {
  columnDict as inventoryColumnDict,
  getComplexColumns as getInventoryComplexColumns,
} from './inventories'
import {
  columnDict as itemColumnDict,
  getComplexColumns as getItemComplexColumns,
} from './items'
import {
  columnDict as labelsDict,
  getComplexColumns as getLabelComplexColumns,
} from './labels'
import {
  columnDict as planningDict,
  getComplexColumns as getPlanningComplexColumns,
} from './planning'
import {
  columnDict as projectDict,
  getComplexColumns as getProjectComplexColumns,
} from './projects'
import {
  columnDict as purchaseOrderItemDict,
  getComplexColumns as purchaseOrderItemComplexColumns,
} from './purchase-order-item'
import {
  columnDict as purchaseOrderDict,
  getComplexColumns as getPurchaseOrderComplexColumns,
} from './purchase-orders'
import {
  columnDict as readingRegisterColumnDict,
  getComplexColumns as getReadingRegisterComplexColumns,
} from './readings-register'
import {
  columnDict as receptionItemDict,
  getComplexColumns as receptionItemComplexColumns,
} from './reception-item'
import {
  columnDict as receptionsDict,
  getComplexColumns as getReceptionsComplexColumns,
} from './receptions'
import {
  columnDict as salesOrderItemDict,
  getComplexColumns as getSalesOrderItemComplexColumns,
} from './sales-order-item'
import {
  columnDict as salesOrderDict,
  getComplexColumns as getSalesOrderComplexColumns,
} from './sales-orders'
import {
  columnDict as shipmentItemDict,
  getComplexColumns as shipmentItemComplexColumns,
} from './shipment-item'
import {
  columnDict as shipmentPlanningDict,
  getComplexColumns as getShipmentPlanningComplexColumns,
} from './shipment-planning'
import {
  columnDict as shipmentColumnDict,
  getComplexColumns as getShipmentComplexColumns,
} from './shipments'

/* ********** SORTS ********** */
export function gridSortToOrderBy(sortField, sortOrder, fields) {
  const orderByList = []

  if (sortField) {
    const fieldInfo = fields?.[sortField]
    if (fieldInfo) {
      orderByList.push(_fieldInfoToOrderBy(fieldInfo, sortOrder))
    }
  }

  return orderByList
}

export function getIdOrderBy(fields, idKey) {
  const idFieldInfo = (fields ?? {})?.[idKey]
  if (idFieldInfo) {
    return _fieldInfoToOrderBy(idFieldInfo)
  }
}

export function viewSortToDbOrderBy(sort, fields) {
  return sort.filter((s) => !!fields?.[s.field]).map((s) => {
    const fieldInfo = fields?.[s.field]
    return _fieldInfoToOrderBy(fieldInfo, s.order, s.subQueryParser)
  })
}

function _fieldInfoToOrderBy(fieldInfo, order = 1, subQueryParser) {
  const dbFieldWithAlias = fieldInfo.useTrimAliasInField && fieldInfo.trimAlias ?
    `${fieldInfo.trimAlias}_${fieldInfo.dbField}` :
    fieldInfo.dbField
  const isSubQuery = typeof subQueryParser === 'function'
  return {
    dataSetName: fieldInfo.dataSetName,
    alias: fieldInfo.dataSetAlias,
    value: isSubQuery ? subQueryParser(dbFieldWithAlias) : dbFieldWithAlias,
    type: isSubQuery ? 'sub-query' : undefined,
    direction: +order === -1 ? 'DESC' : 'ASC',
    isNullLast: +order === -1 ? true : undefined,
    isNullFirst: +order === 1 ? true : undefined,
  }
}

/* ********** CONSTRAINTS ********** */
export const NO_VALUES_MATCH_MODES = NO_VALUES_MATCH_MODES_IMPORT
export const TO_MATCH_MODE_DICT = {}
TO_MATCH_MODE_DICT.in = { abbreviation: 'in', comparator: 'IN' }
TO_MATCH_MODE_DICT[FilterMatchMode.CONTAINS] = { abbreviation: 'ct', comparator: 'ILIKE' }
TO_MATCH_MODE_DICT[FilterMatchMode.NOT_CONTAINS] = { abbreviation: 'nct', comparator: 'NOT LIKE' }
TO_MATCH_MODE_DICT[FilterMatchMode.STARTS_WITH] = { abbreviation: 'sw', comparator: 'START' }
TO_MATCH_MODE_DICT[FilterMatchMode.ENDS_WITH] = { abbreviation: 'ew', comparator: 'END' }
TO_MATCH_MODE_DICT[FilterMatchMode.EQUALS] = { abbreviation: 'eq', comparator: '=' }
TO_MATCH_MODE_DICT[FilterMatchMode.NOT_EQUALS] = { abbreviation: 'neq', comparator: '!=' }
TO_MATCH_MODE_DICT[FilterMatchMode.LESS_THAN] = { abbreviation: 'lt', comparator: '<' }
TO_MATCH_MODE_DICT[FilterMatchMode.LESS_THAN_OR_EQUAL_TO] = { abbreviation: 'lte', comparator: '<=' }
TO_MATCH_MODE_DICT[FilterMatchMode.GREATER_THAN] = { abbreviation: 'gt', comparator: '>' }
TO_MATCH_MODE_DICT[FilterMatchMode.GREATER_THAN_OR_EQUAL_TO] = { abbreviation: 'gte', comparator: '>=' }
TO_MATCH_MODE_DICT[FilterMatchMode.DATE_IS] = { abbreviation: 'di', comparator: '=' }
TO_MATCH_MODE_DICT[FilterMatchMode.DATE_IS_NOT] = { abbreviation: 'din', comparator: '!=' }
TO_MATCH_MODE_DICT[FilterMatchMode.DATE_BEFORE] = { abbreviation: 'db', comparator: '<' }
TO_MATCH_MODE_DICT.dbe = { abbreviation: 'dbe', comparator: '<' }
TO_MATCH_MODE_DICT[FilterMatchMode.DATE_AFTER] = { abbreviation: 'da', comparator: '>' }
TO_MATCH_MODE_DICT.dae = { abbreviation: 'dae', comparator: '>=' }
TO_MATCH_MODE_DICT.isEmpty = { abbreviation: 'ie', comparator: 'IS EMPTY' }
TO_MATCH_MODE_DICT.isNotEmpty = { abbreviation: 'ine', comparator: 'IS NOT EMPTY' }

export const FROM_MATCH_MODE_DICT = Object.keys(TO_MATCH_MODE_DICT).reduce((acc, key) => {
  acc[TO_MATCH_MODE_DICT[key].abbreviation] = key
  return acc
}, {})

const DEFAULT_MATCH_MODE_DICT = {
  'multiselect': 'in',
  'date': FilterMatchMode.DATE_IS,
  'numeric': FilterMatchMode.EQUALS,
}

export function getDefaultConstraint(column) {
  return {
    value: null,
    matchMode: DEFAULT_MATCH_MODE_DICT[column.dataType || column.filterElement?.type] || FilterMatchMode.CONTAINS,
  }
}

export function gridFilterModelToConstraints(filterModel) {
  if (!filterModel) {
    return []
  }
  return (filterModel.constraints ? filterModel.constraints : [filterModel])
    .filter((constraint) => !!constraint.value || NO_VALUES_MATCH_MODES.includes(constraint.matchMode))
}

export function gridFiltersToConditions(filters = {}, fields, isFetchPost) {
  const conditions = []

  Object.keys(filters).forEach((filterKey) => {
    const filterModel = filters[filterKey]
    const fieldInfo = fields?.[filterKey]
    const toFilter = gridFilterModelToConstraints(filterModel)
    if (fieldInfo && toFilter.length > 0) {
      conditions.push({
        type: `${filterModel.operator || 'and'}Conditions`,
        value: toFilter.map((constraint) => _constraintToSubCondition(constraint, fieldInfo, isFetchPost)),
      })
    }
  })

  return conditions
}

export function viewContraintToDbConditionR(constraint, fields, isFetchPost) {
  if (!constraint?.constraints?.length) {
    return null
  }

  return {
    type: `${constraint.operator}Conditions`,
    value: constraint.constraints.map((c) => {
      if (c.operator) {
        return viewContraintToDbConditionR(c, fields, isFetchPost)
      } else {
        return _constraintToSubCondition(c, fields[c.filterField || c.field], isFetchPost)
      }
    }),
  }
}

function _constraintToSubCondition(constraint, fieldInfo, isFetchPost) {
  const dbFieldWithAlias = fieldInfo.useTrimAliasInField && fieldInfo.trimAlias ?
    `${fieldInfo.trimAlias}_${fieldInfo.dbField}` :
    fieldInfo.dbField
  let value = constraint.value

  const dateMatchModes = [FilterMatchMode.DATE_IS, 'dbe', 'dae']
  if (!!constraint.options && dateMatchModes.includes(constraint.matchMode)) {
    value = addToDate(
      getNow(true),
      constraint.options.unit,
      parseRange(constraint.options.type, constraint.options.range),
    )
  }

  let subCondition = {
    dataSetName: fieldInfo.dataSetName,
    alias: fieldInfo.dataSetAlias,
    column: dbFieldWithAlias,
    value: typeof value === 'string' && !isFetchPost ?
      encodeURIComponent(value.replaceAll('\\', '\\\\').replaceAll(/\\*"/g, '\\"')) :
      value,
    comparator: TO_MATCH_MODE_DICT[constraint.matchMode].comparator,
  }

  if (fieldInfo.type === 'enum_array') {
    subCondition.comparator = '@>'
    subCondition.value = `{${subCondition.value.map((v) => `"${v}"`).join(',')}}`
  } else if (constraint.matchMode === FilterMatchMode.DATE_IS) {
    const endDate = new Date(value)
    subCondition = { type: 'andConditions', value: [
      {
        dataSetName: fieldInfo.dataSetName,
        alias: fieldInfo.dataSetAlias,
        column: dbFieldWithAlias,
        value: dateToString(moment(value), fieldInfo.isTimezoned),
        comparator: '>=',
      },
      {
        dataSetName: fieldInfo.dataSetName,
        alias: fieldInfo.dataSetAlias,
        column: dbFieldWithAlias,
        value: dateToString(moment(endDate.setDate(endDate.getDate() + 1)), fieldInfo.isTimezoned),
        comparator: '<',
      },
    ] }
  } else if (constraint.matchMode === 'dbe') {
    const endDate = new Date(value)
    subCondition.value = dateToString(moment(endDate.setDate(endDate.getDate() + 1)), fieldInfo.isTimezoned)
  } else if (constraint.matchMode === 'dae') {
    subCondition.value = dateToString(moment(value), fieldInfo.isTimezoned)
  } else if (constraint.matchMode === FilterMatchMode.NOT_CONTAINS) {
    subCondition = { type: 'orConditions', value: [
      subCondition,
      {
        dataSetName: fieldInfo.dataSetName,
        alias: fieldInfo.dataSetAlias,
        column: dbFieldWithAlias,
        comparator: 'IS NULL',
      },
    ] }
  }

  return subCondition
}

/* ********** COLUMNS ********** */
export const COLUMN_TYPE_DICT = {
  'timestamp': 'date',
  'date': 'date',
}

const dateColumnTemplate = (date, hideHours) => (
  <DateColumn
    date={date}
    hideHours={hideHours}
  />
)

export const columnDict = {
  'attributes': attributesDict,
  'country-of-origins': countryOfOriginsDict,
  'harmonized-system-codes': harmonizedSystemCodesDict,
  'incoterms': incotermsDict,
  'labels': labelsDict,
  'shipments': shipmentColumnDict,
  'inventories': inventoryColumnDict,
  'items': itemColumnDict,
  'sales-orders': salesOrderDict,
  'purchase-orders': purchaseOrderDict,
  'projects': projectDict,
  'cards': cardDict,
  'planning': planningDict,
  'costCards': costCardDict,
  'vendors': contactDict,
  'customers': contactDict,
  'sales-order-items': salesOrderItemDict,
  'purchase-order-items': purchaseOrderItemDict,
  'shipment-line-items': shipmentItemDict,
  'shipment-planning': shipmentPlanningDict,
  'receptions': receptionsDict,
  'reception-line-items': receptionItemDict,
  'email-templates': emailTemplateDict,
  'consumptions': consumptionsDict,
  'clients': clientColumnDict,
  'dashboards': dashboardsDict,
  'readings-register': readingRegisterColumnDict,
}

/**
 * @param {import('i18next').i18n['t']} t
 * @param {keyof columnDict} type
 * @param {Object} options
 * @returns
 */
export function getComplexColumns(t, type, options = { }) {
  const simpleDict = columnDict[type]
  let complexColumnDict = {}
  let hidden = true

  const { skipViewHiddenColumns = true } = options
  switch (type) {
  case 'shipments': {
    complexColumnDict = getShipmentComplexColumns(t, { ...options, dateColumnTemplate })
    break
  }
  case 'inventories': {
    complexColumnDict = getInventoryComplexColumns(t, { ...options, dateColumnTemplate, simpleDict })
    break
  }
  case 'items': {
    hidden = false
    complexColumnDict = getItemComplexColumns(t, { ...options, dateColumnTemplate, simpleDict })
    break
  }
  case 'sales-orders': {
    hidden = false
    complexColumnDict = getSalesOrderComplexColumns(t, { ...options, dateColumnTemplate })
    break
  }
  case 'purchase-orders': {
    hidden = false
    complexColumnDict = getPurchaseOrderComplexColumns(t, { ...options, dateColumnTemplate })
    break
  }
  case 'projects': {
    complexColumnDict = getProjectComplexColumns(t, { ...options, dateColumnTemplate })
    break
  }
  case 'cards': {
    hidden = false
    complexColumnDict = getCardComplexColumns(t, { ...options, dateColumnTemplate, simpleDict })
    break
  }
  case 'planning': {
    hidden = false
    complexColumnDict = getPlanningComplexColumns(t, { ...options, simpleDict })
    break
  }
  case 'consumptions': {
    hidden = false
    complexColumnDict = getConsumptionsComplexColumns(t, { ...options })
    break
  }
  case 'costCards': {
    hidden = false
    complexColumnDict = getCostCardComplexColumns(t, { ...options, dateColumnTemplate, simpleDict })
    break
  }
  case 'shipment-planning': {
    hidden = false
    complexColumnDict = getShipmentPlanningComplexColumns(t, { ...options, simpleDict, dateColumnTemplate })
    break
  }
  case 'vendors':
  case 'customers': {
    hidden = false
    complexColumnDict = getContactComplexColumns(t, { ...options, simpleDict })
    break
  }
  case 'sales-order-items': {
    hidden = false
    complexColumnDict = getSalesOrderItemComplexColumns(t, { ...options, dateColumnTemplate, simpleDict })
    break
  }
  case 'purchase-order-items': {
    hidden = false
    complexColumnDict = purchaseOrderItemComplexColumns(t, { ...options, dateColumnTemplate, simpleDict })
    break
  }
  case 'shipment-line-items': {
    hidden = false
    complexColumnDict = shipmentItemComplexColumns(t, { ...options, dateColumnTemplate })
    break
  }
  case 'receptions': {
    hidden = false
    complexColumnDict = getReceptionsComplexColumns(t, { ...options, dateColumnTemplate })
    break
  }
  case 'reception-line-items': {
    hidden = false
    complexColumnDict = receptionItemComplexColumns(t, { ...options, dateColumnTemplate, simpleDict })
    break
  }
  case 'email-templates': {
    hidden = false
    complexColumnDict = getEmailTemplateComplexColumns(t, { ...options, dateColumnTemplate, simpleDict })
    break
  }
  case 'attributes': {
    hidden = false
    complexColumnDict = getAttributeComplexColumns(t, { ...options, simpleDict })
    break
  }
  case 'country-of-origins': {
    hidden = false
    complexColumnDict = getCountryOfOriginComplexColumns(t, { ...options, simpleDict })
    break
  }
  case 'harmonized-system-codes': {
    hidden = false
    complexColumnDict = getHarmonizedSystemCodeComplexColumns(t, { ...options, simpleDict })
    break
  }
  case 'incoterms': {
    hidden = false
    complexColumnDict = getIncotermComplexColumns(t, { ...options, simpleDict })
    break
  }
  case 'clients': {
    hidden = false
    complexColumnDict = getClientComplexColumns(t, { ...options, simpleDict })
    break
  }
  case 'labels': {
    hidden = false
    complexColumnDict = getLabelComplexColumns(t, { ...options, simpleDict })
    break
  }
  case 'dashboards': {
    hidden = false
    complexColumnDict = getDashboardComplexColumns(t, { ...options, simpleDict })
    break
  }
  case 'readings-register': {
    hidden = false
    complexColumnDict = getReadingRegisterComplexColumns(t, { ...options, simpleDict, dateColumnTemplate })
    break
  }
  }

  const resultDict = { ...simpleDict }
  Object.keys(complexColumnDict).forEach((key) => {
    if (!simpleDict[key]) {
      resultDict[key] = complexColumnDict[key]
    }
  })

  const mergedDict = Object
    .keys(resultDict)
    .reduce(
      (acc, key, index) => {
        const fieldInfo = options.fields?.[key]
        const _mergedColumn = {
          hidden,
          ...simpleDict[key],
          ...complexColumnDict[key],
          ...options.overwriteColumnDict?.[key],
        }

        if (skipViewHiddenColumns && _mergedColumn.isViewHidden) return acc

        acc[key] = {
          ..._mergedColumn,
          id: uuid(),
          order: index,
          fieldInfo,
          dataType: COLUMN_TYPE_DICT[fieldInfo?.type],
          sortable: (
            _mergedColumn.sortable &&
            ((fieldInfo?.dataSetName && fieldInfo?.dbField) || fieldInfo?.dataSetAlias)
          ),
        }
        acc[key].isColumnToggle = typeof acc[key].isColumnToggle === 'boolean' ? acc[key].isColumnToggle : true

        return acc
      },
      {},
    )

  return {
    dict: mergedDict,
    array: Object.keys(mergedDict).map((dictKey, index) => ({ ...mergedDict[dictKey], order: index })),
  }
}

export function getFilterElement(column) {
  if (column?.dataType === 'date') {
    return FilterCalendar
  } else if (column?.filterElement?.type === 'multiselect') {
    return FilterMultiSelect
  }

  return InputText
}

export function getMatchModes(t) {
  return {
    text: [
      { label: t('common:matchMode.startsWith'), value: FilterMatchMode.STARTS_WITH },
      { label: t('common:matchMode.contains'), value: FilterMatchMode.CONTAINS },
      { label: t('common:matchMode.notContains'), value: FilterMatchMode.NOT_CONTAINS },
      { label: t('common:matchMode.endsWith'), value: FilterMatchMode.ENDS_WITH },
      { label: t('common:matchMode.equals'), value: FilterMatchMode.EQUALS },
      { label: t('common:matchMode.notEquals'), value: FilterMatchMode.NOT_EQUALS },
      { label: t('common:matchMode.isEmpty'), value: 'isEmpty' },
      { label: t('common:matchMode.isNotEmpty'), value: 'isNotEmpty' },
    ],
    date: [
      { label: t('common:matchMode.dateIs'), value: FilterMatchMode.DATE_IS },
      { label: t('common:matchMode.dbe'), value: 'dbe' },
      { label: t('common:matchMode.dae'), value: 'dae' },
    ],
  }
}

export const itemConfigTitleFields = [
  'material', 'rawImperial', 'rawMetric', 'description', 'secondaryDescription', 'treatment',
  'category', 'partNumber', 'manufacturer', 'revision', 'sku',
]

export const getItemHiddenColumns = (titleConfigValues, fields = {}, options = {}) => {
  const availableColumns = {
    material_title: [{ field: fields.material || 'material', isGlobalSearch: true }],
    raw_id: [
      { field: fields.rawImperial || 'rawImperial', isGlobalSearch: true },
      { field: fields.rawMetric || 'rawMetric', isGlobalSearch: true },
    ],
    secondary_description: [{
      field: fields.secondaryDescription || 'secondaryDescription',
      isGlobalSearch: true,
    }],
    treatment_title: [{ field: fields.treatment || 'treatment', isGlobalSearch: true }],
    category_title: [{ field: fields.category || 'category', isGlobalSearch: true }],
    part_number: [{ field: fields.partNumber || 'partNumber', isGlobalSearch: true }],
    manufacturer: [{ field: fields.manufacturer || 'manufacturer', isGlobalSearch: true }],
    revision: [{ field: fields.revision || 'revision', isGlobalSearch: true }],
  }

  const _columns = Object.keys(availableColumns)
    .filter((key) => titleConfigValues.includes(key))
    .reduce((acc, key) => ([...acc, ...availableColumns[key]]), [])

  const _addForcedColumn = (field) => {
    if (_columns.some((column) => column.field === field)) return
    _columns.unshift({ field, isGlobalSearch: true })
  }

  if (options.forcePartNumber) _addForcedColumn(fields.partNumber || 'partNumber')
  if (options.forceDescription) _addForcedColumn(fields.description || 'description')
  if (valueOrTrue(options.forceSku)) _addForcedColumn(fields.sku || 'sku')

  return _columns.map((column) => ({ ...column, className: 'a-hidden' }))
}
