export const labelTypes = [
  'inventory',
  'item',
  'address',
  'equipment',
  'tool',
  'location',
  'shipment-line-items',
  'shipment-lists',
]

