import { ApiToSlice, GetFields } from 'types/slices'
import { PrefixedRecord } from 'types/utils'

import { ItemApi } from 'reducers/items/apiType'
import { SalesOrderApi, SalesOrderItemApi } from 'reducers/sales-orders/types'

export const shipmentPlanningTemplateApiPrefix = 'template'
export const shipmentPlanningSalesOrderApiPrefix = 'sales_order'
export const shipmentPlanningSalesOrderItemApiPrefix = 'sales_order_item' as const

export const shipmentPlanningTemplateFieldPrefix = '$template$.'
export const shipmentPlanningSalesOrderItemFieldPrefix = '$salesOrderItem$.'
export const shipmentPlanningSalesOrderFieldPrefix = '$salesOrder$.'

export type ShipmentPlanningApi = {
  current_measure_summed: number;
  planned_measure_summed: number;
  available_measure_summed: number;
  promised_date: string;
  row_number_column: string;
  available_decrement: number;
  available_quantity: number;
  // TODO (odeschenes) #DEV-I1522 : When we do this card, we should use the field in the sales order item getFields,
  sales_order_item_measure_remaining_to_ship: number;
  sales_order_item_to_ship_status: string;
  sales_order_to_ship_status: string;
  card_formated_number: string;
  card_current_step: string;
}
export type ShipmentPlanning = ApiToSlice<ShipmentPlanningApi> & {id: string, readyToShipStatus: string}
export type ShipmentPlanningFields = GetFields<ShipmentPlanningApi, ShipmentPlanning>

export type FullShipmentPlanningApi = ShipmentPlanningApi &
  PrefixedRecord<SalesOrderItemApi, `${typeof shipmentPlanningSalesOrderItemApiPrefix}_`> &
  PrefixedRecord<SalesOrderApi, `${typeof shipmentPlanningSalesOrderApiPrefix}_`> &
  PrefixedRecord<ItemApi, `${typeof shipmentPlanningTemplateApiPrefix}_`>

export type FullShipmentPlanning = ApiToSlice<FullShipmentPlanningApi>

