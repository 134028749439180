import React, { useMemo } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { OrderRecommendation } from 'components/alix-front/order-recommendation/OrderRecommendation'
import { mapColumns, measureTemplate } from 'components/alix-front/smart-grid/columns/utils'
import SmartStatus from 'components/alix-front/smart-status/SmartStatus'

import { getDimensionIcon } from 'utils/iconMapper'

const getStatusColumn = (itemTemplate, template, t) => {
  const simple = { field: 'status', sortable: true, filter: true, filterElement: { type: 'multiselect' } }

  if (typeof t != 'function') {
    return simple
  }

  return {
    ...simple,
    header: t('items:item.fields.status'),
    filterElement: {
      ...simple.filterElement,
      options: [
        { label: t('items:status.active'), value: 'active' },
        { label: t('items:status.inactive'), value: 'inactive' },
      ],
      itemTemplate,
    },
    template,
  }
}

const statusColumnFilterTemplate = (option) => (
  <SmartStatus
    className={option.value == 'active' ? 'a-smart-status-green' : 'a-smart-status-default'}
    status={option.value}
    dictionaryKey="items"
  />
)

const planningPositionColor = {
  red: 'red',
  green: 'green',
  yellow: 'yellow',
}

const getPositionParts = (value) => {
  // format : 1R-000%

  const colorChar = value?.substring(1, 2).toUpperCase()
  let color

  switch (colorChar) {
  case 'R':
    color = planningPositionColor.red
    break
  case 'G':
    color = planningPositionColor.green
    break
  case 'Y':
    color = planningPositionColor.yellow
    break
  default:
    ''
  }

  return {
    rank: Number(value.substring(0, 1)),
    color: color,
    value: `${Number(value.substring(3, 6)) }%`,
  }
}

export function PositionTemplate({ position }) {
  const { color, value } = useMemo(() => {
    return getPositionParts(position)
  }, [position])

  return (
    <div className="a-flex a-justify-center">
      <div
        data-color-type={color}
        className="a-planning-position-container"
      >
        {value}
      </div>
    </div>
  )
}

const formatMeasure = (value, unit, measureDigits, culture) => measureTemplate({
  value,
  measureDigits,
  culture,
  unit,
})

const orderRecommendationTemplate = ({
  topOfGreen,
  topOfYellow,
  topOfRed,
  netFlow,
  recommendation,
  unit,
}, measureDigits, culture) => {
  return (
    <OrderRecommendation
      topOfGreen={topOfGreen}
      topOfYellow={topOfYellow}
      topOfRed={topOfRed}
      netFlow={netFlow}
      recommendation={recommendation}
      unit={unit}
      sectionFormatter={(value) => formatMeasure(value, unit, measureDigits, culture)}
    />
  )
}

export const columnDict = {
  position: {
    field: 'position',
    template: PositionTemplate,
    className: 'a-planning-position-column',
    sortable: true,
    isColumnToggle: false,
  },
  templateTitle: {
    field: 'templateTitle',
    isGlobalSearch: true,
    filter: true,
    sortable: true,
    template: (props, _1, _2, { iframedFullcard }) => (
      <div
        className="a-smart-grid-default-template a-clickable-link"
        onClick={() => iframedFullcard?.open('item', props.id)}
      >
        {props.templateTitle}
      </div>
    ),
  },
  status: getStatusColumn(),
  description: { field: 'description', filter: true, isGlobalSearch: true },
  onHand: { field: 'onHand', sortable: true },
  demand: { field: 'demand', sortable: true },
  available: { field: 'available', sortable: true },
  onOrder: { field: 'onOrder', sortable: true },
  netFlow: { field: 'netFlow', sortable: true },
  recommendation: { field: 'recommendation' },
  sku: { field: 'sku', isGlobalSearch: true, hidden: true, filter: true },
  categoryTitle: { field: 'categoryTitle', isGlobalSearch: true, hidden: true, filter: true },
  materialTitle: { field: 'materialTitle', isGlobalSearch: true, hidden: true, filter: true },
  treatmentTitle: { field: 'treatmentTitle', isGlobalSearch: true, hidden: true, filter: true },
  itemGroup: { field: 'itemGroup', isGlobalSearch: true, hidden: true, filter: true },
  itemType: { field: 'itemType', isGlobalSearch: true, hidden: true, filter: true },
  inventoryManagementType: { field: 'inventoryManagementType', isGlobalSearch: true, hidden: true, filter: true },
  isManufactured: { field: 'isManufactured', hidden: true },
  isPurchased: { field: 'isPurchased', hidden: true },
  isSelling: { field: 'isSelling', hidden: true },
  requiredMetric: {
    field: 'requiredMetric',
    className: 'a-planning-metric-column',
    isColumnToggle: false,
    resizeable: false,
  },
  dimension: {
    field: 'dimension',
    hidden: true,
    isGlobalSearch: true,
    filter: true,
    sortable: true,
    filterElement: {
      type: 'multiselect',
    },
  },
  mainSupplierDisplayName: {
    field: 'mainSupplierDisplayName',
    isGlobalSearch: true,
    hidden: true,
    filter: true,
    sortable: true,
  },
}

export function getComplexColumns(t, { fields, measureDigits, culture, simpleDict }) {
  const complexColumns = mapColumns(
    Object.values(simpleDict),
    { t, fields, defaultHeaderPath: 'planning:planningGrid.fields', handlerOptions: { measureDigits, culture } },
  )
  const complexDict = complexColumns.reduce((acc, column) => {
    acc[column.field] = column
    return acc
  }, {})
  return {
    ...complexDict,
    requiredMetric: {
      ...complexDict.requiredMetric,
      template: (props) => orderRecommendationTemplate(
        {
          ...props,
          sectionFormatter: (value) => formatMeasure(value, props.unit, measureDigits, culture),
        },
        measureDigits,
        culture,
      ),
    },
    status: {
      ...getStatusColumn((option) => statusColumnFilterTemplate(option), {
        isColumnToggle: false,
        dataSetName: 'templateView',
        dbField: 'status',
        defaultValue: 'active',
        isEdit: true,
      }, t),
      header: t('items:planningItemStatus'),
      template: (props) => {
        return (
          <div
            className={props.status == 'active' ?
              'a-smart-status a-smart-status-green' : 'a-smart-status a-smart-status-default'}
          >
            {t(`items:status.${props.status}`)}
          </div>
        )
      },
    },
    dimension: {
      header: t('planning:planningGrid.fields.dimension'),
      filterElement: {
        ...simpleDict.dimension.filterElement,
        options: [
          { label: t('common:dimension.qty'), value: 'qty' },
          { label: t('common:dimension.weight'), value: 'weight' },
          { label: t('common:dimension.length'), value: 'length' },
          { label: t('common:dimension.surface'), value: 'surface' },
          { label: t('common:dimension.volume'), value: 'volume' },
        ],
        itemTemplate: (option) => {
          const iconData = getDimensionIcon(option.value)
          return (
            <div className="a-flex">
              <div className="a-planning-grid-dimension-icon">
                <FontAwesomeIcon
                  icon={['fas', iconData.icon]}
                  transform={iconData.transform}
                />
              </div>
              <div>
                {option.label}
              </div>
            </div>
          )
        },
      },
      template: (props) => {
        const iconData = getDimensionIcon(props.dimension)
        return (
          <div className="a-flex">
            <div className="a-planning-grid-dimension-icon">
              <FontAwesomeIcon
                icon={['fas', iconData.icon]}
                transform={iconData.transform}
              />
            </div>
            <div
              className="a-smart-grid-default-template"
              title={t(`common:dimension.${props.dimension}`)}
            >
              {t(`common:dimension.${props.dimension}`)}
            </div>
          </div>
        )
      },
    },
  }
}
