import React from 'react'

import Joi from 'joi'
import { v4 as uuid } from 'uuid'

import { valueOrDefault } from 'utils/defaultValueHelper'
import { DevLogs } from 'utils/devLogs'
import { parseSchema } from 'utils/joiHelper'
import {
  dataToFormData,
  formDataToArray,
  formKeyDataToObject,
} from 'utils/mapperHelper'
import { safeFetchJson } from 'utils/safeFetch'

import { SmartFormConfig } from 'reducers/smart-form/smartFormTypes'
import { SmartFormFieldType } from 'reducers/smart-form/smartFormTypes'

import * as types from './types'

/**
 * @typedef {object} Form
 * @property {boolean} isValid
 * @property {boolean} hasChanges
 * @property {number} resetCount
 * @property {GlobalForm} global
 * @property {LineItemsForm} lineItems
 */

/**
 * @typedef {Object<string, FormField>&{isValid?: boolean, hasChanges?: boolean}} GlobalForm
 */

/**
 * @typedef {object} LineItemsForm
 * @property {Record<string, any>[]} selections
 * @property {Record<string, Record<string, FormField>>} insertions
 * @property {Record<string, Record<string, FormField>>} updates
 * @property {Record<string, Record<string, any>>} deletions
 */

/**
 * @typedef {object} FormField
 * @property {string} value - Value of the field
 * @property {boolean} [isChanged] - If the field has changed
 * @property {string} [dataSetName]
 * @property {string} [dbField]
 * @property {string} [updateDbField]
 * @property {SmartFormFieldErrorType} [error] - Error object
 */

/**
 * @typedef {'VIEW' | 'DELETE' | 'EDIT' | 'POPUP_EDIT' | 'CREATE' | 'POPUP_CREATE'} SmartFormMode
 */

/**
 * @typedef {object[]} SmartFormOptions - The options for the form (dict by key)
 */

/**
 * @typedef {object} SmartFormError
 * @property {string} message - The error message
 * @property {string} name - The name of the field
 */

/**
 * @typedef {object} SmartFormFetchResult
 * @property {string} id - A unique id for the fetch (uuid)
 * @property {string} type - The type of the fetch (post, put, delete)
 * @property {{name: string, message: string}} [error] - The error of the fetch, undefined if no error
 * @property {string} [entityTitle] - The title of the entity
 * @property {'websocket' | 'fetch'} source - The source of the fetch
 * @property {string} [author] - The author of the fetch
 * @property {boolean} fromLoggedInUser - If the fetch is from the logged in user, default false
 *
 * @typedef {SmartFormFetchResult[]} SmartFormFetchResults
 * @callback onFetchCallback
 * @param {object[]} data - The fetch result
 * @param {httpMethod} httpMethod - The http method
 * @param {httpMethod} [customHttpMethod] - The custom http method @see SmartFormDefaultAction.type.
 * @return {void}
 */

/**
 * @typedef {object} SmartFormOptionObject
 * @property {string} key - The key of the option
 * @property {(fetchData?: Object) => Promise<Object[]>} fetcher - The fetcher function
 * @property {(ids: string[]) => Promise<Object[]>} [fetcherByIds] - The fetcher by ids function
 * @property {(id, data) => Promise<number>} [indexFetcher] - The index fetcher function
 * @property {Object} [fetchData={}] - The data to pass to the fetcher functions
 * @property {(
 * currentFormValues: Record<string, any>
 * ) => Object} [getFetchData] - Allow to get the fetch data based on the current form values
 * @property {string[]} [filterFieldKeys] - The keys of the fields to filter on
 *
 * # Lazy loading
 * @property {boolean} [isLazy=false] - If the options should be lazy loaded
 * @property {() => Promise<number>} [countFetcher] - The count fetcher function
 */

/**
 * @typedef {object} VavigationChecks
 * @property {boolean} isExitWarning - If the exit warning should be shown
 * @property {Function} [callback] - The callback to call when the user wants tto navigate
 */

/**
 * @typedef {(
 * Record<string, Joi.Schema> |
 * (activeData?: SmartForm['activeData'], activeForm?: SmartForm['activeForm']) => Record<string, Joi.Schema>
 * )} ValidationObjectType
 */

/**
 * @typedef {object} SmartForm
 * @property {object} fields - The  fields from the `getFields()` function
 * @property {object} lineItemFields - The  fields from the `getLineItemFields()` function
 * @property {string[]} [bulkIds] - The ids for bulk actions
 * @property {object[]} data - The data array
 * @property {number} count - The count of the data
 * @property {object} activeData - The active data
 * @property {Form} activeForm - The active form
 * @property {SmartFormOptions|SmartFormOptionObject[]} options - The active form
 * @property {SmartFormOptionObject[]} optionObjects - The name of the entity
 * @property {Record<string, any>} [baseCurrency]
 * @property {(Object) => string | number} getTitle - The function to get the title of the entity
 * @property {SmartFormConfig} config - Form fields data
 * @property {SmartFormFieldType[]} formFieldsFlatten - The fields of the form, flatten
 * @property {Function} countFetcher - Form fields data flatten
 * @property {Function} fetcher - Form fields data flatten
 * @property {Function} parser - The parser function to parse the data
 * @property {string} [id] - The id of the form
 * @property {(
 * editOnly?: boolean,
 * skipDuplicate?: boolean
 * ) => Record<string, any>} [getLineItemFields] - Getter for line item fields
 * @property {{id: string, callback: onFetchCallback}[]} [onFetchCallbacks] - The callbacks to call on fetch
 * @property {SmartFormMode} mode - The form mode
 * @property {SmartFormFetchResults} fetchResults - The fetch results
 * @property {boolean} [showConfirmPopupWhenPopup=false]
 * - If the confirm popup should be shown when the form is in popup mode
 *
 * # Validation
 * @property {ValidationObjectType} [globalFormValidationObject] - The validation object
 * @property {ValidationObjectType} [lineItemFormValidationObject] - The line item validation object
 * @property {Record<string, string>} errorTranslationKeys  - The errors translations key
 *
 * # Navigation
 * @property {VavigationChecks} navigationChecks - The navigation checks
 *
 * # Loading
 * @property {boolean} isLoading - If the form is loading
 *
 * # Jobs
 * @property {{
 * jobUUID: string,
 * cid: string,
 * processResultId: string,
 * callbackParams: object,
 * onDoneCallback: Function
 * }[]} jobs - The jobs
 */

/**
 * @typedef {object} SmartFormDefaultAction
 * @property {boolean | (activeData: any) => boolean} [enabled]
 * - If the action is enabled, default to true
 * @property {boolean | (activeData: any) => boolean} [hidden]
 * - If the action is hidden, default to false
 * @property {string} [icon] - The icon of the action
 * @property {boolean} [showInPopup] - If the form should be shown in a popup
 * @property {string} [url] - The url of the action
 * @property {httpMethod} [type] - The type of the action (put, post, delete). Default to normal http method
 * @property {(navigate: any) => void} [handleOnClick] - The function to handle the click
 * if we want to navigate manually. Ex: edit => `navigate('/edit')`, create => `navigate('./../new')`
 *
 * # Process
 * @property {{summaryKey: string, detailKey: string}} [proccessContent] - The content of the process
 */

/**
 * @typedef {object} SmartFormCustomActionOptions
 * @property {boolean} [refreshData] - If the data should be refreshed after the action, default to false
 * - The refresh is already handled by the socket updates, so this should be used only if the action does not
 *  trigger the socket updates
 */

/**
 * @callback SmartFormCustomActionHandleOnClick
 * @param {GlobalForm} globalForm - The global form
 * @param {SmartFormCustomAction} action - The action
 * @return {Promise<{
 * response: import('utils/safeFetch').SafeFetchResponse,
 * options?: SmartFormCustomActionOptions
 * }>}
 */

/**
 * @typedef {object} SmartActionReturnType
 * @property {import('utils/safeFetch').SafeFetchResponse} response
 */

/**
 * @typedef {object} SmartFormCustomActionPopup
 * @property {string} titleTransKey - The translation key for the title
 * @property {(activeData) => React.ReactElement} content - The icon of the popup
 * @property {(activeData: Object, popupState: Object) => SmartActionReturnType} onConfirm -
 * @property {string} [className] - The function to call on cancel
 * @property {(activeData) => boolean} [isConfirm] - If the confirm button is enabled, default to true
 */

/**
 * @typedef {object} SmartFormCustomAction
 * @property {string} name - The name of the action
 * @property {string} [labelKey]
 * - The translation key for the label.
 * - Default to common:button + name property
 * @property {string} [icon] - The icon of the action
 * @property {boolean | (activeData: any) => boolean} [disabled]
 * - If the action is disabled, default to false
 * @property {boolean | (activeData: any) => boolean} [hidden]
 * - If the action is hidden, default to false
 * @property {SmartFormCustomAction[]} [items]
 * - The items of the action
 * - Will show the current action as a title, and the items as children
 * @property {SmartFormCustomActionHandleOnClick} [handleOnClick]
 * @property {SmartFormCustomActionPopup} [popup]
 *
 * # Process
 * @property {{summaryKey: string, detailKey: string}} [proccessContent] - The content of the process
 *
 */

/**
 * @typedef {object} SmartFormActions
 * @property {SmartFormDefaultAction} edit - The edit action
 * @property {SmartFormDefaultAction} delete - The delete action
 * @property {SmartFormDefaultAction} create - The create action
 * @property {SmartFormCustomAction[]} [customs] - The custom actions
 */

/**
 * @typedef {string | (fieldKey: string) => string} SmartFormTranslationPath
 */

/**
 * @typedef {(
 * fetchType: 'put' | 'post' | 'delete',
 * type: 'error' | 'success',
 * source: 'websocket' | 'fetch'
 * ) => string} SmartFormToastTranslationPath
 */

/**
 * @typedef {('put' | 'post' | 'delete')} httpMethod
 */

/**
 * @callback formatFormDataCallback
 * @param {import('utils/mapperHelper').FormKeyDataToObjectReturnType} data - The data to format
 * @param {httpMethod} httpMethod - The http method
 * @param {httpMethod} [customHttpMethod] - The custom http method @see SmartFormDefaultAction.type.
 * @return {void} - the form data
 */

/**
 *
 * @callback formatBodyDataCallback
 * @param {import('utils/mapperHelper').ObjectToInsertDataReturnType} data - The data to format
 * @param {httpMethod} httpMethod - The http method
 * @param {httpMethod} [customHttpMethod] - The custom http method see SmartFormDefaultAction.type.
 * @param {any} [formData] - All the current form data
 * @param {Record<string, any>} [bodyDataInfo] - Extra data for formatting the body
 * @return {object} The formatted data (Will be stringified to JSON)
 */

/**
 * @typedef {object} SmartFormState
 * @property {Record<string, SmartForm>} forms - The forms
 */

/**
 * @type {Object<SmartFormMode, SmartFormMode>}
 */
export const MODE = {
  VIEW: 'VIEW',

  DELETE: 'DELETE',

  EDIT: 'EDIT',
  POPUP_EDIT: 'POPUP_EDIT',

  CREATE: 'CREATE',
  POPUP_CREATE: 'POPUP_CREATE',
}

/**
 * @type {SmartFormState}
 */
const initialState = {
  forms: {},
}

function validateForm(globalForm, lineItemsForm) {
  const validatedGlobalForm = _validateForm(globalForm.form, globalForm.validationSchema)
  const { form: validatedLineItemsFormInsertions, errors: insertionErrors } = _validateLineItemForm(
    lineItemsForm.form.insertions,
    lineItemsForm.validationSchema,
  )
  const { form: validatedLineItemsFormUpdates, errors: updateErrors } = _validateLineItemForm(
    lineItemsForm.form.updates,
    lineItemsForm.validationSchema,
  )
  const errors = [...validatedGlobalForm.errors, ...insertionErrors, ...updateErrors]
  return {
    isValid: !errors.length,
    validatedGlobalForm: validatedGlobalForm.form,
    validatedLineItemsForm: {
      ...lineItemsForm.form,
      insertions: validatedLineItemsFormInsertions,
      updates: validatedLineItemsFormUpdates,
    },
    errors,
  }
}

function _validateLineItemForm(_form = {}, schema) {
  const errors = []
  const form = Object.keys(_form).reduce((acc, id) => {
    const _validated = _validateForm(_form[id], schema)
    errors.push(..._validated.errors.map((error) => ({ ...error, isLineItem: true })))
    acc[id] = _validated.form
    return acc
  }, {})
  return { form, errors }
}

function _validateForm(form, validationSchema) {
  const test = validationSchema.validate(form, { allowUnknown: true, abortEarly: false })

  Object.keys(form).forEach((key) => {
    delete form[key].error
  })

  test.error?.details?.forEach(( error) => {
    const label = error.context?.label

    const field = form[label]

    if (!field) return
    else if (!field.error) field.error = []

    field.error.push({
      type: error.type,
      fallbackMessage: error.message,
      context: error.context ?? {},
    })
  })

  return { isValid: !test.error, form, errors: test.error?.details || [] }
}

function hasChanges(globalForm, lineItemsForm) {
  return Object.keys(globalForm).some((key) => globalForm[key].isChanged) ||
    Object.keys(lineItemsForm.updates).some((key) => lineItemsForm.updates[key].isGlobalChange) ||
    Object.keys(lineItemsForm.insertions).length > 0 ||
    Object.keys(lineItemsForm.deletions).length > 0
}

/**
 * @param {ValidationObjectType} globalFormValidationObject
 * @param {ValidationObjectType} lineItemFormValidationObject
 * @param {SmartForm['config']} configRows
 * @param {SmartForm['activeData']} activeData
 * @param {SmartForm['activeForm']} activeForm
 * @returns {{
 * globalFormValidationSchema: Joi.ObjectSchema,
 * lineItemsFormValidationSchema: Joi.ObjectSchema,
 * config: SmartForm['config'],
 * formFieldsFlatten: SmartFormFieldType[]
 * }}
 */
function _getValidationFormSchemas(
  globalFormValidationObject,
  lineItemFormValidationObject,
  config,
  activeData,
  activeForm,
) {
  const buildSchema = (acc, field, _validationObject) => {
    const schema = _validationObject[field]

    if (!schema) return acc

    const fomattedFieldSchema = formatSchema(field, schema)

    return {
      ...acc,
      [field]: Joi.object().keys({
        value: fomattedFieldSchema,
      }).unknown(true),
    }
  }

  const _globalFormValidationObject = formatValidationObject(globalFormValidationObject, activeData, activeForm)
  const _lineItemFormValidationObject = formatValidationObject(lineItemFormValidationObject, activeData, activeForm)

  const formattedFields = config.contents.map((content) => ({
    ...content,
    rows: content.rows.map((row) => ({
      ...row,
      columns: row.columns.map((field) => parseSchema(_globalFormValidationObject, field)),
    })),
  }))

  const formFieldsFlatten = formattedFields.map(({ rows }) => rows.map(({ columns }) => columns).flat()).flat()
  return {
    globalFormValidationSchema: Joi.object().keys(Object.keys(_globalFormValidationObject).reduce(
      (acc, field) => buildSchema(acc, field, _globalFormValidationObject), {},
    )),
    lineItemsFormValidationSchema: Joi.object().keys(Object.keys(_lineItemFormValidationObject).reduce(
      (acc, field) => buildSchema(acc, field, _lineItemFormValidationObject), {},
    )),
    config: { ...config, contents: formattedFields },
    formFieldsFlatten,
  }
}

/**
 * @param {SmartFormState} state
 * @param {string} key
 * @param {any} data
 * @param {{forceIsChangedWhenNotEmpty: boolean}} [options]
 * @param {string} mode
 * @returns {{ activeForm: Form, config: SmartForm['config'], formFieldsFlatten: SmartFormFieldType[] }}
 */
function createActiveForm(state, key, data = {}, options = { forceIsChangedWhenNotEmpty: false }, mode) {
  /**
   * @type {SmartForm}
   */
  const {
    fields,
    config,
    getLineItemFields,
    globalFormValidationObject,
    lineItemFormValidationObject,
  } = state.forms[key]

  const schemas = _getValidationFormSchemas(globalFormValidationObject, lineItemFormValidationObject, config, data)

  schemas.formFieldsFlatten.forEach((field) => {
    if (!field.defaultValue || data[field.name] || getNormalizedMode(mode) !== MODE.CREATE) return

    data[field.name] = field.defaultValue
  })

  const globalForm = dataToFormData(
    data,
    fields,
    false,
    {
      forceIsChangedWhenNotEmpty: options.forceIsChangedWhenNotEmpty,
    },
  )
  const lineItemsForm = buildInitialLineItemsFormState(data.lineItems, getLineItemFields)
  const { isValid, validatedGlobalForm, validatedLineItemsForm, errors } = validateForm(
    { form: globalForm, validationSchema: schemas.globalFormValidationSchema },
    { form: lineItemsForm, validationSchema: schemas.lineItemsFormValidationSchema },
  )

  return { activeForm: {
    ...state.activeForm,
    isValid,
    errors,
    global: validatedGlobalForm,
    lineItems: validatedLineItemsForm,
    hasChanges: hasChanges(validatedGlobalForm, validatedLineItemsForm),
  }, config: schemas.config, formFieldsFlatten: schemas.formFieldsFlatten }
}

function buildInitialLineItemsFormState(lineItems, getLineItemFields, isDuplicate = false) {
  return {
    selections: [],
    insertions: isDuplicate ? buildLineItemsFormData(lineItems, getLineItemFields, true) : {},
    updates: !isDuplicate ? buildLineItemsFormData(lineItems, getLineItemFields) : {},
    deletions: {},
  }
}

function buildLineItemsFormData(lineItems = [], getLineItemFields, isDuplicate = false) {
  const formData = {}
  const lineItemFields = getLineItemFields?.(!isDuplicate, isDuplicate) ?? {}

  lineItems.forEach((lineItem) => {
    formData[lineItem.id] = dataToFormData(lineItem, lineItemFields, isDuplicate)
    formData[lineItem.id].isGlobalChange = isDuplicate
  })

  return formData
}

/**
 * @param {string} key
 * @param {Joi.Schema} schema
 * @return {Joi.Schema}
 */
function formatSchema(key, schema) {
  return schema.label(key).empty('')
}

/**
 * @param {ValidationObjectType} validationObject
 * @param {SmartForm['activeData']} activeData
 * @param {SmartForm['activeForm']} activeForm
 * @return {Record<string, Joi.Schema>}
 */
export function formatValidationObject(validationObject, activeData, activeForm) {
  const _validationObject = typeof validationObject === 'function' ?
    validationObject(activeData, activeForm) :
    validationObject

  return Object.keys(_validationObject)
    .reduce((acc, key) => {
      const schema = _validationObject[key]

      if (schema === undefined) throw new Error(`The validation schema for \`${key}\` is undefined.`)

      return {
        ...acc,
        [key]: formatSchema(key, schema),
      }
    }, {})
}

/**
 *
 * @param {SmartFormState} state
 * @param {object} action
 * @return {SmartFormState} - The new state
 */
export default function smartFormReducer(state = initialState, action) {
  const { payload } = action

  switch (action.type) {
  case types.SET_ACTIVE_DATA: {
    const { key, data: newActiveData } = payload

    /**
     * @type {SmartForm}
     */
    let { data } = state.forms[key]

    if (newActiveData) {
      const activeDataIndex = data.findIndex((d) => d.id === newActiveData.id)

      if (activeDataIndex === -1) {
        data = [...data, newActiveData]
      }
    }
    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          mode: MODE.VIEW,
          activeData: newActiveData,
          data: data,
          ...createActiveForm(state, key, newActiveData, undefined, MODE.VIEW),
        },
      },
    }
  }
  case types.ADD_CREATED_DATA: {
    const { key, data } = payload

    const newData = [...state.forms[key].data, data]

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          mode: MODE.VIEW,
          activeData: data,
          data: newData,
          count: newData.length,
          ...createActiveForm(state, key, data, undefined, MODE.VIEW),
        },
      },
    }
  }
  case types.UPDATE_DATA: {
    const { key, updatedFields } = payload

    /**
     * @type {SmartForm}
     */
    const { activeForm, config, globalFormValidationObject, lineItemFormValidationObject } = state.forms[key]

    const global = { ...activeForm.global }

    updatedFields.forEach((fieldValue) => {
      /**
       * This is used to skip useless updates.
       * Value did not change or field is not in edit mode.
       *
       * (the `onBlur` event is triggered when the user clicks on the field and click out,
       * even if the value did not change)
       */
      const isChanged = global[fieldValue.field].value !== fieldValue.value

      if (isChanged) {
        global[fieldValue.field] = {
          ...global[fieldValue.field],
          isChanged: true,
          value: fieldValue.value,
        }
      }
    })

    const schemas = _getValidationFormSchemas(
      globalFormValidationObject,
      lineItemFormValidationObject,
      config,
      undefined,
      { global, lineItems: activeForm.lineItems },
    )
    const { isValid, validatedGlobalForm, validatedLineItemsForm, errors } = validateForm(
      { form: global, validationSchema: schemas.globalFormValidationSchema },
      { form: activeForm.lineItems, validationSchema: schemas.lineItemsFormValidationSchema },
    )

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          config: schemas.config,
          formFieldsFlatten: schemas.formFieldsFlatten,
          activeForm: {
            ...activeForm,
            hasChanges: hasChanges(validatedGlobalForm, validatedLineItemsForm),
            isValid: isValid,
            errors,
            global: validatedGlobalForm,
            lineItems: validatedLineItemsForm,
          },
        },
      },
    }
  }
  case types.UPDATE_LINE_ITEMS_DATA: {
    const { key, lineItems } = payload

    /**
     * @type {SmartForm}
     */
    const { activeForm, config, globalFormValidationObject, lineItemFormValidationObject } = state.forms[key]

    const schemas = _getValidationFormSchemas(
      globalFormValidationObject,
      lineItemFormValidationObject,
      config,
      undefined,
      { global: activeForm.global, lineItems },
    )
    const { isValid, validatedGlobalForm, validatedLineItemsForm, errors } = validateForm(
      { form: activeForm.global, validationSchema: schemas.globalFormValidationSchema },
      { form: lineItems, validationSchema: schemas.lineItemsFormValidationSchema },
    )

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          config: schemas.config,
          formFieldsFlatten: schemas.formFieldsFlatten,
          activeForm: {
            ...activeForm,
            hasChanges: hasChanges(validatedGlobalForm, validatedLineItemsForm),
            isValid: isValid,
            errors,
            global: validatedGlobalForm,
            lineItems: validatedLineItemsForm,
          },
        },
      },
    }
  }
  case types.INIT_FORM: {
    /**
     * @type {CreateFormParams}
     */
    const {
      countFetcher,
      data,
      fetcher,
      fields,
      config,
      key,
      getTitle,
      getLineItemFields,
      id,
      bulkIds,
      globalFormValidationObject,
      lineItemFormValidationObject,
      parser,
      errorTranslationKeys,
      onFetchCallbacks,
      baseCurrency,
      mode,
    } = payload

    const activeData = data[0]

    const newState = {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          fields,
          lineItemFields: getLineItemFields?.(),
          data,
          countFetcher,
          fetcher,
          getTitle,
          getLineItemFields,
          id,
          bulkIds,
          parser,
          config,
          globalFormValidationObject,
          lineItemFormValidationObject,
          errorTranslationKeys: {
            required: 'common:validation.required',
            max: 'common:validation.max',
            min: 'common:validation.min',
            ...errorTranslationKeys,
          },
          activeData: activeData,
          count: data.length,
          mode: mode || MODE.VIEW,
          onFetchCallbacks,
          baseCurrency,
          fetchResults: [],
          navigationChecks: {
            isExitWarning: false,
            callback: undefined,
          },
          isLoading: false,
          jobs: [],
        },
      },
    }

    const _activeForm = createActiveForm(newState, key, activeData, undefined, MODE.VIEW)
    newState.forms[key].config = _activeForm.config
    newState.forms[key].formFieldsFlatten = _activeForm.formFieldsFlatten
    newState.forms[key].activeForm = _activeForm.activeForm

    return newState
  }
  case types.SET_MODE: {
    const { key, mode, setFormToActiveData } = payload

    /**
     * @type {SmartForm}
     */
    const { activeData } = state.forms[key]

    const newData = setFormToActiveData ? activeData : undefined

    const keepOldForm = getNormalizedMode(mode) !== MODE.CREATE

    const newFormData = keepOldForm? activeData : newData ?? {}

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          ...createActiveForm(state, key, newFormData, { forceIsChangedWhenNotEmpty: !keepOldForm }, mode),
          mode,
        },
      },
    }
  }
  case types.REMOVED_ACTIVE_DATA: {
    const { key } = payload

    const activeDataId = state.forms[key].activeData?.id

    const activeDataIndex = state.forms[key].data.findIndex((item) => item.id == activeDataId)

    if (activeDataIndex === -1) {
      DevLogs.error('SmartForm', 'REMOVE_ACTIVE_DATA', 'Active data not found in data list')

      return state
    }

    const data = [...state.forms[key].data]

    data.splice(activeDataIndex, 1)

    const newActiveDataIndex = Math.max(0, activeDataIndex - 1)

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          data,
          count: data.length,
          activeData: data[newActiveDataIndex],
          ...createActiveForm(state, key, undefined, undefined, state.forms[key].mode),
        },
      },
    }
  }
  case types.SET_OPTIONS: {
    const { key, options, optionObjects } = payload

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          options: {
            ...state.forms[key].options,
            ...options,
          },
          optionObjects: optionObjects || state.forms[key].optionObjects,
        },
      },
    }
  }
  case types.REMOVE_FORM: {
    const { key } = payload

    const newForms = { ...state.forms }

    delete newForms[key]

    return {
      ...state,
      forms: newForms,
    }
  }
  case types.REFRESH_DATA: {
    const { key, data, activeData = undefined } = payload

    /**
     * @type {SmartForm}
     */
    const { activeData: oldActiveData } = state.forms[key]

    const { id, modifiedDate } = state.forms[key].activeData ?? {}

    /**
     * By default, we take the new forced activeData or the old activeData
     */
    let _activeData = activeData ?? oldActiveData

    const newActiveData = data.find((item) => item.id === id)

    /**
     * If we did not pass a new activeData, and the old action data has changed, we take the refreshed data
     */
    if (!activeData && newActiveData && modifiedDate !== newActiveData.modifiedDate) {
      _activeData = newActiveData
    }

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          /**
           * If the a new activeData is not passed, we take the same activeData id from the new data
           */
          activeData: _activeData,
          data,
        },
      },
    }
  }
  case types.REFRESH_DATA_COUNT: {
    const { key, count } = payload

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          count,
        },
      },
    }
  }
  case types.SET_ACTIVE_FORM_DATA : {
    const { key, data } = payload

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          ...createActiveForm(state, key, data, undefined, state.forms[key].mode),
        },
      },
    }
  }
  case types.SET_FETCH_RESULT : {
    const {
      key,
      type,
      summary = 'Loading',
      detail = '',
      error = undefined,
      entityTitle = undefined,
      source = 'fetch',
      author = undefined,
      fromLoggedInUser = false,
      id = undefined,
    } = payload

    /**
     * @type {SmartFormFetchResults}
     */
    const newFetchResults = [
      ...state.forms[key].fetchResults,
      {
        id: id ?? uuid(),
        type: type,
        error: error,
        summary: summary,
        detail: detail,
        entityTitle,
        source,
        author,
        fromLoggedInUser,
      },
    ]

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          fetchResults: newFetchResults,
        },
      },
    }
  }
  case types.REMOVE_FETCH_RESULT : {
    const { key, id } = payload

    const newFetchResults = state.forms[key].fetchResults.filter((item) => item.id !== id)

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          fetchResults: newFetchResults,
        },
      },
    }
  }
  case types.ADD_ON_FETCH_CALLBACK : {
    const { key, callback: newCallback, id } = payload

    const _onFetchCallbacks = state.forms[key].onFetchCallbacks.filter((item) => item.id !== id)

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          onFetchCallbacks: [..._onFetchCallbacks, { id, callback: newCallback }],
        },
      },
    }
  }
  case types.SET_LOADING_STATE : {
    const { key, isLoading } = payload

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          isLoading,
        },
      },
    }
  }
  case types.ADD_JOB: {
    const { key, id, cid, processResultId, callbackParams, onDoneCallback } = payload

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          jobs: [
            ...state.forms[key].jobs,
            {
              id,
              cid,
              processResultId,
              callbackParams,
              onDoneCallback,
            },
          ],
        },
      },
    }
  }
  case types.REMOVE_JOB: {
    const { key, id } = payload

    return {
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          jobs: state.forms[key].jobs.filter((item) => item.id !== id),
        },
      },
    }
  }
  default: {
    return state
  }
  }
}

/**
 * @typedef {object} CreateFormParams
 * @property {string} key
 * @property {SmartForm['data']} data
 * @property {SmartForm['fields']} fields
 * @property {SmartForm['parser']} parser
 * @property {string} id
 * @property {string[]} bulkIds
 * @property {SmartForm['config']} config
 * @property {SmartForm['countFetcher']} countFetcher
 * @property {SmartForm['fetcher']} fetcher
 * @property {SmartForm['getTitle']} getTitle
 * @property {SmartForm['getLineItemFields']} [getLineItemFields]
 * @property {ValidationObjectType} [globalFormValidationObject] - The validation schema to use for the form
 * @property {ValidationObjectType} [lineItemFormValidationObject] - The validation schema to use for the line item form
 * @property {SmartForm['errorTranslationKeys']} [errorTranslationKeys] - The translation keys to use for the form
 * @property {{id: string, callback: onFetchCallback}[]} [onFetchCallbacks]
 * @property {SmartForm['baseCurrency']} [baseCurrency]
 * @property {SmartFormMode} [mode]
 */

/**
 * @param {CreateFormParams} payload
 * @return {(function(*): Promise<void>)|*}
 */
export function createForm(payload) {
  return async function(dispatch) {
    await dispatch({
      type: types.INIT_FORM,
      payload,
    })
  }
}

export function setActiveDataByIndex({ key, index }) {
  return async function(dispatch, getState) {
    const state = getState()

    const { data } = state.smartForm.forms[key] ?? {}

    if (!data?.[index]) return

    await dispatch({
      type: types.SET_ACTIVE_DATA,
      payload: { key, data: data[index] },
    })
  }
}

export function setActionDataById({ key, id }) {
  return async function(dispatch, getState) {
    const state = getState()
    /**
     * @type {SmartForm}
     */
    const { data, fetcher } = state.smartForm.forms[key]

    let activeData = data.find((item) => item.id == id)

    if (!activeData) {
      const [missingData] = await fetcher({ ids: id })

      if (!missingData) {
        return false
      }

      activeData = missingData
    }

    await dispatch({
      type: types.SET_ACTIVE_DATA,
      payload: { key, data: activeData },
    })

    return true
  }
}

export function getNormalizedMode(mode) {
  switch (mode) {
  case MODE.CREATE:
  case MODE.POPUP_CREATE:
    return MODE.CREATE
  case MODE.EDIT:
  case MODE.POPUP_EDIT:
    return MODE.EDIT
  default:
    return MODE.VIEW
  }
}

export function changeFormMode({ key, mode, setFormToActiveData = false }) {
  return {
    type: types.SET_MODE,
    payload: { key, mode, setFormToActiveData },
  }
}

/**
 * @typedef SmartFormFieldErrorType
 * @property {string} type
 * @property {string} fallbackMessage
 * @property {Joi.Context | undefined} context
 */

export function updateForm({ key, fields }) {
  return { type: types.UPDATE_DATA, payload: { key, updatedFields: fields } }
}

export function addToLineItemsForm({ key, insertions }) {
  return function addToLineItemsFormThunk(dispatch, getState) {
    /**
     * @type {SmartForm}
     */
    const { activeForm: { lineItems }, getLineItemFields } = getState().smartForm.forms[key]
    const lineItemFields = getLineItemFields?.() ?? {}
    const newInsertions = { ...lineItems.insertions }
    insertions.forEach((insertion) => newInsertions[insertion.id] = dataToFormData(insertion, lineItemFields))
    dispatch({
      type: types.UPDATE_LINE_ITEMS_DATA,
      payload: { key, lineItems: { ...lineItems, insertions: newInsertions } },
    })
  }
}

/**
 * @param {Object} param
 * @param {string} param.key
 * @param {{id: string, fields: {field: string, value: any}[]}[]} param.updates
 * @returns
 */
export function updateLineItemsForm({ key, updates }) {
  return function updateLineItemsFormThunk(dispatch, getState) {
    /**
     * @type {SmartForm}
     */
    const { activeForm: { lineItems } } = getState().smartForm.forms[key]
    const newLineItemsForm = { ...lineItems }
    updates.forEach(({ id, fields }) => {
      fields.forEach((fieldValue) => {
        const lineItemState = newLineItemsForm.insertions[id] ? 'insertions' : 'updates'
        if (lineItemState === 'updates') {
          newLineItemsForm.updates[id].isGlobalChange = true
        }
        const lineItemData = newLineItemsForm[lineItemState][id]
        const isChanged = lineItemData[fieldValue.field].value !== fieldValue.value

        if (isChanged) {
          lineItemData[fieldValue.field] = {
            ...lineItemData[fieldValue.field],
            value: fieldValue.value,
            isChanged: true,
          }
        }
      })
    })

    dispatch({ type: types.UPDATE_LINE_ITEMS_DATA, payload: { key, lineItems: newLineItemsForm } })
  }
}

export function resetLineItemsForm(key) {
  return function resetLineItemsFormThunk(dispatch, getState) {
    /**
     * @type {SmartForm}
     */
    const { getLineItemFields } = getState().smartForm.forms[key]
    dispatch({
      type: types.UPDATE_LINE_ITEMS_DATA,
      payload: { key, lineItems: buildInitialLineItemsFormState([], getLineItemFields) },
    })
  }
}

export function setLineItemsForm({ key, lineItems }) {
  return async function setLineItemsFormThunk(dispatch, getState) {
    /**
     * @type {SmartForm}
     */
    const { activeForm: { lineItems: _lineItems } } = getState().smartForm.forms[key]
    dispatch({ type: types.UPDATE_LINE_ITEMS_DATA, payload: { key, lineItems: { ..._lineItems, ...lineItems } } })
  }
}

export function deleteFromSelection(key) {
  return async function deleteFromSelectionThunk(dispatch, getState) {
    /**
     * @type {SmartForm}
     */
    const { activeForm: { lineItems } } = getState().smartForm.forms[key]
    const newInsertions = { ...lineItems.insertions }
    const newDeletions = { ...lineItems.deletions }
    lineItems.selections.forEach((selection) => {
      if (newInsertions[selection.id]) delete newInsertions[selection.id]
      else newDeletions[selection.id] = selection
    })
    dispatch({
      type: types.UPDATE_LINE_ITEMS_DATA,
      payload: {
        key,
        lineItems: { ...lineItems, selections: [], insertions: newInsertions, deletions: newDeletions },
      },
    })
  }
}

export function discardChanges({ key }) {
  return function(dispatch, getState) {
    const { activeData } = getState().smartForm.forms[key]

    dispatch({
      type: types.SET_ACTIVE_DATA,
      payload: { key, data: activeData },
    })
  }
}

export function fetchOptions({ key, options }) {
  return async function(dispatch, getState) {
    /**
     * @type {SmartForm}
     */
    const { activeData } = getState().smartForm.forms[key]

    const optionsToFetch = options.filter((option) => !option.isLazy)
    const promises = optionsToFetch.map((option) =>
      option.fetcher(option.getFetchData?.(activeData) || option.fetchData),
    )

    const results = await Promise.allSettled(promises)
    const payload = optionsToFetch.reduce((acc, option, index) => {
      const failed = results[index].status === 'rejected'

      acc[option.key] = !failed ? results[index].value : []

      return acc
    }, {})

    await dispatch({
      type: types.SET_OPTIONS,
      payload: { key, options: payload, optionObjects: options },
    })
  }
}

export function appendToOptions({ key, optionKey, options: newOptions }) {
  return async function(dispatch, getState) {
    /**
     * @type {SmartForm}
     */
    const options = getState().smartForm.forms[key].options?.[optionKey] || []
    const optionIds = options.map((o) => o.id)

    await dispatch({
      type: types.SET_OPTIONS,
      payload: {
        key,
        options: { [optionKey]: [...options, ...newOptions.filter((o) => !optionIds.includes(o.id))] },
      },
    })
  }
}

export function buildUrl(url, id) {
  return url.replace(':id', id)
}

/**
 * @param {{
 * key: string,
 * dispatch: function,
 * id: string|string[],
 * action: SmartFormDefaultAction | SmartFormCustomAction,
 * data: object,
 * lineItemData: object,
 * config: SmartFormConfig,
 * fields: object[],
 * method: string,
 * customMethod: string | undefined,
 * bodyDataInfo: Record<string, any> | undefined,
 * globalFormValidationObject: ValidationObjectType,
 * lineItemFormValidationObject: ValidationObjectType,
 * }} param0
 * @returns
 */
export async function _handleFetch({
  key,
  dispatch,
  id,
  action,
  data,
  lineItemData,
  config,
  fields,
  method,
  customMethod = undefined,
  bodyDataInfo = undefined,
  globalFormValidationObject,
  lineItemFormValidationObject,
}) {
  const schemas = _getValidationFormSchemas(
    globalFormValidationObject,
    lineItemFormValidationObject,
    config,
    undefined,
    { global: data, lineItems: lineItemData },
  )

  const { value: globalValue } = await schemas.globalFormValidationSchema.validate(data, { abortEarly: false })
  Promise.all([
    schemas.globalFormValidationSchema.validate(data, { abortEarly: false }),
    schemas.lineItemsFormValidationSchema.validate(data?.lineItems, { abortEarly: false }),
  ])

  const validateLineItems = (_form) => Object.keys(_form).reduce(async(acc, id) => ({
    ...await acc,
    [id]: (await schemas.lineItemsFormValidationSchema.validate(_form[id], { abortEarly: false })).value,
  }), Promise.resolve({}))

  const globalFormData = formKeyDataToObject(globalValue, {
    isDatabaseNull: false,
    skipNonEditableField: true,
    onlyChanged: method !== 'post',
    fieldsData: fields,
    skipNullValues: method === 'post',
  })

  globalFormData.id = id
  if (globalValue?.lineItems) {
    const insertionsValue = await validateLineItems(lineItemData.insertions)
    const insertions = formDataToArray(insertionsValue, true, true, false)
    if (method === 'post') {
      globalFormData.lineItems = insertions
    } else {
      const updates = { ...lineItemData.updates }
      Object.keys(updates)
        .filter((key) => !updates[key].isGlobalChange)
        .forEach((key) => delete updates[key])
      const updatesValue = await validateLineItems(updates)
      globalFormData.lineItems = {
        insertions,
        updates: formDataToArray(updatesValue),
        deletions: Object.keys(lineItemData.deletions),
      }
    }
  }

  if (config.formatFormData) {
    config.formatFormData(globalFormData, method, customMethod)
  }

  let bodyData
  const ids = [].concat(id)

  if (config.formatBodyData) {
    bodyData = config.formatBodyData(globalFormData, method, customMethod, data, bodyDataInfo)
  } else if (ids.length > 1) {
    bodyData = { data: ids.map((id) => ({ ...globalFormData, id })) }
  } else {
    bodyData = { data: globalFormData }
  }

  const request = () => safeFetchJson(
    buildUrl(action.url, id),
    {
      method: customMethod ?? method,
      body: JSON.stringify(bodyData),
      headers: { 'Content-Type': 'application/json' },
    },
  )

  return await handleFetch({
    key,
    dispatch,
    action,
    request,
  })
}

export async function handleActionResult({ dispatch, key, action, request, id }) {
  const { response, processResultId } = await handleFetch({
    request,
    action,
    dispatch,
    key,
  })
  if (!response) return

  dispatch(handleResponse({
    key,
    response: response,
    action,
    id,
    processResultId,
  }))

  return response
}

export async function handleFetch({ dispatch, key, action, request }) {
  dispatch(setLoadingState({ key, isLoading: true }))

  let processResultId
  // eslint-disable-next-line prefer-const
  let response

  const timeoutId = setTimeout(async() => {
    if (response) return

    processResultId = await dispatch(initializeSlowResponse({
      key,
      summary: action.proccessContent?.summaryKey,
      detail: action.proccessContent?.detailKey,
    }))
  }, 1000)

  response = await request()

  const isJob = response?.response?.result?.isTimeout

  if (processResultId && !isJob) {
    dispatch(removeFetchResult({ key, id: processResultId }))
  }

  clearTimeout(timeoutId)

  if (!isJob) {
    dispatch(setLoadingState({ key, isLoading: false }))
  }

  return { response, processResultId }
}

/**
 * @param {object} result
 * @param {string} [id]
 * @param {Function} parser
 * @return {{data: object[], dataFromId: object}}}
 */
function _handleResult(result, id, parser) {
  const parsedData = (Array.isArray(result) ? result : [result]).map((item) => parser(item)).flat()

  return {
    data: parsedData,
    dataFromId: id ? parsedData.find((item) => item.id === id) : null,
  }
}

export function handlePut({ key, action, changeMode = false, customData = undefined, bodyDataInfo = undefined }) {
  return async function(dispatch, getState) {
    /**
     * @type {SmartForm}
     */
    const {
      activeForm,
      config,
      fields,
      bulkIds,
      globalFormValidationObject,
      lineItemFormValidationObject,
    } = getState().smartForm.forms[key]

    const data = customData ?? activeForm.global
    const id = bulkIds?.length > 1 ? bulkIds : data.id.value

    if (!action.url) return

    const { response, processResultId } = await _handleFetch({
      key,
      dispatch,
      id,
      action,
      data,
      lineItemData: activeForm.lineItems,
      config,
      fields,
      method: 'put',
      bodyDataInfo,
      globalFormValidationObject,
      lineItemFormValidationObject,
    })

    const handledResponse = await dispatch(
      handleResponse({
        key,
        action,
        id,
        response: { response },
        type: 'put',
        processResultId,
      }),
    )

    if (changeMode) {
      dispatch(changeFormMode({ key, mode: MODE.VIEW }))
    }

    return handledResponse
  }
}

export function handlePost({ key, action, changeMode = true, customData = undefined }) {
  return async function(dispatch, getState) {
    /**
     * @type {SmartForm}
     */
    const {
      activeForm,
      config,
      fields,
      globalFormValidationObject,
      lineItemFormValidationObject,
    } = getState().smartForm.forms[key]

    const customMethod = action.type

    if (!action.url) return

    const id = uuid()

    const { response, processResultId } = await _handleFetch({
      key,
      dispatch,
      id: id,
      action,
      data: customData ?? activeForm.global,
      lineItemData: activeForm.lineItems,
      config,
      fields,
      method: 'post',
      customMethod,
      globalFormValidationObject,
      lineItemFormValidationObject,
    })

    const handledResponse = await dispatch(handleResponse({
      key, action, id,
      response: { response, options: { refreshData: true } },
      type: 'post',
      processResultId,
    }))

    if (changeMode) {
      dispatch(changeFormMode({ key, mode: MODE.VIEW }))
    }

    return handledResponse
  }
}

export function handleDelete({ key, action, id, changeMode = true }) {
  return async function(dispatch, getState) {
    /**
     * @type {SmartForm}
     */
    const { config, fields, globalFormValidationObject, lineItemFormValidationObject } = getState().smartForm.forms[key]

    const customMethod = action.type

    if (changeMode) {
      dispatch(changeFormMode({ key, mode: MODE.VIEW }))
    }

    if (!action.url) return

    const { response, processResultId } = await _handleFetch({
      key,
      dispatch,
      id,
      action,
      config,
      fields,
      method: 'delete',
      customMethod: customMethod ?? 'delete',
      globalFormValidationObject,
      lineItemFormValidationObject,
    })

    return await dispatch(handleResponse({
      key, action, id,
      response: { response },
      type: 'delete',
      processResultId,
    }))
  }
}

export function removeForm({ key }) {
  return async function(dispatch) {
    await dispatch({
      type: types.REMOVE_FORM,
      payload: { key },
    })
  }
}

export function fetchData(key) {
  return function(smartGridData) {
    return async function fetchDataThunk(dispatch, getState) {
      const { fetcher } = getState().smartForm.forms[key]

      if (!fetcher) return

      const data = await fetcher(smartGridData)

      await dispatch({ type: types.REFRESH_DATA, payload: { key, data } })

      return data
    }
  }
}

export function fetchDataCount(key) {
  return function(smartGridData) {
    return async function fetchDataThunk(dispatch, getState) {
      const { countFetcher } = getState().smartForm.forms[key]

      if (!countFetcher) return

      const count = await countFetcher(smartGridData)

      await dispatch({ type: types.REFRESH_DATA_COUNT, payload: { key, count } })

      return count
    }
  }
}

/**
 * Refresh a specific data by id
 * This does not handle the case were the data is not in the old data
 *
 * @param {{key: string, data: any[]}} params
 * @returns void
 */
export function refreshDataById({ key, data: dataToRefresh }) {
  return async function(dispatch, getState) {
    const { data: oldData } = getState().smartForm.forms[key]

    const id = dataToRefresh.id

    const newData = oldData.map((item) => {
      if (item.id == id) {
        return dataToRefresh
      }

      return item
    })
    await dispatch({
      type: types.REFRESH_DATA, payload: {
        key,
        data: newData,
      },
    })
  }
}

/**
 * Refresh the data with new data
 * Note that this also handles new data that is not in the old data
 *
 * @param {{key: string, data: any[]}} params
 * @returns void
 */
export function refreshData({ key, data: dataToRefresh }) {
  return async function(dispatch, getState) {
    /**
     * @type {SmartForm}
     */
    const { data: oldData, activeData } = getState().smartForm.forms[key]

    /**
     * Swap the new data with the old data
     */
    const newData = oldData.map((oldItem) => {
      /**
       * The index of the current old item in our new data array
       */
      const refreshedItemIndex = dataToRefresh.findIndex((newItem) => newItem.id == oldItem.id)
      /**
       * The new data
       */
      const newItem = refreshedItemIndex !== -1 ? dataToRefresh[refreshedItemIndex] : null

      /**
       * If the old item is to be refreshed, we removed it from our new data array and return the new data
       */
      if (newItem) {
        dataToRefresh.splice(refreshedItemIndex, 1)
        return newItem
      }

      /**
       * If the old item is not to be refreshed, we return the old item
       */
      return oldItem
    })

    let changedActiveData = activeData?.id ? newData.find((d) => d.id === activeData.id) : undefined
    /**
     * If there is still data left in our new data array, it means that we have new data
     */
    if (dataToRefresh.length) {
      /**
       * We add the new data to the beginning of our new data array
       */
      newData.unshift(...dataToRefresh)

      /**
       * This means that we will have to change the active data
       */
      changedActiveData = dataToRefresh[0]
    }
    await dispatch({
      type: types.REFRESH_DATA, payload: {
        key,
        data: newData,
        activeData: changedActiveData,
      },
    })
  }
}

export function refreshDeletedData({ key, id }) {
  return async function(dispatch, getState) {
    const { data: oldData, activeData } = getState().smartForm.forms[key]

    const newData = oldData.filter((item) => item.id !== id)

    const newActiveData = (activeData?.id === id) ? newData[0] : activeData

    await dispatch({
      type: types.REFRESH_DATA, payload: {
        key,
        data: newData,
        activeData: newActiveData,
      },
    })
  }
}

export function removeFetchResult({ key, id }) {
  return {
    type: types.REMOVE_FETCH_RESULT, payload: { key, id },
  }
}

/**
 * @param {'post' | 'put' | 'delete'} method
 * @return {'insertions' | 'updates' | 'deletions'}
 */
export function convertHttpMethodToApiKey(method) {
  switch (method) {
  case 'post':
    return 'insertions'
  case 'put':
    return 'updates'
  case 'delete':
    return 'deletions'
  default:
    throw new Error(`Unknown method ${method}`)
  }
}

export function addOnFetchCallback({ key, callback, id }) {
  return {
    type: types.ADD_ON_FETCH_CALLBACK, payload: { key, callback, id },
  }
}

/**
 * @typedef {object} HandleResponseParams
 * @property {string} key
 * @property {string} [id]
 * @property {{
 * response: import('utils/safeFetch').SafeFetchResponse,
 * options: SmartFormCustomActionOptions
 * }} response
 * @property {{type: (string | undefined)}} action
 * @property {httpMethod} [type] - The type of the action result, default to 'put'
 * @property {SmartForm} [state]
 */

/**
 * @param {HandleResponseParams} params
 * @return {object}
 */
export function handleResponse({ key, id, response: actionResponse, action, type, processResultId, onDoneCallback }) {
  return async(dispatch, getState) => {
    if (!actionResponse) return

    /**
     * @type {SmartForm}
     */
    const { parser, onFetchCallbacks, getTitle, activeData = {} } = getState().smartForm.forms[key]

    const { response, options } = actionResponse

    const { isSuccess, result, error } = response

    if (!isSuccess) {
      dispatch({ type: types.SET_FETCH_RESULT, payload: {
        key,
        type: type ?? 'put',
        error: result ?? error,
      },
      })

      onDoneCallback?.(response)

      return result
    }

    if (result?.isTimeout) {
      dispatch(addJob({
        cid: result.cid,
        key,
        id: result.jobUUID,
        processResultId: processResultId,
        callbackParams: {
          id, action, type,
        },
        onDoneCallback,
      }))

      return null
    }

    const { dataFromId: parsedResult, data: allParsedData } = _handleResult(result, id, parser)

    let titles

    if (parsedResult) {
      titles = getTitle(parsedResult)
    }

    dispatch(
      {
        type: types.SET_FETCH_RESULT,
        payload: {
          key,
          type: type ?? 'put',
          error: isSuccess ? undefined : result,
          entityTitle: titles,
        },
      })

    const dataToRefresh = parsedResult ? [parsedResult] : allParsedData

    const shouldRefreshData =
      valueOrDefault(options?.refreshData, true) ||
      activeData.modifiedDate !== dataToRefresh.find((item) => item.id == activeData?.id)?.modifiedDate

    if (shouldRefreshData) {
      const cloneDataToRefresh = JSON.parse(JSON.stringify(dataToRefresh))
      if (type === 'delete' && id) {
        await dispatch(refreshDeletedData({ key, id }))
      } else {
        await dispatch(refreshData({ key, data: dataToRefresh }))
      }

      await Promise.all(
        onFetchCallbacks
          .map(({ callback }) => callback(cloneDataToRefresh, type, action.type)),
      )
    }

    onDoneCallback?.(response)

    return parsedResult
  }
}

export function initializeSlowResponse({ key, summary, detail }) {
  const id = uuid()

  return async function(dispatch) {
    await dispatch(
      {
        type: types.SET_FETCH_RESULT,
        payload: {
          key,
          type: 'loading',
          id,
          summary: summary ?? `common:toastMessage.processing`,
          detail: detail,
        },
      },
    )

    return id
  }
}

/**
 * @param {Object} state
 * @param {string} formKey
 * @return {SmartForm}
 */
export function getSmartFormState(state, formKey) {
  return state.smartForm.forms[formKey]
}

export function setLoadingState({ key, isLoading }) {
  return { type: types.SET_LOADING_STATE, payload: { key, isLoading: !!isLoading } }
}

export function addJob({ key, id, cid, processResultId, callbackParams, onDoneCallback }) {
  return { type: types.ADD_JOB, payload: { key, id, cid, processResultId, callbackParams, onDoneCallback } }
}

export function removeJob({ key, id }) {
  return { type: types.REMOVE_JOB, payload: { key, id } }
}

/**
 *
 * @param {SmartFormMode} mode - The mode to check
 */
export function isPopupMode(mode) {
  return mode.startsWith('POPUP_')
}
