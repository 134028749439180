import { buildGetUrl, parse } from 'utils/api'
import { joinOnlyStrings } from 'utils/getFirstOfType'
import { safeFetch, safeFetchJson } from 'utils/safeFetch'

import { parseAddress } from 'reducers/addresses/addressesSlice'
import { fetchChecklistTemplates } from 'reducers/checklists/checkListsSlice'
import { parseContactCarrierAccount } from 'reducers/contact-carrier-account/contactCarrierAccountsSlice'
import { _fetchContactCategories } from 'reducers/contact-categories/contactCategoriesSlice'
import { parseContactPerson } from 'reducers/contact-people/contactPeopleSlice'
import { fetchCurrencies } from 'reducers/currencies/currenciesSlice'
import { _fetchTaxes } from 'reducers/integrations/integrationsSlice'
import { _fetchPriceLists } from 'reducers/price-lists/priceListsSlice'
import { _fetchResources } from 'reducers/resources/resourcesSlice'

/**
 * @typedef {'vendor' | 'customer'} type
 */

const dataSetName = 'contactView'
const fields = getFields()
const detailsFields = getDetailsFields()
const initialState = {
  dataSetName,
  fields,
}

export function getContactAddressFields(isEdit = false) {
  const dataSetName = 'contactAddressRel'

  const fields = {
    'id': { dataSetName, dbField: 'id', isEdit: false, type: 'id' },
    'exist': { dataSetName, dbField: 'exist', isEdit: false, type: 'exist' },
    'createdDate': { dataSetName, dbField: 'created_date', isEdit: false, type: 'timestamp' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', isEdit: false, type: 'timestamp' },
    'createdById': { dataSetName, dbField: 'created_by_id', isEdit: false, type: 'id' },
    'createdBy': { dataSetName, dbField: 'created_by', isEdit: false, type: 'string' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id', isEdit: false, type: 'id' },
    'modifiedBy': { dataSetName, dbField: 'modified_by', isEdit: false, type: 'string' },
    'contactId': { dataSetName, dbField: 'contact_id', isEdit: false, type: 'id', relationEntity: 'contacts' },
    'addressId': { dataSetName, dbField: 'address_id', isEdit: false, type: 'id', relationEntity: 'addresses' },
    'addressType': { dataSetName, dbField: 'address_type', isEdit: false, type: 'string' },
    'isDefault': { dataSetName, dbField: 'is_default', isEdit: false, type: 'boolean' },
    'attention': { dataSetName, dbField: 'attention', isEdit: true, type: 'string' },
    'street': { dataSetName, dbField: 'address', isEdit: true, type: 'string' },
    'street2': { dataSetName, dbField: 'street2', isEdit: true, type: 'string' },
    'stateCode': { dataSetName, dbField: 'state_code', isEdit: true, type: 'string' },
    'city': { dataSetName, dbField: 'city', isEdit: true, type: 'string' },
    'state': { dataSetName, dbField: 'state', isEdit: true, type: 'string' },
    'zip': { dataSetName, dbField: 'zip', isEdit: true, type: 'string' },
    'country': { dataSetName, dbField: 'country', isEdit: true, type: 'string' },
    'fax': { dataSetName, dbField: 'fax', isEdit: true, type: 'string' },
    'phone': { dataSetName, dbField: 'phone', isEdit: true, type: 'string' },
    'zohoBooksAddressId': { dataSetName, dbField: 'zoho_books_address_id', isEdit: false, type: 'id' },
    'externalId': { dataSetName, dbField: 'external_id', isEdit: false, type: 'id' },

    'addressLine2': { parse: (address) => joinOnlyStrings([address?.city, address?.state], ', ').trim() },
    'addressLine3': { parse: (address) => joinOnlyStrings([address?.zip, address?.country], ', ').trim() },
    'fullAddress': {
      parse: (address) => joinOnlyStrings([
        address?.address, address?.city, address?.state, address?.zip, address?.country,
      ], ', ').trim(),
    },
  }

  if (!isEdit) return fields

  return Object.keys(fields).reduce((acc, key) => {
    const field = fields[key]

    if (field.isEdit) acc[key] = field

    return acc
  }, {})
}

export function parseContactAddress(address) {
  const fields = getContactAddressFields()

  const options = {
    defaultData: parse({}, { fields }),
    fields: fields,
    dataSetName: 'contactAddressRel',
  }
  return parse(address, options)
}

export function parseContactPersonFromContact(contact) {
  const persons = contact?.contactPersons

  return persons?.map((person) => parseContactPerson(person))
}

export function parseContactCarrierAccountFromContact(contact) {
  const carrierAccounts = contact?.contactCarrierAccounts

  return carrierAccounts?.map((carrierAccount) => parseContactCarrierAccount(carrierAccount))
}

/**
 * @param {'shipping' | 'billing'} type
 * @return {object}
 */
export function getContactAddressParser(type) {
  return (contact) => {
    const addresses = contact[`${type}Addresses`]

    return addresses.map((address) => parseContactAddress(address))
  }
}

export async function fetchContactAddressesByIds(ids, data) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson(buildGetUrl('/new_api/contacts/addresses', { ...data, ids }))

  return isSuccess ? result.map((address) => parseAddress(address)) : []
}

export default function contactsReducer(state = initialState, action) {
  switch (action.type) {
  default: {
    return state
  }
  }
}

// contacts exports
/**
 * @param {boolean} isEdit
 * @returns {Record<string, any>}
 */
export function getFields(isEdit = false) {
  const fields = {
    id: { dataSetName, dbField: 'id', isEdit: false, type: 'id' },
    exist: { dataSetName, dbField: 'exist', isEdit: false, type: 'boolean' },
    cid: { dataSetName, dbField: 'cid', isEdit: false, type: 'id' },
    displayName: { dataSetName, dbField: 'display_name', isEdit: true, type: 'string' },
    companyName: { dataSetName, dbField: 'company_name', isEdit: true, type: 'string' },
    website: { dataSetName, dbField: 'website', isEdit: true, type: 'string' },
    isPortalEnabled: { dataSetName, dbField: 'is_portal_enabled', isEdit: false, type: 'boolean' },
    paymentTerms: { dataSetName, dbField: 'payment_terms', isEdit: true, type: 'string' },
    code: { dataSetName, dbField: 'code', isEdit: true, type: 'string' },
    isGuest: { dataSetName, dbField: 'is_guest', isEdit: true, type: 'boolean' },
    isCarrier: { dataSetName, dbField: 'is_carrier', isEdit: true, type: 'boolean' },

    status: {
      dataSetName,
      dbField: 'status',
      type: 'status',
      isEdit: true,
      dictionaryKey: 'contact',
      translationPath: 'contacts:status',
      values: ['active', 'inactive'],
      defaultValue: 'active',
      parse: (props) => props['status'] ?? 'active',
      customEventValueTranslationKey: (value) => `contacts:status.${value ?? 'active'}`,
    },

    defaultChecklistId: {
      dataSetName,
      dbField: 'default_checklist_id',
      isEdit: true,
      type: 'id',
      relationEntity: 'checklist-templates',
    },
    defaultChecklistTitle: {
      dataSetName,
      dbField: 'default_checklist_title',
      isEdit: false,
      type: 'string',
    },

    notes: { dataSetName, dbField: 'notes', isEdit: true, type: 'text' },
    language: { dataSetName, dbField: 'language', isEdit: true, type: 'language' },

    createdDate: { dataSetName, dbField: 'created_date', isEdit: false, type: 'timestamp' },
    modifiedDate: { dataSetName, dbField: 'modified_date', isEdit: false, type: 'timestamp' },
    externalId: { dataSetName, dbField: 'external_id', isEdit: true, type: 'string' },

    createdBy: { dataSetName, dbField: 'created_by', isEdit: false, type: 'string' },
    createdById: { dataSetName, dbField: 'created_by_id', isEdit: false, type: 'id' },

    modifiedBy: { dataSetName, dbField: 'modified_by', isEdit: false, type: 'string' },
    modifiedById: { dataSetName, dbField: 'modified_by_id', isEdit: false, type: 'id' },

    markup: { dataSetName, dbField: 'markup', isEdit: true, type: 'percentage' },

    type: {
      dataSetName,
      dbField: 'contact_type',
      type: 'enum',
      isEdit: true,
      translationPath: 'items:item.fields.type',
      values: ['vendor', 'customer'],
      defaultValue: 'vendor',
      parse: (props) => props['contact_type'] ?? 'vendor',
    },

    // External ids
    zohoBooksContactId: { dataSetName, dbField: 'zoho_books_contact_id', isEdit: true },
    zohoCrmAccountId: { dataSetName, dbField: 'zoho_crm_account_id', isEdit: true, type: 'id' },
    zohoBooksTaxId: {
      dataSetName,
      dbField: 'zoho_books_tax_id',
      isEdit: true,
      type: 'id',
      relationEntity: 'zohoBooksTaxes',
    },
    zohoBooksTaxRuleId: {
      dataSetName,
      dbField: 'zoho_books_tax_rule_id',
      isEdit: true,
      type: 'id',
      relationEntity: 'zohoBooksTaxRules',
    },
    wooCommerceContactId: { dataSetName, dbField: 'woo_commerce_contact_id', isEdit: true, type: 'id' },

    currencyId: { dataSetName, dbField: 'currency_id', isEdit: true, type: 'id', relationEntity: 'currencies' },
    priceListId: { dataSetName, dbField: 'price_list_id', isEdit: true, type: 'id', relationEntity: 'price-lists' },
    categoryId: { dataSetName, dbField: 'category_id', isEdit: true, type: 'id', relationEntity: 'contact-categories' },
    ownerId: { dataSetName, dbField: 'owner_id', isEdit: true, type: 'id', relationEntity: 'resources' },
    carrierId: { dataSetName, dbField: 'carrier_id', isEdit: true, type: 'id', relationEntity: 'contacts' },
    mergedIntoContactId: {
      dataSetName,
      dbField: 'merged_into_contact_id',
      isEdit: false,
      type: 'id',
      relationEntity: 'contacts',
    },

    relatedContactId: {
      dataSetName,
      dbField: 'related_contact_id',
      isEdit: false,
      type: 'id',
      relationEntity: 'contacts',
    },
    relatedContactDisplayName: {
      dataSetName, dbField: 'related_contact_display_name', isEdit: false, type: 'string',
    },
    relatedContactCompanyName: {
      dataSetName, dbField: 'related_contact_company_name', isEdit: false, type: 'string',
    },

    mainPersonSalutation: { dataSetName, dbField: 'main_person_salutation', isEdit: false, type: 'string' },
    mainPersonFirstName: { dataSetName, dbField: 'main_person_first_name', isEdit: false, type: 'string' },
    mainPersonLastName: { dataSetName, dbField: 'main_person_last_name', isEdit: false, type: 'string' },
    mainPersonFullName: { dataSetName, dbField: 'main_person_full_name', isEdit: false, type: 'string' },
    mainPersonEmail: { dataSetName, dbField: 'main_person_email', isEdit: false, type: 'string' },
    mainPersonPhone: { dataSetName, dbField: 'main_person_phone', isEdit: false, type: 'string' },
    mainPersonMobile: { dataSetName, dbField: 'main_person_mobile', isEdit: false, type: 'string' },
    mainPersonDesignation: { dataSetName, dbField: 'main_person_designation', isEdit: false, type: 'string' },
    mainPersonDepartment: { dataSetName, dbField: 'main_person_department', isEdit: false, type: 'string' },
    mainPersonSkype: { dataSetName, dbField: 'main_person_skype', isEdit: false, type: 'string' },

    mainBillingAttention: { dataSetName, dbField: 'main_billing_attention', isEdit: false, type: 'string' },
    mainBillingAddress: { dataSetName, dbField: 'main_billing_address', isEdit: false, type: 'string' },
    mainBillingStreet2: { dataSetName, dbField: 'main_billing_street2', isEdit: false, type: 'string' },
    mainBillingStateCode: { dataSetName, dbField: 'main_billing_state_code', isEdit: false, type: 'string' },
    mainBillingCity: { dataSetName, dbField: 'main_billing_city', isEdit: false, type: 'string' },
    mainBillingState: { dataSetName, dbField: 'main_billing_state', isEdit: false, type: 'string' },
    mainBillingZip: { dataSetName, dbField: 'main_billing_zip', isEdit: false, type: 'string' },
    mainBillingCountry: { dataSetName, dbField: 'main_billing_country', isEdit: false, type: 'string' },
    mainBillingFax: { dataSetName, dbField: 'main_billing_fax', isEdit: false, type: 'string' },
    mainBillingPhone: { dataSetName, dbField: 'main_billing_phone', isEdit: false, type: 'string' },

    mainShippingAttention: { dataSetName, dbField: 'main_shipping_attention', isEdit: false, type: 'string' },
    mainShippingAddress: { dataSetName, dbField: 'main_shipping_address', isEdit: false, type: 'string' },
    mainShippingStreet2: { dataSetName, dbField: 'main_shipping_street2', isEdit: false, type: 'string' },
    mainShippingStateCode: { dataSetName, dbField: 'main_shipping_state_code', isEdit: false, type: 'string' },
    mainShippingCity: { dataSetName, dbField: 'main_shipping_city', isEdit: false, type: 'string' },
    mainShippingState: { dataSetName, dbField: 'main_shipping_state', isEdit: false, type: 'string' },
    mainShippingZip: { dataSetName, dbField: 'main_shipping_zip', isEdit: false, type: 'string' },
    mainShippingCountry: { dataSetName, dbField: 'main_shipping_country', isEdit: false, type: 'string' },
    mainShippingFax: { dataSetName, dbField: 'main_shipping_fax', isEdit: false, type: 'string' },
    mainShippingPhone: { dataSetName, dbField: 'main_shipping_phone', isEdit: false, type: 'string' },

    // Relation fields name
    category: { dataSetName, dbField: 'category_name', isEdit: false, type: 'string' },
    priceListName: { dataSetName, dbField: 'price_list_name', isEdit: false, type: 'string' },

    currencyCode: { dataSetName, dbField: 'currency_code', isEdit: false, type: 'string' },
    currencyName: { dataSetName, dbField: 'currency_name', isEdit: false, type: 'string' },
    currencySymbol: { dataSetName, dbField: 'currency_symbol', isEdit: false, type: 'string' },
    currencyIsBase: { dataSetName, dbField: 'is_base_currency', isEdit: false, type: 'boolean' },
    currencyExchangeRate: { dataSetName, dbField: 'currency_exchange_rate', isEdit: false, type: 'number' },

    carrierDisplayName: { dataSetName: 'carrier', dbField: 'display_name', isEdit: false, type: 'string' },
    carrierCompanyName: { dataSetName: 'carrier', dbField: 'company_name', isEdit: false, type: 'string' },

    contactCarrierAccounts: { parse: (contact) => {
      return (contact.contactCarrierAccounts ?? []).map((data) => parseContactCarrierAccount(data))
    } },
    contactPersons: { parse: (contact) => (contact.contactPersons ?? []).map((data) => parseContactPerson(data)) },
    billingAddresses: { parse: (contact) => (contact.billingAddresses ?? []).map((data) => parseContactAddress(data)) },
    shippingAddresses: {
      parse: (contact) => (contact.shippingAddresses ?? []).map((data) => parseContactAddress(data)),
    },
  }

  if (!isEdit) return fields

  return Object.keys(fields).reduce((acc, key) => {
    const field = fields[key]

    if (field.isEdit) acc[key] = field

    return acc
  }, {})
}

export function getDetailsFields() {
  const contactFields = getFields()

  const fields = [
    'id',
    'displayName',
    'type',
    'language',
    'currencyCode',
    'currencySymbol',
    'contactPersons',
    'contactCarrierAccounts',
    'billingAddresses',
    'shippingAddresses',
    'currencyIsBase',
  ]

  const fieldsFromContact = fields.reduce((acc, key) => ({
    ...acc,
    [key]: contactFields[key],
  }), {})

  return {
    ...fieldsFromContact,
  }
}

export function getDefaultContactDetails() {
  return parse({}, { fields: detailsFields })
}

export async function _fetchContactsCount(data = {}) {
  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/contacts/count', data))).json()
    if (result.isSuccess) {
      return +result.result[0]?.count || 0
    }
  } catch (err) {
    console.error(err)
  }
  return 0
}

export async function _fetchContacts(data = {}) {
  let contacts = []

  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/contacts', data))).json()
    if (result.isSuccess) {
      contacts = result.result.map((contact) => parseContact(contact))
    }
  } catch (err) {
    console.error(err)
  }

  return contacts
}

export async function fetchContactByIds(ids, data = {}) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson(
    buildGetUrl(`/new_api/contacts/${ids}`, data),
  )

  return isSuccess ? result.map((contact) => parseContact(contact)) : []
}

export async function fetchContactIndex(id, data = {}) {
  let index = 0

  if (id) {
    try {
      const result = await (await safeFetch(buildGetUrl(`/new_api/contacts/${id}/index`, data))).json()
      if (result.isSuccess) {
        index = +result.result || 0
      }
    } catch (err) {
      console.error(err)
    }
  }

  return index
}

export async function fetchContactDetails(ids) {
  const contacts = await fetchContactByIds(ids)

  const parsed = contacts.map((contact) => parseContactDetails(contact))

  return parsed
}

// parsing functions
export function parseContact(contact) {
  const options = {
    defaultData: parse({}, { fields }),
    fields: initialState.fields,
    dataSetName,
  }

  return parse(contact, options)
}

export function getDefaultContact() {
  return parse({}, { fields })
}

export function parseContactDetails(contact = {}) {
  const primaryContactPerson = contact.contactPersons?.find((person) => person.isPrimaryContact) ?? {}
  const primaryBillingAddress = contact.billingAddresses?.find((address) => address.isDefault) ?? {}
  const primaryShippingAddress = contact.shippingAddresses?.find((address) => address.isDefault) ?? {}

  return {
    ...contact,
    contactPerson: primaryContactPerson,
    billingAddress: primaryBillingAddress,
    shippingAddress: primaryShippingAddress,
  }
}

export function getContactTitle(contact) {
  return `${contact?.displayName}${contact?.companyName ? ` (${contact?.companyName})` : ''}` ?? ''
}

export function fetchCarriers(data = {}) {
  return _fetchContacts({ isCarrier: true, exist: true, ...data })
}

export function fetchCarriersCount(data = {}) {
  return _fetchContactsCount({ isCarrier: true, exist: true, ...data })
}

export async function fetchOptions() {
  const options = [
    {
      key: 'contactCategories',
      fetcher: _fetchContactCategories,
    },
    {
      key: 'priceLists',
      fetcher: _fetchPriceLists,
    },
    {
      key: 'carriers',
      fetcher: fetchCarriers,
    },
    {
      key: 'resources',
      fetcher: _fetchResources,
    },
    {
      key: 'taxes',
      fetcher: _fetchTaxes,
    },
    {
      key: 'currencies',
      fetcher: fetchCurrencies,
    },
    {
      key: 'defaultChecklist',
      fetcher: fetchChecklistTemplates,
    },
  ]

  const promises = options.map((option) => option.fetcher())

  const results = await Promise.allSettled(promises)

  const payload = options.reduce((acc, option, index) => {
    const failed = results[index].status === 'rejected'

    acc[option.key] = !failed ? results[index].value : []

    return acc
  }, {})

  return payload
}

/**
 *
 * @param {string[]} ids
 * @param {'active' | 'inactive'} status
 * @return {Promise<Object[]>}
 */
export function _changeContactStatusFetch(ids, status) {
  let pathSuffix = 'activate'
  if (status === 'inactive') {
    pathSuffix = 'inactivate'
  }
  return safeFetchJson(`/api/integrations/contacts/${ids.join(',')}/${pathSuffix}`, {
    method: 'POST',
    body: JSON.stringify({
      status: status,
    }),
    headers: { 'Content-Type': 'application/json' },
  })
}

/**
 *
 * @param {string} id
 * @param {string | null} linkedContactId - null to unlink
 * @return {Promise<Object>}
 */
export function _changeLinkContactFetch(id, linkedContactId) {
  const pathSuffix = linkedContactId ? 'link' : 'unlink'
  return safeFetchJson(`/api/integrations/contacts/${id}/${pathSuffix}`, {
    method: 'POST',
    body: JSON.stringify({
      linkedContactId,
    }),
    headers: { 'Content-Type': 'application/json' },
  })
}

/**
 *
 * @param {string} id
 * @param {string | null} linkedContactId - null to unlink
 * @return {Promise<Object>}
 */
export function _deleteContactFetch(id) {
  return safeFetchJson(`/api/integrations/contacts/${id}`, {
    method: 'DELETE',
    body: JSON.stringify({
    }),
    headers: { 'Content-Type': 'application/json' },
  })
}
