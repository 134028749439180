import React, { useRef, useState, useMemo, useCallback, forwardRef, useImperativeHandle, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { InputNumber } from 'primereact/inputnumber'

/**
 * @typedef {Object} Props
 * @property {any} [onFocus]
 * @property {any} [onBlur]
 * @property {any} [onChange]
 * @property {string} [mode]
 * @property {number} [minFractionDigits]
 * @property {number} [maxFractionDigits]
 * @property {number | string} [initialValue]
 * @property {number} [min]
 * @property {number} [max]
 * @property {string} [prefix]
 * @property {string} [suffix]
 * @property {boolean} [showButtons]
 * @property {string} [buttonLayout]
 * @property {boolean} [disabled]
 * @property {boolean} [autoFocus]
 * @property {boolean} [required]
 * @property {number} [step]
 * @property {(value: number) => boolean} [isError]
 * @property {string} [id]
 * @property {string} [disabledTooltip]
 * @property {(ev: Event) => void} [onClick]
 * @property {string} [className]
 */

/**
 * @param {React.PropsWithRef<Props>} props
 * @return {React.ReactElement}
 */
function SmartInputNumber({
  onFocus,
  onBlur,
  onChange,
  mode,
  minFractionDigits,
  maxFractionDigits,
  initialValue,
  min = null,
  max = null,
  prefix,
  suffix,
  showButtons = false,
  buttonLayout,
  disabled = false,
  autoFocus = false,
  required = false,
  step = 1,
  isError,
  id,
  disabledTooltip = null,
  onClick,
  className,
}, ref) {
  /**
   * @type {React.MutableRefObject<HTMLInputElement>}
   */
  const inputRef = useRef(null)
  const [value, setValue] = useState(null)
  const [errorClassName, setErrorClassName] = useState('')
  const [state, setState] = useState({
    prefix: null,
    suffix: null,
    isFocus: false,
    minFractionDigits: minFractionDigits,
  })

  const loginUser = useSelector((state) => state.loginUser)

  useImperativeHandle(ref, () => ({ value, setValue }))

  const culture = useMemo(() => `${loginUser.language || 'fr'}-CA`, [loginUser.language])

  const onKeyDown = useCallback((ev) => {
    const currentPosition = inputRef.current.selectionStart
    if (
      ev.which === 108 || // Numpad ,
      ev.which === 110 || // Numpad .
      ev.which === 188 || // ,
      ev.which === 190 // .
    ) {
      const isForwardCursor = (
        currentPosition === ev.target.value.indexOf(',') || currentPosition === ev.target.value.indexOf('.')
      )
      if (isForwardCursor) {
        const newPosition = currentPosition + 1
        inputRef.current.setSelectionRange(newPosition, newPosition)
      } else {
        const charToAdd = loginUser.language === 'fr' ? ',' : '.'
        if (!ev.target.value.includes(charToAdd)) {
          ev.target.value += charToAdd
        }
      }
    }
  }, [loginUser.language])

  const onInputChange = useCallback((ev) => {
    /**
     * `onValueChange()` is called on render with no originalEvent and value at null (skip it).
     */
    if (!ev.originalEvent && ev.value === null) return

    if (typeof onChange === 'function') {
      onChange(ev)
    }

    if (typeof isError === 'function') {
      const _isError = isError(Number(ev.value))
      setErrorClassName(_isError ? 'p-invalid' : '')
    }

    setValue(ev.value)
  }, [isError, onChange])

  const onInputBlur = useCallback((ev) => {
    setState((state) => {
      return {
        ...state,
        prefix: prefix ? `${prefix} ` : state.prefix,
        suffix: suffix ? ` ${suffix}` : state.suffix,
        minFractionDigits: minFractionDigits,
      }
    })
    if (typeof onBlur === 'function') {
      onBlur(ev)
    }
  }, [onBlur, prefix, suffix, minFractionDigits])

  const onInputFocus = useCallback((ev) => {
    setState((state) => {
      return {
        ...state,
        prefix: null,
        suffix: null,
        isFocus: true,
        minFractionDigits: 0,
      }
    })

    if (typeof onFocus === 'function') {
      onFocus(ev)
    }
  }, [onFocus])

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    setState((state) => {
      if (!prefix) return state
      return { ...state, prefix: `${prefix} ` }
    })
  }, [prefix])

  useEffect(() => {
    setState((state) => {
      if (!suffix) return state
      return { ...state, suffix: ` ${suffix}` }
    })
  }, [suffix])

  useEffect(() => {
    if (state.isFocus) {
      inputRef.current.select()
      setState((state) => ({ ...state, isFocus: false }))
    }
  }, [state.isFocus])

  useEffect(() => {
    setState((state) => {
      if (!minFractionDigits) return state
      return { ...state, minFractionDigits: minFractionDigits }
    })
  }, [minFractionDigits])

  /**
   * This is a hack to prevent the input from losing focus when clicking on the buttons
   * Used to make sure that the `ònBlur` is called when the user never use the input directly.
   */
  useEffect(() => {
    if (!inputRef.current || !showButtons) {
      return
    }

    function fakeBlur(ev) {
      inputRef.current.focus()
    }

    const buttons = inputRef.current.parentElement.querySelectorAll('button')

    buttons.forEach((button) => {
      button.addEventListener('click', fakeBlur)
    })

    return () => {
      buttons.forEach((button) => {
        button.removeEventListener('click', fakeBlur)
      })
    }
  }, [showButtons])

  const handleAutoFocus = useCallback((nativeRef) => {
    if (autoFocus) {
      nativeRef.focus()
    }

    inputRef.current = nativeRef
  }, [autoFocus])

  return (
    <InputNumber
      inputId={id}
      inputClassName={errorClassName || className}
      inputRef={handleAutoFocus}
      step={step}
      value={value}
      onValueChange={onInputChange}
      mode={mode}
      prefix={state.prefix}
      suffix={state.suffix}
      onFocus={onInputFocus}
      onBlur={onInputBlur}
      onKeyDown={onKeyDown}
      min={min}
      max={max}
      locale={culture}
      minFractionDigits={state.minFractionDigits}
      maxFractionDigits={maxFractionDigits}
      showButtons={showButtons}
      buttonLayout={buttonLayout}
      disabled={disabled}
      useGrouping={false}
      required={required}
      tooltip={disabled ? disabledTooltip : null}
      onClick={onClick}
    />
  )
}

export default forwardRef(SmartInputNumber)
