import React, { forwardRef, useCallback, useMemo } from 'react'
import { createRoot } from 'react-dom/client'

import { v4 as uuid } from 'uuid'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Menu } from 'primereact/menu'
import { Tooltip } from 'primereact/tooltip'

import { valueOrDefault, valueOrTrue } from 'utils/defaultValueHelper'
import { waitForElement } from 'utils/waitForElement'

import './style.css'

const mapActions = (actions, template) => {
  return actions
    // Polyfill https://github.com/primefaces/primereact/pull/3177
    .filter((action) => valueOrDefault(action.visible, true))
    .sort((a, b) => (a.actionOrder > b.actionOrder) ? 1 : ((b.actionOrder > a.actionOrder) ? -1 : 0))
    .map((action) => {
      if (action.items?.length > 0) {
        action.items = mapActions(action.items, template)
      }
      return { ...action, template }
    })
}

/**
 * @typedef {Object} Props
 * @property {any[]} actions
 * @property {string} [className]
 * @property {any} [footer]
 */

/**
 * @param {Props} props
 * @return {React.ReactElement}
 */
function SmartMenu({ className, actions, footer }, ref) {
  const uniqueId = useMemo(() => uuid(), [])

  const template = useCallback((item, options) => {
    const iconClassNames = [options.iconClassName]
    if (item.iconClassName) {
      iconClassNames.push(item.iconClassName)
    }
    const isEnabled = valueOrTrue(item.isEnabled)
    return (
      <div>
        <div
          className={item.tooltipTarget}
          data-pr-tooltip={item.disabledTooltip}
          data-pr-position="left"
          data-selected={!!item.selected}
        >
          <a
            target={item.target}
            className={options.className}
            onClick={isEnabled ? (ev) => options.onClick(ev) : undefined}
          >
            {item.faIcon ? (
              <FontAwesomeIcon
                className={iconClassNames.join(' ')}
                icon={['fas', item.faIcon]}
                onClick={isEnabled ? (ev) => item.iconClick?.(ev) : undefined}
                fixedWidth
              />
            ) : null}
            <span className={options.labelClassName}>
              {item.label}
            </span>
          </a>
        </div>
        {item.tooltipTarget ?
          <Tooltip
            className="a-no-arrow"
            target={`.${item.tooltipTarget}`}
            style={{ textAlign: 'center' }}
          /> : null}
      </div>
    )
  }, [])

  const onShow = useMemo(() => !!footer ? () => waitForElement(`.p-menu[data-id="${uniqueId}"]`).then((element) => {
    const footerContainerNode = document.createElement('div')
    footerContainerNode.classList.add('a-smart-menu-footer')
    element.appendChild(footerContainerNode)

    const root = createRoot(element.querySelector('.a-smart-menu-footer'))
    root.render(footer)
  }) : undefined, [uniqueId, footer])

  return (
    <Menu
      ref={ref}
      data-id={uniqueId}
      className={className}
      model={mapActions(actions, template)}
      popup
      onShow={onShow}
    />
  )
}

export default forwardRef(SmartMenu)
