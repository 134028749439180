import { GetFields } from 'types/slices'

import { parse } from 'utils/api'
import { getFirstString, joinOnlyStrings } from 'utils/getFirstOfType'
import { buildDictionary } from 'utils/mapperHelper'
import { convertToBase } from 'utils/unitConverter'

import { getDefaultContactDetails } from 'reducers/contacts/contactsSlice'
import { parseInventoryLedger } from 'reducers/inventories/inventoriesSlice'
import { parseReportingTag } from 'reducers/reporting-tags/reportingTagAssociationSlice'
import { parseTemplateSupplierPrice } from 'reducers/template-supplier-prices/templateSupplierPricesSlice'

import { PurchaseOrder, PurchaseOrderApi, PurchaseOrderItem, PurchaseOrderItemApi } from './types'

export const dataSetName = 'purchaseOrderView'
export const itemDataSetName = 'purchaseOrderItemView'
export const fields = getFields()
export const lineItemFields = getLineItemFields()

export const defaultPurchaseOrderMapData = {
  calculateStatuses: true,
}

export function getDefaultPurchaseOrder(): PurchaseOrder {
  return {
    ...parse({}, { fields }),
    vendorDetails: getDefaultContactDetails(),
    zohoBooksBillId: null,
  }
}

type MapData = {
  defaultUnits?: {
    qty: string
    weight: string
    length: string
    surface: string
    volume: string
  }
  primaryLanguage?: string
  isPrimaryLanguage?: boolean
  isDocumentPrimaryLanguage?: boolean
}
type PurchaseOrderGetFields = GetFields<PurchaseOrderApi, PurchaseOrder, MapData, null, {config: { apiKey: 'config'} }>
export function getFields(editOnly?: boolean, skipDuplicate?: boolean): PurchaseOrderGetFields {
  const fields: PurchaseOrderGetFields = {
    'exist': { dataSetName, dbField: 'exist', isEdit: false, type: 'boolean' },
    'id': { dataSetName, dbField: 'id', isEdit: false, type: 'id' },
    'vendorId': { dataSetName, dbField: 'supplier_id', isEdit: true, type: 'id', relationEntity: 'contacts' },
    'dropshipClientId': {
      dataSetName,
      dbField: 'dropship_client_id',
      isEdit: true,
      type: 'id',
      relationEntity: 'contacts',
    },
    'plantId': { dataSetName, dbField: 'ship_to_plant_id', isEdit: true, type: 'id', relationEntity: 'plants' },
    'referenceNumber': { dataSetName, dbField: 'reference_number', isEdit: true },
    'expectedDeliveryDate': {
      dataSetName,
      dbField: 'expected_delivery_date',
      isEdit: true,
      type: 'date',
      isTimezoned: false,
    },
    'ownerId': { dataSetName, dbField: 'owner_id', isEdit: true, type: 'id', relationEntity: 'resources' },
    'orderDate': { dataSetName, dbField: 'order_date', isEdit: true, type: 'date', isTimezoned: false },
    'shipmentPreference': { dataSetName, dbField: 'shipment_preference', isEdit: true },
    'notes': { dataSetName, dbField: 'notes', isEdit: true, type: 'text' },
    'termsAndConditions': { dataSetName, dbField: 'terms_and_conditions', isEdit: true, type: 'text' },
    'config': {
      dataSetName,
      dbField: 'config',
      isEdit: true,
      type: 'json',
      properties: {
        // config fields, they are not really in the db, but are stored in the dbfield `config` as json
        'language': { dataSetName, dbField: 'language', type: 'language' },
        'hideDiscount': { dataSetName, dbField: 'hideDiscount', type: 'boolean' },
        'hidePartNumber': { dataSetName, dbField: 'hidePartNumber', type: 'boolean' },
        'hideSKU': { dataSetName, dbField: 'hideSKU', type: 'boolean' },
        'showLongDescription': { dataSetName, dbField: 'showLongDescription', type: 'boolean' },
        'showNotes': { dataSetName, dbField: 'showNotes', type: 'boolean' },
      },
    },
    'isDropship': { dataSetName, dbField: 'is_dropship', isEdit: true, formDefaultValue: false, type: 'boolean' },
    'isSupplierPickup': {
      dataSetName,
      dbField: 'is_supplier_pickup',
      isEdit: true,
      formDefaultValue: false,
      type: 'boolean',
    },
    'dropshipClientName': { dataSetName, dbField: 'dropship_client_display_name', isEdit: false },
    'dropshipClientCompanyName': { dataSetName, dbField: 'dropship_client_company_name', isEdit: false },
    'plantName': { dataSetName, dbField: 'ship_to_plant_name', isEdit: false },
    'plantCompanyName': { dataSetName, dbField: 'plant_company_name', isEdit: false },
    'deliveryType': { dataSetName, dbField: 'delivery_type' },
    'poNumber': { dataSetName, dbField: 'name' },
    'createdDate': { dataSetName, dbField: 'created_date', type: 'date' },
    'createdBy': { dataSetName, dbField: 'created_by' },
    'createdById': { dataSetName, dbField: 'created_by_id', type: 'id' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'date' },
    'modifiedBy': { dataSetName, dbField: 'modified_by' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id', type: 'id' },
    'status': {
      dataSetName,
      dbField: 'status',
      customEventValueTranslationKey: (value) => `purchaseOrders:status.${value ?? 'draft'}`,
    },
    'measureSum': { dataSetName, dbField: 'measure_sum', dataSetAlias: 'calculated', type: 'measure' },
    'receivingStatus': { dataSetName, dbField: 'receiving_status', dataSetAlias: 'calculated' },
    'billingStatus': { dataSetName, dbField: 'billing_status', dataSetAlias: 'calculated' },
    'billedSum': { dataSetName, dbField: 'billed_sum', dataSetAlias: 'calculated' },
    'paymentStatus': { dataSetName, dbField: 'calculated_status', dataSetAlias: 'payment' },
    'vendorName': { dataSetName, dbField: 'supplier_display_name' },
    'vendorCompanyName': { dataSetName, dbField: 'supplier_company_name' },
    'vendorZohoId': { dataSetName, dbField: 'supplier_zoho_books_contact_id', type: 'id' },
    'vendorAddress.id': {
      dataSetName,
      dbField: 'supplier_address_id',
      type: 'id',
      relationEntity: 'addresses',
    },
    'vendorAddress.attention': { dataSetName, dbField: 'supplier_address_attention' },
    'vendorAddress.street': { dataSetName, dbField: 'supplier_address_address' },
    'vendorAddress.street2': { dataSetName, dbField: 'supplier_address_street2' },
    'vendorAddress.city': { dataSetName, dbField: 'supplier_address_city' },
    'vendorAddress.state': { dataSetName, dbField: 'supplier_address_state' },
    'vendorAddress.zip': { dataSetName, dbField: 'supplier_address_zip' },
    'vendorAddress.country': { dataSetName, dbField: 'supplier_address_country' },
    'vendorAddress.phone': { dataSetName, dbField: 'supplier_address_phone' },
    'ownerName': { dataSetName, dbField: 'owner_name' },
    'ownerEmail': { dataSetName, dbField: 'owner_email' },
    'ownerPhone': { dataSetName, dbField: 'owner_phone' },
    'currencyId': { dataSetName, dbField: 'currency_id', type: 'id' },
    'currencyCode': { dataSetName, dbField: 'currency_code' },
    'currencySymbol': { dataSetName, dbField: 'currency_symbol' },
    'exchangeRate': { dataSetName, dbField: 'snapped_exchange_rate', type: 'float' },
    'subtotal': { dataSetName, dbField: 'sub_total', parse: getSubTotal, type: 'currency' },
    'lineItems': { parse: (purchaseOrder, options) => {
      const isPrimaryLanguage = options?.primaryLanguage === purchaseOrder?.supplier_language
      const documentLanguage = purchaseOrder?.config?.language ?
        `${purchaseOrder?.config.language}-CA` :
        purchaseOrder?.supplier_language
      const isDocumentPrimaryLanguage = options?.primaryLanguage === documentLanguage

      return (purchaseOrder.lineItems || []).map((lineItem) => {
        return parsePurchaseOrderLineItem(lineItem, options?.defaultUnits, isPrimaryLanguage, isDocumentPrimaryLanguage)
      })
    } },
    'shipTo.id': {
      dataSetName,
      dbField: 'ship_to_address_id',
      type: 'id',
      relationEntity: 'addresses',
    },
    'shipTo.attention': { dataSetName, dbField: 'ship_to_attention' },
    'shipTo.street': { dataSetName, dbField: 'ship_to_address' },
    'shipTo.street2': { dataSetName, dbField: 'ship_to_street2' },
    'shipTo.city': { dataSetName, dbField: 'ship_to_city' },
    'shipTo.state': { dataSetName, dbField: 'ship_to_state' },
    'shipTo.zip': { dataSetName, dbField: 'ship_to_zip' },
    'shipTo.country': { dataSetName, dbField: 'ship_to_country' },
    'shipTo.phone': { dataSetName, dbField: 'ship_to_phone' },
    'billTo.id': {
      dataSetName,
      dbField: 'bill_to_address_id',
      type: 'id',
      relationEntity: 'addresses',
    },
    'billTo.attention': { dataSetName, dbField: 'bill_to_attention' },
    'billTo.street': { dataSetName, dbField: 'bill_to_address' },
    'billTo.street2': { dataSetName, dbField: 'bill_to_street2' },
    'billTo.city': { dataSetName, dbField: 'bill_to_city' },
    'billTo.state': { dataSetName, dbField: 'bill_to_state' },
    'billTo.zip': { dataSetName, dbField: 'bill_to_zip' },
    'billTo.country': { dataSetName, dbField: 'bill_to_country' },
    'billTo.phone': { dataSetName, dbField: 'bill_to_phone' },
    'emailingDetails': { dataSetName, dbField: 'emailing_details', type: 'json' },

    'cid': { dataSetName, dbField: 'cid', isEdit: false },
    'externalId': { dataSetName, dbField: 'external_id', isEdit: false },
    'paymentTerms': { dataSetName, dbField: 'payment_terms', isEdit: false },
    'generatedNumber': { dataSetName, dbField: 'generated_number', isEdit: false },
    'total': { dataSetName, dbField: 'total', isEdit: false },
    'supplierLanguage': { dataSetName, dbField: 'supplier_language', isEdit: false },
    'supplierAddressStateCode': { dataSetName, dbField: 'supplier_address_state_code', isEdit: false },
    'supplierZohoBooksTaxId': { dataSetName, dbField: 'supplier_zoho_books_tax_id', isEdit: false },
    'shipToPlantCode': { dataSetName, dbField: 'ship_to_plant_code', isEdit: false },
    'plantCompanyId': { dataSetName, dbField: 'plant_company_id', isEdit: false },
    'shipToStateCode': { dataSetName, dbField: 'ship_to_state_code', isEdit: false },
    'billToStateCode': { dataSetName, dbField: 'bill_to_state_code', isEdit: false },
  }

  let fieldsToReturn = Object.keys(fields)
  let _toSkip = []
  if (skipDuplicate) {
    _toSkip = ['referenceNumber', 'expectedDeliveryDate', 'config']
  } else if (editOnly) {
    const _toInclude = [
      'id', 'vendorId', 'dropshipClientId', 'plantId', 'referenceNumber',
      'expectedDeliveryDate', 'ownerId', 'orderDate', 'shipmentPreference',
      'notes', 'termsAndConditions', 'config', 'isDropship',
      'isSupplierPickup', 'dropshipClientName', 'dropshipClientCompanyName',
      'plantName', 'plantCompanyName', 'name',
    ]
    fieldsToReturn = Object.keys(fields).filter((key) => _toInclude.includes(key))
  }

  return fieldsToReturn.reduce((acc, key) => {
    const newAcc = { ...acc }
    newAcc[key] = fields[key]
    if (_toSkip.includes(key)) {
      newAcc[key].skip = true
    }
    return newAcc
  }, {})
}

function getSubTotal(purchaseOrder: PurchaseOrderApi) {
  return +purchaseOrder?.sub_total || 0
}

type PurchaseOrderItemGetFields = GetFields<PurchaseOrderItemApi, PurchaseOrderItem, MapData>
export function getLineItemFields(editOnly?: boolean, skipDuplicate?: boolean): PurchaseOrderItemGetFields {
  function getTemplateUnit(lineItem: PurchaseOrderItemApi, options: MapData) {
    const dimension = lineItem?.template_dimension
    return lineItem?.template_unit || options?.defaultUnits?.[dimension]
  }

  function getUnit(lineItem: PurchaseOrderItemApi, options: MapData) {
    return +lineItem?.conversion_factor > 0 ? lineItem?.unit || '' : getTemplateUnit(lineItem, options)
  }

  function getConversionFactor(lineItem: PurchaseOrderItemApi, options: MapData, isInitial = false) {
    if (isInitial) {
      return +lineItem?.conversion_factor || 0
    }

    const dimension = lineItem?.template_dimension
    const templateUnit = getTemplateUnit(lineItem, options)

    return +lineItem?.conversion_factor > 0 ? +lineItem?.conversion_factor : convertToBase(dimension, 1, templateUnit)
  }

  function _parseItemName(item: PurchaseOrderItemApi, isPrimaryLanguage: boolean) {
    return item?.overwritten_name || (
      isPrimaryLanguage ? item?.primary_name || item?.secondary_name : item?.secondary_name || item?.primary_name
    )
  }

  function _parseItemNotes(item: PurchaseOrderItemApi, isPrimaryLanguage: boolean) {
    return item?.overwritten_notes || (
      isPrimaryLanguage ? item?.primary_notes || item?.secondary_notes : item?.secondary_notes || item?.primary_notes
    )
  }

  function parseItemName(item: PurchaseOrderItemApi, options: MapData) {
    return _parseItemName(item, options?.isPrimaryLanguage)
  }

  function parseItemNotes(item: PurchaseOrderItemApi, options: MapData) {
    return _parseItemNotes(item, options?.isPrimaryLanguage)
  }

  function parseItemDocumentName(item: PurchaseOrderItemApi, options: MapData) {
    return _parseItemName(item, options?.isDocumentPrimaryLanguage)
  }

  function parseItemDocumentNotes(item: PurchaseOrderItemApi, options: MapData) {
    return _parseItemNotes(item, options?.isDocumentPrimaryLanguage)
  }

  const fields: PurchaseOrderItemGetFields = {
    displayTitle: {
      dataSetName: itemDataSetName,
      dbField: 'purchase_order_name',
      parseWithParsedData: (item) => joinOnlyStrings(
        [item.purchaseOrderName, getFirstString(item.name, item.templateTitle)], ' - ',
      ),
    },
    id: { dataSetName: itemDataSetName, dbField: 'id', isEdit: false, type: 'id' },
    name: { dataSetName: itemDataSetName, dbField: 'overwritten_name', isEdit: true, parse: parseItemName },
    overwrittenName: { dataSetName: itemDataSetName, dbField: 'overwritten_name', isEdit: true },
    primaryName: { dataSetName: itemDataSetName, dbField: 'primary_name' },
    secondaryName: { dataSetName: itemDataSetName, dbField: 'secondary_name' },
    partNumber: { dataSetName: itemDataSetName, dbField: 'supplier_part_number', isEdit: true },
    notes: {
      dataSetName: itemDataSetName,
      dbField: 'overwritten_notes',
      isEdit: true,
      type: 'text',
      parse: parseItemNotes,
    },
    overwrittenNotes: { dataSetName: itemDataSetName, dbField: 'overwritten_notes', isEdit: true, type: 'text' },
    primaryNotes: { dataSetName: itemDataSetName, dbField: 'primary_notes', type: 'text' },
    secondaryNotes: { dataSetName: itemDataSetName, dbField: 'secondary_notes', type: 'text' },
    contractNumber: { dataSetName: itemDataSetName, dbField: 'contract_number', isEdit: true },
    expectedDeliveryDate: {
      dataSetName: itemDataSetName,
      dbField: 'expected_delivery_date',
      isEdit: true,
      type: 'date',
      isTimezoned: false,
    },
    measure: { dataSetName: itemDataSetName, dbField: 'measure', isEdit: true, formDefaultValue: 0, type: 'measure' },
    unitCostWithoutDiscount: {
      dataSetName: itemDataSetName,
      dbField: 'unit_cost_without_discount',
      isEdit: true,
      formDefaultValue: 0,
      type: 'currency',
    },
    discount: {
      dataSetName: itemDataSetName,
      dbField: 'discount',
      isEdit: true,
      formDefaultValue: 0,
      type: 'percentage',
    },
    unitCost: {
      dataSetName: itemDataSetName,
      dbField: 'unit_cost',
      isEdit: false,
      formDefaultValue: 0,
      type: 'currency',
    },
    baseUnitCost: {
      dataSetName: itemDataSetName,
      dbField: 'unit_cost',
      isEdit: false,
      formDefaultValue: 0,
      type: 'currency',
    },
    templateId: { dataSetName: itemDataSetName, dbField: 'template_id', isEdit: true, type: 'id' },
    rank: { dataSetName: itemDataSetName, dbField: 'rank', isEdit: true, type: 'integer' },
    exist: { dataSetName: itemDataSetName, dbField: 'exist', type: 'boolean' },
    billedCount: { dataSetName: itemDataSetName, dbField: 'billed_count', formDefaultValue: 0, type: 'measure' },
    dimension: { dataSetName: itemDataSetName, dbField: 'template_dimension' },
    unit: { dataSetName: itemDataSetName, dbField: 'unit', parse: getUnit },
    conversionFactor: {
      dataSetName: itemDataSetName,
      dbField: 'conversion_factor',
      parse: getConversionFactor,
      type: 'float',
    },
    initialConversionFactor: { parse: (item, options) => getConversionFactor(item, options, true) },
    purchaseOrderCurrencyCode: {
      dataSetName: itemDataSetName,
      dbField: 'purchase_order_currency_code',
    },
    purchaseOrderCurrencySymbol: {
      dataSetName: itemDataSetName,
      dbField: 'purchase_order_currency_symbol',
    },
    purchaseOrderDate: {
      dataSetName: itemDataSetName,
      dbField: 'purchase_order_date',
      isTimezoned: false,
    },
    purchaseOrderExchangeRate: {
      dataSetName: itemDataSetName,
      dbField: 'purchase_order_exchange_rate',
    },
    purchaseOrderId: {
      dataSetName: itemDataSetName,
      dbField: 'purchase_order_id',
      type: 'id',
      relationEntity: 'purchase-orders',
    },
    purchaseOrderName: {
      dataSetName: itemDataSetName,
      dbField: 'purchase_order_name',
    },
    purchaseOrderStatus: {
      dataSetName: itemDataSetName,
      dbField: 'purchase_order_status',
      type: 'status',
      dictionaryKey: 'purchaseOrder',
      translationPath: 'purchaseOrders:status',
      values: ['canceled', 'closed', 'draft', 'issued'],
      defaultValue: 'draft',
      parse: (props) => props['purchase_order_status'] ?? 'draft',
    },
    referenceNumber: {
      dataSetName: itemDataSetName,
      dbField: 'reference_number',
    },
    templateDimension: { dataSetName: itemDataSetName, dbField: 'template_dimension' },
    templateUnit: { dataSetName: itemDataSetName, dbField: 'template_unit', parse: getTemplateUnit },
    templateDescription: { dataSetName: itemDataSetName, dbField: 'template_description' },
    templateSecondaryDescription: { dataSetName: itemDataSetName, dbField: 'template_secondary_description' },
    templateSku: { dataSetName: itemDataSetName, dbField: 'template_sku' },
    templateTitle: { dataSetName: itemDataSetName, dbField: 'template_title' },
    templatePartNumber: { dataSetName: itemDataSetName, dbField: 'template_part_number' },
    templateManufacturer: { dataSetName: itemDataSetName, dbField: 'template_manufacturer' },
    templateRevision: { dataSetName: itemDataSetName, dbField: 'template_revision' },
    templateMaterial: { dataSetName: itemDataSetName, dbField: 'material_title' },
    templateTreatment: { dataSetName: itemDataSetName, dbField: 'treatment_title' },
    templateCategory: { dataSetName: itemDataSetName, dbField: 'category_title' },
    templateRawImperial: { dataSetName: itemDataSetName, dbField: 'raw_imperial_title' },
    templateRawMetric: { dataSetName: itemDataSetName, dbField: 'raw_metric_title' },
    templatePurchaseMoq: { dataSetName: itemDataSetName, dbField: 'template_moq', type: 'measure' },
    templateUnitCost: { dataSetName: itemDataSetName, dbField: 'template_unit_cost', type: 'currency' },
    templateInventoryManagementType: {
      dataSetName: itemDataSetName,
      dbField: 'template_inventory_management_type',
      type: 'string',
    },
    inventories: {
      parse: (lineItem, options) => {
        return (lineItem?.inventories || []).map((inventory) => {
          return { ...parseInventoryLedger(inventory, options), relationId: inventory.relation_id }
        })
      } },
    inventoryCount: { parse: (lineItem) => getInventoryCount(lineItem?.inventories) },
    templateVendorPrices: {
      parse: (lineItem) => {
        if (!lineItem?.templateSupplierPrices || !lineItem?.templateSupplierPrices.length) return []

        return lineItem?.templateSupplierPrices.map(parseTemplateSupplierPrice)
      },
    },
    receivedCount: {
      dataSetName: itemDataSetName,
      dbField: 'received_count',
      type: 'measure',
      parse: (data) => data.received_count ?? 0,
    },
    receivingStatus: { parse: (lineItem) => getReceivingStatus(lineItem?.inventories) },
    createdDate: { dataSetName: itemDataSetName, dbField: 'created_date', type: 'date' },
    createdBy: { dataSetName: itemDataSetName, dbField: 'created_by' },
    createdById: { dataSetName: itemDataSetName, dbField: 'created_by_id', type: 'id' },
    modifiedDate: { dataSetName: itemDataSetName, dbField: 'modified_date', type: 'date' },
    modifiedBy: { dataSetName: itemDataSetName, dbField: 'modified_by', type: 'id' },
    modifiedById: { dataSetName: itemDataSetName, dbField: 'modified_by_id', type: 'id' },
    isTaxed: { dataSetName: itemDataSetName, dbField: 'template_is_taxed', type: 'boolean' },
    accountId: {
      dataSetName: itemDataSetName,
      dbField: 'account_id',
      type: 'id',
      relationEntity: 'chart-of-accounts',
      isEdit: true,
    },
    accountExternalId: { dataSetName: itemDataSetName, dbField: 'account_external_id', type: 'id' },
    documentName: { parse: parseItemDocumentName },
    documentNotes: { parse: parseItemDocumentNotes },
    vendorName: { dataSetName: itemDataSetName, dbField: 'supplier_display_name' },
    vendorPartNumber: { dataSetName: itemDataSetName, dbField: 'supplier_part_number' },
    reportingTagsCount: { parse: (poItem) => poItem.reportingTags?.length || 0 },
    reportingTags: {
      dataSetName: itemDataSetName,
      dbField: 'reportingTags',
      type: 'array',
      parse: (poItem) =>
        poItem.reportingTags?.map((reportingTag) => parseReportingTag('purchase-order-items', reportingTag)) ?? [],
    },
  }

  let fieldsToReturn = Object.keys(fields)
  let _toSkip = []
  if (editOnly) {
    const _toInclude = [
      'id', 'name', 'partNumber', 'notes', 'contractNumber', 'expectedDeliveryDate', 'measure',
      'unitCostWithoutDiscount', 'discount', 'unitCost', 'templateId', 'rank', 'reportingTags',
      'accountId',
    ]
    fieldsToReturn = Object.keys(fields).filter((key) => _toInclude.includes(key))
  } else if (skipDuplicate) {
    _toSkip = [
      'expectedDeliveryDate', 'inventories', 'inventoryCount', 'receivingStatus',
      'createdDate', 'createdBy', 'createdById', 'modifiedDate', 'modifiedBy', 'modifiedById',
    ]
  }

  return fieldsToReturn.reduce((acc, key) => {
    const newAcc = { ...acc }
    newAcc[key] = fields[key]
    if (_toSkip.includes(key)) {
      newAcc[key].skip = true
    }
    return newAcc
  }, {})
}

export function parsePurchaseOrder(purchaseOrder: PurchaseOrderApi, mapData: MapData): PurchaseOrder {
  const options = {
    ...mapData,
    defaultData: getDefaultPurchaseOrder(),
    fields,
    dataSetName,
  }
  const parsedPurchaseOrder = parse(purchaseOrder, options)
  parsedPurchaseOrder.lineItemDict = buildDictionary(parsedPurchaseOrder.lineItems, 'id')
  return parsedPurchaseOrder
}

export function parsePurchaseOrderLineItem(
  lineItem: PurchaseOrderItemApi,
  defaultUnits?: MapData['defaultUnits'],
  isPrimaryLanguage?: MapData['isPrimaryLanguage'],
  isDocumentPrimaryLanguage?: MapData['isDocumentPrimaryLanguage'],
): PurchaseOrderItem {
  const options = {
    fields: lineItemFields,
    dataSetName: itemDataSetName,
    isPrimaryLanguage,
    isDocumentPrimaryLanguage,
    defaultUnits,
  }
  return parse(lineItem, options)
}

const notReceivedInventoryStatuses = ['active']
export function getReceivingStatus(inventories = []) {
  const receivedInventories = inventories.filter((inventory) =>
    !notReceivedInventoryStatuses.includes(inventory.status),
  )
  if (inventories.length > 0 && receivedInventories.length >= inventories.length) {
    return 'received'
  } else if (receivedInventories.length <= 0) {
    return 'to_receive'
  } else {
    return 'partially_received'
  }
}

export function getInventoryCount(inventories = []) {
  return new Set(inventories.map((inventory) => inventory.id)).size
}
