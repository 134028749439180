import logicParser from 'logic-query-parser'
import { v4 as uuid } from 'uuid'

import { FilterOperator } from 'primereact/api'

import { buildGetUrl, parse } from 'utils/api'
import { dataToFormData, formKeyDataToObject } from 'utils/mapperHelper'
import { safeFetchJson } from 'utils/safeFetch'
import { toCamel } from 'utils/stringUtils'

import {
  GET_VIEW,
  CLEAR_VIEW,
  CREATE_VIEW,
  UPDATE_VIEW,
  UPDATE_MODEL,
  DELETE_VIEW,
  SET_IS_CREATE,
  SET_GLOBAL_FORM,
  SET_CONFIG_FORM,
  RESET_FORM,
} from './types'

const dataSetName = 'genTableView'
const fields = getFields()
const initialState = {
  dataSetName,
  fields,
  activeView: getDefaultView(),
  activeForm: getDefaultForm(),
}

export const NO_VALUES_MATCH_MODES = ['isEmpty', 'isNotEmpty']
const EQUATION_ALLOWED_CHARS = ['(', ')', 'AND', 'OR']

export default function viewsReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
  case GET_VIEW: {
    return buildViewState(state, payload)
  }
  case CREATE_VIEW: {
    return buildViewState(state, payload)
  }
  case UPDATE_VIEW: {
    return buildViewState(state, payload)
  }
  case CLEAR_VIEW: {
    return {
      ...state,
      activeView: getDefaultView(),
      activeForm: getDefaultForm(),
    }
  }
  case SET_IS_CREATE: {
    const globalForm = dataToFormData(getDefaultView(), getFields(true))
    const configForm = getDefaultConfigForm()

    const newActiveForm = {
      ...state.activeForm,
      isCreate: payload,
      global: globalForm,
      config: configForm,
      isValid: isFormValid(globalForm, configForm),
    }

    return {
      ...state,
      activeForm: {
        ...newActiveForm,
        hasChanges: hasChanges(newActiveForm),
      },
    }
  }
  case SET_GLOBAL_FORM: {
    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        hasChanges: hasChanges({ ...state.activeForm, global: payload }),
        isValid: isFormValid(payload, state.activeForm.config),
        global: payload,
      },
    }
  }
  case SET_CONFIG_FORM: {
    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        hasChanges: hasChanges({ ...state.activeForm, config: payload }),
        isValid: isFormValid(state.activeForm.global, payload),
        config: payload,
      },
    }
  }
  case RESET_FORM: {
    const globalForm = dataToFormData(
      state.activeForm.isCreate ? getDefaultView() : state.activeView,
      getFields(true),
    )
    const configForm = getDefaultConfigForm()

    const newActiveForm = {
      ...state.activeForm,
      isValid: isFormValid(globalForm, configForm),
      resetCount: state.activeForm.resetCount + 1,
      global: globalForm,
      config: configForm,
    }

    return {
      ...state,
      activeForm: {
        ...newActiveForm,
        hasChanges: hasChanges(newActiveForm),
      },
    }
  }
  default: {
    return state
  }
  }
}

export function getFields(editOnly) {
  const fields = {
    id: { dataSetName, dbField: 'id', isEdit: false, type: 'id' },
    name: { dataSetName, dbField: 'name', isEdit: true },
    key: { parse: (view) => view.issystem ? view.name : null },
    tab: { dataSetName, dbField: 'tab', isEdit: false },
    config: { dataSetName, dbField: 'config', type: 'json' },
    isDefault: { dataSetName, dbField: 'issystem', type: 'boolean' },
    datasetName: { dataSetName, dbField: 'dataset_name' },
    createdById: { dataSetName, dbField: 'created_by_id' },
    ownerId: { dataSetName, dbField: 'owner_id', isEdit: true },
    shareWith: { dataSetName, dbField: 'share_with', isEdit: true, formDefaultValue: 'only_me' },
    isLocked: { dataSetName, dbField: 'is_locked', isEdit: true, type: 'boolean', formDefaultValue: false },
  }

  let fieldsToReturn = Object.keys(fields)
  if (editOnly) {
    const _toInclude = ['id', 'name', 'tab', 'ownerId', 'shareWith', 'isLocked']
    fieldsToReturn = Object.keys(fields).filter((key) => _toInclude.includes(key))
  }

  return fieldsToReturn.reduce((acc, key) => {
    const newAcc = { ...acc }
    newAcc[key] = fields[key]
    return newAcc
  }, {})
}

export function fetchView(viewId) {
  return async function fetchViewThunk(dispatch) {
    if (viewId === 'new') {
      dispatch({ type: SET_IS_CREATE, payload: true })
      return null
    } else {
      const [view] = await _fetchViews(viewId)
      dispatch({ type: GET_VIEW, payload: view })
      return view
    }
  }
}

export async function fetchViewsByTab(tab) {
  let views = []

  try {
    views = await _fetchViews(null, { tab })
  } catch (err) {
    console.error(err)
  }

  return views
}

async function _fetchViews(viewIds, data = {}) {
  let parsedViews = []
  try {
    let url = '/new_api/views'
    if (viewIds) {
      url += `/${viewIds}`
    }
    const { isSuccess, result } = await safeFetchJson(buildGetUrl(url, { ...data, sharedWithOnly: true }))
    if (!isSuccess) {
      return
    }

    parsedViews = result.map((view) => parseView(view))
  } catch (err) {
    console.error(err)
  }

  return parsedViews
}

export function saveView(setPage, entity) {
  return async function saveViewThunk(dispatch, getState) {
    const viewsStore = getState().views

    const globalFormData = formKeyDataToObject(viewsStore.activeForm.global)
    const configFormData = { ...viewsStore.activeForm.config }
    delete configFormData.isChanged

    if (viewsStore.activeForm.isCreate) {
      setPage((page) => ({ ...page, isCreating: true }))
      if (viewsStore.activeForm.config.isChanged.constraintCount <= 0) {
        configFormData.constraint = { operator: FilterOperator.AND, constraints: [] }
      }
      const view = {
        ...globalFormData,
        tab: `react-${entity}`,
        config: configFormData,
      }
      return _createView(dispatch, view)
    } else {
      const view = {
        id: viewsStore.activeView.id,
        ...globalFormData,
        config: configFormData,
      }

      return _updateView(dispatch, view)
    }
  }
}

export function deleteViews(viewIds) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  }
  return async function deleteViewsThunk(dispatch) {
    try {
      const result = await safeFetchJson(`/new_api/views/${viewIds}`, requestOptions)
      const error = result.isSuccess ? null : result.result
      dispatch({ type: DELETE_VIEW, payload: result.isSuccess, error })

      return result
    } catch (error) {
      dispatch({ type: DELETE_VIEW, error })
    }
  }
}

async function _createView(dispatch, view) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ view, setCreatedAsPrefered: true }),
  }

  try {
    const result = await safeFetchJson(`/new_api/views`, requestOptions)
    const [created] = result.isSuccess ? result.result : []
    const payload = created ? parseView(created) : null
    const error = !result.isSuccess ? result.result : null
    dispatch({ type: CREATE_VIEW, payload, error })

    return { isCreate: true, view: payload }
  } catch (error) {
    dispatch({ type: CREATE_VIEW, error })
  }
}

async function _updateView(dispatch, view) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ view }),
  }

  try {
    const result = await safeFetchJson(`/new_api/views/${view.id}`, requestOptions)
    const [updated] = result.isSuccess ? result.result : []
    const payload = updated ? parseView(updated) : null
    const error = !result.isSuccess ? result.result : null
    dispatch({ type: UPDATE_VIEW, payload, error })

    return { view: payload }
  } catch (error) {
    dispatch({ type: UPDATE_VIEW, error })
  }
}

export function clearView(dispatch) {
  dispatch({ type: CLEAR_VIEW })
}

export function updateGlobalFormFields(fieldValues) {
  return async function updateGlobalFormFieldsThunk(dispatch, getState) {
    const viewsStore = getState().views
    const payload = { ...viewsStore.activeForm.global }

    fieldValues.forEach((fieldValue) => {
      payload[fieldValue.field] = {
        ...viewsStore.activeForm.global[fieldValue.field],
        value: fieldValue.value,
        isChanged: true,
      }
    })

    dispatch({ type: SET_GLOBAL_FORM, payload })
  }
}

export function addConstraintsToConfigForm(dispatch, getState) {
  const viewsStore = getState().views
  viewsStore.activeForm.config.constraint = simplifyConstraintR({
    operator: FilterOperator.AND,
    constraints: [viewsStore.activeForm.config.constraint, getEmptyConstraint()],
  })

  const payload = {
    ...viewsStore.activeForm.config,
    constraint: viewsStore.activeForm.config.constraint,
    isChanged: {
      ...viewsStore.activeForm.config.isChanged,
      constraintCount: viewsStore.activeForm.config.isChanged.constraintCount + 1,
    },
  }

  dispatch({ type: SET_CONFIG_FORM, payload })
}

export function addSortToConfigForm(dispatch, getState) {
  const viewsStore = getState().views

  const payload = {
    ...viewsStore.activeForm.config,
    sort: [...viewsStore.activeForm.config.sort, getEmptySort()],
    isChanged: {
      ...viewsStore.activeForm.config.isChanged,
      sortCount: viewsStore.activeForm.config.isChanged.sortCount + 1,
    },
  }

  dispatch({ type: SET_CONFIG_FORM, payload })
}

export function setConfigFormConstraintFromEquation(equation, flatConstraints) {
  return async function setConfigFormConstraintFromEquationThunk(dispatch, getState) {
    const viewsStore = getState().views
    const inputValues = equation.toUpperCase().split(' ')
    const inputChars = inputValues.filter((v) => !Number.isInteger(+v))

    const error = new Error('An error occured while updating the view model')
    try {
      if (inputChars.some((v) => !EQUATION_ALLOWED_CHARS.includes(v))) {
        error.name = 'NotAllowedChars'
        throw error
      }

      const inputIndexes = inputValues.filter((v) => Number.isInteger(+v))
      if ((new Set(inputIndexes)).size !== inputIndexes.length) {
        error.name = 'DuplicateIndexes'
        throw error
      }

      if (Math.max(...inputIndexes) != flatConstraints.length) {
        error.name = 'IndexMismatch'
        throw error
      }

      let constraint = {}
      try {
        constraint = parsedEquationToConstaint(
          logicParser.utils.binaryTreeToQueryJson(logicParser.parse(equation)),
          flatConstraints.map((c) => {
            const _c = { ...c }
            delete _c.operator
            return _c
          }),
        )
      } catch (err) {
        error.name = 'ParsingError'
        throw error
      }

      const _flatConstraints = flattenConstraintsR(constraint.constraints, constraint.operator, constraint.operator)
      if (_flatConstraints.length != flatConstraints.length) {
        error.name = 'MissingIndexes'
        throw error
      }

      dispatch({
        type: SET_CONFIG_FORM,
        payload: {
          ...viewsStore.activeForm.config,
          constraint: simplifyConstraintR(constraint),
          isChanged: {
            ...viewsStore.activeForm.config.isChanged,
            constraintCount: viewsStore.activeForm.config.isChanged.constraintCount + 1,
          },
        },
      })
    } catch (error) {
      dispatch({ type: UPDATE_MODEL, error })
      return error
    }
  }
}

const configConstraintUpdateFields = ['field', 'value', 'options', 'matchMode', 'filterField']
export function updateConfigFormConstraint(constraint) {
  return function updateConfigFormConstraintThunk(dispatch, getState) {
    const viewsStore = getState().views
    const { found: _toUpdate } = searchConstraintR(viewsStore.activeForm.config.constraint, constraint.id)
    if (_toUpdate) {
      for (const property in constraint) {
        if (configConstraintUpdateFields.includes(property)) {
          _toUpdate[property] = constraint[property]
        }
      }

      dispatch({
        type: SET_CONFIG_FORM,
        payload: {
          ...viewsStore.activeForm.config,
          constraint: viewsStore.activeForm.config.constraint,
          isChanged: {
            ...viewsStore.activeForm.config.isChanged,
            constraintCount: viewsStore.activeForm.config.isChanged.constraintCount + 1,
          },
        },
      })
    }
  }
}

export function updateConfigFormSort(sort) {
  return function updateConfigFormSortThunk(dispatch, getState) {
    const viewsStore = getState().views
    const _toUpdate = viewsStore.activeForm.config.sort.find((s) => s.id === sort.id)

    if (_toUpdate) {
      for (const property in sort) {
        _toUpdate[property] = sort[property]
      }

      dispatch({
        type: SET_CONFIG_FORM,
        payload: {
          ...viewsStore.activeForm.config,
          sort: viewsStore.activeForm.config.sort,
          isChanged: {
            ...viewsStore.activeForm.config.isChanged,
            sortCount: viewsStore.activeForm.config.isChanged.sortCount + 1,
          },
        },
      })
    }
  }
}

export function updateConfigFormConstraintOperator(id, operator) {
  return function updateConfigFormConstraintOperatorThunk(dispatch, getState) {
    const viewsStore = getState().views
    const { found: _toUpdate, from } = searchConstraintR(viewsStore.activeForm.config.constraint, id)

    if (_toUpdate) {
      const toUpdateIndex = from.constraint.constraints.findIndex((c) => c.id === _toUpdate.id)
      if (toUpdateIndex === 0) {
        from.parent.constraints[from.index - 1] = { operator, constraints: [
          from.parent.constraints[from.index - 1],
          from.constraint,
        ] }
        from.parent.constraints.splice(from.index, 1)
      } else if (from.constraint.constraints.length > 2 && operator === FilterOperator.AND) {
        from.constraint.constraints[toUpdateIndex - 1] = { operator, constraints: [
          from.constraint.constraints[toUpdateIndex - 1],
          _toUpdate,
        ] }
        from.constraint.constraints.splice(toUpdateIndex, 1)
      } else {
        from.constraint.operator = operator

        if (from.constraint.constraints.length > 2 && operator === FilterOperator.OR) {
          from.constraint.constraints = from.constraint.constraints.reduce((acc, constraint, index) => {
            if (index < toUpdateIndex) {
              if (!acc[0]) {
                acc[0] = { operator: FilterOperator.AND, constraints: [constraint] }
              } else {
                acc[0].constraints.push(constraint)
              }
            } else if (!acc[1]) {
              acc[1] = { operator: FilterOperator.AND, constraints: [constraint] }
            } else {
              acc[1].constraints.push(constraint)
            }

            return acc
          }, []).reduce((acc, constraint) => {
            if (constraint.constraints.length < 2) {
              acc = acc.concat(constraint.constraints)
            } else {
              acc.push(constraint)
            }

            return acc
          }, [])
        }
      }

      viewsStore.activeForm.config.constraint = simplifyConstraintR(viewsStore.activeForm.config.constraint)

      dispatch({
        type: SET_CONFIG_FORM,
        payload: {
          ...viewsStore.activeForm.config,
          constraint: viewsStore.activeForm.config.constraint,
          isChanged: {
            ...viewsStore.activeForm.config.isChanged,
            constraintCount: viewsStore.activeForm.config.isChanged.constraintCount + 1,
          },
        },
      })
    }
  }
}

export function removeConstraintFromConfigForm(id) {
  return function removeConstraintFromConfigFormThunk(dispatch, getState) {
    const viewsStore = getState().views
    const { found: _toRemove, from } = searchConstraintR(viewsStore.activeForm.config.constraint, id)

    if (_toRemove) {
      from.constraint.constraints = from.constraint.constraints.filter((constraint) => constraint.id != id)
      viewsStore.activeForm.config.constraint = simplifyConstraintR(viewsStore.activeForm.config.constraint)

      dispatch({
        type: SET_CONFIG_FORM,
        payload: {
          ...viewsStore.activeForm.config,
          constraint: viewsStore.activeForm.config.constraint,
          isChanged: {
            ...viewsStore.activeForm.config.isChanged,
            constraintCount: viewsStore.activeForm.config.isChanged.constraintCount + 1,
          },
        },
      })
    }
  }
}

export function removeSortFromConfigForm(id) {
  return function removeSortFromConfigFormThunk(dispatch, getState) {
    const viewsStore = getState().views

    dispatch({
      type: SET_CONFIG_FORM,
      payload: {
        ...viewsStore.activeForm.config,
        sort: viewsStore.activeForm.config.sort.filter((s) => s.id != id),
        isChanged: {
          ...viewsStore.activeForm.config.isChanged,
          sortCount: viewsStore.activeForm.config.isChanged.sortCount + 1,
        },
      },
    })
  }
}

export function addVisibleColumnsToConfigForm(newVisibleColumns, isInitial = true) {
  return async function addVisibleColumnsToConfigFormThunk(dispatch, getState) {
    const viewsStore = getState().views
    const currentVisible = [...viewsStore.activeForm.config.columns.visible]
    const payload = {
      ...viewsStore.activeForm.config,
      columns: {
        ...viewsStore.activeForm.config.columns,
        visible: currentVisible.concat(newVisibleColumns.filter((newVisibleColumn) =>
          currentVisible.findIndex((column) => column === newVisibleColumn) === -1,
        )),
      },
      isChanged: { ...viewsStore.activeForm.config.isChanged, columns: !isInitial },
    }

    dispatch({ type: SET_CONFIG_FORM, payload })
  }
}

export function setConfigFormVisibleColumns(visible) {
  return async function setConfigFormVisibleColumnsThunk(dispatch, getState) {
    const viewsStore = getState().views
    const payload = {
      ...viewsStore.activeForm.config,
      columns: {
        ...viewsStore.activeForm.config.columns,
        visible,
      },
      isChanged: { ...viewsStore.activeForm.config.isChanged, columns: true },
    }

    dispatch({ type: SET_CONFIG_FORM, payload })
  }
}

export function removeVisibleColumnsFromConfigForm(toRemove) {
  return async function removeVisibleColumnsFromConfigFormThunk(dispatch, getState) {
    const viewsStore = getState().views
    const payload = {
      ...viewsStore.activeForm.config,
      columns: {
        ...viewsStore.activeForm.config.columns,
        visible: viewsStore.activeForm.config.columns.visible.filter((column) => !toRemove.includes(column)),
      },
      isChanged: { ...viewsStore.activeForm.config.isChanged, columns: true },
    }

    dispatch({ type: SET_CONFIG_FORM, payload })
  }
}

export function resetForm(dispatch) {
  dispatch({ type: RESET_FORM })
}

export function parseView(view) {
  const options = {
    defaultData: getDefaultView(),
    fields,
    dataSetName,
  }
  return parse(view, options)
}

function getDefaultView() {
  return parse({}, { fields })
}

function getDefaultForm() {
  return {
    isCreate: false,
    hasChanges: false,
    isValid: false,
    resetCount: 0,
    global: dataToFormData(getDefaultView(), getFields(true)),
    config: getDefaultConfigForm(),
  }
}

function getDefaultConfigForm(initial = {
  sort: [],
  columns: { visible: [] },
  constraint: { operator: FilterOperator.AND, constraints: [getEmptyConstraint()] },
}) {
  return {
    ...initial,
    isChanged: { constraintCount: 0, sortCount: 0, columns: false },
  }
}

function getEmptyConstraint() {
  return { id: uuid(), field: null, value: null, matchMode: null }
}

function getEmptySort() {
  return { id: uuid(), field: null, order: 1 }
}

function buildViewState(state, payload) {
  if (!payload) {
    return state
  }

  const globalForm = dataToFormData(payload, getFields(true))
  const configForm = getDefaultConfigForm(payload.config)
  const newActiveForm = {
    ...state.activeForm,
    isCreate: false,
    isValid: isFormValid(globalForm, configForm),
    global: globalForm,
    config: configForm,
  }

  return {
    ...state,
    activeView: payload,
    activeForm: {
      ...newActiveForm,
      hasChanges: hasChanges(newActiveForm),
    },
  }
}

function isFormValid(globalForm, configForm) {
  const constraintOperator = configForm.constraint.operator
  const constraints = flattenConstraintsR(configForm.constraint.constraints, constraintOperator, constraintOperator)
  return (
    !!globalForm.name.value &&
    !!globalForm.ownerId.value &&
    (
      configForm.isChanged.constraintCount === 0 ||
      !constraints.some((constraint) => !constraint.value && !NO_VALUES_MATCH_MODES.includes(constraint.matchMode))
    ) &&
    !configForm.sort.some((s) => !s.field)
  )
}

function hasChanges(form) {
  return (
    Object.keys(form.global).some((key) => form.global[key].isChanged) ||
    form.config.isChanged.constraintCount > 0 ||
    form.config.isChanged.columns ||
    form.config.isChanged.sortCount > 0
  )
}

function simplifyConstraintR(constraint, isRoot = true) {
  constraint.constraints
    .forEach((c, index) => {
      if (!!c.constraints) {
        if (c.constraints.length === 0) {
          constraint.constraints.splice(index, 1)
        } else {
          constraint.constraints[index] = simplifyConstraintR(c, false)
        }
      }
    })

  constraint.constraints = constraint.constraints.reduce((acc, c) => {
    if (!c.operator || c.operator != constraint.operator) {
      acc.push(c)
    } else {
      acc = acc.concat(c.constraints)
    }
    return acc
  }, [])

  if (constraint.constraints.length === 1 && (!isRoot || !!constraint.constraints[0].operator)) {
    constraint = constraint.constraints[0]
  }

  return constraint
}

function searchConstraintR(constraint, matchId, from = {}) {
  let result = { found: null }

  for (let index = 0; index < constraint.constraints.length; index++) {
    const c = constraint.constraints[index]
    if (c.id === matchId) {
      result = { found: c, from: { constraint, ...from } }
    } else if (c.constraints) {
      result = searchConstraintR(c, matchId, { parent: constraint, index })
    }
    if (result.found) {
      break
    }
  }

  return result
}

export function flattenConstraintsR(constraints, parentOperator, operator) {
  let _constraints = []
  constraints.forEach((constraint, index) => {
    if (!!constraint.operator) {
      _constraints = [..._constraints, ...flattenConstraintsR(constraint.constraints, operator, constraint.operator)]
    } else {
      _constraints.push({ ...constraint, operator: index === 0 ? parentOperator : operator })
    }
  })
  return _constraints
}

export function constraintToLogicEquationR(constraints, operator, counter = 1, isRoot = true) {
  let equation = ''
  constraints.forEach((c, index) => {
    if (!!c.operator) {
      const interimResult = constraintToLogicEquationR(c.constraints, c.operator, counter, false)
      equation += interimResult.equation
      counter = interimResult.counter
    } else {
      equation += counter.toString()
      counter++
    }
    if (index != constraints.length - 1) {
      equation += ` ${operator.toUpperCase()} `
    }
  })
  return { equation: !isRoot ? `( ${equation} )` : equation, counter }
}

function parsedEquationToConstaint(parsedEquation, flatConstraints) {
  const constraint = { operator: parsedEquation.type, constraints: [] }
  parsedEquation.values.forEach((v) => {
    if (v.type === 'and' || v.type === 'or') {
      constraint.constraints.push(parsedEquationToConstaint(v, flatConstraints))
    } else if (flatConstraints[+v.value - 1]) {
      constraint.constraints.push(flatConstraints[+v.value - 1])
    } else {
      constraint.constraints.push(v)
    }
  })
  return constraint
}

export function getEntityNamespace(entity) {
  switch (entity) {
  case 'vendors':
  case 'customers':
    return 'contacts'
  default:
    return toCamel(entity)
  }
}

export function getBackRoute(baseUrl, entity) {
  switch (entity) {
  case 'vendors':
  case 'customers':
    return `${baseUrl}/${entity}`
  case 'sales-order-items':
    return `${baseUrl}/sales-orders/items`
  case 'purchase-order-items':
    return `${baseUrl}/purchase-orders/items`
  case 'reception-line-items':
    return `${baseUrl}/receptions/items`
  case 'shipment-line-items':
    return `${baseUrl}/shipments/items`
  case 'harmonized-system-codes':
  case 'country-of-origins':
  case 'email-templates':
  case 'attributes':
  case 'incoterms':
  case 'labels':
    return `${baseUrl}/settings/${entity}`
  case 'shipment-planning':
    return `${baseUrl}/planning/shipment`
  default:
    return `${baseUrl}/${entity}`
  }
}
