import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { getDimensionIcon } from 'utils/iconMapper'
import { parseCurrency, parseNumber, parsePercentage } from 'utils/numberParser'
import { formatMeasure } from 'utils/unitConverter'

import { _prefixPurchaseOrderItem } from 'reducers/purchase-orders/purchaseOrderItemsSlice'

import DateColumn from './DateColumn'
import { columnDict as _poColumnDict, getComplexColumns as poGetComplexColumns } from './purchase-orders'

const defaultOptions = {
  isColumnToggle: true,
  sortable: true,
  filter: true,
  isGlobalSearch: false,
}

const poVisibleColumns = [
  'poNumber',
  'status',
  'vendorName',
  'referenceNumber',
  'orderDate',
]

const poColumnDict = Object.keys(_poColumnDict)
  .reduce((acc, key) => {
    return {
      ...acc,
      [key]: {
        ..._poColumnDict[key],
        hidden: !poVisibleColumns.includes(key),
        isColumnToggle: key !== 'poNumber',
        isDefaultSort: key === 'poNumber',
      },
    }
  }, {})

export const prefixField = (field) => `${_prefixPurchaseOrderItem}.${field}`

const _invisibleOptions = { isGlobalSearch: true, isViewHidden: true, hidden: true }
export const columnDict = {
  ...poColumnDict,
  [prefixField('primaryName')]: { field: prefixField('primaryName'), ..._invisibleOptions },
  [prefixField('secondaryName')]: { field: prefixField('secondaryName'), ..._invisibleOptions },
  [prefixField('primaryNotes')]: { field: prefixField('primaryNotes'), ..._invisibleOptions },
  [prefixField('secondaryNotes')]: { field: prefixField('secondaryNotes'), ..._invisibleOptions },

  [prefixField('displayTitle')]: {
    field: prefixField('displayTitle'),
    isGlobalSearch: false,
    isColumnToggle: false,
    isViewHidden: true,
    translationKey: 'purchaseOrderItem',
    hidden: true,
  },
  [prefixField('partNumber')]: {
    field: prefixField('partNumber'),
    ...defaultOptions,
    translationPath: 'purchaseOrders:purchaseOrder.fields.partNumber',
    isGlobalSearch: true,
  },
  [prefixField('templateSku')]: {
    field: prefixField('templateSku'),
    ...defaultOptions,
    translationPath: 'purchaseOrders:purchaseOrder.fields.templateSku',
    isGlobalSearch: true,
  },
  [prefixField('name')]: {
    field: prefixField('name'),
    ...defaultOptions,
    isGlobalSearch: true,
    isColumnToggle: false,
    sortable: false,
    filter: false,
  },
  [prefixField('notes')]: {
    field: prefixField('notes'),
    ...defaultOptions,
    isGlobalSearch: true,
    sortable: false,
    filter: false,
  },
  [prefixField('contractNumber')]: { field: prefixField('contractNumber'), ...defaultOptions, isGlobalSearch: true },
  [prefixField('expectedDeliveryDate')]: { field: prefixField('expectedDeliveryDate'), ...defaultOptions },
  [prefixField('templateTitle')]: {
    field: prefixField('templateTitle'),
    ...defaultOptions,
    isGlobalSearch: true,
  },
  [prefixField('templateDimension')]: {
    field: prefixField('templateDimension'),
    ...defaultOptions,
    hidden: true,
    translationPath: 'items:item.fields.item',
    filterElement: {
      type: 'multiselect',
    },
  },
  [prefixField('measure')]: {
    field: prefixField('measure'),
    ...defaultOptions,
    translationPath: 'purchaseOrders:purchaseOrder.fields.measure',
  },
  [prefixField('templatePurchaseMoq')]: {
    field: prefixField('templatePurchaseMoq'),
    ...defaultOptions,
    translationPath: 'items:item.fields.moq',
  },
  [prefixField('inventoryCount')]: {
    field: prefixField('inventoryCount'),
    ...defaultOptions,
    translationPath: 'purchaseOrders:purchaseOrder.fields.inventories',
  },
  [prefixField('receivedCount')]: { field: prefixField('receivedCount'), ...defaultOptions },
  [prefixField('billedCount')]: { field: prefixField('billedCount'), ...defaultOptions },
  [prefixField('unitCostWithoutDiscount')]: {
    field: prefixField('unitCostWithoutDiscount'),
    ...defaultOptions,
    translationKey: 'unitCost',
  },
  [prefixField('discount')]: { field: prefixField('discount'), ...defaultOptions, hidden: true },
  [prefixField('discounted')]: {
    field: prefixField('discounted'),
    ...defaultOptions,
    translationPath: 'purchaseOrders:purchaseOrder.fields.discounted',
  },
  [prefixField('total')]: {
    field: prefixField('total'),
    ...defaultOptions,
    translationPath: 'purchaseOrders:purchaseOrder.fields.total',
  },
}

const _timestamp = (value, language) => {
  if (!value) return null

  return (
    <DateColumn
      date={value}
      format={language === 'en' ? 'MMMM Do YYYY' : 'DD MMMM YYYY'}
    />
  )
}

const _measure = (value, unit, conversionFactor, { measureDigits, culture }) => {
  const parsedNumber = parseNumber(formatMeasure((+value || 0)/(+conversionFactor || 1), unit), measureDigits, culture)

  return `${parsedNumber} ${unit}`
}

const _currency = (value, options) => {
  return parseCurrency(
    value,
    options.baseCurrency.code,
    options.baseCurrency.symbol,
    options.measureDigits.minimumFractionDigits,
    options.culture,
  )
}

const _percentage = (value, options) => {
  return parsePercentage(value/100, options.measureDigits, options.culture)
}

const addDefaultProps = (key) => columnDict[prefixField(key)]

const getUnitCost = (props, { baseCurrency, measureDigits, culture }) => _currency(
  props[prefixField('unitCostWithoutDiscount')] * props[prefixField('conversionFactor')],
  { baseCurrency, measureDigits, culture },
)

export function getComplexColumns(t, {
  measureDigits,
  culture,
  dateColumnTemplate,
  language,
  baseCurrency,
  simpleDict,
}) {
  const getCurrency = (props) => {
    const _code = props.currencyCode
    const _symbol = props.currencySymbol

    return {
      code: _code || baseCurrency.code,
      symbol: _symbol || baseCurrency.symbol,
    }
  }

  const complexColumns = {
    [prefixField('primaryName')]: addDefaultProps(`primaryName`),
    [prefixField('secondaryName')]: addDefaultProps(`secondaryName`),
    [prefixField('primaryNotes')]: addDefaultProps(`primaryNotes`),
    [prefixField('secondaryNotes')]: addDefaultProps(`secondaryNotes`),
    [prefixField('displayTitle')]: {
      header: t('purchaseOrders:item.fields.purchaseOrderItem'),
      ...addDefaultProps(`displayTitle`),
    },
    [prefixField('partNumber')]: {
      header: t('purchaseOrders:purchaseOrder.fields.partNumber'),
      ...addDefaultProps(`partNumber`),
    },
    [prefixField('templateSku')]: {
      header: t('purchaseOrders:purchaseOrder.fields.templateSku'),
      ...addDefaultProps(`templateSku`),
    },
    [prefixField('name')]: {
      header: t('purchaseOrders:item.fields.overwrittenName'),
      ...addDefaultProps(`name`),
    },
    [prefixField('notes')]: {
      header: t('purchaseOrders:item.fields.overwrittenNotes'),
      ...addDefaultProps(`notes`),
    },
    [prefixField('contractNumber')]: {
      header: t('purchaseOrders:item.fields.contractNumber'),
      ...addDefaultProps(`contractNumber`),
    },
    [prefixField('expectedDeliveryDate')]: {
      header: t('purchaseOrders:item.fields.expectedDeliveryDate.label'),
      ...addDefaultProps(`expectedDeliveryDate`),
      template: (props) => _timestamp(props[prefixField('expectedDeliveryDate')], language),
    },
    [prefixField('templateTitle')]: {
      header: t('items:item.fields.item'),
      template: (props) => {
        const iconData = getDimensionIcon(props[prefixField('templateDimension')])
        return (
          <div className="a-flex">
            <div className="a-item-grid-dimension-icon">
              <FontAwesomeIcon
                icon={['fas', iconData.icon]}
                transform={iconData.transform}
              />
            </div>
            <div
              className="a-smart-grid-default-template"
              title={props[prefixField('templateTitle')]}
            >
              {props[prefixField('templateTitle')]}
            </div>
          </div>
        )
      },
    },
    [prefixField('templateDimension')]: {
      header: t('items:item.fields.dimension'),
      filterElement: {
        ...simpleDict[prefixField('templateDimension')].filterElement,
        options: [
          { label: t('common:dimension.qty'), value: 'qty' },
          { label: t('common:dimension.weight'), value: 'weight' },
          { label: t('common:dimension.length'), value: 'length' },
          { label: t('common:dimension.surface'), value: 'surface' },
          { label: t('common:dimension.volume'), value: 'volume' },
        ],
        itemTemplate: (option) => {
          const iconData = getDimensionIcon(option.value)
          return (
            <div className="a-flex">
              <div className="a-item-grid-dimension-icon">
                <FontAwesomeIcon
                  icon={['fas', iconData.icon]}
                  transform={iconData.transform}
                />
              </div>
              <div>
                {option.label}
              </div>
            </div>
          )
        },
      },
      template: (props) => {
        const iconData = getDimensionIcon(props[prefixField('templateDimension')])
        return (
          <div className="a-flex">
            <div className="a-item-grid-dimension-icon">
              <FontAwesomeIcon
                icon={['fas', iconData.icon]}
                transform={iconData.transform}
              />
            </div>
            <div
              className="a-smart-grid-default-template"
              title={t(`common:dimension.${props[prefixField('templateDimension')]}`)}
            >
              {t(`common:dimension.${props[prefixField('templateDimension')]}`)}
            </div>
          </div>
        )
      },
    },
    [prefixField('measure')]: {
      header: t('purchaseOrders:purchaseOrder.fields.measure'),
      ...addDefaultProps(`measure`),
      parseValue: (props) => _measure(
        props[prefixField('measure')],
        props[prefixField('unit')],
        props[prefixField('conversionFactor')],
        { measureDigits, culture },
      ),
    },
    [prefixField('templatePurchaseMoq')]: {
      header: t('items:item.fields.moq'),
      ...addDefaultProps(`templatePurchaseMoq`),
      parseValue: (props) => _measure(
        props[prefixField('templatePurchaseMoq')],
        props[prefixField('unit')],
        props[prefixField('conversionFactor')],
        { measureDigits, culture },
      ),
    },
    [prefixField('inventoryCount')]: {
      header: t('purchaseOrders:purchaseOrder.fields.inventories'),
      ...addDefaultProps(`inventoryCount`),
    },
    [prefixField('receivedCount')]: {
      header: t('purchaseOrders:purchaseOrder.fields.receivedCount'),
      ...addDefaultProps(`receivedCount`),
      parseValue: (props) => _measure(
        props[prefixField('receivedCount')],
        props[prefixField('unit')],
        props[prefixField('conversionFactor')],
        { measureDigits, culture },
      ),
    },
    [prefixField('billedCount')]: {
      header: t('purchaseOrders:item.fields.billedCount'),
      ...addDefaultProps(`billedCount`),
      parseValue: (props) => _measure(
        props[prefixField('billedCount')],
        props[prefixField('unit')],
        props[prefixField('conversionFactor')],
        { measureDigits, culture },
      ),
    },
    [prefixField('unitCostWithoutDiscount')]: {
      header: t('purchaseOrders:item.fields.unitCost.label'),
      ...addDefaultProps(`unitCostWithoutDiscount`),
      parseValue: (props) => getUnitCost(props, { baseCurrency: getCurrency(props), measureDigits, culture }),
    },
    [prefixField('discount')]: {
      header: t('purchaseOrders:item.fields.discount.label'),
      ...addDefaultProps(`discount`),
      parseValue: (props) => _percentage(
        props[prefixField('discount')],
        { measureDigits, culture },
      ),
    },
    [prefixField('discounted')]: {
      header: t('purchaseOrders:purchaseOrder.fields.discounted'),
      ...addDefaultProps(`discounted`),
      parseValue: (props) => {
        const conversionFactor = props[prefixField('conversionFactor')]
        const unitCost = props[prefixField('unitCostWithoutDiscount')]
        const discount = 1 - Number(props[prefixField('discount')]) / 100

        return _currency(
          unitCost * conversionFactor * discount,
          { baseCurrency: getCurrency(props), measureDigits, culture },
        )
      },
      sortable: false,
      filter: false,
    },
    [prefixField('total')]: {
      header: t('purchaseOrders:purchaseOrder.fields.total'),
      ...addDefaultProps(`total`),
      parseValue: (props) => _currency(
        (+props[prefixField('measure')]) * (+props[prefixField('unitCost')]),
        { baseCurrency: getCurrency(props), measureDigits, culture },
      ),
      sortable: false,
      filter: false,
    },
  }

  const _complexPurchaseOrderColumns = poGetComplexColumns(t, { dateColumnTemplate })

  return {
    ..._complexPurchaseOrderColumns,
    ...complexColumns,
  }
}
