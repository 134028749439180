import React, {
  useState,
  useReducer,
  useRef,
  useEffect,
  useMemo,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useContext,
  useId,
} from 'react'
import { createRoot } from 'react-dom/client'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import equal from 'fast-deep-equal/react'
import { saveAs } from 'file-saver'
import { createBrowserHistory } from 'history'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import { utils, write } from 'xlsx'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FilterMatchMode, FilterOperator, FilterService } from 'primereact/api'
import { Column } from 'primereact/column'
import { DataTable, DataTableRowExpansionTemplate } from 'primereact/datatable'
import { MultiSelect } from 'primereact/multiselect'
import { Tooltip } from 'primereact/tooltip'
import { ObjectUtils } from 'primereact/utils'

import { SmartGridContext } from 'components/alix-front/legacy-smart-grid/contexts/SmartGridContext'
import SmartButton from 'components/alix-front/smart-button/SmartButton'
import SmartGlobalFilter from 'components/alix-front/smart-global-filter/SmartGlobalFilter'
import SmartMoreActions from 'components/alix-front/smart-more-actions/SmartMoreActions'
import SmartSkeleton from 'components/alix-front/smart-skeleton/SmartSkeleton'

import { debounceByKey } from 'utils/debounce'
import fetchLoad from 'utils/fetchLoad'
import { sortArrayOfObjects } from 'utils/mapperHelper'
import { rows as defaultRows, debounceInitialFetch, debounceLazyLoad } from 'utils/virtualScrollerHelper'

import { clearSmartGrid } from 'reducers/smart-grid-inputs/smartGridInputSlice'

import {
  getMatchModes,
  getFilterElement,
  getDefaultConstraint,
  gridFilterModelToConstraints,
  gridFiltersToConditions,
  viewContraintToDbConditionR,
  gridSortToOrderBy,
  getIdOrderBy,
  viewSortToDbOrderBy,
  NO_VALUES_MATCH_MODES,
  TO_MATCH_MODE_DICT,
} from './columns'
import ViewContext, { getInitialState as getViewInitialState } from './contexts/ViewContext'
import { getGridReducer, getGridState } from './reducer'

import './style.css'

export const SELECT_ALL_MAX = 2000

const onOldSelectAll = (newSelection, activeSelection, onSelectionChange) => {
  const selectionIds = activeSelection.map((obj) => obj.id)
  const missingFromSelection = newSelection.filter((obj) => !selectionIds.includes(obj.id))
  onSelectionChange([...activeSelection, ...missingFromSelection])
}

const onOldUnselectAll = (toRemove, activeSelection, onSelectionChange) => {
  const toRemoveIds = toRemove.map((obj) => obj.id)
  onSelectionChange(activeSelection.filter((obj) => !toRemoveIds.includes(obj.id)))
}

const getDisplayedInsertions = (rows, insertions = [], first = 0, lazy = true ) => {
  if (!lazy) {
    return insertions
  }
  return insertions.slice(first, first + rows)
}

const filterData = (data = [], columns = [], fields = {}, globalFilter = '') => {
  if (!globalFilter) {
    return data
  }

  const filterFields = columns
    .filter((column) => column.isGlobalSearch && fields[column.field])
    .map((column) => column.filterField || column.field)
  return data.filter((obj) => {
    return filterFields.some((filterField) =>
      (obj[filterField] || '').toString().toLowerCase().includes(globalFilter.toLowerCase()),
    )
  })
}

const loadChunk = (value, first, size, gridRef) => {
  const chunk = value.map((obj) => ({ ...obj, isLazyRender: true }))

  const last = Math.min(value.length, first + size)
  for (let i = first; i < last; i++) {
    chunk[i] = { ...value[i] }
  }

  if (gridRef.current) {
    const gridBody = gridRef.current.getTable()
    chunk.forEach((rowData) => {
      const column = gridBody.querySelector(`.a-lazy-render-identifier[data-id="${rowData.id}"]`)
      if (column) {
        const row = column.closest('tr')
        if (rowData.isLazyRender) {
          const height = +row.getBoundingClientRect().height
          row.style.height = height > 0 ? `${height}px` : ''
        } else {
          row.style.height = ''
        }
      }
    })
  }

  return chunk
}

const resetResizedColumns = (gridRef) => {
  const gridElementAttributes = [...(gridRef.current.getElement().attributes || [])]
  const prId = gridElementAttributes.find((attribute) => attribute.name.includes('pr_id_'))?.name
  if (prId) {
    const prIdStyle = [...(window.document.head.children || [])].find((style) => style.innerHTML.includes(prId))
    if (prIdStyle) {
      window.document.head.removeChild(prIdStyle)
      const gridTable = gridRef.current.getTable()
      gridTable.style.minWidth = ''
      gridTable.style.width = ''

      const gridBody = gridTable.querySelector(':scope > tbody')
      if (gridBody) {
        gridBody.style.minWidth = ''
        gridBody.style.width = ''
      }
    }
  }
}

const isTooManySelected = (t, selections, action) => {
  const max = action.maximumSelection || SELECT_ALL_MAX
  const tooManySelected = selections.length > max
  const message = t('common:grid.tooManySelectedRows', { max })

  return {
    ...action,
    isEnabled: action.isEnabled && !tooManySelected,
    disabledTooltip: tooManySelected ?
      ((action.disabledTooltip?.length > 0) ? action.disabledTooltip?.concat('\n', message) :
        message) :
      action.disabledTooltip,
  }
}

const setActionDisabledData = (t, selection, selectionAction) => {
  // default to prevent undefined
  selectionAction.isEnabled = typeof selectionAction.isEnabled === 'boolean' ? selectionAction.isEnabled : true
  selectionAction = isTooManySelected(t, selection,
    (selectionAction.getDisabledData?.(selection, selectionAction) || selectionAction))
  const disabledClass = selectionAction.isEnabled ? '' : 'a-disabled'
  const className = selectionAction.className?.concat(' ', disabledClass) || disabledClass
  return {
    ...selectionAction,
    className,
  }
}

FilterService.register('in', (value, filter) => {
  if (!Array.isArray(filter)) {
    return true
  }

  if (Array.isArray(value)) {
    return value.some((v) => filter.includes(v))
  } else if (typeof value === 'boolean' || Number.isInteger(value)) {
    return filter.includes(value.toString())
  }

  return filter.includes(value)
})

FilterService.register('isEmpty', () => true)

FilterService.register('isNotEmpty', () => true)

FilterService.register(FilterMatchMode.DATE_IS, (value, filter) => {
  if (filter === undefined || filter === null || !Date.parse(filter)) {
    return true
  }

  if (value === undefined || value === null || !Date.parse(value)) {
    return false
  }

  const endDate = new Date(filter)
  return moment(value).isSameOrAfter(filter) && moment(value).isBefore(moment(endDate.setDate(endDate.getDate() + 1)))
})

FilterService.register(FilterMatchMode.NOT_CONTAINS, (value, filter, filterLocale) => {
  if (filter === undefined || filter === null || typeof filter === 'string' && filter.trim() === '') {
    return true
  }

  if (value === undefined || value === null) {
    return true
  }

  const filterValue = ObjectUtils.removeAccents(filter.toString()).toLocaleLowerCase(filterLocale)
  const stringValue = ObjectUtils.removeAccents(value.toString()).toLocaleLowerCase(filterLocale)
  return stringValue.indexOf(filterValue) === -1
})

FilterService.register('dbe', (value, filter) => {
  if (filter === undefined || filter === null || !Date.parse(filter)) {
    return true
  }

  if (value === undefined || value === null || !Date.parse(value)) {
    return false
  }

  const endDate = new Date(filter)
  return moment(value).isBefore(moment(endDate.setDate(endDate.getDate() + 1)))
})

FilterService.register('dae', (value, filter) => {
  if (filter === undefined || filter === null || !Date.parse(filter)) {
    return true
  }

  if (value === undefined || value === null || !Date.parse(value)) {
    return false
  }

  return moment(value).isSameOrAfter(filter)
})

/**
 * @typedef {object} Props
 * @property {any} [dataHandler]
 * @property {string} [multiSelectPanelClassName]
 * @property {string} [emptyMessage]
 * @property {string} [selectionMode]
 * @property {any} [activeSelection]
 * @property {any} [selection]
 * @property {(data: any) => void} [onSelectionChange]
 * @property {({data: any, index: number}) => boolean} [isDataSelectable]
 * @property {(ev: any) => void} [onRowClick]
 * @property {string} [dataKey]
 * @property {boolean} [scrollable]
 * @property {string | (rowData: any) => object} [rowClassName]
 * @property {boolean} [lazy]
 * @property {string} [className]
 * @property {boolean} [isSearchUrl]
 * @property {boolean} [lazyRender]
 * @property {number} [lazyRenderPosition]
 * @property {number} [lazyRenderRows]
 * @property {boolean} [menuGrid]
 * @property {boolean} [reorderableColumns]
 * @property {boolean} [resizableColumns]
 * @property {boolean} [reorderableRows]
 * @property {(ev: any) => void} [onRowReorder]
 * @property {number} [itemSize]
 * @property {string} [idKey]
 * @property {number} [customLazyRowCount]
 * @property {boolean} [embedded]
 * @property {boolean} [fixedLayout]
 * @property {boolean} [rowExtendable]
 * @property {string} [entity]
 * @property {any} [exportOptions]
 * @property {Function} [setToastState]
 * @property {() => void} [onRowToggle]
 * @property {() => void} [onRowExpand]
 * @property {() => void} [onRowCollapse]
 * @property {(data: unknown, options: DataTableRowExpansionTemplate) => React.ReactNode} [rowExpansionTemplate]
 * @property {{rowIndex: number, data: any}} [addCustomRow]
 * @property {boolean} [useOldSelectAll]
 * @property {boolean} [useShiftSelect]
 * @property {boolean} [forceLoading]
 *
 */

const _dataHandler = {}
const _onSelectionChange = () => {}
const _isDataSelectable = (_) => true
const _exportOptions = {}

/**
 * @param {React.PropsWithRef<Props>} props
 * @return {React.ReactElement}
 */
function SmartGrid({
  dataHandler = _dataHandler,
  multiSelectPanelClassName = '',
  emptyMessage,
  selectionMode,
  activeSelection,
  selection,
  onSelectionChange = _onSelectionChange,
  isDataSelectable = _isDataSelectable,
  onRowClick,
  dataKey,
  scrollable,
  rowClassName,
  lazy,
  className,
  isSearchUrl = true,
  lazyRender,
  lazyRenderPosition,
  lazyRenderRows,
  menuGrid,
  reorderableColumns = false,
  resizableColumns = false,
  reorderableRows = false,
  onRowReorder,
  itemSize = 46,
  idKey = 'id',
  customLazyRowCount,
  embedded = false,
  fixedLayout = true,
  exportOptions = _exportOptions,
  entity,
  // DataTable Row Expansion
  rowExtendable = false,
  onRowToggle,
  onRowExpand,
  onRowCollapse,
  rowExpansionTemplate,
  setToastState,
  addCustomRow,
  useOldSelectAll = true,
  useShiftSelect = false,
  forceLoading = false,
  onKeyUp,
}, ref) {
  const id = useId()

  // init globals
  const { t } = useTranslation(['common'])
  const dispatch = useDispatch()

  const isFirstRender = useRef(true)
  const gridRef = useRef(null)
  const globalFilterInputRef = useRef(null)
  const filterApplyRefs = useRef({})
  const [lastRecordedIndex, setLastRecordedIndex] = useState(null)

  const iframedFullcardRef = useSelector((state) => state.refs.refs.iframedFullcardRef)

  const navigate = useNavigate()
  const history = createBrowserHistory()
  const selectAllCheckBoxUUID = useRef(uuid())

  const rows = useMemo(() => {
    if (!lazy || !customLazyRowCount) return defaultRows

    const parsedRowCount = Number.parseInt(customLazyRowCount)

    if (Number.isNaN(parsedRowCount)) {
      return defaultRows
    } else {
      return parsedRowCount
    }
  }, [customLazyRowCount, lazy])

  // init state
  const { state: _viewState, setState: _setViewState } = useContext(ViewContext)
  const { viewState, setViewState } = useMemo(
    () => {
      if (embedded) {
        return { viewState: getViewInitialState(), setViewState: () => {} }
      }
      return { viewState: _viewState, setViewState: _setViewState }
    },
    [_setViewState, _viewState, embedded],
  )

  const [gridState, dispatchGridState] = useReducer(
    getGridReducer(dataHandler),
    {
      columns: dataHandler.columns,
      sort: dataHandler.sort,
      query: new URLSearchParams(location?.search),
      isSearchUrl,
      menuGrid,
      fields: dataHandler.fields,
    },
    getGridState,
  )
  const [lazyItems, setLazyItems] = useState({ items: [] })
  const [displayedInsertions, setDisplayedInsertions] = useState([])
  const [filteredInsertions, setFilteredInsertions] = useState([])
  const [firstRecord, setFirstRecord] = useState(0)
  const [loading, setLoading] = useState(false)
  const [loadingSelectAll, setLoadingSelectAll] = useState(false)
  const [mountFetching, setMountFetching] = useState(true)
  const [actionsRoot, setActionsRoot] = useState(null)
  const [selectAllRoot, setSelectAllRoot] = useState(null)
  const [relativeDateData, setRelativeDateData] = useState({})

  const _debounceInitialFetch = useMemo(() => debounceInitialFetch(uuid()), [])
  const _debounceLazyLoad = useMemo(() => debounceLazyLoad(uuid()), [])

  // export functions
  const _parseExportFields = useCallback((exportedItems) => {
    if (!exportedItems?.length) {
      return []
    }

    const fieldsToKeep = ['id'].concat(gridState.columns.displayed.map((column) => column.field))
    const parseColumns = dataHandler.columns
      .filter((column) => typeof column.parseValue === 'function' || typeof column.parseExcel === 'function')
      .map((column) => ({ field: column.field, parse: column.parseExcel || column.parseValue }))

    return exportedItems.map((exportedItem) => {
      const _exportedItem = {}
      parseColumns.forEach((parseColumn) => {
        exportedItem[parseColumn.field] = parseColumn.parse(exportedItem)
      })
      fieldsToKeep.forEach((field) => {
        _exportedItem[
          t([`inventories:inventory.fields.${ field }.label`, `inventories:inventory.fields.${ field }`])
        ] = exportedItem[field]
      })
      return _exportedItem
    })
  }, [t, dataHandler.columns, gridState.columns.displayed])

  const _saveAsExcelFile = useCallback((buffer) => {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const EXCEL_EXTENSION = '.xlsx'
    const data = new Blob([buffer], {
      type: EXCEL_TYPE,
    })
    saveAs(data, `ALIX_${entity}_export_${new Date().getTime()}${EXCEL_EXTENSION}`)
  }, [entity])

  // on mount/rerender
  const getGlobalSearchColumns = useCallback(() => {
    return JSON.stringify(gridState.columns.initial
      .filter((column) => column.isGlobalSearch && column.fieldInfo)
      .map((column) => ({
        dataSetName: column.fieldInfo.dataSetName,
        name: column.fieldInfo.useTrimAliasInField && column.fieldInfo.trimAlias ?
          `${column.fieldInfo.trimAlias}_${column.fieldInfo.dbField}` :
          column.fieldInfo.dbField,
        dataSetAlias: column.fieldInfo.dataSetAlias,
      })))
  }, [gridState.columns.initial])

  const getOrderByList = useCallback(() => {
    let orderByList = []

    if (gridState.sort.sortField) {
      orderByList = gridSortToOrderBy(
        gridState.sort.sortField,
        gridState.sort.sortOrder,
        dataHandler.fields,
      )
    } else if (viewState.selected?.config?.sort?.length > 0) {
      orderByList = viewSortToDbOrderBy(viewState.selected?.config?.sort, dataHandler.fields)
    }

    const idOrderBy = getIdOrderBy(dataHandler.fields, idKey)
    if (idOrderBy) {
      orderByList.push(idOrderBy)
    }

    return orderByList
  }, [
    dataHandler.fields,
    gridState.sort.sortField,
    gridState.sort.sortOrder,
    viewState.selected?.config?.sort,
    idKey,
  ])

  const getConditionObj = useCallback(() => {
    const conditions = gridFiltersToConditions(gridState.filters.filters, dataHandler.fields, dataHandler.isFetchPost)
    const viewCondition = viewContraintToDbConditionR(
      viewState.selected?.config?.constraint,
      dataHandler.fields,
      dataHandler.isFetchPost,
    )
    if (viewCondition) {
      conditions.push(viewCondition)
    }
    return JSON.stringify(conditions)
  }, [dataHandler.fields, dataHandler.isFetchPost, gridState.filters.filters, viewState.selected?.config?.constraint])

  const _initialFetch = useCallback(async(options = {}) => {
    const dataHandlerFetch = dataHandler.fetch
    const dataHandlerFetchCount = dataHandler.fetchCount
    if (typeof dataHandlerFetch != 'function' || (lazy && typeof dataHandlerFetchCount != 'function')) {
      return
    }

    const currentFetchData = options.fetchData ?? dataHandler.fetchData ?? {}
    if (options.dataSetName) {
      currentFetchData.dataSetName = options.dataSetName
    }
    const lazyData = {
      fetch: (fetchData, mapData) => dispatch(dataHandlerFetch(fetchData, mapData)),
      fetchCount: (fetchData, mapData) => lazy ?
        dispatch(dataHandlerFetchCount(fetchData, mapData)) :
        Promise.resolve(0),
      fetchData: { ...currentFetchData },
      mapData: dataHandler.mapData,
      orderByList: [],
    }

    if (lazy) {
      const interimInsertions = getDisplayedInsertions(rows, options.newInsertions || dataHandler.insertions)
      const deletionIds = options.hasOwnProperty('deletionIds') ? options.deletionIds : dataHandler.deletionIds
      const deletedCount = +deletionIds?.length || 0
      if (options.globalFilter !== '') {
        const globalFilter = options.globalFilter || gridState.filters.globalFilter
        lazyData.fetchData.searchText = !!dataHandler.isFetchPost ? globalFilter : encodeURIComponent(globalFilter)
        lazyData.fetchData.searchColumns = getGlobalSearchColumns()
      }
      lazyData.fetchData.conditionObj = getConditionObj()
      lazyData.orderByList = getOrderByList()

      lazyData.getOffset = (count) => {
        const lazyCount = count - deletedCount + filteredInsertions.length
        const newFirstRecord = options.isResetFirstRecord || lazyCount < rows ? 0 : firstRecord
        setFirstRecord(newFirstRecord)
        return newFirstRecord
      }

      lazyData.getTopValue = () => {
        return rows + deletedCount - interimInsertions.length
      }
    }
    return _debounceInitialFetch({ lazyData, setLazyItems }).then(() => {
      setViewState((viewState) => ({ ...viewState, loading: false }))
      setMountFetching(false)
    })
  }, [
    setViewState,
    dataHandler.fetch,
    dataHandler.fetchData,
    dataHandler.mapData,
    dataHandler.fetchCount,
    dataHandler.insertions,
    dataHandler.deletionIds,
    dataHandler.isFetchPost,
    lazy,
    getOrderByList,
    rows,
    gridState.filters.globalFilter,
    getConditionObj,
    dispatch,
    filteredInsertions.length,
    firstRecord,
    getGlobalSearchColumns,
    _debounceInitialFetch,
  ])

  const renderSelectAll = useCallback(() => {
    const gridHeader = gridRef.current.getTable().querySelector('.p-datatable-thead')
    if (!gridHeader.querySelector('.a-smart-grid-selection-header-section') && selectionMode === 'checkbox') {
      const selectNode = document.createElement('div')
      selectNode.classList.add('a-smart-grid-selection-header-section')
      setSelectAllRoot(createRoot(selectNode))
      const selectAllCheckbox = gridHeader.querySelector('.p-column-header-content')
      selectAllCheckbox.appendChild(selectNode)
    }
  }, [selectionMode])

  useEffect(() => {
    return () => {
      dispatch(clearSmartGrid({ smartGridId: id }))
    }
  }, [dispatch, id])

  useEffect(() => {
    if (selection?.length || !selectAllRoot) {
      renderSelectAll()
      return
    }

    const renderSelectAllTooltip = () => (
      <div className="a-smart-grid-selection-header-tooltip">
        {dataHandler.count > SELECT_ALL_MAX ?
          <Tooltip
            className="a-no-arrow"
            target={`.p-checkbox[data-pr-id="${selectAllCheckBoxUUID.current}"]`}
            style={{ textAlign: 'center' }}
          /> : null }
        <div className='a-smart-grid-selection-header-spinner a-hidden'>
          <FontAwesomeIcon
            className="a-spin"
            icon={['fad', 'spinner-third']}
          />
        </div>
      </div>
    )
    selectAllRoot.render(renderSelectAllTooltip())
  }, [dataHandler.count, renderSelectAll, selectAllRoot, selection])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      fetchLoad(() => _initialFetch({ isResetFirstRecord: true }), setLoading)
      return
    }

    search()
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    gridState.filters.filters,
    gridState.sort.sortField,
    gridState.sort.sortOrder,
    viewState.selected,
  ])

  useEffect(() => {
    if (viewState.selected?.config?.sort?.length > 0 && !gridState.search.params.sortField) {
      dispatchGridState({ type: 'appendSort', payload: { sortField: null, sortOrder: null } })
    }
  }, [viewState.selected?.config?.sort?.length, gridState.search.params.sortField])

  const lazyCount = useMemo(
    () => dataHandler.count - (+dataHandler.deletionIds?.length || 0) + filteredInsertions.length,
    [dataHandler.count, dataHandler.deletionIds, filteredInsertions.length],
  )

  useEffect(() => {
    const hasSelection = activeSelection ? activeSelection.length > 0 : selection?.length > 0

    const headerRow = gridRef.current.getTable().querySelector('thead')

    if (headerRow.classList.contains('a-smart-grid-active-actions-header')) {
      if (!hasSelection) {
        headerRow.classList.remove('a-smart-grid-active-actions-header')
      }
    } else if (hasSelection) {
      headerRow.classList.add('a-smart-grid-active-actions-header')
    }
  }, [activeSelection, selection])

  useEffect(() => {
    if (useOldSelectAll) return
    const headerRow = gridRef.current.getTable().querySelector('thead')
    const hasSelection = activeSelection ? activeSelection.length > 0 : selection?.length > 0

    if (headerRow.classList.contains('a-smart-grid-active-actions-header')) return

    const selectColumnHeader = headerRow.querySelector('.p-column-header-content')
    const selectAllCheckbox = selectColumnHeader.querySelector('.p-checkbox')
    if (dataHandler.count > SELECT_ALL_MAX && !hasSelection) {
      selectColumnHeader.classList.add('a-disabled')
      selectAllCheckbox.classList.add('p-checkbox-disabled')
      selectAllCheckbox.setAttribute('data-pr-id', selectAllCheckBoxUUID.current)
      selectAllCheckbox.setAttribute('data-pr-tooltip', dataHandler.count > SELECT_ALL_MAX ?
        t('common:grid.selectAllMaxRows', { max: SELECT_ALL_MAX }) : '')
      selectAllCheckbox.setAttribute('data-pr-position', 'bottom')
      selectAllCheckbox.setAttribute('data-pr-at', 'center bottom')
    } else {
      selectColumnHeader.classList.remove('a-disabled')
      selectAllCheckbox.classList.remove('p-checkbox-disabled')
    }
  }, [activeSelection, dataHandler.count, selection?.length, t, useOldSelectAll])

  useEffect(() => {
    const gridTable = gridRef.current.getTable()
    const gridHeader = gridTable.querySelector('.p-selection-column') || gridTable.querySelector('.p-datatable-thead')

    if (!gridHeader.querySelector('.a-smart-grid-selection-actions') && selectionMode === 'checkbox') {
      const actionsNode = document.createElement('div')
      actionsNode.classList.add('a-smart-grid-selection-actions')
      setActionsRoot(createRoot(actionsNode))
      gridHeader.appendChild(actionsNode)
    }

    renderSelectAll()
  }, [lazyCount, renderSelectAll, selectionMode])

  useEffect(() => {
    const gridTable = gridRef.current.getTable()
    const gridHeader = gridTable.querySelector('.p-datatable-thead')
    const virtualScrollerContent = gridTable.querySelector('.p-virtualscroller-content')
    if (virtualScrollerContent) {
      virtualScrollerContent.style.top = `${gridHeader.getBoundingClientRect().height}px`
      if (lazyCount < rows) {
        virtualScrollerContent.style.minHeight = 'unset'
      } else {
        virtualScrollerContent.style.minHeight = ''
      }
    }
  }, [lazyCount, gridState.columns.available, rows])

  useEffect(() => {
    if (!viewState.selected && viewState.data.length > 0) {
      const defaultView = viewState.data.find((view) => view.isDefaultSelected)

      const viewId = gridState.search.params.viewId || viewState.preferenceId

      setViewState((viewState) => ({
        ...viewState,
        selected: viewState.data.find((view) => view.id === viewId) || defaultView,
      }))
    }
  }, [setViewState, viewState.selected, viewState.data, gridState.search.params.viewId, viewState.preferenceId])

  useEffect(() => {
    let columns
    if (viewState.selected?.config?.columns?.visible?.length > 0) {
      columns = dataHandler.columns.map((column) => {
        let columnIndex = viewState.selected.config.columns.visible.indexOf(column.field)
        if (columnIndex === -1) {
          columnIndex = viewState.selected.config.columns.visible.length
        }
        return {
          ...column,
          hidden: !viewState.selected.config.columns.visible.includes(column.field),
          columnIndex,
        }
      })
    } else {
      columns = dataHandler.columns.map((column, index) => ({
        ...column,
        columnIndex: !column.hidden ? index : dataHandler.columns.length,
      }))
    }

    dispatchGridState({
      type: 'updateColumns',
      payload: columns.sort(sortArrayOfObjects([
        { field: 'columnIndex', order: 1 },
        { field: 'header', order: 1 },
      ])),
    })
  }, [dataHandler.columns, viewState.selected?.config?.columns?.visible])

  useEffect(() => {
    dispatchGridState({ type: 'updateMenuGrid', payload: menuGrid })
    if (menuGrid) {
      resetResizedColumns(gridRef)
    }
  }, [menuGrid])

  useEffect(() => {
    if (!lazy) {
      setDisplayedInsertions((displayedInsertions) => {
        const newDisplayedInsertions = dataHandler.insertions || []
        if (equal(newDisplayedInsertions, displayedInsertions)) {
          return displayedInsertions
        }

        return newDisplayedInsertions
      })
      setFilteredInsertions((filteredInsertions) => {
        const newFilteredInsertions = []
        if (equal(newFilteredInsertions, filteredInsertions)) {
          return filteredInsertions
        }

        return newFilteredInsertions
      })
    }
  }, [
    dataHandler.insertions,
    lazy,
  ])

  useEffect(() => {
    if (lazy) {
      const newFilteredInsertions = filterData(
        dataHandler.insertions,
        gridState.columns.initial,
        dataHandler.fields,
        gridState.search.params.globalFilter,
      )
      setFilteredInsertions((filteredInsertions) => {
        if (equal(newFilteredInsertions, filteredInsertions)) {
          return filteredInsertions
        }

        return newFilteredInsertions
      })
      setDisplayedInsertions((displayedInsertions) => {
        const newDisplayedInsertions = getDisplayedInsertions(rows, newFilteredInsertions, firstRecord)
        if (equal(newDisplayedInsertions, displayedInsertions)) {
          return displayedInsertions
        }

        return newDisplayedInsertions
      })
    }
  }, [
    firstRecord,
    dataHandler.insertions,
    gridState.columns.initial,
    dataHandler.fields,
    gridState.search.params.globalFilter,
    lazy,
    rows,
  ])

  const buildFetchData = useCallback((options = {}) => {
    const fetchData = dataHandler.fetchData ? { ...dataHandler.fetchData } : {}

    if (gridState.filters.globalFilter) {
      fetchData.searchText = !!dataHandler.isFetchPost ?
        gridState.filters.globalFilter :
        encodeURIComponent(gridState.filters.globalFilter)
      fetchData.searchColumns = getGlobalSearchColumns()
    }

    fetchData.conditionObj = getConditionObj()
    fetchData.orderByList = JSON.stringify(getOrderByList())

    Object.assign(fetchData, options)
    return fetchData
  }, [
    dataHandler.fetchData,
    dataHandler.isFetchPost,
    getConditionObj,
    getGlobalSearchColumns,
    getOrderByList,
    gridState.filters.globalFilter,
  ])

  const exportExcel = useCallback(async() => {
    setToastState((state) => (
      { ...state, processing: { ...state.processing, active: true } }
    ))

    const fetchExport = dataHandler.fetchExport
    const exportedItems = await fetchExport(buildFetchData(), dataHandler.mapData)
    const parsedExportedItems = _parseExportFields(exportedItems)

    const worksheet = utils.json_to_sheet(parsedExportedItems)
    worksheet['!autofilter'] = { ref: `A1:${utils.encode_col(Object.keys(parsedExportedItems[0]).length - 1)}1` }
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] }
    const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' })
    try {
      _saveAsExcelFile(excelBuffer)
      setToastState((state) => ({
        ...state,
        processing: { ...state.processing, active: false },
        success: { ...state.success, active: true },
      }))
    } catch (e) {
      setToastState((state) => ({
        ...state,
        processing: { ...state.processing, active: false },
        error: { ...state.success, active: true, detail: e.message },
      }))
    }
  }, [
    buildFetchData,
    _parseExportFields,
    _saveAsExcelFile,
    dataHandler.fetchExport,
    dataHandler.mapData,
    setToastState,
  ])

  const onSelectAll = useCallback((data, selection, onSelectionChange) => {
    const selectAll = async() => {
      if (dataHandler.count > SELECT_ALL_MAX) return
      setLoadingSelectAll(true)
      const fetch = dataHandler.fetch
      const fetchData = buildFetchData({
        topValue: SELECT_ALL_MAX,
        selectList: dataHandler.actionDependencyFields,
      })
      const allSelectedRows = await dispatch(fetch(fetchData, { ...dataHandler.mapData, skipDispatch: true }))
      onSelectionChange(allSelectedRows
        .filter((row) => isDataSelectable({ data: row }) )
        .map((selection) => ({ ...selection, _fromSelectAll: true })))
      setLoadingSelectAll(false)
    }

    useOldSelectAll ?
      onOldSelectAll(data, selection, onSelectionChange) :
      selectAll()
  }, [
    dataHandler.count,
    dataHandler.fetch,
    dataHandler.mapData,
    dataHandler.actionDependencyFields,
    useOldSelectAll,
    dispatch,
    buildFetchData,
    isDataSelectable,
  ])

  const onUnselectAll = useCallback((data, selection, onSelectionChange) => {
    useOldSelectAll ?
      onOldUnselectAll(data, selection, onSelectionChange) :
      onSelectionChange([])
  }, [useOldSelectAll])

  useEffect(() => {
    const header = gridRef.current.getTable().querySelector('.p-datatable-thead')
    const checkbox = header.querySelector('.p-checkbox')
    const spinner = header.querySelector('.a-smart-grid-selection-header-spinner')
    if (!checkbox || !spinner) return

    if (loadingSelectAll) {
      checkbox.classList.add('a-hidden')
      spinner.classList.remove('a-hidden')
    } else {
      checkbox.classList.remove('a-hidden')
      spinner.classList.add('a-hidden')
    }
  }, [loadingSelectAll])

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === 'POP' && isSearchUrl) {
        const query = new URLSearchParams(location.search)
        dispatchGridState({ type: 'setRerouteSearch', payload: query })
      }
    })

    return () => setTimeout(unlisten, 0)
  }, [history, isSearchUrl])

  const items = useMemo(
    () => {
      if (viewState.loading) {
        return []
      }

      if (!lazy) {
        return dataHandler.data
      }

      const deletionIds = dataHandler.deletionIds || []
      const deletionIdKey = dataHandler.deletionIdKey || 'id'
      return lazyItems.items.filter((obj) => !deletionIds.includes(obj[deletionIdKey]))
    },
    [viewState.loading, lazy, dataHandler.data, dataHandler.deletionIds, dataHandler.deletionIdKey, lazyItems.items],
  )
  useEffect(() => {
    const data = [...displayedInsertions, ...dataHandler.data]
    const gridHeader = gridRef.current.getTable().querySelector('.p-datatable-thead')
    if (!selection || !gridHeader || !actionsRoot) {
      return
    }

    const selectionActions = () => {
      const selectionActions = [...(dataHandler.customSelectionActions || [])]
      const onDelete = dataHandler.onDelete
      if (typeof onDelete === 'function') {
        selectionActions.push({
          key: 'deleteAction',
          classNames: ['a-red'],
          onClick: () => onDelete(),
          isEnabled: dataHandler.isDeleteEnabled,
          disabledTooltip: dataHandler.deleteDisabledTooltip,
          title: t('common:button.delete'),
        })
      }

      const selectionActionTemplate = (selectionAction) => {
        selectionAction = setActionDisabledData(t, selection, selectionAction)
        const { isEnabled, disabledTooltip } = selectionAction
        const classNames = ['a-smart-grid-selection-action',
          ...(selectionAction.classNames || []), selectionAction.className || '']

        return (
          <div
            className="a-smart-grid-selection-action-container"
            key={selectionAction.key}
          >
            <div
              className={classNames.join(' ')}
              onClick={isEnabled ? () => selectionAction.onClick() : undefined}
              data-pr-tooltip={!isEnabled ? disabledTooltip : ''}
              data-pr-position="bottom"
              data-pr-at="center bottom"
            >
              {selectionAction.title}
            </div>
            {!isEnabled ?
              <Tooltip
                className="a-no-arrow"
                target=".a-smart-grid-selection-action"
                style={{ textAlign: 'center' }}
              /> : null}
          </div>
        )
      }

      const moreSelectionActions = selectionActions.filter((selectionAction) => selectionAction.isMoreAction)
        .map((selectionAction) => setActionDisabledData(t, selection, selectionAction))
      const selections = activeSelection ? activeSelection : selection
      const hasSelection = selections.length > 0
      return (
        <div className="a-flex">
          <div className="a-smart-grid-selection-action-container">
            <div
              className={`a-smart-grid-selection-action a-smart-grid-selection-selected${
                (dataHandler.count > SELECT_ALL_MAX && !hasSelection) ? ' a-disabled' : ''
              }`}
              data-pr-tooltip={
                dataHandler.count > SELECT_ALL_MAX ? t('common:grid.selectAllMaxRows', { max: SELECT_ALL_MAX }) : ''
              }
              data-pr-position="bottom"
              data-pr-at="center bottom"
              data-clickable="true"
              onClick={() => {
                const dataToSelect = data.filter((obj) => isDataSelectable({ data: obj }))
                if (
                  (useOldSelectAll &&
                    dataToSelect.some((obj) => !selection.map((select) => select.id).includes(obj.id))) ||
                  (!useOldSelectAll && selections.length < dataHandler.count)
                ) {
                  onSelectAll(dataToSelect, selections, onSelectionChange)
                } else {
                  onUnselectAll(data, selections, onSelectionChange)
                }
              }}
            >
              {`${activeSelection ? activeSelection.length : selection.length} ${t('common:button.selected')}`}
              {dataHandler.count > SELECT_ALL_MAX ?
                <Tooltip
                  className="a-no-arrow"
                  target=".a-smart-grid-selection-action"
                  style={{ textAlign: 'center' }}
                /> : null }
              {hasSelection ?
                // added a clear selection button in case there is more than 2k rows in the grid
                <div
                  className='a-smart-grid-selection-selected-cancel'
                  title={t('common:button.clearSelection')}
                  onClick={(ev) => {
                    ev.stopPropagation()
                    onSelectionChange([])
                  }}
                >
                  <div>
                    <FontAwesomeIcon
                      icon={['fad', 'xmark']}
                      className="p-icon p-checkbox-icon"
                    />
                  </div>
                </div> : null}
            </div>
          </div>
          {selectionActions
            .filter((selectionAction) => !selectionAction.isMoreAction)
            .map((selectionAction) => selectionActionTemplate(selectionAction))}
          <SmartMoreActions
            actions={moreSelectionActions}
            className="a-smart-grid-selection-action a-smart-grid-selection-more-action"
            wrapperOptions={{ active: true, className: 'a-smart-grid-selection-action-container' }}
          />
        </div>
      )
    }

    actionsRoot.render(selectionActions())
  }, [
    t,
    onSelectionChange,
    useOldSelectAll,
    actionsRoot,
    activeSelection,
    selection,
    displayedInsertions,
    dataHandler.data,
    dataHandler.customSelectionActions,
    dataHandler.isDeleteEnabled,
    dataHandler.onDelete,
    dataHandler.deleteDisabledTooltip,
    dataHandler.count,
    onSelectAll,
    onUnselectAll,
    isDataSelectable,
  ])

  const [expandedRows, setExpandedRows] = useState(null)

  // define grid callbacks
  const onLazyLoad = useCallback((ev) => {
    const dataHandlerFetch = dataHandler.fetch
    if ((typeof dataHandlerFetch != 'function' && ev.firstRecord != firstRecord) || viewState.loading) {
      return
    }

    const interimInsertions = getDisplayedInsertions(rows, dataHandler.insertions, ev.first, lazy)
    const offset = ev.first - filteredInsertions.length

    const fetchData = buildFetchData(
      {
        offset: offset < 0 ? 0 : offset,
        topValue: rows + (+dataHandler.deletionIds?.length || 0) - interimInsertions.length,
      },
    )
    setFirstRecord(ev.first)
    _debounceLazyLoad({
      setLazyItems,
      ev,
      lazyData: {
        fetch: (fetchData, mapData) => fetchLoad(
          () => dispatch(dataHandlerFetch(fetchData, mapData)),
          setLoading,
          false,
        ),
        fetchData,
        mapData: dataHandler.mapData,
        orderByList: lazy ? getOrderByList() : [],
      },
    })
  }, [
    dataHandler.fetch,
    dataHandler.insertions,
    dataHandler.deletionIds?.length,
    dataHandler.mapData,
    firstRecord,
    viewState.loading,
    rows,
    lazy,
    filteredInsertions.length,
    buildFetchData,
    _debounceLazyLoad,
    getOrderByList,
    dispatch,
  ])

  const onSort = useCallback((event) => {
    const [newSort] = event.multiSortMeta
    if (newSort.field === gridState.sort.sortField && gridState.sort.sortOrder === -1) {
      dispatchGridState({ type: 'appendSort', payload: { sortField: null, sortOrder: null } })
    } else {
      dispatchGridState({ type: 'appendSort', payload: { sortField: newSort.field, sortOrder: newSort.order } })
    }
  }, [gridState.sort])

  const search = useCallback((options = {}) => {
    const params = {}
    const fieldsToKeep = ['projectId', 'fullcardId']
    if (viewState.selected?.id && !viewState.selected?.isAll) {
      params.viewId = viewState.selected.id
    }
    if (options.globalFilter !== '') {
      params.globalFilter = options.globalFilter || gridState.filters.globalFilter
    }
    if (gridState.sort.sortField) {
      params.sortField = gridState.sort.sortField
    }
    if (gridState.sort.sortOrder) {
      params.sortOrder = gridState.sort.sortOrder
    }

    Object.keys(gridState.filters.filters).forEach((filterKey) => {
      const filterModel = gridState.filters.filters[filterKey]
      const fieldInfo = dataHandler.fields?.[filterKey]
      const toFilter = gridFilterModelToConstraints(filterModel)
      if (fieldInfo && toFilter.length > 0) {
        const values = []
        const matchModes = []
        toFilter.forEach((constraint, index) => {
          const dateLikeType = ['date', 'timestamp'].includes(fieldInfo.type)

          values.push(dateLikeType ? moment(constraint.value).format('YYYY-MM-DD') : constraint.value)
          if (index > 0) {
            matchModes.push(TO_MATCH_MODE_DICT[constraint.matchMode].abbreviation)
          }
        })

        let paramKey = `${filterKey}_${TO_MATCH_MODE_DICT[toFilter[0].matchMode].abbreviation}`
        if (matchModes.length > 0) {
          paramKey += `_${filterModel.operator}_${matchModes.join('_')}`
        }
        params[paramKey] = values
      }
    })

    dispatchGridState({ type: 'setSearchParams', payload: params })

    if (isSearchUrl && !gridState.isReroute) {
      // ? This is temporary. Query params not related to the grid get overwritten at grid load.
      // TODO (bzoretic): We need to find a better way to handle this.
      const currentQuery = new URLSearchParams(location.search)
      fieldsToKeep.forEach((field) => {
        const value = currentQuery.get(field)
        if (value) {
          params[field] = value
        }
      })

      navigate(`?${new URLSearchParams(params).toString()}`)
    } else if (isSearchUrl) {
      dispatchGridState({ type: 'resetReroute' })
    }

    if (lazy) {
      fetchLoad(() => _initialFetch({
        globalFilter: options.globalFilter,
        dataSetName: viewState?.selected?.datasetName,
      }), setLoading)
    }
  }, [
    _initialFetch,
    navigate,
    isSearchUrl,
    gridState.isReroute,
    gridState.sort,
    gridState.filters.filters,
    gridState.filters.globalFilter,
    viewState.selected?.id,
    viewState.selected?.isAll,
    dataHandler.fields,
    lazy,
    viewState?.selected?.datasetName,
  ])

  useEffect(() => {
    setViewState((viewState) => ({
      ...viewState,
      refresh: () => search(),
    }))
  }, [setViewState, search])

  const setGridFilter = useCallback((field, filterModel) => {
    const newFilters = {}
    newFilters[field] = filterModel
    dispatchGridState({ type: 'appendFilters', payload: newFilters })
  }, [])

  const _getFilterElement = useCallback((options, column) => {
    const FilterElement = getFilterElement(column)
    if (column.dataType === 'date') {
      return (
        <FilterElement
          value={options.value}
          onChange={(ev, relativeDateData) => {
            options.filterCallback(ev.value, options.index)
            setRelativeDateData((state) => ({ ...state, [column.field]: relativeDateData }))
          }}
          dateFormat="yy-mm-dd"
          placeholder={column.header}
          relativeDateData={relativeDateData[column.field]}
        />
      )
    } else if (column.filterElement?.type === 'multiselect') {
      return (
        <FilterElement
          options={column.filterElement.options}
          value={options.value}
          onChange={(ev) => {
            const newValue = ev.value?.length > 0 ? ev.value : null
            setGridFilter(options.field, { value: newValue, matchMode: 'in' })
          }}
          onHide={() => filterApplyRefs.current[column.field].onClick()}
          itemTemplate={column.filterElement.itemTemplate}
          autoOpen
        />
      )
    }

    return (
      <FilterElement
        type="text"
        placeholder={t('common:filter')}
        value={options.value || ''}
        title={options.value}
        onChange={(ev) => options.filterCallback(ev.target.value, options.index)}
        disabled={NO_VALUES_MATCH_MODES.includes(options.filterModel?.matchMode)}
        onKeyUp={(ev) => {
          if (ev.code === 'Enter') {
            filterApplyRefs.current[column.field].onClick()
          }
        }}
      />
    )
  }, [t, setGridFilter, relativeDateData])

  // define grid templates
  const _searchDebounceUuid = useMemo(() => uuid(), [])
  const gridHeaderGlobalSearch = useCallback(() => {
    if (!dataHandler.isGlobalSearch) {
      return null
    }

    const _search = debounceByKey(
      `smart-grid-search-${_searchDebounceUuid}`,
      (globalFilter) => search({ globalFilter }),
      1500,
    )
    const _clearSearch = debounceByKey(`smart-grid-search-${_searchDebounceUuid}`, () => {}, 1500)
    return (
      <SmartGlobalFilter
        ref={globalFilterInputRef}
        value={gridState.filters.globalFilter}
        placeholder={dataHandler.globalSearchPlaceholder}
        onClear={() => {
          if (gridState.search.params.globalFilter) {
            dispatchGridState({ type: 'setGlobalFilter', payload: '' })
            search({ globalFilter: '' })
            _clearSearch()
          }
        }}
        onChange={(ev) => {
          dispatchGridState({ type: 'setGlobalFilter', payload: ev.target.value })
          _search(ev.target.value)
        }}
        onEnter={() => {
          search()
          _clearSearch()
        }}
        onBlur={(ev) => {
          if ((gridState.search.params.globalFilter || '') != ev.target.value) {
            search()
            _clearSearch()
          }
        }}
      />
    )
  }, [
    search,
    _searchDebounceUuid,
    dataHandler.isGlobalSearch,
    dataHandler.globalSearchPlaceholder,
    gridState.filters.globalFilter,
    gridState.search.params.globalFilter,
  ])

  const gridHeaderDisplayedColumnTemplate = useCallback((item) => (
    <div className="a-flex-center">
      {item.header}
      <div className="a-smart-grid-column-header-filter-icon">
        {gridFilterModelToConstraints(gridState.filters.filters[item.filterField || item.field]).length > 0 ?
          <FontAwesomeIcon icon={['fas', 'filter']} /> :
          null}
      </div>
    </div>
  ), [gridState.filters.filters])

  const gridHeaderColumnToggle = useCallback(() => {
    if (!dataHandler.isColumnToggle) {
      return null
    }

    const _value = gridState.columns.displayed.reduce((acc, column) => {
      if (column.isViewHidden) return acc

      return [...acc, column.field]
    }, [])

    const _options = gridState.columns.available.reduce((acc, column) => {
      if (column.isViewHidden) return acc

      return [...acc, {
        ...column,
        disabled: typeof column.isColumnToggle === 'boolean' ? !column.isColumnToggle : false,
      }]
    }, [])

    return (
      <MultiSelect
        className="a-smart-multiselect-toggle"
        panelClassName={`${multiSelectPanelClassName} a-smart-multiselect-toggle-panel`}
        value={_value}
        options={_options}
        optionLabel="header"
        optionValue="field"
        showSelectAll={false}
        filter
        filterPlaceholder={t('common:search')}
        itemTemplate={gridHeaderDisplayedColumnTemplate}
        maxSelectedLabels={0}
        onChange={(ev) => {
          const newDisplayedColumns = gridState.columns.available
            .filter((column) => ev.value.some((field) => field === column.field))
          dispatchGridState({ type: 'setDisplayedColumns', payload: newDisplayedColumns })

          resetResizedColumns(gridRef)
        }}
        dropdownIcon={<FontAwesomeIcon icon={['fas', 'table-columns']} />}
      />
    )
  }, [
    t,
    multiSelectPanelClassName,
    gridHeaderDisplayedColumnTemplate,
    gridState.columns.available,
    gridState.columns.displayed,
    dataHandler.isColumnToggle,
  ])

  const gridHeaderInsert = useCallback(() => {
    if (typeof dataHandler.onInsert != 'function') {
      return null
    }

    const handleInsert = dataHandler.onInsert
    return (
      <div className="a-flex-center">
        <SmartButton
          title={t('common:button.add')}
          onClick={handleInsert}
        />
      </div>
    )
  }, [t, dataHandler.onInsert])

  const filterClearTemplate = useCallback((options, column) => (
    <SmartButton
      title={t('common:button.clear')}
      onClick={() => {
        setGridFilter(
          options.field,
          { constraints: [getDefaultConstraint(column)], operator: FilterOperator.AND },
        )
        options.filterClearCallback()
      }}
    />
  ), [t, setGridFilter])

  const filterApplyTemplate = useCallback((options, column) => (
    <SmartButton
      ref={(_filterApply) => filterApplyRefs.current[column.field] = _filterApply}
      title={t('common:button.apply')}
      onClick={() => {
        setGridFilter(options.field, options.filterModel)
        options.filterApplyCallback()
      }}
      primary
    />
  ), [t, setGridFilter])

  const gridFooter = useCallback((data) => {
    let totalRecords = t('common:totalRecords', { total: data.length })

    if ((lazy && lazyCount <= 0) || (!lazy && data.length <= 0)) {
      totalRecords = (
        <span>
          &nbsp;
        </span>
      )
    } else if (lazy) {
      const first = firstRecord + 1
      const last = first - 1 + rows

      totalRecords = t(
        'common:totalRecordsLazy',
        { first, last: last > lazyCount ? lazyCount : last, total: lazyCount },
      )
    }

    return (
      <div className="a-smart-grid-footer">
        {totalRecords}
      </div>
    )
  }, [lazy, lazyCount, t, firstRecord, rows])

  const matchModes = useMemo(() => getMatchModes(t), [t])

  const dynamicColumns = useMemo(() => {
    const defaultTemplate = (fieldValue, isFakeRow = false) => (
      <div
        className="a-smart-grid-default-template"
        title={fieldValue?.toString?.()}
        data-isfakerow={isFakeRow}
      >
        {fieldValue}
      </div>
    )
    const loading = () => (<SmartSkeleton height="1.2rem" />)
    const lazyRenderIdentifier = (props) => (
      <div
        className="a-lazy-render-identifier"
        data-id={props.id}
      />
    )

    const _columns = gridState.columns.displayed.map((column, index) => {
      const headerClassNames = []
      if (gridFilterModelToConstraints(gridState.filters.filters[column.filterField || column.field]).length > 0) {
        headerClassNames.push('a-smart-grid-highlight')
      }
      if (index === 0) {
        headerClassNames.push('a-smart-grid-first-column-header')
        if (column.className) {
          headerClassNames.push(column.className)
        }
      }

      const filterMenuClassNames = []
      if (column.filterElement?.type === 'multiselect') {
        filterMenuClassNames.push('a-smart-grid-multiselect-filter-container')
      }

      const onGridInsert = dataHandler.onGridInsert

      const _field = column.field

      return (
        <Column
          key={_field}
          field={_field}
          dataType={column.dataType || 'text'}
          className={column.className}
          header={
            index === 0 &&
            (gridState.menuGrid || dataHandler.isGridInsert) &&
            typeof onGridInsert === 'function' ?
              (
                <>
                  {column.header}
                  <div className="a-smart-grid-insert-column-header-button">
                    <SmartButton
                      onClick={() => onGridInsert()}
                      primary
                      icon="plus"
                      fixedWidth
                    />
                  </div>
                </>
              ) :
              column.header
          }
          headerClassName={headerClassNames.join(' ')}
          sortable={column.sortable}
          body={(props) => {
            if (typeof props.id === 'undefined' || props.id === null || props.id?.startsWith?.('_temp_')) {
              return loading()
            }

            let fieldValue = props[column.field]
            if (!props._addCustomRow) {
              if (typeof column.parseValue === 'function') {
                fieldValue = column.parseValue(props)
              }

              if (typeof column.template === 'function') {
                return column.template(props, fieldValue, index, { iframedFullcard: iframedFullcardRef.current })
              }
            }

            return defaultTemplate(fieldValue, props._addCustomRow)
          }}
          filterHeaderClassName={column.className}
          filterMenuClassName={filterMenuClassNames.join(' ')}
          filter={column.filter}
          filterField={column.filterField}
          filterElement={(options) => _getFilterElement(options, column)}
          filterClear={(options) => filterClearTemplate(options, column)}
          filterApply={(options) => filterApplyTemplate(options, column)}
          filterMatchModeOptions={matchModes[column.dataType || 'text']}
          resizeable={column.resizeable}
          style={column.style}
        />
      )
    })

    if (reorderableRows) {
      _columns.unshift((
        <Column
          key="rowReorder"
          rowReorder
          className="p-reorder-column"
        />
      ))
    }

    if (selectionMode === 'checkbox') {
      _columns.unshift((
        <Column
          key="checkbox"
          selectionMode="multiple"
          filterHeaderClassName="p-selection-column"
          className="a-smart-grid-checkbox-column"
        />
      ))
    }

    if (rowExtendable) {
      _columns.unshift((
        <Column
          key="rowExtend"
          className="a-smart-grid-extendable-column"
          expander
        />
      ))
    }

    if (lazyRender) {
      _columns.unshift((
        <Column
          key="lazyRender"
          className="a-hidden"
          body={lazyRenderIdentifier}
        />
      ))
    }

    return _columns
  }, [
    _getFilterElement,
    filterClearTemplate,
    filterApplyTemplate,
    iframedFullcardRef,
    lazyRender,
    rowExtendable,
    reorderableRows,
    selectionMode,
    matchModes,
    gridState.menuGrid,
    gridState.columns.displayed,
    gridState.filters.filters,
    dataHandler.isGridInsert,
    dataHandler.onGridInsert,
  ])

  const { value, multiSortMeta } = useCallback(() => {
    const isInsertOrdering = typeof dataHandler.isInsertOrdering === 'boolean' ? dataHandler.isInsertOrdering : true
    const multiSortMeta = [{ field: 'virtualSort', order: 1 }]
    if (isInsertOrdering) {
      multiSortMeta.push({ field: 'insertOrder', order: -1 })
    }
    if (gridState.sort.sortField) {
      multiSortMeta.push({ field: gridState.sort.sortField, order: +gridState.sort.sortOrder === -1 ? -1 : 1 })
    }
    let value = [
      ...displayedInsertions.map((displayedInsertion, index) => ({ ...displayedInsertion, insertOrder: index })),
      ...items.map((obj) => ({ ...obj, insertOrder: -1 })),
    ]
    if (!lazy) {
      value = filterData(value, gridState.columns.initial, dataHandler.fields, gridState.search.params.globalFilter)
      value = value.sort(sortArrayOfObjects(multiSortMeta))
    }
    if (addCustomRow) {
      value.splice(addCustomRow.rowIndex, 0, { ...addCustomRow.data, _addCustomRow: true })
    }
    return { value: value.map((item, index) => ({ ...item, virtualSort: index })), multiSortMeta }
  }, [
    addCustomRow,
    displayedInsertions,
    lazy,
    items,
    dataHandler.fields,
    dataHandler.isInsertOrdering,
    gridState.sort,
    gridState.columns.initial,
    gridState.search.params.globalFilter,
  ])()

  const gridHeaderExcelExport = useCallback(() => {
    if (!exportOptions?.excel?.active) {
      return null
    }

    return (
      <div
        className="a-flex-center"
      >
        <SmartButton
          title={t('common:button.exportXslx')}
          icon="file-excel"
          onClick={exportExcel}
        />
      </div>
    )
  }, [exportExcel, exportOptions?.excel?.active, t])

  const gridHeader = useCallback(() => {
    if (dataHandler.customHeader) {
      return dataHandler.customHeader
    }

    const globalSearch = gridHeaderGlobalSearch()
    const columnToggle = gridHeaderColumnToggle()
    const insert = gridHeaderInsert()
    const excelExport = gridHeaderExcelExport()

    if (!globalSearch && !insert && !columnToggle && !dataHandler.customActions && !dataHandler.customFilters &&
      !excelExport) {
      return null
    }

    return (
      <div className="a-smart-grid-header">
        <div className="a-smart-grid-search-container">
          {columnToggle}
          {globalSearch}
        </div>
        <div className="a-smart-grid-header-actions">
          {dataHandler.customActions}
          {excelExport}
          {insert}
          {dataHandler.customFilters}
        </div>
      </div>
    )
  }, [
    gridHeaderGlobalSearch,
    gridHeaderColumnToggle,
    gridHeaderInsert,
    dataHandler.customActions,
    dataHandler.customFilters,
    dataHandler.customHeader,
    gridHeaderExcelExport,
  ])

  const expandAll = useCallback(() => {
    const _expandedRows = {}
    value.forEach((v) => _expandedRows[`${v.id}`] = true)
    setExpandedRows(_expandedRows)
  }, [value])

  const collapseAll = useCallback(() => setExpandedRows(null), [])

  useImperativeHandle(ref, () => ({
    ...gridRef.current,
    exportExcel,
    fetch: async(options) => fetchLoad(
      () => _initialFetch(options),
      setLoading,
      !!options?.changeLoadingState,
    ),
    renderSelectAll,
    expandAll,
    collapseAll,
    addLazyItem: (newLazyItem) => setLazyItems((lazyItems) => {
      const newState = [...lazyItems.items]
      const lazyIndex = lazyItems.items.findIndex((obj) => obj.id === newLazyItem.id)
      if (lazyIndex === -1) {
        newState[newState.length] = newLazyItem
      }
      return { ...lazyItems, items: newState }
    }),
    updateLazyItems: (newLazyItems) => setLazyItems((lazyItems) => {
      const newState = [...lazyItems.items]
      let isChanged = false
      newLazyItems.forEach((lazyItem) => {
        const lazyIndex = lazyItems.items.findIndex((obj) => obj.id === lazyItem.id)
        if (lazyIndex > -1) {
          newState[lazyIndex] = lazyItem
          isChanged = true
        }
      })

      if (isChanged) {
        return { ...lazyItems, items: newState }
      }

      return lazyItems
    }),
    removeLazyItem: (toRemove) => setLazyItems((lazyItems) => {
      const newState = [...lazyItems.items]
      const lazyIndex = lazyItems.items.findIndex((obj) => obj.id === toRemove.id)
      if (lazyIndex > -1) newState.splice(lazyIndex, 1)
      return { ...lazyItems, items: newState }
    }),
    globalFilterInput: globalFilterInputRef.current?.inputRef?.current,
  }))

  const _className = useMemo(() => {
    const classNames = ['a-smart-grid-container']

    if (className) {
      classNames.push(className)
    }

    return classNames.join(' ')
  }, [className])

  const virtualScrollerOptions = useMemo(() => {
    if (!lazy) {
      return
    }

    return {
      lazy: true,
      onLazyLoad: !mountFetching ? onLazyLoad : undefined,
      itemSize,
      delay: 100,
      numToleratedItems: rows / 6,
    }
  }, [mountFetching, lazy, onLazyLoad, itemSize, rows])

  const handleOnRowToggle = useCallback((event) => {
    setExpandedRows(event.data)

    if (typeof onRowToggle === 'function') {
      onRowToggle(event)
    }
  }, [onRowToggle])

  const handleOnRowExpand = useCallback((event) => {
    if (typeof onRowExpand === 'function') {
      onRowExpand(event)
    }
  }, [onRowExpand])

  const handleOnRowCollapse = useCallback((event) => {
    if (typeof onRowCollapse === 'function') {
      onRowCollapse(event)
    }
  }, [onRowCollapse])

  /**
   * Copy selection to prevent changing original selection
   */
  const copiedSelection = useMemo(() => {
    if (!selection) {
      return selection
    }

    if (Array.isArray(selection)) {
      return selection.map((item) => ({ ...item }))
    }

    return { ...selection }
  }, [selection])

  const _resizableColumns = useMemo(
    () => resizableColumns && !gridState.menuGrid,
    [gridState.menuGrid, resizableColumns],
  )

  const selectionChange = useCallback(async(ev) => {
    setLastRecordedIndex(ev.data.virtualSort)
    if (!ev.originalEvent.shiftKey || lastRecordedIndex == null || !useShiftSelect) {
      return onSelectionChange([...(selection || []), ev.data])
    }

    const fetch = dataHandler.fetch

    const fetchData = buildFetchData({
      selectList: dataHandler.actionDependencyFields,
      offset: Math.min(lastRecordedIndex, ev.data.virtualSort),
      topValue: Math.abs(lastRecordedIndex - ev.data.virtualSort) + 1,
    })

    const fetchedSelection = await dispatch(fetch(fetchData))
    onSelectionChange([
      ...(selection || []),
      ...fetchedSelection.filter((newSelection) =>
        !selection.some((oldSelection) => oldSelection.id === newSelection.id)),
    ])
  }, [
    lastRecordedIndex,
    useShiftSelect,
    dataHandler.fetch,
    dataHandler.actionDependencyFields,
    buildFetchData,
    dispatch,
    onSelectionChange,
    selection,
  ])

  return (
    <SmartGridContext id={id}>
      <div
        ref={ref}
        className={_className}
        data-row-expendable={rowExtendable}
      >
        <DataTable
          ref={gridRef}
          isDataSelectable={isDataSelectable}
          tableStyle={{ tableLayout: fixedLayout ? 'fixed' : undefined }}
          filters={gridState.filters.filters}
          reorderableColumns={reorderableColumns}
          reorderableRows={reorderableRows}
          value={lazyRender ? loadChunk(value, lazyRenderPosition, lazyRenderRows, gridRef) : value}
          emptyMessage={emptyMessage || t('common:noRecords')}
          selectionMode={selectionMode}
          selection={copiedSelection}
          loadingIcon={
            <FontAwesomeIcon
              className='a-spin a-loading-spinner'
              icon={['fad', 'spinner-third']}
            />
          }
          dataKey={dataKey}
          onRowClick={(ev) => {
            const classList = [...(ev.originalEvent?.target?.classList || [])]

            if (
              typeof onRowClick != 'function' ||
              classList.includes('a-clickable-link') ||
              classList.includes('p-checkbox-icon') ||
              ev.originalEvent?.target?.nodeName === 'path'
            ) {
              return
            }

            onRowClick(ev)
          }}
          onAllRowsSelect={useOldSelectAll ?
            (ev) => onSelectAll(
              ev.data.filter((obj) => isDataSelectable({ data: obj })),
              selection,
              onSelectionChange,
            ) :
            undefined}
          onAllRowsUnselect={(ev) => onUnselectAll(ev.data, selection, onSelectionChange)}
          onSelectAllChange={!useOldSelectAll ?
            () => onSelectAll(undefined, undefined, onSelectionChange) :
            undefined}
          onRowReorder={onRowReorder}
          onRowSelect={(ev) => {
            if (selectionMode === 'single') {
              onSelectionChange(ev.data)
            } else {
              selectionChange(ev)
            }
          }}
          onRowUnselect={(ev) => {
            if (selectionMode === 'single') {
              onSelectionChange({})
            } else {
              setLastRecordedIndex(ev.data.virtualSort)
              onSelectionChange(selection.filter((obj) => obj.id != ev.data.id))
            }
          }}
          scrollable={scrollable || lazy}
          lazy={lazy}
          rowClassName={rowClassName}
          header={gridHeader()}
          footer={gridFooter(value)}
          virtualScrollerOptions={virtualScrollerOptions}
          onSort={onSort}
          sortMode="multiple"
          multiSortMeta={multiSortMeta}
          loading={forceLoading || loading}
          responsiveLayout="scroll"
          onRowToggle={handleOnRowToggle}
          onRowExpand={handleOnRowExpand}
          onRowCollapse={handleOnRowCollapse}
          rowExpansionTemplate={rowExpansionTemplate}
          expandedRows={expandedRows}
          resizableColumns={_resizableColumns}
          columnResizeMode="expand"
          selectAll={selection?.length === dataHandler?.count && selection?.length > 0}
          onKeyUp={onKeyUp}
        >
          {dynamicColumns}
        </DataTable>
      </div>
    </SmartGridContext>
  )
}

export default forwardRef(SmartGrid)
