import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TFunction } from 'i18next'

import { Calendar } from 'primereact/calendar'

import SmartDropdown from 'components/alix-front/smart-dropdown/SmartDropdown'
import SmartInputNumber from 'components/alix-front/smart-input-number/SmartInputNumber'

import { addToDate, getNow } from 'utils/dateParser'
import { convertToBase } from 'utils/unitConverter'

const TIME_UNITS = ['day', 'week', 'month'] as const
type TimeUnit = typeof TIME_UNITS[number]
const _parseTimeOption = (t: TFunction, key: string) => ({ key, label: t(`common:time.${key}`, { count: 2 }) })

const TYPES = ['before', 'after'] as const
type RelativeDateDataType = typeof TYPES[number]
const _parseTypeOption = (t: TFunction, key: string) => ({ key, label: t(`common:matchMode.${key}`).toLowerCase() })

type RelativeDateData = {
  isActive: boolean
  range: number
  unit: TimeUnit
  type: RelativeDateDataType
  date: Date
  max: number
  refreshKey: number
}

export const parseRange = (type: RelativeDateDataType, range: number) => type === 'before' ? -range : range

type FilterCalendarProps = {
  className: string,
  onChange: (ev: { value: string | Date | Date[], [key: string]: any }, relativeDateData?: RelativeDateData) => void
  value: Date
  placeholder: string
  dateFormat: string
  relativeDateData: RelativeDateData
}

const defaultRelativeDateData: RelativeDateData = {
  isActive: false,
  range: 1,
  refreshKey: 1,
  unit: 'day',
  type: 'before',
  date: addToDate(getNow(true), 'day', -1),
  max: Math.floor(convertToBase('time', 2, 'year', 'day')),
}

function FilterCalendar({
  className,
  onChange,
  value,
  placeholder,
  dateFormat,
  relativeDateData: _relativeDateData = defaultRelativeDateData,
}: FilterCalendarProps) {
  const { t } = useTranslation()

  const [relativeDateData, setRelativeDateData] = useState<RelativeDateData>(_relativeDateData)

  const updateRelativeDateData = useCallback((update: Partial<RelativeDateData>) => setRelativeDateData(
    (relativeDateData) => {
      const _state = { ...relativeDateData, ...update }
      _state.date = addToDate(
        getNow(true),
        _state.unit,
        parseRange(_state.type, _state.range),
      )
      onChange({ value: _state.date }, _state)
      return _state
    },
  ), [onChange])

  const _className = useMemo(() => {
    const classes = ['a-smart-filter-calendar-container']
    if (className) classes.push(className)
    return classes.join(' ')
  }, [className])

  return (
    <div className={_className}>
      {relativeDateData.isActive ? (
        <div className="a-smart-filter-relative-calendar">
          <div className="a-form-element-value a-right-input">
            <SmartInputNumber
              key={relativeDateData.refreshKey}
              initialValue={relativeDateData.range}
              min={0}
              max={relativeDateData.max}
              onChange={({ value }) => {
                const _update: Partial<RelativeDateData> = { range: value || 0 }
                if (!value) _update.refreshKey = relativeDateData.refreshKey + 1
                updateRelativeDateData(_update)
              }}
            />
          </div>
          <div className="a-form-element-value">
            <SmartDropdown
              value={relativeDateData.unit}
              options={TIME_UNITS.map((key) => _parseTimeOption(t, key))}
              optionLabel="label"
              optionValue="key"
              onChange={({ value }) => {
                const max = Math.floor(convertToBase('time', 2, 'year', value))
                const newRange = Math.min(max, relativeDateData.range)
                updateRelativeDateData({ unit: value, max, range: newRange })
              }}
            />
          </div>
          <div className="a-form-element-value">
            <SmartDropdown
              value={relativeDateData.type}
              options={TYPES.map((key) => _parseTypeOption(t, key))}
              optionLabel="label"
              optionValue="key"
              onChange={({ value }) => updateRelativeDateData({ type: value })}
            />
          </div>
        </div>
      ) : (
        <Calendar
          value={value}
          onChange={onChange}
          dateFormat={dateFormat}
          placeholder={placeholder}
        />
      )}
      <div className="a-flex a-justify-end">
        <div
          className="a-form-inline-action-button"
          onClick={() => updateRelativeDateData({ isActive: !relativeDateData.isActive })}
        >
          {!relativeDateData.isActive ? t('common:selectRelativeDate') : t('common:selectPersonalizedDate')}
        </div>
      </div>
    </div>
  )
}

export default FilterCalendar
