import * as React from 'react'

import { Tooltip } from 'primereact/tooltip'

export function targetClass(id) {
  return `id-${id}`
}

export function SmartInputTooltip({
  id,
  disabled = false,
  message = null,
  position = 'top',
}) {
  return (
    <Tooltip
      content={message}
      target={`.${targetClass(id)}`}
      position={position}
      disabled={!disabled || !message}
      style={{ textAlign: 'center' }}
    />
  )
};
