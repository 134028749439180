import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch } from 'store'

import { putCard } from 'reducers/cards/cardsSlice'

const initialState = {
  cardInstruction: { id: null, htmlValue: '', textValue: '', isActiveChange: false },
}

type CardInstructionState = typeof initialState.cardInstruction

export type CardInstructionActionFunctionState = Omit<CardInstructionState, 'isActiveChange' | 'id'>
export type CardInstructionActionFunction = (
  id: string,
  state: CardInstructionActionFunctionState
) => Promise<CardInstructionActionFunctionState>

const messageCallbacksSlice = createSlice({
  name: 'messageCallbacks',
  initialState,
  reducers: {
    setCardInstruction: (state, action: {type: string, payload: {data: CardInstructionState}}) => {
      state.cardInstruction = action.payload.data
      /**
       * ! We need to do the following because we have the react app that opens an item FC
       * which in turn opens a new react app. They then both have different reducers.
       * Session storage allows to centralize the data in one place.
       * This was implemented for instruction blur on FC close.
       */
      sessionStorage.setItem('cardInstruction', JSON.stringify(state.cardInstruction))
    },
  },
})

export const { setCardInstruction } = messageCallbacksSlice.actions

export function setCardInstructionState(newState: CardInstructionState): any {
  return function setCardInstructionStateThunk(dispatch: AppDispatch) {
    dispatch(setCardInstruction({ data: newState }))
  }
}

export function updateCardInstructionState(newState: Partial<CardInstructionState>): any {
  return function updateCardInstructionStateThunk(dispatch: AppDispatch) {
    const currenState = _getInstructionState()
    dispatch(setCardInstruction({ data: { ...currenState, ...newState } }))
  }
}

export function handleCardInstructionChangeAction(
  id: string,
  actionFunction?: CardInstructionActionFunction,
  notifyFrontOfChange?: (id: string) => void,
  setInitialValue?: (htmlValue: string) => void,
): any {
  return async function handleCardInstructionChangeActionThunk(dispatch: AppDispatch) {
    if (!actionFunction) return

    const currenState = _getInstructionState()
    const newState = await actionFunction(id, { htmlValue: currenState.htmlValue, textValue: currenState.textValue })
    dispatch(setCardInstructionState({ ...currenState, ...newState }))

    setInitialValue?.(newState.htmlValue)
    notifyFrontOfChange?.(id)
  }
}

export function handleCardInstructionOnBlur(
  id: string,
  notifyFrontOfChange?: (id: string) => void,
  setInitialValue?: (htmlValue: string) => void,
  forcedState: CardInstructionActionFunctionState = null,
) {
  return async function handleOnBlurActionThunk(dispatch: AppDispatch) {
    if (forcedState) {
      dispatch(setCardInstructionState({ ...forcedState, isActiveChange: true, id }))
    }

    const currenState = _getInstructionState()

    if (!currenState?.isActiveChange) return

    dispatch(updateCardInstructionState({ isActiveChange: false }))
    dispatch(handleCardInstructionChangeAction(id, _onInstructionBlur, notifyFrontOfChange, setInitialValue))
  }
}

function _getInstructionState() {
  let currentState: CardInstructionState
  try {
    currentState = JSON.parse(sessionStorage.getItem('cardInstruction'))
  } catch (err) {
    sessionStorage.removeItem('cardInstruction')
    console.error(err)
  }
  return currentState
}

async function _onInstructionBlur(id, { htmlValue, textValue }: CardInstructionActionFunctionState) {
  const card = await putCard({
    id,
    instructions: htmlValue,
    trimmed_instructions: textValue,
  })

  return {
    textValue: card.trimmedInstructions,
    htmlValue: card.instructions,
  }
}

export default messageCallbacksSlice.reducer
