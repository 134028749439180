import React from 'react'

import SmartStatus from 'components/alix-front/smart-status/SmartStatus'

import { parseCurrency } from 'utils/numberParser'

const getPlannedDateColumn = (t, hideHours, dateColumnTemplate) => {
  const field = hideHours ? 'plannedDate' : 'plannedDatetime'
  const simple = { field, sortable: true, filter: true, isGlobalSearch: true }

  if (typeof t != 'function') {
    return simple
  }

  return {
    ...simple,
    header: t('shipments:shipment.fields.plannedDate'),
    hidden: false,
    template: (props) =>
      dateColumnTemplate(hideHours ? props.plannedDate : props.plannedDatetime, hideHours),
  }
}

export const columnDict = {
  formattedNumber: {
    field: 'formattedNumber',
    isColumnToggle: false,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
  },
  name: { field: 'name', sortable: true, filter: true, isGlobalSearch: true },
  status: { field: 'status', sortable: true, filter: true },
  customer: { field: 'customer', sortable: true, filter: true, isGlobalSearch: true },
  plannedDate: getPlannedDateColumn(),
  expeditedDate: { field: 'expeditedDate', sortable: true, filter: true, isGlobalSearch: true },
  carrier: { field: 'carrier', sortable: true, filter: true, isGlobalSearch: true },
  poNumber: { field: 'poNumber', sortable: true, filter: true, isGlobalSearch: true },
  address: { field: 'address', sortable: true, filter: true, isGlobalSearch: true },
  city: { field: 'city', sortable: true, filter: true, isGlobalSearch: true },
  province: { field: 'province', sortable: true, filter: true, isGlobalSearch: true },
  country: { field: 'country', sortable: true, filter: true, isGlobalSearch: true },
  trailer: { field: 'trailer', sortable: true, filter: true, isGlobalSearch: true },
  postalCode: { field: 'postalCode', sortable: true, filter: true, isGlobalSearch: true },
  salesOrderNumberAggregate: {
    field: 'salesOrderNumberAggregate',
    sortable: true,
    filter: true,
    isGlobalSearch: false,
  },
  shipper: { field: 'shipper', sortable: true, filter: false, isGlobalSearch: false },
  cost: { field: 'cost', sortable: true, filter: false, isGlobalSearch: false },
  trackingNumber: { field: 'trackingNumber', sortable: true, filter: true, isGlobalSearch: true },
  createdDate: { field: 'createdDate', sortable: true, filter: true, isGlobalSearch: true },
  createdBy: { field: 'createdBy', sortable: true, filter: true, isGlobalSearch: true },
  modifiedDate: { field: 'modifiedDate', sortable: true, filter: true, isGlobalSearch: true },
  modifiedBy: { field: 'modifiedBy', sortable: true, filter: true, isGlobalSearch: true },
  plantName: { field: 'plantName', sortable: true, filter: true, isGlobalSearch: true },
}

export function getComplexColumns(
  t,
  {
    dateColumnTemplate,
    culture,
    currencyCode,
    currencySymbol,
    priceDigits,
    isTimeOnShipmentPlannedDate,
  },
) {
  return {
    formattedNumber: { header: t('shipments:shipment.fields.shipment'), hidden: false },
    name: { header: t('shipments:shipment.fields.name'), hidden: false },
    status: {
      header: t('shipments:shipment.fields.status'),
      hidden: false,
      filterElement: {
        type: 'multiselect',
        options: [
          { label: t('shipments:status.1'), value: '1' },
          { label: t('shipments:status.4'), value: '4' },
          { label: t('shipments:status.5'), value: '5' },
        ],
        itemTemplate: (option) => {
          return (
            <SmartStatus
              status={option.value}
              dictionaryKey="shipment"
            />
          )
        },
      },
      template: (props) => {
        if (!props.id) {
          return null
        }
        return (
          <SmartStatus
            status={props.status}
            dictionaryKey="shipment"
          />
        )
      },
    },
    customer: { header: t('shipments:shipment.fields.shippingCustomer'), hidden: false },
    plannedDate: getPlannedDateColumn(t, !isTimeOnShipmentPlannedDate, dateColumnTemplate),
    expeditedDate: {
      header: t('shipments:shipment.fields.expeditedDate'),
      hidden: false,
      template: (props) => dateColumnTemplate(props.expeditedDate, true),
    },
    carrier: { header: t('shipments:shipment.fields.carrier'), hidden: false },
    poNumber: { header: t('shipments:shipment.fields.poNumber'), hidden: false },
    address: { header: t('shipments:shipment.fields.address') },
    city: { header: t('shipments:shipment.fields.city'), hidden: false },
    province: { header: t('shipments:shipment.fields.province'), hidden: false },
    country: { header: t('shipments:shipment.fields.country') },
    trailer: { header: t('shipments:shipment.fields.trailer') },
    postalCode: { header: t('shipments:shipment.fields.postalCode') },
    salesOrderNumberAggregate: { header: t('shipments:shipment.fields.salesOrderNumberAggregate') },
    shipper: {
      header: t('shipments:shipment.fields.shipper'),
      template: (props) => {
        if (!props.id) {
          return null
        }
        return props.clientIsShipper ?
          t('shipments:shipment.shipper.shipperCustomer') :
          t('shipments:shipment.shipper.shipperCompany')
      },
    },
    cost: {
      header: t('shipments:shipment.fields.cost'),
      hidden: false,
      template: (props) => {
        if (!props.id) {
          return null
        }
        return `${parseCurrency(
          props.cost,
          currencyCode || undefined,
          currencySymbol || undefined,
          priceDigits,
          culture,
        )}`
      },
    },
    trackingNumber: { header: t('shipments:shipment.fields.trackingNumber') },
    createdDate: {
      header: t('shipments:shipment.fields.createdDate'),
      template: (props) => dateColumnTemplate(props.createdDate),
    },
    createdBy: { header: t('shipments:shipment.fields.createdBy') },
    modifiedDate: {
      header: t('shipments:shipment.fields.modifiedDate'),
      template: (props) => dateColumnTemplate(props.modifiedDate),
    },
    modifiedBy: { header: t('shipments:shipment.fields.modifiedBy') },
    plantName: { header: t('shipments:shipment.fields.plant') },
  }
}
