import { AppDispatch } from 'store'
import { ApiToSlice, BaseEntityApi, GetFields, Modify } from 'types/slices'

import { buildGetUrl, parse } from 'utils/api'
import { isJob, safeFetch, safeFetchJson } from 'utils/safeFetch'
import { convertFromBase } from 'utils/unitConverter'

import { InventoryApi } from 'reducers/inventories/inventoriesSlice'

import { GET_RECEPTIONS, GET_RECEPTIONS_COUNT, DELETE_RECEPTIONS } from './types'

const dataSetName = 'receptionView'
export const receptionItemDataSetName = 'receptionItemView'
const initialState = {
  dataSetName,
  fields: getFields(),
  itemFields: getLineItemFields(),
  receptionsCount: 0,
  receptions: [],
}

export default function receptionsReducer(state = initialState, action: any) {
  const { payload } = action
  switch (action.type) {
  case GET_RECEPTIONS: {
    return {
      ...state,
      receptions: payload,
    }
  }
  case GET_RECEPTIONS_COUNT: {
    return {
      ...state,
      receptionsCount: payload,
    }
  }
  default: {
    return state
  }
  }
}

type MapData = {
  isTimeOnReceptionDate?: boolean
}

type ItemMapData = {
  defaultUnits?: {
    qty: string
    weight: string
    length: string
    surface: string
    volume: string
  }
  isTimeOnReceptionDate?: boolean
}

export type ReceptionApi = Modify<{
  name: string
  invoice_reference: string
  status: 'received' | 'canceled'
  receipt_datetime: Date
  receipt_posting_date: Date
  checklist_id: string
  carrier: string
  vendor_id: string
  vendor_name: string
}, BaseEntityApi>

type Exceptions = {
  name: 'reception'
  invoice_reference: 'reference'
  receipt_datetime: 'receptionDate'
  vendor_name: 'vendor'
}

export type Reception = ApiToSlice<ReceptionApi, Exceptions>

export function getFields(): GetFields<ReceptionApi, Reception, MapData> {
  return {
    'id': { dataSetName, dbField: 'id', type: 'string' },
    'exist': { dataSetName, dbField: 'exist', type: 'boolean' },
    'reception': { dataSetName, dbField: 'name', type: 'string' },
    'reference': { dataSetName, dbField: 'invoice_reference', type: 'string' },
    'status': { dataSetName, dbField: 'status', type: 'status', dictionaryKey: 'reception' },
    'receptionDate': { parse: parseReceptionDate, type: 'timestamp', dataSetName, dbField: 'receipt_datetime' },
    'checklistId': { dataSetName, dbField: 'checklist_id', type: 'string' },
    'carrier': { dataSetName, dbField: 'carrier', type: 'string' },
    'vendorId': { dataSetName, dbField: 'vendor_id', type: 'string' },
    'vendor': { dataSetName, dbField: 'vendor_name', type: 'string' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'date' },
  }
}

export async function fetchReceptionsByIds(ids: any[], mapData?: MapData, data?: Record<string, any>) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson<ReceptionApi>(
    buildGetUrl(`/new_api/receptions/${ids}`, data),
  )

  return isSuccess && !isJob(result) ? result.map((reception) => parseReception(reception, mapData)) : []
}

export function fetchReceptions(data: Record<string, any>, mapData?: MapData) {
  return async function fetchReceptionsThunk(dispatch: AppDispatch) {
    const receptions = await _fetchReceptions(data, mapData)
    dispatch({ type: GET_RECEPTIONS, payload: receptions })
    return receptions
  }
}

export async function _fetchReceptions(data: Record<string, any>, mapData?: MapData) {
  let receptions = []

  try {
    const { isSuccess, result } = await safeFetchJson<ReceptionApi>(buildGetUrl('/new_api/receptions', data))
    if (isSuccess && !isJob(result)) {
      receptions = result.map((reception) => parseReception(reception, mapData))
    }
  } catch (err) {
    console.error(err)
  }

  return receptions
}

export function fetchReceptionsCount(data?: Record<string, any>) {
  return async function fetchReceptionsCountThunk(dispatch: AppDispatch) {
    const count = await _fetchReceptionsCount(data)
    dispatch({ type: GET_RECEPTIONS_COUNT, payload: count })
    return count
  }
}

export async function _fetchReceptionsCount(data?: Record<string, any>) {
  let count = 0
  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/receptions/count', data))).json()
    if (result.isSuccess) {
      count = +result.result[0].count || 0
    }
  } catch (err) {
    console.error(err)
  }

  return count
}

export async function fetchNextNumber() {
  let nextNumber = ''

  try {
    const result = await (await safeFetch('/new_api/receptions/next-number')).json()
    if (result.isSuccess) {
      nextNumber = result.result
    }
  } catch (err) {
    console.error(err)
  }

  return nextNumber
}

export function deleteReceptions(ids: string[]) {
  return async function deleteReceptionsThunk(dispatch: AppDispatch) {
    try {
      const result = await (await safeFetch(`/new_api/receptions/${ids}`, { method: 'DELETE' })).json()
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: DELETE_RECEPTIONS, payload: !!result.isSuccess, error })
      return result
    } catch (error) {
      dispatch({ type: DELETE_RECEPTIONS, payload: false, error })
      console.error(error)
    }
  }
}

export function parseReception(reception: ReceptionApi, mapData?: MapData): Reception {
  const options = {
    defaultData: getDefaultReception(),
    fields: initialState.fields,
    dataSetName,
    isTimeOnReceptionDate: mapData?.isTimeOnReceptionDate,
  }

  return parse(reception, options)
}

export function getReceptionTitle(reception) {
  return reception.reception
}

function getDefaultReceptionItem() {
  return parse({}, { fields: initialState.fields })
}

export type ReceptionItemApi = Modify<{
  dimension: string
  reception_id: string
  reception_name: string
  reception_invoice_reference: string
  reception_status: ReceptionApi['status']
  reception_receipt_datetime: Date
  reception_receipt_posting_date: Date
  reception_carrier: string
  vendor_display_name: string
  inventory_id: InventoryApi['id']
  inventory_invoice_title: InventoryApi['invoice_title']
  inventory_invoice_date: InventoryApi['invoice_date']
  inventory_tag: InventoryApi['tag']
  inventory_status: InventoryApi['status']
  inventory_manufacturer: InventoryApi['manufacturer']
  inventory_part_number: InventoryApi['part_number']
  inventory_supplier_part_number: InventoryApi['supplier_part_number']
  inventory_revision: InventoryApi['revision']
  inventory_serial_number: InventoryApi['serial_number']
  location_title: string
  template_sku: string
  template_title: string
  measure: number
  measure_unit: string
  project_title: string
}, BaseEntityApi>

type ReceptionItemExceptions = {
  reception_invoice_reference: 'receptionReference'
  reception_receipt_datetime: 'receptionDate'
  vendor_display_name: 'vendor'
}

export type ReceptionItem = ApiToSlice<
  Modify<ReceptionItemApi, {converted_measure: string | number}>,
  ReceptionItemExceptions
>

export function getLineItemFields(): GetFields<ReceptionItemApi, ReceptionItem, ItemMapData> {
  const dataSetName = receptionItemDataSetName
  return {
    'id': { dataSetName, dbField: 'id', type: 'id' },
    'dimension': { dataSetName, dbField: 'dimension', type: 'string' },
    'receptionId': { dataSetName, dbField: 'reception_id', type: 'id' },
    'exist': { dataSetName, dbField: 'exist', type: 'boolean' },
    'createdDate': { dataSetName, dbField: 'created_date', type: 'date' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'date' },
    'createdBy': { dataSetName, dbField: 'created_by', type: 'string' },
    'createdById': { dataSetName, dbField: 'created_by_id', type: 'id', relationEntity: 'resources' },
    'modifiedBy': { dataSetName, dbField: 'modified_by', type: 'string' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id', type: 'id', relationEntity: 'resources' },
    'receptionName': { dataSetName, dbField: 'reception_name', type: 'string' },
    'receptionReference': { dataSetName, dbField: 'reception_invoice_reference', type: 'string' },
    'receptionStatus': { dataSetName, dbField: 'reception_status', type: 'string' },
    'receptionDate': {
      parse: parseReceptionDateFromLineItem,
      type: 'date',
      dataSetName,
      dbField: 'reception_receipt_datetime',
    },
    'receptionCarrier': { dataSetName, dbField: 'reception_carrier', type: 'string' },
    'vendor': { dataSetName, dbField: 'vendor_display_name', type: 'string' },
    'inventoryId': { dataSetName, dbField: 'inventory_id', type: 'id' },
    'inventoryInvoiceTitle': { dataSetName, dbField: 'inventory_invoice_title', type: 'string' },
    'inventoryInvoiceDate': { dataSetName, dbField: 'inventory_invoice_date', type: 'date', isTimezoned: false },
    'inventoryTag': { dataSetName, dbField: 'inventory_tag', type: 'string' },
    'inventoryStatus': { dataSetName, dbField: 'inventory_status', type: 'string' },
    'inventoryManufacturer': { dataSetName, dbField: 'inventory_manufacturer', type: 'string' },
    'inventoryPartNumber': { dataSetName, dbField: 'inventory_part_number', type: 'string' },
    'inventorySupplierPartNumber': { dataSetName, dbField: 'inventory_supplier_part_number', type: 'string' },
    'inventoryRevision': { dataSetName, dbField: 'inventory_revision', type: 'string' },
    'inventorySerialNumber': { dataSetName, dbField: 'inventory_serial_number', type: 'string' },
    'locationTitle': { dataSetName, dbField: 'location_title', type: 'string' },
    'templateSku': { dataSetName, dbField: 'template_sku', type: 'string' },
    'templateTitle': { dataSetName, dbField: 'template_title', type: 'string' },
    'measure': { dataSetName, dbField: 'measure', type: 'measure' },
    'measureUnit': { dataSetName, dbField: 'measure_unit', type: 'string' },
    'convertedMeasure': {
      dataSetName,
      dbField: 'measure',
      parseWithParsedData: getConvertedMeasure,
      type: 'measure',
    },
    'projectTitle': { dataSetName, dbField: 'project_title', type: 'string' },
  }
}

export function getReceptionItemTitle(receptionItem: ReceptionItem) {
  return receptionItem.receptionName
}

function getMeasureUnit(receptionItem: ReceptionItem, options: { defaultUnits?: any } = {}): string {
  const dimension = receptionItem.dimension
  const unit = receptionItem.measureUnit

  return unit || options.defaultUnits?.[dimension]
}

function getConvertedMeasure(receptionItem: ReceptionItem, options = {}) {
  return convertFromBase(
    receptionItem.dimension?.toString(),
    +receptionItem.measure,
    getMeasureUnit(receptionItem, options),
    false,
  )
}

export function parseReceptionItem(
  item: ReceptionItemApi,
  mapData: ItemMapData,
  onlyParseAvailableFields = false,
): ReceptionItem {
  const options = {
    defaultData: getDefaultReceptionItem(),
    fields: initialState.itemFields,
    dataSetName: receptionItemDataSetName,
    defaultUnits: mapData?.defaultUnits,
    onlyParseAvailableFields,
  }

  return parse(item, options)
}

function parseReceptionDate(reception: ReceptionApi, options: MapData) {
  return options?.isTimeOnReceptionDate ? reception.receipt_datetime : reception.receipt_posting_date
}

function parseReceptionDateFromLineItem(receptionLineItem: ReceptionItemApi, options: ItemMapData) {
  return options.isTimeOnReceptionDate ?
    receptionLineItem.reception_receipt_datetime :
    receptionLineItem.reception_receipt_posting_date
}

function getDefaultReception(): Reception {
  return parse({}, { fields: initialState.fields })
}
