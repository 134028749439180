import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  getFulfillmentStatusColumn,
} from 'components/alix-front/legacy-smart-grid/columns/sales-orders'
import {
  getPrefixedColumnsHelpers,
  baseComplexColumnHeader,
} from 'components/alix-front/legacy-smart-grid/columns/utils'
import {
  getSalesOrderItemTotalPrice,
} from 'components/alix-front/sales-orders/sales-order/overview/DataHandlerUnitPriceTemplate'

import { getDimensionIcon } from 'utils/iconMapper'
import { parseCurrency, parseNumber, parsePercentage } from 'utils/numberParser'
import { convertFromBase, convertFromDollarPerBase } from 'utils/unitConverter'

import { _prefixSalesOrderItem } from 'reducers/sales-orders/salesOrderItemsSlice'
import { parseSalesOrderItemNetWeight } from 'reducers/sales-orders/shared'

import { getComplexColumns as soGetComplexColumns, columnDict as salesOrderColumnDict } from './sales-orders'

const defaultOptions = {
  isColumnToggle: true,
  sortable: true,
  filter: true,
  isGlobalSearch: false,
}

const _invisibleOptions = { isGlobalSearch: true, isViewHidden: true, hidden: true }

export function getPrefixedComplexColumns({
  prefix = undefined,
  addSalesOrderFields = true,
  forceIsViewHiddenFields = [],
  forceColumnOptions = undefined,
  prefixHeaders = false,
  overrideVisibleFields = [],
} = {},
) {
  const { prefixColumnDict, prefixComplexColumns, prefixField } = getPrefixedColumnsHelpers({
    prefix,
    formatHeader: baseComplexColumnHeader('salesOrders:itemsSmartGridHeaderWithPrefix'),
    forceColumnOptions,
    prefixHeaders,
    overrideVisibleFields,
    forceIsViewHiddenFields,
  })

  const _measure = (value, unit, dimension, { measureDigits, culture }) => {
    const displayMeasure = parseNumber(
      convertFromBase(dimension, +value || 0, unit),
      measureDigits,
      culture,
    )

    return `${displayMeasure} ${unit}`
  }

  const _currency = (value, options) => {
    return parseCurrency(
      value,
      options.baseCurrency.code,
      options.baseCurrency.symbol,
      options.measureDigits.minimumFractionDigits,
      options.culture,
    )
  }

  const _currencyPerBase = (value, dimension, unit, options) => {
    const convertFromBase = convertFromDollarPerBase(
      dimension, +value || 0,
      unit)

    return _currency(
      convertFromBase,
      options,
    )
  }

  const _percentage = (value, options) => {
    return parsePercentage(value/100, options.measureDigits, options.culture)
  }

  const soVisibleColumns = [
    'name',
    'salesOrderDate',
    'noReferenceNumber',
    'customer',
    'status',
  ]

  const soColumnDict = Object.keys(salesOrderColumnDict)
    .reduce((acc, key) => {
      return {
        ...acc,
        [key]: {
          ...salesOrderColumnDict[key],
          hidden: !soVisibleColumns.includes(key),
          isColumnToggle: key !== 'name',
          isDefaultSort: key === 'name',
        },
      }
    }, {})

  const columnDict = {
    'description': { field: 'description', ..._invisibleOptions },
    'secondaryName': { field: 'secondaryName', ..._invisibleOptions },
    'primaryNotes': { field: 'primaryNotes', ..._invisibleOptions },
    'secondaryNotes': { field: 'secondaryNotes', ..._invisibleOptions },

    'displayTitle': {
      field: `displayTitle`,
      isGlobalSearch: false,
      isColumnToggle: false,
      translationKey: 'salesOrderItem',
      hidden: true,
    },
    'name': {
      field: 'name',
      ...defaultOptions,
      isGlobalSearch: true,
      isColumnToggle: false,
      sortable: false,
      filter: false,
    },
    'notes': {
      field: 'notes',
      ...defaultOptions,
      isGlobalSearch: true,
      sortable: false,
      filter: false,
    },
    'partNumber': {
      field: 'partNumber',
      ...defaultOptions,
      isGlobalSearch: true,
      hidden: true,
    },
    'sku': {
      field: 'sku',
      ...defaultOptions,
      isGlobalSearch: true,
    },
    'manufacturingOrderFormattedNumber': {
      field: 'manufacturingOrderFormattedNumber',
      ...defaultOptions,
      isGlobalSearch: true,
    },
    'templateTitle': {
      field: 'templateTitle',
      ...defaultOptions,
      isGlobalSearch: true,
    },
    'dimension': {
      field: 'dimension',
      ...defaultOptions,
      hidden: true,
      filterElement: {
        type: 'multiselect',
      },
    },
    'contractNumber': { field: 'contractNumber', ...defaultOptions },
    'promisedDate': { field: 'promisedDate', ...defaultOptions },

    'quantity': { field: 'quantity', ...defaultOptions, translationKey: 'ordered' },
    'reserved': { field: 'reserved', ...defaultOptions },
    'shipped': { field: 'shipped', ...defaultOptions },
    'invoiced': { field: 'invoiced', ...defaultOptions },
    'netWeight': { field: 'netWeight', ...defaultOptions, hidden: true },

    'unitPrice': { field: 'unitPrice', ...defaultOptions },
    'discount': { field: 'discount', ...defaultOptions, filter: false, hidden: true },
    'discounted': {
      field: 'discounted',
      ...defaultOptions,
      sortable: false,
      filter: false,
    },
    'totalPrice': { field: 'totalPrice', ...defaultOptions, translationKey: 'total' },
    'zohoTaxId': { field: 'zohoTaxId', ...defaultOptions, translationKey: 'tax' },
    'fulfillmentStatus': getFulfillmentStatusColumn(),
  }

  const prefixedColumnDict = {
    ...prefixColumnDict(columnDict),
    ...(addSalesOrderFields ? soColumnDict : {}),
  }

  const addDefaultProps = (key) => prefixedColumnDict[prefixField(key)]

  /**
     * @typedef {Object} ComplexColumnsOptions
     * @property {string} [culture]
     * @property {string} [language]
     * @property {string} [primaryLanguage]
     * @property {string} [secondaryLanguage]
     * @property {any} [measureDigits]
     * @property {any} [priceDigits]
     * @property {any} [fields]
     */
  function getComplexColumns(t, {
    measureDigits,
    culture,
    dateColumnTemplate,
    language,
    baseCurrency,
    taxes,
    priceMaxDigits,
    measureMaxDigits,
    simpleDict,
  }) {
    const getCurrency = (props) => {
      const _code = props[prefixField('currencyCode')]
      const _symbol = props[prefixField('currencySymbol')]

      return {
        code: _code || baseCurrency.code,
        symbol: _symbol || baseCurrency.symbol,
      }
    }

    const complexColumns = {
      'description': addDefaultProps(`description`),
      'secondaryName': addDefaultProps(`secondaryName`),
      'primaryNotes': addDefaultProps(`primaryNotes`),
      'secondaryNotes': addDefaultProps(`secondaryNotes`),
      'displayTitle': {
        header: t('salesOrders:salesOrder.item.salesOrderItem'),
        ...addDefaultProps(`displayTitle`),
      },
      'name': {
        header: t('salesOrders:salesOrder.item.overwrittenName'),
        ...addDefaultProps('name'),
      },
      'notes': {
        header: t('salesOrders:salesOrder.item.overwrittenNotes'),
        ...addDefaultProps('notes'),
      },
      'partNumber': {
        header: t('items:item.fields.partNumber'),
        ...addDefaultProps('partNumber'),
      },
      'sku': {
        header: t('items:item.fields.sku.label'),
        ...addDefaultProps('sku'),
      },
      'manufacturingOrderFormattedNumber': {
        header: t('salesOrders:salesOrder.item.itemNumber'),
        ...addDefaultProps('manufacturingOrderFormattedNumber'),
      },
      'templateTitle': {
        header: t('items:item.fields.item'),
        template: (props) => {
          const iconData = getDimensionIcon(props[prefixField('dimension')])
          return (
            <div className="a-flex">
              <div className="a-item-grid-dimension-icon">
                <FontAwesomeIcon
                  icon={['fas', iconData.icon]}
                  transform={iconData.transform}
                />
              </div>
              <div
                className="a-smart-grid-default-template"
                title={props[prefixField('templateTitle')]}
              >
                {props[prefixField('templateTitle')]}
              </div>
            </div>
          )
        },
      },
      'dimension': {
        header: t('items:item.fields.dimension'),
        filterElement: {
          ...simpleDict[prefixField('dimension')].filterElement,
          options: [
            { label: t('common:dimension.qty'), value: 'qty' },
            { label: t('common:dimension.weight'), value: 'weight' },
            { label: t('common:dimension.length'), value: 'length' },
            { label: t('common:dimension.surface'), value: 'surface' },
            { label: t('common:dimension.volume'), value: 'volume' },
          ],
          itemTemplate: (option) => {
            const iconData = getDimensionIcon(option.value)
            return (
              <div className="a-flex">
                <div className="a-item-grid-dimension-icon">
                  <FontAwesomeIcon
                    icon={['fas', iconData.icon]}
                    transform={iconData.transform}
                  />
                </div>
                <div>
                  {option.label}
                </div>
              </div>
            )
          },
        },
        template: (props) => {
          const iconData = getDimensionIcon(props[prefixField('dimension')])
          return (
            <div className="a-flex">
              <div className="a-item-grid-dimension-icon">
                <FontAwesomeIcon
                  icon={['fas', iconData.icon]}
                  transform={iconData.transform}
                />
              </div>
              <div
                className="a-smart-grid-default-template"
                title={t(`common:dimension.${props[prefixField('dimension')]}`)}
              >
                {t(`common:dimension.${props[prefixField('dimension')]}`)}
              </div>
            </div>
          )
        },
      },
      'contractNumber': {
        header: t('salesOrders:salesOrder.item.contractNumber'),
        ...addDefaultProps('contractNumber'),
      },
      'promisedDate': {
        header: t('salesOrders:salesOrder.item.promisedDate'),
        ...addDefaultProps('promisedDate'),
        template: (props) => dateColumnTemplate(props[prefixField('promisedDate')], true),
      },
      'quantity': {
        header: t('salesOrders:salesOrder.item.ordered'),
        ...addDefaultProps('quantity'),
        parseValue: (props) => _measure(
          props[prefixField('quantity')],
          props[prefixField('unit')],
          props[prefixField('dimension')],
          { measureDigits, culture },
        ),
      },
      'reserved': {
        header: t('salesOrders:salesOrder.item.reserved'),
        ...addDefaultProps('reserved'),
        parseValue: (props) => _measure(
          props[prefixField('reserved')],
          props[prefixField('unit')],
          props[prefixField('dimension')],
          { measureDigits, culture },
        ),
      },
      'shipped': {
        header: t('salesOrders:salesOrder.item.shipped'),
        ...addDefaultProps('shipped'),
        parseValue: (props) => _measure(
          props[prefixField('shipped')],
          props[prefixField('unit')],
          props[prefixField('dimension')],
          { measureDigits, culture },
        ),
      },
      'invoiced': {
        header: t('salesOrders:salesOrder.item.invoiced'),
        ...addDefaultProps('invoiced'),
        parseValue: (props) => _measure(
          props[prefixField('invoiced')],
          props[prefixField('unit')],
          props[prefixField('dimension')],
          { measureDigits, culture },
        ),
      },
      'netWeight': {
        header: t('salesOrders:salesOrder.item.netWeight'),
        ...addDefaultProps('netWeight'),
        parseValue: (props) => {
          return parseSalesOrderItemNetWeight(
            props[prefixField('quantity')],
            {
              dimension: props[prefixField('dimension')],
              unitWeight: props[prefixField('unitWeight')],
              weightUnit: props[prefixField('weightUnit')],
            },
          )
        },
      },
      'unitPrice': {
        header: t('salesOrders:salesOrder.item.unitPrice'),
        ...addDefaultProps('unitPrice'),
        sortable: false,
        filter: false,
        parseValue: (props) => {
          return _currencyPerBase(
            props[prefixField('unitPrice')],
            props[prefixField('dimension')],
            props[prefixField('unit')],
            { baseCurrency: getCurrency(props), measureDigits, culture },
          )
        },
      },
      'discount': {
        header: t('salesOrders:salesOrder.item.discount'),
        ...addDefaultProps('discount'),
        parseValue: (props) => _percentage(
          props[prefixField('discount')],
          { measureDigits, culture },
        ),
      },
      'discounted': {
        header: t('salesOrders:salesOrder.item.discounted'),
        ...addDefaultProps('discounted'),
        parseValue: (props) => _currencyPerBase(
          props[prefixField('discounted')],
          props[prefixField('dimension')],
          props[prefixField('unit')],
          { baseCurrency: getCurrency(props), measureDigits, culture },
        ),
      },
      'totalPrice': {
        header: t('salesOrders:salesOrder.item.total'),
        ...addDefaultProps('totalPrice'),
        sortable: false,
        filter: false,
        parseValue: (props) => {
          return getSalesOrderItemTotalPrice(
            {
              quantity: props[prefixField('quantity')],
              discount: props[prefixField('discount')],
              unitPrice: props[prefixField('unitPrice')],
              plannedUnitCost: props[prefixField('plannedUnitCost')],
              dimension: props[prefixField('dimension')],
              unit: props[prefixField('unit')],
            },
            {
              exchangeRate: props.exchangeRate,
              culture,
              currency: getCurrency(props),
              priceMaxDigits,
              measureMaxDigits,
            },
          )
        },
      },
      'zohoTaxId': {
        header: t('salesOrders:salesOrder.item.tax'),
        ...addDefaultProps('zohoTaxId'),
        sortable: false,
        filter: false,
        parseValue: (props) => {
          const taxId = props[prefixField('zohoTaxId')]

          return taxes.find((t) => t.tax_id === taxId)?.displayName ?? taxId
        },
      },
      'fulfillmentStatus': getFulfillmentStatusColumn(t, prefixField, 'quantity', 'fulfillmentStatus'),
    }

    const prefixedComplexColumns = prefixComplexColumns(complexColumns, prefixedColumnDict, { t })

    /**
     * (odeschenes): For my usecase, the prefix and the salesOrderFields we're mutualy exclusive.
     * But if the usecase changes, we need to change this.
     */
    if (!addSalesOrderFields) {
      return prefixedComplexColumns
    }

    const _complexSalesOrderColumns = soGetComplexColumns(t, { dateColumnTemplate, language })

    return {
      ..._complexSalesOrderColumns,
      ...prefixedComplexColumns,
    }
  }

  return {
    getComplexColumns,
    columnDict: prefixedColumnDict,
    prefixField,
  }
}

const { columnDict, getComplexColumns, prefixField } = getPrefixedComplexColumns({
  prefix: `${_prefixSalesOrderItem}.`,
  addSalesOrderFields: true,
})

export {
  columnDict,
  getComplexColumns,
  prefixField,
}
